import React from 'react'
import { Route, Routes } from "react-router-dom";
import ClientDashboard from '../ClientComponents/ClientDashboard';

function Clientroute({ changeTheme }) {
  return (
    <Routes>
      <Route
        path='/'
        exact
        element={<ClientDashboard changeTheme={changeTheme} />}
      />
      <Route
        path='/client/:clientComponent'
        exact
        element={<ClientDashboard changeTheme={changeTheme} />}
      />
      <Route
        path='/client/:clientComponent/:Status/:ClientInfo'
        exact
        element={<ClientDashboard changeTheme={changeTheme} />}
      />
      <Route
        path='/client/:clientComponent/:subcomponent/:username/:clientinfo'
        exact
        element={<ClientDashboard changeTheme={changeTheme} />}
      />
    </Routes>
  )
}

export default Clientroute