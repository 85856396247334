import React from 'react'
import { deactivateVendorUser } from '../../redux/actions/UserManagementAction'
import { useDispatch, useSelector } from 'react-redux'
import ButtonSpinner from '../ReusableComponents/ButtonSpinner'

const DeactivateUserModal = ({ vendorId, active, handleClose }) => {
  const dispatch = useDispatch()
  const deactivateVendorUserLoading = useSelector(state => state.deactivateVendorUser?.loading)
  const handleDeactivateVendorUser = () => {
    const data = {
      "vendor_id": vendorId,
      "vendor_activation_status": active === "Active" ? false : true
    }

    const successFn = (response) => {
      console.log(response.data)
      handleClose()

    }
    const failureFn = (error) => {
      handleClose()
      console.log(error)
    }
    dispatch(deactivateVendorUser({ data, successFn, failureFn }))
  }
  return (
    <main className='AddUserModal'>
      <section className='Modal'>
        <h1 className='Header'>{active === "Deactivated" ? 'Activate User' : 'Deactivate User'}</h1>
        <span className='Caption'>
          {active === "Deactivated" ? "Are you sure want to activate this user?" : "Are you sure? Want to de-activate this user?"}
        </span>
        <div className='ButtonContainer'>
          <button className='Yes CenterDiv' onClick={() => handleDeactivateVendorUser()}>{deactivateVendorUserLoading ? <ButtonSpinner/> : 'Yes'}</button>
          <button onClick={handleClose}>No</button>
        </div>
      </section>
    </main>
  )
}

export default DeactivateUserModal