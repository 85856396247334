import { createSlice } from "@reduxjs/toolkit";
import { deactivateVendorUser, registerVendorUser, updateVendorUser, vendorUserList, viewVendorUser, accessPagesList, subuserSetPassword } from "../actions/UserManagementAction";

const generateExtraReducers = (builder, asyncThunk) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // Call the success callback if provided
      if (typeof action.meta.arg.successFn === "function") {
        action.meta.arg.successFn(action.payload);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      // Call the failure callback if provided
      if (typeof action.meta.arg.failureFn === "function") {
        action.meta.arg.failureFn(action.error.message);
      }
    });
};

const createAsyncSlice = (name, asyncThunk) => createSlice({
  name,
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => generateExtraReducers(builder, asyncThunk)
});



const vendorUserListSlice = createAsyncSlice("vendorUserList", vendorUserList);
const registerVendorUserSlice = createAsyncSlice("registerVendorUser", registerVendorUser);
const viewVendorUserSlice = createAsyncSlice("viewVendorUser", viewVendorUser);
const updateVendorUserSlice = createAsyncSlice("updateVendorUser", updateVendorUser);
const deactivateVendorUserSlice = createAsyncSlice("deactivateVendorUser", deactivateVendorUser);
const accessPagesListSlice = createAsyncSlice("accessPagesList", accessPagesList);
const subuserSetPasswordSlice = createAsyncSlice("subuserSetPassword", subuserSetPassword);




export const reducers = {
  vendorUserList: vendorUserListSlice.reducer,
  registerVendorUser: registerVendorUserSlice.reducer,
  viewVendorUser: viewVendorUserSlice.reducer,
  updateVendorUser: updateVendorUserSlice.reducer,
  deactivateVendorUser: deactivateVendorUserSlice.reducer,
  accessPagesList: accessPagesListSlice.reducer,
  subuserSetPassword: subuserSetPasswordSlice.reducer,

};