import { createSlice } from "@reduxjs/toolkit";
import {adminInsufficientCandidateList, vendorFetchInsufficientCheckwise, addCompanyInsufficientEducationInfo,
  addCompanyInsufficientEmpInfo, vendorAddInsufficientOtherCheck, vendorAddReferenceInsufficientChecks
 } from "../actions/CompanyInsufficiencyAction";

const generateExtraReducers = (builder, asyncThunk) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // Call the success callback if provided
      if (typeof action.meta.arg.successFn === "function") {
        action.meta.arg.successFn(action.payload);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      // Call the failure callback if provided
      if (typeof action.meta.arg.failureFn === "function") {
        action.meta.arg.failureFn(action.error.message);
      }
    });
};

const createAsyncSlice = (name, asyncThunk) => createSlice({
  name,
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => generateExtraReducers(builder, asyncThunk)
});



const adminInsufficientCandidateListSlice = createAsyncSlice("adminInsufficientCandidateList", adminInsufficientCandidateList);
const vendorFetchInsufficientCheckwiseSlice = createAsyncSlice("vendorFetchInsufficientCheckwise", vendorFetchInsufficientCheckwise);
const addCompanyInsufficientEducationInfoSlice = createAsyncSlice("addCompanyInsufficientEducationInfo", addCompanyInsufficientEducationInfo);
const addCompanyInsufficientEmpInfoSlice = createAsyncSlice("addCompanyInsufficientEmpInfo", addCompanyInsufficientEmpInfo);
const vendorAddInsufficientOtherCheckSlice = createAsyncSlice("vendorAddInsufficientOtherCheck", vendorAddInsufficientOtherCheck);
const vendorAddReferenceInsufficientChecksSlice = createAsyncSlice("vendorAddReferenceInsufficientChecks", vendorAddReferenceInsufficientChecks);

export const reducers = {
  adminInsufficientCandidateList: adminInsufficientCandidateListSlice.reducer,
  vendorFetchInsufficientCheckwise: vendorFetchInsufficientCheckwiseSlice.reducer,
  addCompanyInsufficientEducationInfo: addCompanyInsufficientEducationInfoSlice.reducer,
  addCompanyInsufficientEmpInfo: addCompanyInsufficientEmpInfoSlice.reducer,
  vendorAddInsufficientOtherCheck: vendorAddInsufficientOtherCheckSlice.reducer,
  vendorAddReferenceInsufficientChecks: vendorAddReferenceInsufficientChecksSlice.reducer,
};