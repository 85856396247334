import React, { useState } from "react";
import "./LicenceVerify.scss";
import logo from "../../../assets/images/Valiantt_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { licenseVerification } from "../../../redux/actions/LoginAction";
import { useParams, useNavigate } from "react-router-dom";
import CustomAlert from "../../ReusableComponents/CustomAlert";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";

function LicenceVerify() {
  const licenseVerificationLoading = useSelector(
    (state) => state.licenseVerification?.loading
  );
  const [userData, setUserData] = useState({
    'username': "",
    'licenseKey': ""
  });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { component } = useParams();
  const [customAlert, setCustomAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    message: "",
    handleClose: () => setCustomAlert(false),
  });
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    if (!userData.username) {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        message: "Please enter username",
        type: "Failed",
      });
      return;
    }
    else if (!userData.licenseKey) {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        message: "Please enter license key",
        type: "Failed",
      });
      return;
    }

    const data = {
      'username': userData?.username,
      'licenseKey': userData?.licenseKey,
    };
    const successFn = (response) => {
      console.log("success", response);
      navigate(`/set-password/${userData.username}`)
      setUserData({
        'username': "",
        'licenseKey': ""
      })
    };
    const failureFn = (response) => {
      setCustomAlert(true);
      setAlertData({ ...alertData, message: response, type: "Failed" });
    };

    dispatch(licenseVerification({data, successFn, failureFn}));
  };

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      handleSubmit(e);
    }
  }

  return (
    <div
      className={`LicenceVerify ${component === "license-verify" ? "active" : "next"}`}
    >
      {customAlert && <CustomAlert {...alertData} />}
        <>
          <div className="top">
            <img src={logo} alt="Valiant Logo" />
            <h1>Unlock Your Account</h1>
            <p>Enter your license key to unlock your Valiantt Info account.</p>
          </div>
          <div className="bottom">
            <input
              className="EmailInput"
              name="username"
              type="text"
              placeholder="Enter username"
              id="username"
              onKeyDown={handleKeyDown}
              value={userData.username}
              onChange={handleChange}
            />
            <input
              className="EmailInput"
              name="licenseKey"
              type="text"
              placeholder="Enter license key"
              id="licenseKey"
              onKeyDown={handleKeyDown}
              value={userData.licenseKey}
              onChange={handleChange}
            />
            <button type="button" onKeyDown={handleKeyDown} onClick={handleSubmit}>
              {licenseVerificationLoading ? <ButtonSpinner size={1}/> : "Verify"}
            </button>
          </div>
        </>
    </div>
  );
}

export default LicenceVerify;
