import React, { useEffect, useState } from 'react'
import './TrunAroundList.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import Lottie from 'react-lottie';
import animationDataLight from '../../assets/json/timer.json'
import animationDataDark from '../../assets/json/timerDark.json'

const TrunAroundList = ({data}) => {
  const [SelectedId, setSelectedId] = useState('')
  const [Data, setData] = useState('')

  useEffect(()=>{
    setData(data);
  },[data])
  const dispatch = useDispatch()
  const navigate = useNavigate()
  
  const handleCandiadteCheckClick = (id) => {
    if(SelectedId === id) {
      setSelectedId('')
    }else setSelectedId(id)
  }


  console.log("Dataa", Data);


  const DarkMode = localStorage.getItem('Theme') === 'true'

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: DarkMode ? animationDataDark : animationDataLight,
    renderer: 'svg'
  }

  return (
    <main className='TrunAroundList'>
      <section className='CandidateList'>
        {Data && Data.map((data, index) => (
          <article
            className={
              `Candidate ${(data?.remaining_day < 10 && data?.remaining_day > 0) ? 'Nearing' :
                data?.remaining_day < 1 ? `Nearing Expired ${DarkMode ? 'Dark' : ''}` : ''}`
            }
            key={index}
          >
            <div className='NameDateCheck'>
              <h3 onClick={() => {navigate(`/company/candidate-details/view/${data.user_name}/${data.client_info}`)}} title={data?.Candidate_Name}>{data?.Candidate_Name}</h3>
              <span className='LastDate'>Last Date: {data?.Last_Date}</span>
              <div className='DaysLeft'>
                <Lottie
                  options={defaultOptions}
                  height={20}
                  width={20}
                />
                <span>{data?.remaining_day}</span>
                <div className='Tooltip'>
                  <b>TAT {data?.remaining_day > 0 ? 'remaining' : 'exceeded'}: </b>
                  {Math.abs(data?.remaining_day)} days
                </div>
              </div>
              <div className='ChekcksCount' onClick={() => {handleCandiadteCheckClick(data?.user_name)}}>
                <div className='Check'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='1rem' height='1rem' viewBox='0 0 25 25' fill='none'>
                    <path d='M12.9631 23C10.8864 23 8.85637 22.3842 7.12965 21.2304C5.40294 20.0767 4.05712 18.4368 3.2624 16.5182C2.46768 14.5996 2.25975 12.4884 2.66489 10.4516C3.07004 8.41476 4.07007 6.54383 5.53852 5.07538C7.00697 3.60693 8.87789 2.6069 10.9147 2.20176C12.9515 1.79661 15.0627 2.00455 16.9813 2.79927C18.8999 3.59399 20.5398 4.9398 21.6936 6.66652C22.8473 8.39323 23.4631 10.4233 23.4631 12.5C23.4631 15.2848 22.3569 17.9555 20.3878 19.9246C18.4186 21.8938 15.7479 23 12.9631 23ZM12.9631 3.5C11.1831 3.5 9.44305 4.02785 7.96301 5.01678C6.48297 6.00571 5.32941 7.41132 4.64822 9.05585C3.96704 10.7004 3.7888 12.51 4.13607 14.2558C4.48334 16.0016 5.34051 17.6053 6.59918 18.864C7.85785 20.1226 9.4615 20.9798 11.2073 21.3271C12.9532 21.6743 14.7628 21.4961 16.4073 20.8149C18.0518 20.1337 19.4574 18.9802 20.4464 17.5001C21.4353 16.0201 21.9631 14.28 21.9631 12.5C21.9631 10.1131 21.0149 7.82387 19.3271 6.13604C17.6393 4.44822 15.3501 3.5 12.9631 3.5ZM11.9581 16.0625L18.7081 10.0625L17.7181 8.93751L11.4631 14.4725L8.24564 11.2175L7.18064 12.2825L10.9306 16.0325C11.0007 16.102 11.0838 16.157 11.1752 16.1943C11.2666 16.2317 11.3644 16.2506 11.4631 16.25C11.6454 16.2497 11.8214 16.1831 11.9581 16.0625Z' fill='#19D122'/>
                  </svg>
                  <span>{data?.completed_count}</span>
                </div>
                <div className='Check'>
                  <svg xmlns='http://www.w3.org/2000/svg' width='1rem' height='1rem' viewBox='0 0 25 25' fill='none'>
                    <path d='M16.4931 8.96945C16.2031 8.67945 15.7231 8.67945 15.4331 8.96945L12.9631 11.4395L10.4931 8.96945C10.2031 8.67945 9.72314 8.67945 9.43314 8.96945C9.14314 9.25945 9.14314 9.73945 9.43314 10.0295L11.9031 12.4995L9.43314 14.9695C9.14314 15.2595 9.14314 15.7395 9.43314 16.0295C9.58314 16.1795 9.77314 16.2495 9.96314 16.2495C10.1531 16.2495 10.3431 16.1795 10.4931 16.0295L12.9631 13.5595L15.4331 16.0295C15.5831 16.1795 15.7731 16.2495 15.9631 16.2495C16.1531 16.2495 16.3431 16.1795 16.4931 16.0295C16.7831 15.7395 16.7831 15.2595 16.4931 14.9695L14.0231 12.4995L16.4931 10.0295C16.7831 9.73945 16.7831 9.25945 16.4931 8.96945Z' fill='#FF5555'/>
                    <path d='M12.9631 2.5C7.44519 2.5 2.96313 6.98205 2.96313 12.5C2.96313 18.0179 7.44519 22.5 12.9631 22.5C18.4811 22.5 22.9631 18.0179 22.9631 12.5C22.9631 6.98205 18.4811 2.5 12.9631 2.5ZM12.9631 20.9615C8.29647 20.9615 4.5016 17.1667 4.5016 12.5C4.5016 7.83333 8.29647 4.03846 12.9631 4.03846C17.6298 4.03846 21.4247 7.83333 21.4247 12.5C21.4247 17.1667 17.6298 20.9615 12.9631 20.9615Z' fill='#FF5555'/>
                  </svg>
                  <span>{data?.pending_count}</span>
                </div>
              </div>
            </div>
            <div className={`ChecksListWrapper ${SelectedId === data?.user_name ? 'Show' : ''}`}>
              <div className='CheckListWrap'>
                <div className='Checklists'>
                  {data?.check_details && Object.entries(data.check_details).map(([key, value], index) => (
                    <span className='Check' key={index}>
                      <span>{key}</span>
                      {value === 'completed' ?
                        <svg xmlns='http://www.w3.org/2000/svg' width='1rem' height='1rem' viewBox='0 0 25 25' fill='none'>
                          <path d='M12.9631 23C10.8864 23 8.85637 22.3842 7.12965 21.2304C5.40294 20.0767 4.05712 18.4368 3.2624 16.5182C2.46768 14.5996 2.25975 12.4884 2.66489 10.4516C3.07004 8.41476 4.07007 6.54383 5.53852 5.07538C7.00697 3.60693 8.87789 2.6069 10.9147 2.20176C12.9515 1.79661 15.0627 2.00455 16.9813 2.79927C18.8999 3.59399 20.5398 4.9398 21.6936 6.66652C22.8473 8.39323 23.4631 10.4233 23.4631 12.5C23.4631 15.2848 22.3569 17.9555 20.3878 19.9246C18.4186 21.8938 15.7479 23 12.9631 23ZM12.9631 3.5C11.1831 3.5 9.44305 4.02785 7.96301 5.01678C6.48297 6.00571 5.32941 7.41132 4.64822 9.05585C3.96704 10.7004 3.7888 12.51 4.13607 14.2558C4.48334 16.0016 5.34051 17.6053 6.59918 18.864C7.85785 20.1226 9.4615 20.9798 11.2073 21.3271C12.9532 21.6743 14.7628 21.4961 16.4073 20.8149C18.0518 20.1337 19.4574 18.9802 20.4464 17.5001C21.4353 16.0201 21.9631 14.28 21.9631 12.5C21.9631 10.1131 21.0149 7.82387 19.3271 6.13604C17.6393 4.44822 15.3501 3.5 12.9631 3.5ZM11.9581 16.0625L18.7081 10.0625L17.7181 8.93751L11.4631 14.4725L8.24564 11.2175L7.18064 12.2825L10.9306 16.0325C11.0007 16.102 11.0838 16.157 11.1752 16.1943C11.2666 16.2317 11.3644 16.2506 11.4631 16.25C11.6454 16.2497 11.8214 16.1831 11.9581 16.0625Z' fill='#19D122'/>
                        </svg>
                        :
                        <svg xmlns='http://www.w3.org/2000/svg' width='1rem' height='1rem' viewBox='0 0 25 25' fill='none'>
                          <path d='M16.4931 8.96945C16.2031 8.67945 15.7231 8.67945 15.4331 8.96945L12.9631 11.4395L10.4931 8.96945C10.2031 8.67945 9.72314 8.67945 9.43314 8.96945C9.14314 9.25945 9.14314 9.73945 9.43314 10.0295L11.9031 12.4995L9.43314 14.9695C9.14314 15.2595 9.14314 15.7395 9.43314 16.0295C9.58314 16.1795 9.77314 16.2495 9.96314 16.2495C10.1531 16.2495 10.3431 16.1795 10.4931 16.0295L12.9631 13.5595L15.4331 16.0295C15.5831 16.1795 15.7731 16.2495 15.9631 16.2495C16.1531 16.2495 16.3431 16.1795 16.4931 16.0295C16.7831 15.7395 16.7831 15.2595 16.4931 14.9695L14.0231 12.4995L16.4931 10.0295C16.7831 9.73945 16.7831 9.25945 16.4931 8.96945Z' fill='#FF5555'/>
                          <path d='M12.9631 2.5C7.44519 2.5 2.96313 6.98205 2.96313 12.5C2.96313 18.0179 7.44519 22.5 12.9631 22.5C18.4811 22.5 22.9631 18.0179 22.9631 12.5C22.9631 6.98205 18.4811 2.5 12.9631 2.5ZM12.9631 20.9615C8.29647 20.9615 4.5016 17.1667 4.5016 12.5C4.5016 7.83333 8.29647 4.03846 12.9631 4.03846C17.6298 4.03846 21.4247 7.83333 21.4247 12.5C21.4247 17.1667 17.6298 20.9615 12.9631 20.9615Z' fill='#FF5555'/>
                        </svg>
                      }
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </article>
        ))}
      </section>

    </main>
  )
}

export default TrunAroundList