import React, { useEffect, useState } from "react";
import { updateVendorUser } from "../../redux/actions/UserManagementAction";
import { useDispatch, useSelector } from "react-redux";
import FullPageLoader from "../Loader/FullPageLoader";
import ButtonSpinner from "../ReusableComponents/ButtonSpinner";

const ViewEditUserModal = ({
  type,
  viewEditdata,
  handleClose,
  handleEditSave,
  vendorId,
}) => {
  const dispatch = useDispatch();
  const Type = type;
  const viewVendorUserLoading = useSelector(
    (state) => state.viewVendorUser?.loading
  );
  const updateVendorUserLoading = useSelector(
    (state) => state.updateVendorUser?.loading
  );
  const [FormData, setFormData] = useState({
    UserName: "",
    EmailId: "",
    PhoneNumber: "",
    Options: {},
  });

  useEffect(() => {
    if (viewEditdata?.length > 0) {
      setFormData((prev) => ({
        ...prev,
        Options: { ...viewEditdata[0]?.access_control_json },
        UserName: viewEditdata[0]?.first_name || "",
        EmailId: viewEditdata[0]?.email_id || "",
        PhoneNumber: viewEditdata[0]?.phone_number || "",
      }));
    }
  }, [viewEditdata]);

  const handleToggleClick = (option, name) => {
    setFormData((prev) => ({
      ...prev,
      Options: {
        ...prev.Options,
        [option]: { name: name, bool_value: !prev.Options[option]?.bool_value },
      },
    }));
  };

  const handleInputChange = (e, id) => {
    let value = e.target.value;
    if (id === "PhoneNumber") {
      value = value?.replace(/[^0-9]/g, "");
    }
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleSaveEditClick = (e) => {
    e.preventDefault();

    if (type === "View") {
      handleEditSave();
    } else if (type === "Edit") {
      updateSingleVendorUser();
    }
  };

  const updateSingleVendorUser = () => {
    if (
      Object.values(FormData.Options).some((item) => item.bool_value === true)
    ) {
      const data = {
        vendor_id: vendorId,
        client_access_control_json: { ...FormData.Options },
      };
      const successFn = (response) => {
        handleClose();
      };

      const failureFn = (error) => {
        console.log(error);
      };
      dispatch(updateVendorUser({ data, successFn, failureFn }));
    } else alert("Select at least one option to proceed");
  };

  return (
    <form
      className='AddUserModal'
      onSubmit={(e) => e.preventDefault()}>
      <section className='Modal'>
        {viewVendorUserLoading ? (
          <div className='LoadCont'>
            <FullPageLoader />
          </div>
        ) : (
          <>
            <header>
              <h1>{Type} User</h1>
              <button onClick={handleClose}>Close</button>
              <button
                className='Save CenterDiv'
                onClick={(e) => {
                  handleSaveEditClick(e);
                }}>
                {updateVendorUserLoading ? (
                  <ButtonSpinner />
                ) : Type === "View" ? (
                  "Edit"
                ) : (
                  "Save"
                )}
              </button>
            </header>
            <article className='InputContainer'>
              <input
                type='text'
                value={FormData?.UserName}
                placeholder='Name'
                onChange={(e) => {
                  handleInputChange(e, "UserName");
                }}
                required
                disabled
              />
              <input
                type='email'
                value={FormData?.EmailId}
                placeholder='Email Id'
                onChange={(e) => {
                  handleInputChange(e, "EmailId");
                }}
                required
                disabled
              />
              <input
                type='text'
                value={FormData?.PhoneNumber}
                placeholder='Phone Number'
                onChange={(e) => {
                  handleInputChange(e, "PhoneNumber");
                }}
                required
                maxLength={10}
                disabled
              />
            </article>
            <h3>Access</h3>
            <article
              className={`ToggleSection ${Type === "View" ? "Opacity" : ""}`}>
              {FormData?.Options &&
                Object.keys(FormData?.Options)?.length > 0 &&
                Object.entries(FormData?.Options).map(([key, value], index) => (
                  <div
                    className='ToggleValue'
                    key={index}>
                    <span>{value?.name}</span>
                    <div
                      className={`Toggle ${
                        FormData.Options[key]?.["bool_value"]
                          ? "Active"
                          : Type === "View"
                          ? "NotAllowed"
                          : ""
                      }`}
                      onClick={() => {
                        if (Type !== "View") {
                          handleToggleClick(key, value?.name);
                        }
                      }}
                    />
                  </div>
                ))}
            </article>
          </>
        )}
      </section>
    </form>
  );
};

export default ViewEditUserModal;
