import React, { useEffect, useState } from "react";
import { ResponsivePie } from "@nivo/pie";

const PieChartComp = ({ handleMouseMove, marginValue, data }) => {
  const [inprogressColor, setInprogressColor] = useState("");
  const [acceptedColor, setAcceptedColor] = useState("");
  const [invitationColor, setInvitationColor] = useState("");
  const [pendingColor, setPendingColor] = useState("");
  const [cancelledColor, setCancelledColor] = useState("");
  const [reinitiateColor, setReinitiateColor] = useState("");
  const [completedColor, setCompletedColor] = useState("");
  const [mode, setMode] = useState(localStorage.getItem("Theme") === "true");

  console.log("Dataaaaaaaaaaaaaaaaaaa", data);

  // Function to update the mode based on localStorage change
  const updateModeFromLocalStorage = () => {
    setMode(localStorage.getItem("Theme") === "true");
  };

  const DarkMode = localStorage.getItem("Theme") === "true" ? true : false;

  useEffect(() => {
    // Set initial mode based on localStorage
    setMode(localStorage.getItem("Theme") === "true");

    window.addEventListener("storage", updateModeFromLocalStorage);

    return () => {
      window.removeEventListener("storage", updateModeFromLocalStorage);
    };
  }, []);
  useEffect(() => {
    console.log("Theme mode updated:", mode);
  }, [mode]);

  var themeElement;

  useEffect(() => {
    // Attempt to find the element with .LightTheme class

    if (DarkMode) {
      themeElement =
        document.querySelector(".DarkTheme") || document.documentElement;
    } else {
      themeElement =
        document.querySelector(".LightTheme") || document.documentElement;
    }

    if (themeElement) {
      const rootStyle = getComputedStyle(themeElement);
      const inProgressColor = rootStyle.getPropertyValue("--Inprogress").trim();
      const acceptedColor = rootStyle.getPropertyValue("--Accepted").trim();
      const invitationColor = rootStyle.getPropertyValue("--Invitation").trim();
      const pendingColor = rootStyle.getPropertyValue("--Pending").trim();
      const cancellColor = rootStyle.getPropertyValue("--Cancelled").trim();
      const reinitiateColor = rootStyle
        .getPropertyValue("--Reinitiated")
        .trim();
      const completeColor = rootStyle.getPropertyValue("--Completed").trim();

      if (inProgressColor) {
        console.log("Inprogress Color:", inProgressColor);
        // Set the color or use it as needed
        setInprogressColor(inProgressColor);
        setAcceptedColor(acceptedColor);
        setInvitationColor(invitationColor);
        setPendingColor(pendingColor);
        setCancelledColor(cancellColor);
        setReinitiateColor(reinitiateColor);
        setCompletedColor(completeColor);
      } else {
        console.warn("CSS variable --Inprogress not found or is empty.");
      }
    } else {
      console.error("No valid element found to retrieve CSS variable.");
    }
  }, [DarkMode]);

  // Log the CSS variable color to the console
  console.log("Inprogress Color:", inprogressColor);
  const transformData = (data) => {
    const colors = {
      invitation: invitationColor,
      accepted: acceptedColor,
      pending: pendingColor,
      cancelled: cancelledColor,
      "re-initiated": reinitiateColor,
      inprogress: inprogressColor,
      completed: completedColor,
    };

    return Object.keys(data)
      .map((key) => {
        // Convert the key to CamelCase for the label
        const label = key
          .split("_")
          .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
          .join(" ");
        return {
          id: key,
          label: label,
          value: data[key],
          color: colors[key] || "#000000", // default color if not found
        };
      })
      .filter((item) => item.id !== "total_invitation_sent" && item.value > 0); // Exclude 'total_invitation_sent' and entries with value 0
  };

  const pieChartData = transformData(data);

  // Check if all values are zero
  // const hasData = pieChartData.length > 0;

  const nivoPieTheme = {
    fontSize: 14,
    textColor: "#333333",
    tooltip: {
      container: {
        background: "#ffffff",
        color: "#333333",
        fontSize: "12px",
        borderRadius: "3px",
        boxShadow: "0 3px 9px rgba(0, 0, 0, 0.15)",
        padding: "5px 10px",
      },
    },
    labels: {
      text: {
        fontSize: 12,
        fontWeight: 500,
        fill: "#333333",
      },
    },
    legends: {
      text: {
        fontSize: 12,
        fill: "#333333",
      },
    },
  };

  return (
    <div style={{width:'10rem', height:'10rem'}}>
      <ResponsivePie
        onMouseMove={handleMouseMove}
        activeOuterRadiusOffset={6}
        isInteractive={true}
        enableArcLabels={false}
        fit={false}
        data={pieChartData}
        enableArcLinkLabels={false}
        // Tooltip customization
        tooltip={({ datum: { id, value, label } }) => (
          <div
          // style={{
          //   padding: "5px 10px",
          //   background: "white",
          //   border: "1px solid #ccc",
          // }}
          >
            {/* <strong>{label}:</strong> {value} */}
          </div>
        )}
        margin={marginValue}
        innerRadius={0.4}
        colors={({ data }) => data.color} // Apply custom colors
        theme={nivoPieTheme} // Apply the custom theme
      />
    </div>
  );
};

export default PieChartComp;
