import React from "react";
import "./Pagination.scss"; // Create a separate SCSS file for pagination styles if needed.

function Pagination({ currentPage, totalPages, onPageChange, dataPerPage, setDataPerPage }) {
  const renderPageNumbers = () => {
    return (
      <>
        {Array.from({ length: totalPages }).map((_, index) => (
          <button
            key={index}
            className={`${currentPage === index + 1 ? "pageButton" : ""}`}
            onClick={() => onPageChange(index + 1)}
          >
            {index + 1}
          </button>
        ))}
      </>
    );
  };

  return (
    <div className="pagination">
      <button
        className="pageButton"
        disabled={currentPage <= 1}
        onClick={() => onPageChange(currentPage - 1)}
      >
        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M3.24219 1.01886L0.759429 3.50162L3.24219 5.98438" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      {renderPageNumbers()}

      <button
        className="pageButton"
        disabled={currentPage >= totalPages}
        onClick={() => onPageChange(currentPage + 1)}
      >
        <svg width="4" height="7" viewBox="0 0 4 7" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M0.757812 1.01886L3.24057 3.50162L0.757812 5.98438" stroke="white" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
      </button>

      <div className="dataPerpage">
        <p>Show</p>
        <select
          id="options"
          value={dataPerPage}
          onChange={(e) => {
            setDataPerPage(parseInt(e.target.value, 10));
            onPageChange(1); // Reset page to 1 when items per page changes
          }}
        >
          <option value="10">10</option>
          <option value="25">25</option>
          <option value="50">50</option>
        </select>
      </div>
    </div>
  );
}

export default Pagination;
