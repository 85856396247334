import React, { useEffect, useState } from 'react'
import './CustomizePack.scss'
import { GetListOfChecks, createCustomPlan } from '../../redux/actions/CompanyPackDetailsAction'
import { getApiClient } from '../../redux/actions/CompanyDashboardAction'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CustomAlert from '../ReusableComponents/CustomAlert'
import FullPageLoader from '../Loader/FullPageLoader'
import ButtonSpinner from '../ReusableComponents/ButtonSpinner'

const CustomizePack = () => {
  const dispatch = useDispatch()
  const clientInfoID = localStorage.getItem("client_info_id");
  const clientID = localStorage.getItem("user_id");
  const [Checks, setChecks] = useState('')
  const [Modal, setModal] = useState(false)
  const CreatePlanLoading = useSelector((state) => state.createCustomPlan?.loading)
  const getApiClientLoading = useSelector((state) => state.getApiClient?.loading)
  const navigate = useNavigate()

  const GetChecksList = () => {
    const master_id = localStorage.getItem('master_id')
    const data = {
      'client_info_id': clientInfoID,
      'master_id': master_id
    }
    const successFn = (response) => {
      console.log('Checks::::', response.data);
      setChecks(response?.data?.[0]?.service_provider_list)
    };
  
    const failureFn = (error) => {
      console.error('Response failed:', error);
      alert(error);
    };
    dispatch(getApiClient({data, successFn, failureFn }));
  };

  useEffect(()=> {
    GetChecksList()
  },[dispatch])

  const [checkedItems, setCheckedItems] = useState([]);
  const [selectedDisplayNames, setSelectedDisplayNames] = useState([]);
  const [price, setPrice] = useState(0);
  const [PackName, setPackName] = useState('')
  const handleInputChange = (e) => {
    setPackName(e.target.value)
  }

  const handleMakePlan = () => {
    if(checkedItems.length > 0) {
      setModal(true)
    }
    else {
      alert('Select checks from the list given before proceeding')
    }
  }


  const [SuccessFailed, setSuccessFailed] = useState('Success')
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState('')
  const [Alert, setAlert] = useState(false)
  const handleSuccessClose = () => {
    setAlert(false)
    setCheckedItems([])
    setSelectedDisplayNames([])
    setPackName('')
    setPrice(0)
    navigate('/company/pack-details')
  }
  const handleFailedClose = () => {
    setAlert(false)
  }
  const handleInformationClose = () => {
    setAlert(false)
  }

  const createPlan = (e) => {

    e.preventDefault()

    if(!PackName.trim()) {
      alert('Please enter valid pack name')
      return
    }

    if(PackName.trim().length > 0) {
      const requestBody = {
        plan_name: PackName,
        list_of_checks: checkedItems,
        client_id: clientID,
        client_info_id: clientInfoID
      };
  
      const successFn = (response) => {
        console.log('Checks::::', response.data);
        setSuccessFailed('Success')
        setSuccessFailedMessage(response.message)
        setAlert(true)
        setModal(false)
      };
    
      const failureFn = (error) => {
        console.error('Response failed:', error);
        // alert(error);
        setSuccessFailed('Failed')
        setSuccessFailedMessage(error)
        setAlert(true)
      };
      dispatch(createCustomPlan({ data: requestBody, successFn, failureFn }));
    }

    else {
      setSuccessFailed('Failed')
      setSuccessFailedMessage('Give a name to the pack')
      setAlert(true)
    }

  };

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;
    const itemIndex = Checks.findIndex(item => item.serviceprovider_id === value);
    if (checked) {
      setCheckedItems([...checkedItems, value]);
      setSelectedDisplayNames([...selectedDisplayNames, Checks[itemIndex].display_name]);
      setPrice((prev) => (prev + (Checks[itemIndex].valiant_rate_tax ? Number(Checks[itemIndex].valiant_rate_tax) : 0)));
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== value));
      setSelectedDisplayNames(selectedDisplayNames.filter((name) => name !== Checks[itemIndex].display_name));
      setPrice((prev) => (Math.abs(prev - (Checks[itemIndex].valiant_rate_tax ? Number(Checks[itemIndex].valiant_rate_tax) : 0))));
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      createPlan(e);
    }
  };

  const [SelectAllChecked, setSelectAllChecked] = useState(false)
  const handleSelectAll = (e) => {
    if(e.target.checked) {
      setCheckedItems(Checks.map(data => data.serviceprovider_id))
      setSelectedDisplayNames(Checks.map(data => data.display_name));
      setPrice(Checks.map(data => data.valiant_rate_tax).reduce((accumulator, currentValue) => accumulator + currentValue, 0))
    } else {
      setCheckedItems([])
      setSelectedDisplayNames([])
      setPrice(0)
    }
  }

  useEffect(()=> {
    if(checkedItems.length !== Checks.length) {
      setSelectAllChecked(false)
    }
  },[checkedItems])

  const handleClear = () => {
    if (Checks) {
      setSelectAllChecked(false)
      setCheckedItems([])
      setSelectedDisplayNames([])
      setPrice(0)
    }
  }

  return (
    <main className='CustomizePack'>
      {getApiClientLoading &&
        <section className='LoaderContainer'>
          <FullPageLoader/>
        </section>
      }
      <header className='Header'>
        <svg xmlns="http://www.w3.org/2000/svg" width="2rem" height="2rem" viewBox="0 0 54 54" fill="none" onClick={()=> {navigate('/company/pack-details')}}>
          <path d="M14.175 28.7996L25.425 40.0496C26.325 40.9496 27.675 40.9496 28.575 40.0496C29.475 39.1496 29.475 37.7996 28.575 36.8996L21.15 29.4746H38.25C39.6 29.4746 40.5 28.5746 40.5 27.2246C40.5 25.8746 39.6 24.9746 38.25 24.9746H21.15L28.575 17.5496C29.475 16.6496 29.475 15.2996 28.575 14.3996C27.675 13.4996 26.325 13.4996 25.425 14.3996L14.175 25.6496C13.275 26.3246 13.275 27.8996 14.175 28.7996Z" fill="#102C97"/>
        </svg>
        <span>Customize Pack</span>
      </header>
      <section className='ContentContainer'>
        <div className='ChecksContainer'>
          <div className='Header'>
            <h3 className='Ellipsis'>Select Checks</h3>
            <div className='SelectAll'>
              <label>
                <input type='checkbox' checked={SelectAllChecked} onChange={(e) => {setSelectAllChecked(!SelectAllChecked); handleSelectAll(e)}}/>
                Select All
              </label>
              <label className='Clear' onClick={() => {handleClear()}}>
                Clear
              </label>
            </div>
          </div>
          <div className='Checks'>
          {Array.isArray(Checks) && Checks.map((data, index) => (
              <label key={index}>
                <input
                  type='checkbox'
                  value={data.serviceprovider_id}
                  checked={checkedItems.includes(String(data.serviceprovider_id))}
                  onChange={handleCheckboxChange}
                />
                <span className={data?.serviceprovider_id === 'CLIENT_1253' ? 'Express' : ''}>{data.display_name}</span>
              </label>
            ))}
          </div>
        </div>
          <div className='Selected'>
            <h3>Selected Checks</h3>
            <div className='SelectedChecks'>
              {(selectedDisplayNames && selectedDisplayNames?.length > 0) ?
                selectedDisplayNames.map((check, index) => (
                  <div className='Check' key={index}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.875rem" height="0.875rem" viewBox="0 0 28 28" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V23C0 25.7614 2.23858 28 5 28H23C25.7614 28 28 25.7614 28 23V5C28 2.23858 25.7614 0 23 0H5ZM11.3877 16.8182L19.812 8L21 9.26276L11.9749 18.6913C11.8062 18.8597 11.5834 19 11.3809 19C11.1784 19 10.9489 18.8597 10.7801 18.6843L7 14.7557L8.20154 13.507L11.3877 16.8182Z" fill="#102C97"/>
                    </svg>
                    <span className='Ellipsis'>{(check === 'Express Court Check (8 Hours)' || check === 'Express Court Check (Results Expected in 8 Hours)') ? <b>{check}</b> : check}</span>
                  </div>
                ))
                :
                (
                  <div className='NoSelection'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="189" height="156" viewBox="0 0 189 156" fill="none">
                      <g opacity="0.5" clipPath="url(#clip0_13793_74990)">
                        <path d="M126.724 93.1864H6.57256C3.3127 93.1864 0.670898 90.5415 0.670898 87.2779V7.0803C0.670898 3.81671 3.3127 1.17188 6.57256 1.17188H126.726C129.985 1.17188 132.627 3.81671 132.627 7.0803V87.2779C132.627 90.5415 129.984 93.1864 126.724 93.1864Z" fill="#EAEBED"/>
                        <path d="M126.724 93.8558H6.57247C2.94894 93.8558 0 90.9053 0 87.2777V7.08001C0 3.45233 2.94894 0.5 6.57247 0.5H126.725C130.349 0.5 133.298 3.45233 133.298 7.08001V87.2777C133.296 90.9053 130.349 93.8558 126.724 93.8558ZM6.57247 1.83939C3.68571 1.83939 1.33786 4.18994 1.33786 7.08001V87.2777C1.33786 90.1659 3.68571 92.5164 6.57059 92.5164H126.724C129.608 92.5164 131.956 90.1659 131.956 87.2777V7.08001C131.956 4.19182 129.608 1.84128 126.724 1.84128H6.57247V1.83939Z" fill="#212121"/>
                        <path d="M8.21359 10.3846C6.66846 10.3846 5.41162 9.12637 5.41162 7.57758C5.41162 6.02878 6.66846 4.77051 8.21359 4.77051C9.75872 4.77051 11.0174 6.02878 11.0174 7.57758C11.0174 9.12637 9.75872 10.3846 8.21359 10.3846ZM8.21359 6.11179C7.4071 6.11179 6.74948 6.77017 6.74948 7.57758C6.74948 8.38499 7.40522 9.04336 8.21359 9.04336C9.02007 9.04336 9.6777 8.38499 9.6777 7.57758C9.6777 6.77017 9.02007 6.11179 8.21359 6.11179Z" fill="#212121"/>
                        <path d="M15.393 10.3846C13.8478 10.3846 12.5891 9.12637 12.5891 7.57758C12.5891 6.02878 13.8459 4.77051 15.393 4.77051C16.94 4.77051 18.1968 6.02878 18.1968 7.57758C18.1968 9.12637 16.94 10.3846 15.393 10.3846ZM15.393 6.11179C14.5865 6.11179 13.9289 6.77017 13.9289 7.57758C13.9289 8.38499 14.5865 9.04336 15.393 9.04336C16.1994 9.04336 16.8571 8.38499 16.8571 7.57758C16.859 6.77017 16.2013 6.11179 15.393 6.11179Z" fill="#212121"/>
                        <path d="M22.5741 10.3846C21.029 10.3846 19.7703 9.12637 19.7703 7.57758C19.7703 6.02878 21.0271 4.77051 22.5741 4.77051C24.1193 4.77051 25.378 6.02878 25.378 7.57758C25.378 9.12637 24.1193 10.3846 22.5741 10.3846ZM22.5741 6.11179C21.7676 6.11179 21.11 6.77017 21.11 7.57758C21.11 8.38499 21.7676 9.04336 22.5741 9.04336C23.3806 9.04336 24.0382 8.38499 24.0382 7.57758C24.0382 6.77017 23.3825 6.11179 22.5741 6.11179Z" fill="#212121"/>
                        <path d="M72.9623 45.9854L60.7068 54.101L61.5001 55.3007C62.951 57.4966 65.9056 58.1003 68.0989 56.6477L75.3177 51.8674L72.9623 45.9854Z" fill="#EAEBED"/>
                        <path d="M65.4815 58.1098C65.1178 58.1098 64.7504 58.0739 64.3867 57.9985C62.9641 57.7079 61.7412 56.8817 60.9422 55.6706L60.1489 54.4708C60.0509 54.3217 60.0151 54.1406 60.0509 53.9671C60.0867 53.7916 60.1904 53.6407 60.3373 53.5407L72.5929 45.4251C72.7606 45.3138 72.9698 45.2837 73.162 45.344C73.3542 45.4044 73.5087 45.5478 73.584 45.7345L75.9375 51.6184C76.0563 51.9127 75.9507 52.2504 75.685 52.4258L68.4663 57.2061C67.5674 57.8004 66.5367 58.1098 65.4815 58.1098ZM61.6337 54.2897L62.0577 54.9311C62.6607 55.8441 63.5821 56.4666 64.6543 56.6855C65.7246 56.9081 66.8175 56.6911 67.7295 56.0875L74.4923 51.609L72.6475 46.9966L61.6337 54.2897Z" fill="#212121"/>
                        <path d="M76.0563 80.3847L68.7754 69.362C66.1882 65.4438 65.2592 60.6578 66.1957 56.0567L69.7364 38.6635L57.7295 36.2129C54.9351 35.6432 53.1299 32.9116 53.7009 30.114L54.0118 28.5859L78.2648 33.5342L90.9838 46.1188L91.3701 71.7993L102.548 88.7172L87.9501 98.3835L76.0563 80.3847Z" fill="#EAEBED"/>
                        <path d="M87.95 99.0572C87.7333 99.0572 87.5204 98.9516 87.3904 98.7573L68.2175 69.7339C65.5117 65.6384 64.562 60.7355 65.5418 55.925L68.9487 39.1901L57.5976 36.8735C54.4451 36.2302 52.4044 33.1402 53.047 29.9841L53.3579 28.4561C53.3937 28.2806 53.4973 28.1297 53.6443 28.0316C53.7913 27.9335 53.9722 27.8977 54.1474 27.9335L78.4003 32.8817C78.5285 32.9081 78.6453 32.9704 78.7376 33.0609L91.4567 45.6475C91.5811 45.7701 91.6527 45.938 91.6546 46.1134L92.0371 71.5959L103.106 88.3515C103.204 88.5006 103.239 88.6798 103.204 88.8552C103.168 89.0306 103.064 89.1816 102.917 89.2797L88.3194 98.9459C88.2044 99.0214 88.0763 99.0572 87.95 99.0572ZM76.6159 80.0152L88.1385 97.4575L101.619 88.5307L90.8104 72.1713C90.7407 72.0637 90.7011 71.9392 90.6992 71.8109L90.3167 46.4058L77.9349 34.1532L54.5337 29.3786L54.3566 30.2501C53.861 32.6818 55.4344 35.0625 57.8633 35.5586L69.8701 38.0092C70.0434 38.045 70.1961 38.1488 70.294 38.2959C70.392 38.4449 70.4278 38.6241 70.392 38.7996L66.8514 56.191C65.9432 60.6506 66.825 65.197 69.3331 68.9926L76.6159 80.0152Z" fill="#212121"/>
                        <path d="M70.5973 43.0456L56.1993 40.1084L55.9129 41.5176C55.3872 44.0983 57.051 46.6148 59.6288 47.1412L68.1082 48.8711L70.5973 43.0456Z" fill="#EAEBED"/>
                        <path d="M68.1082 49.5408C68.063 49.5408 68.0196 49.5371 67.9744 49.5276L59.495 47.7978C56.5611 47.1997 54.6599 44.321 55.2572 41.3838L55.5436 39.9746C55.6171 39.6124 55.9714 39.3784 56.3331 39.452L70.7312 42.3892C70.929 42.4289 71.0967 42.5552 71.1909 42.7345C71.2851 42.9118 71.2927 43.1231 71.2135 43.3098L68.7225 49.1353C68.617 49.3843 68.372 49.5408 68.1082 49.5408ZM56.7213 40.8989L56.5687 41.6516C56.1183 43.8645 57.5504 46.0339 59.7607 46.4848L67.7068 48.1052L69.659 43.5381L56.7213 40.8989Z" fill="#212121"/>
                        <path d="M71.6527 45.4189L57.0701 47.2337L57.2472 48.6618C57.5713 51.2746 59.9493 53.129 62.5591 52.8045L71.1477 51.7367L71.6527 45.4189Z" fill="#EAEBED"/>
                        <path d="M61.9636 53.5099C60.7614 53.5099 59.6007 53.1119 58.6378 52.3611C57.494 51.4688 56.7648 50.1822 56.5858 48.7428L56.4087 47.3148C56.3634 46.9469 56.6235 46.613 56.9909 46.5677L71.5736 44.7529C71.7752 44.7322 71.9731 44.7944 72.1182 44.934C72.2633 45.0736 72.3386 45.2717 72.3217 45.4717L71.8167 51.7876C71.7922 52.1045 71.5472 52.3592 71.2325 52.3988L62.6439 53.4665C62.4159 53.4967 62.1879 53.5099 61.9636 53.5099ZM57.8181 47.8147L57.9124 48.5768C58.048 49.6634 58.5964 50.6312 59.4594 51.3027C60.3224 51.9762 61.3964 52.2724 62.478 52.1366L70.5259 51.1367L70.9216 46.1848L57.8181 47.8147Z" fill="#212121"/>
                        <path d="M108.253 45.8891L57.085 36.6133L48.1628 38.8525L55.2893 44.0007L106.458 53.2765L106.835 52.8501C108.431 51.0448 109.002 48.5509 108.348 46.2286L108.253 45.8891Z" fill="#102C97"/>
                        <path d="M103.987 53.7031C103.942 53.7031 103.895 53.6994 103.848 53.688C103.486 53.6107 103.256 53.2542 103.334 52.8938L104.82 45.9101C104.898 45.5479 105.254 45.3178 105.615 45.3932C105.977 45.4706 106.207 45.8271 106.13 46.1874L104.643 53.1711C104.573 53.4862 104.296 53.7031 103.987 53.7031Z" fill="#212121"/>
                        <path d="M76.697 52.7293L65.1349 35.2285L65.0482 35.2945C62.3009 37.4093 61.4247 41.169 62.9528 44.2836L66.7987 53.4311L76.697 52.7293Z" fill="#EAEBED"/>
                        <path d="M66.7986 54.1029C66.5367 54.1029 66.2899 53.9482 66.1806 53.6916L62.3347 44.5442C60.6897 41.1919 61.6526 37.0643 64.6392 34.7647L64.7259 34.6987C64.8729 34.5855 65.0594 34.5383 65.2441 34.5685C65.4269 34.5987 65.5889 34.7043 65.6925 34.859L77.2547 52.3598C77.4582 52.6692 77.3734 53.0842 77.0662 53.2879C76.7572 53.4917 76.3427 53.4068 76.1392 53.0993L64.9897 36.2229C62.8774 38.1886 62.265 41.3579 63.5539 43.9895L67.4148 53.1729C67.558 53.5143 67.3978 53.9067 67.0568 54.0501C66.9739 54.0859 66.8853 54.1029 66.7986 54.1029Z" fill="#212121"/>
                        <path d="M108.864 142.129L87.9502 98.387L102.548 88.7207L162.288 139.454L108.864 142.129Z" fill="#102C97"/>
                        <path d="M128.387 64.2718C137.634 64.2718 145.129 58.0555 145.129 50.3874C145.129 42.7192 137.634 36.5029 128.387 36.5029C119.141 36.5029 111.645 42.7192 111.645 50.3874C111.645 58.0555 119.141 64.2718 128.387 64.2718Z" fill="#212121"/>
                        <path d="M156.407 141.554L123.394 128.422L122.636 95.1861L153.454 94.4824L156.407 141.554Z" fill="#EAEBED"/>
                        <path d="M156.407 142.223C156.324 142.223 156.239 142.208 156.16 142.176L123.147 129.044C122.896 128.944 122.731 128.705 122.725 128.437L121.967 95.2009C121.964 95.0236 122.03 94.8519 122.152 94.7237C122.275 94.5954 122.444 94.5199 122.621 94.5161L153.439 93.8125C153.801 93.82 154.101 94.0823 154.123 94.4407L157.076 141.512C157.091 141.74 156.987 141.961 156.801 142.097C156.684 142.18 156.546 142.223 156.407 142.223ZM124.053 127.963L155.674 140.54L152.827 95.167L123.32 95.8405L124.053 127.963Z" fill="#212121"/>
                        <path d="M145.879 106.625C152.964 104.367 159.288 99.4035 163.2 92.2462C171.106 77.7845 165.996 60.1649 152.186 52.1814C129.527 39.0836 114.788 57.7276 113.17 62.3174C112.791 63.3927 112.422 64.4321 112.054 65.4357C111.244 67.0467 110.586 68.6955 110.079 70.3669C108.51 73.8135 106.778 76.2848 104.379 77.1073C100.707 78.3637 102.695 106.87 123.987 104.776L145.879 106.625Z" fill="#EAEBED"/>
                        <path d="M145.879 107.294C145.597 107.294 145.333 107.113 145.242 106.828C145.131 106.476 145.325 106.098 145.678 105.987C152.9 103.686 158.915 98.6921 162.614 91.9253C170.289 77.89 165.561 60.6853 151.853 52.7622C143.658 48.0252 135.74 47.0141 128.316 49.7589C125.367 50.8493 116.068 56.1125 113.801 62.5397C113.42 63.6169 113.051 64.6582 112.683 65.6656C112.674 65.6901 112.665 65.7147 112.653 65.7373C111.868 67.2974 111.219 68.9198 110.722 70.561C110.712 70.5893 110.703 70.6176 110.69 70.644C108.821 74.749 106.94 76.9373 104.598 77.739C103.776 78.0201 102.981 82.1402 104.198 87.7543C105.109 91.9555 109.224 105.559 123.923 104.108C124.291 104.074 124.619 104.342 124.654 104.71C124.69 105.078 124.423 105.406 124.053 105.442C118.042 106.032 113.03 104.334 109.154 100.392C106.186 97.3734 103.961 92.9874 102.889 88.0392C101.809 83.057 101.988 77.2165 104.165 76.4713C106.124 75.7997 107.756 73.8435 109.454 70.129C109.966 68.4425 110.635 66.7749 111.44 65.1695C111.802 64.1791 112.165 63.1528 112.538 62.0945C113.66 58.9139 119.305 51.6642 127.852 48.5025C135.666 45.6143 143.967 46.6557 152.522 51.602C166.865 59.893 171.815 77.8881 163.788 92.5705C159.923 99.6391 153.635 104.857 146.083 107.264C146.015 107.285 145.947 107.294 145.879 107.294Z" fill="#212121"/>
                        <path d="M157.349 47.1935C148.001 42.0717 137.112 42.3868 128.118 47.0426C130.916 47.7104 133.654 48.731 136.271 50.1628C154.023 59.8894 159.548 83.9891 148.614 103.991C148.007 105.102 147.355 106.172 146.675 107.21C155.878 105.493 164.359 99.6921 169.298 90.6559C177.873 74.968 172.524 55.509 157.349 47.1935Z" fill="#212121"/>
                        <path d="M126.102 45.0167C126.102 45.0167 128.587 53.4718 135.644 57.5711C139.375 59.7387 132.222 59.0577 132.77 63.5022C136.884 96.7908 139.533 103.086 139.533 103.086C140.155 105.944 142.565 108.064 145.476 108.315L154.244 109.072L156.999 101.017L161.47 66.3074C161.47 66.3092 143.479 40.3212 126.102 45.0167Z" fill="#212121"/>
                        <path d="M146.818 77.5679C144.973 75.6211 142.267 74.9929 139.861 75.7003C142.035 79.9052 142.584 84.7157 141.527 89.2187C143.324 89.2923 145.15 88.6717 146.56 87.3323C149.326 84.7082 149.441 80.3353 146.818 77.5679Z" fill="#EAEBED"/>
                        <path d="M108.907 91.294C108.57 91.294 108.28 91.0393 108.242 90.6979C108.203 90.33 108.466 89.998 108.834 89.9584C111.078 89.7093 112.271 87.5739 112.282 87.5512C112.459 87.2267 112.866 87.106 113.19 87.2852C113.515 87.4626 113.633 87.87 113.456 88.1945C113.394 88.3077 111.909 90.9657 108.981 91.2902C108.956 91.2921 108.932 91.294 108.907 91.294Z" fill="#212121"/>
                        <path d="M121.679 68.5918C121.649 68.5918 121.619 68.5899 121.589 68.5861C121.223 68.5371 120.965 68.1994 121.014 67.8315C121.121 67.0392 120.505 65.5866 119.292 64.5246C118.581 63.902 117.093 62.9399 115.131 63.4795C114.775 63.5776 114.406 63.3682 114.308 63.0116C114.21 62.6551 114.419 62.2853 114.775 62.1872C116.676 61.6628 118.595 62.1344 120.172 63.5153C121.634 64.7943 122.525 66.6431 122.341 68.0108C122.297 68.3465 122.009 68.5918 121.679 68.5918Z" fill="#212121"/>
                        <path d="M115.352 72.661C114.835 73.3911 113.991 73.6797 113.467 73.3081C112.944 72.9364 112.936 72.0441 113.452 71.3141C113.969 70.584 114.813 70.2954 115.337 70.667C115.861 71.0387 115.868 71.931 115.352 72.661Z" fill="#212121"/>
                        <path d="M133.886 60.5186C133.886 60.5186 121.892 69.2737 123.392 75.5273C124.892 81.781 135.885 83.0317 134.636 93.7884C133.386 104.545 131.6 109.823 118.585 110.491C101.223 111.383 94.907 127.309 94.907 137.066C94.907 146.823 139.384 122.806 139.384 122.806C139.384 122.806 154.625 107.048 133.886 60.5186Z" fill="#212121"/>
                        <path d="M75.3818 155.5C80.3715 135.889 111.796 123.734 123.111 122.517L172.267 120.767L189.002 155.5H75.3818Z" fill="#102C97"/>
                        <path d="M142.631 42.506C150.877 38.2539 179.861 47.009 176.612 76.5266C174.998 91.2053 171.615 113.3 176.113 120.804C180.611 128.308 192.354 136.313 186.607 142.317C180.86 148.322 149.377 151.823 145.129 147.32C140.882 142.817 126.639 128.057 142.881 100.79C159.122 73.5234 142.382 60.0144 140.633 59.0145C138.883 58.0166 130.535 48.7446 142.631 42.506Z" fill="#212121"/>
                        <path d="M27.9274 48.0823H21.0704C17.4318 48.0823 14.481 45.13 14.481 41.4853V34.6204C14.481 30.9777 17.4299 28.0234 21.0704 28.0234H27.9274C31.566 28.0234 34.5168 30.9758 34.5168 34.6204V41.4853C34.5149 45.13 31.566 48.0823 27.9274 48.0823Z" fill="#EAEBED"/>
                        <path d="M27.9274 48.7518H21.0704C17.0681 48.7518 13.812 45.4919 13.812 41.4851V34.6202C13.812 30.6133 17.0681 27.3535 21.0704 27.3535H27.9274C31.9296 27.3535 35.1857 30.6133 35.1857 34.6202V41.4851C35.1857 45.4919 31.9296 48.7518 27.9274 48.7518ZM21.0704 28.6948C17.8067 28.6948 15.1518 31.3528 15.1518 34.6202V41.4851C15.1518 44.7524 17.8067 47.4105 21.0704 47.4105H27.9274C31.191 47.4105 33.846 44.7524 33.846 41.4851V34.6202C33.846 31.3528 31.191 28.6948 27.9274 28.6948H21.0704Z" fill="#212121"/>
                        <path d="M23.6086 43.2944C23.1639 43.2944 22.7399 43.1171 22.4252 42.8039L18.6415 39.0159C17.9877 38.3613 17.9877 37.3011 18.6415 36.6465C19.2954 35.9919 20.3544 35.9919 21.0082 36.6465L23.6086 39.2498L39.3425 23.4978C39.9964 22.8432 41.0554 22.8432 41.7092 23.4978C42.3631 24.1524 42.3631 25.2126 41.7092 25.8672L24.7919 42.8039C24.4772 43.119 24.0514 43.2944 23.6086 43.2944Z" fill="#102C97"/>
                        <path d="M27.9274 74.8254H21.0704C17.4318 74.8254 14.481 71.8731 14.481 68.2285V61.3636C14.481 57.7208 17.4299 54.7666 21.0704 54.7666H27.9274C31.566 54.7666 34.5168 57.7189 34.5168 61.3636V68.2285C34.5149 71.8731 31.566 74.8254 27.9274 74.8254Z" fill="#EAEBED"/>
                        <path d="M27.9274 75.4949H21.0704C17.0681 75.4949 13.812 72.2351 13.812 68.2282V61.3634C13.812 57.3565 17.0681 54.0967 21.0704 54.0967H27.9274C31.9296 54.0967 35.1857 57.3565 35.1857 61.3634V68.2282C35.1857 72.2351 31.9296 75.4949 27.9274 75.4949ZM21.0704 55.438C17.8067 55.438 15.1518 58.096 15.1518 61.3634V68.2282C15.1518 71.4956 17.8067 74.1536 21.0704 74.1536H27.9274C31.191 74.1536 33.846 71.4956 33.846 68.2282V61.3634C33.846 58.096 31.191 55.438 27.9274 55.438H21.0704Z" fill="#212121"/>
                        <path d="M23.6086 70.0376C23.1808 70.0376 22.7512 69.8735 22.4252 69.5471L18.6415 65.7591C17.9877 65.1045 17.9877 64.0443 18.6415 63.3897C19.2954 62.735 20.3544 62.735 21.0082 63.3897L23.6086 65.993L39.3425 50.241C39.9964 49.5863 41.0554 49.5863 41.7092 50.241C42.3631 50.8956 42.3631 51.9558 41.7092 52.6104L24.7919 69.5471C24.4659 69.8753 24.0363 70.0376 23.6086 70.0376Z" fill="#102C97"/>
                      </g>
                      <defs>
                        <clipPath id="clip0_13793_74990">
                          <rect width="189" height="155" fill="white" transform="translate(0 0.5)"/>
                        </clipPath>
                      </defs>
                    </svg>
                    <span>Selected checks will be shown here</span>
                  </div>
                )
              }
            </div>
          </div>
          <div className='Total'>
            <div className='TotalAmount'>
              <h3>Total Amount</h3>
              <h1>₹ {price?.toFixed(2)}</h1>
            </div>
            <div className='BreakdownContainer'>
              <span>**Inclusive of all tax and services**</span>
              {selectedDisplayNames && selectedDisplayNames.map((check, index) => (
                <div className='Breakdown' key={index}>
                  <span>{check}</span>
                  {Array.isArray(Checks) && Checks.find(item => item.display_name === check) && (
                    <span>₹ {Checks.find(item => item.display_name === check).valiant_rate_tax}</span>
                  )}
                </div>
              ))}
            </div>
            <div className='Button'><button onClick={handleMakePlan} disabled={checkedItems.length === 0}>Make a plan</button></div>
          </div>
      </section>
      {Modal &&
        <div className='ModalContainer'>
          <form className='PackModal' onSubmit={createPlan}>
            <h1>Name your pack</h1>
            <input type='text' placeholder='Pack name' maxLength={20} value={PackName} onChange={handleInputChange} autoFocus={Modal} required onKeyPress={handleKeyPress}/>
            <div className='ModalButtonContainer'>
              <button className='Cancel' onClick={()=> {setModal(false)}}>Cancel</button>
              <button className='Create CenterDiv' type='submit'>
                {CreatePlanLoading ? <ButtonSpinner/> : 'Create'}
              </button>
            </div>
          </form>
        </div>
      }
      {Alert &&
        <CustomAlert type={SuccessFailed} message={SuccessFailedMessage} handleClose={PackName.length === 0 ? handleInformationClose : (SuccessFailed === 'Success' ? handleSuccessClose : handleFailedClose)}/>
      }
    </main>
  )
}

export default CustomizePack