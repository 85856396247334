import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "./PlanDetailsModal.scss";
import rightArrow from "../../../assets/images/Vector.svg";
import checked from "../../../assets/images/checked.svg";
import PdfViewer from "../PdfViewer/PdfViewer";
import { clientPaymentLinkGenerate } from "../../../redux/actions/CompanyBeginVerificationAction";
import axios from "axios";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";

function PlanDetailsModal({
  checkedValue,
  planName,
  data,
  beginOld,
  submitFunction,
  closeModal,
}) {
  const candidateBeginVerificationUpdateOldLoading = useSelector(
    (state) => state.candidateBeginVerificationUpdateOld?.loading
  );
  const clientPaymentLinkGenerateLoading = useSelector(
    (state) => state.clientPaymentLinkGenerate?.loading
  );
  const accessData = useSelector((state) => state.getClientAccessControl.data);
  const paymentInterval = accessData?.data?.payment_interval;
  //  const paymentInterval = "postpaid";

  const clientInfoID = localStorage.getItem("client_info_id");
  const dispatch = useDispatch();
  const [fileURL, setFileURL] = useState(null);
  const [linkResp, setLinkResp] = useState({});
  // const [paymentUrl, setPaymentUrl] = useState("");
  useEffect(() => {
    if (data.resume_url) {
      const url = URL.createObjectURL(data.resume_url);
      setFileURL(url);

      return () => {
        // Revoke the object URL to avoid memory leaks
        URL.revokeObjectURL(url);
      };
    }
  }, [data]);

  if (!data.resume_url) return null;

  const fileExtension = data.resume_url.name.split(".").pop().toLowerCase();

  const filteredNames = data?.selected_display_name.filter(
    (name) => !data?.added_checks?.includes(name)
  );

  return (
    <div className='modalBg'>
      <div className='modalContainer'>
        <div className='header'>
          <h1 className='Ellipsis'>{paymentInterval === "prepaid" ? 'Proceed to Payment' : 'Begin Verification'}</h1>
          <div>
            <button
              onClick={closeModal}
              disabled={
                candidateBeginVerificationUpdateOldLoading ||
                clientPaymentLinkGenerateLoading
              }>
              <p>Back</p>
            </button>
            <button
              onClick={() => {
                paymentInterval === "prepaid" ? submitFunction() : beginOld();
              }}
              disabled={
                candidateBeginVerificationUpdateOldLoading ||
                clientPaymentLinkGenerateLoading
              }>
              {candidateBeginVerificationUpdateOldLoading ||
              clientPaymentLinkGenerateLoading ? (
                <ButtonSpinner />
              ) : (
                <>
                  <p>
                    {paymentInterval === "prepaid"
                      ? "Make Payment"
                      : "Proceed"}
                  </p>
                  <img
                    src={rightArrow}
                    alt=''
                  />
                </>
              )}
            </button>
          </div>
        </div>
        <div className='containerBody'>
          <div className='leftSide'>
            <h1>Resume</h1>
            <div className='pdfViewer'>
              {fileExtension === "jpg" || fileExtension === "jpeg" ? (
                <img
                  src={fileURL}
                  alt='Uploaded File'
                />
              ) : fileExtension === "pdf" ? (
                <PdfViewer pdf={fileURL} />
              ) : fileExtension === "doc" || fileExtension === "docx" ? (
                <p className='NoPrev'>
                  There is no preview for the docs file
                </p>
              ) : (
                <p>Unsupported file type</p>
              )}
            </div>

            {/* <div>
                        
                        <iframe src={formValues.CV_URL} frameborder="0"></iframe>
                    </div> */}
          </div>
          <div className='rightSide'>
            <div className='packDetails'>
              <div className='planInfo'>
                <p>Pack Details</p>
                <p>Pack Name</p>
                <p>{planName?.replace("_", " ")}</p>
              </div>
              <div className='priceInfo'>
                <p>₹ {data.total_amount?.toFixed(2)} /-</p>
                <p>*Inclusive of all taxes</p>
              </div>
            </div>
            <div className='packDetailsContainer'>
              <div className='packageDetails'>
                <div className='userDetails'>
                  <h1>Candidate Details</h1>
                  <div className='userCred'>
                    <div>
                      <p>First Name</p>
                      <input
                        type='text'
                        value={data?.first_name}
                        disabled
                      />
                    </div>
                    <div>
                      <p>Last Name</p>
                      <input
                        type='text'
                        value={data?.last_name}
                        disabled
                      />
                    </div>
                    <div>
                      <p>Email Id</p>
                      <input
                        type='text'
                        value={data?.candidate_email}
                        disabled
                      />
                    </div>
                    <div>
                      <p>Mobile Number</p>
                      <input
                        type='text'
                        value={data?.candidate_phonenumber}
                        disabled
                      />
                    </div>
                    <div>
                      <p>Job Seeker Id</p>
                      <input
                        type='text'
                        value={data?.job_seeker_id}
                        disabled
                      />
                    </div>
                    <div>
                      <p>Work Order Id</p>
                      <input
                        type='text'
                        value={data?.workorder_id}
                        disabled
                      />
                    </div>
                  </div>
                </div>
                {checkedValue && (
                  <div className='Type'>
                    <h1>Type</h1>
                    <p>
                      {checkedValue === "DIY"
                        ? "Do it by Myself"
                        : checkedValue === "PRE"
                        ? "Pre-OnBoarding"
                        : ""}
                    </p>
                    {checkedValue === "BOTH" && (
                      <p>Do it by Myself & Pre-OnBoarding</p>
                    )}
                  </div>
                )}
              </div>
              <div className='packageDetails'>
                <div className='userDetails'>
                  {filteredNames.length > 0 && (
                    <>
                      <h1>Newly Added Checks</h1>
                      {filteredNames.map((val, idx) => (
                        <div
                          className='planNames'
                          key={idx}>
                          <svg
                            width='0.75rem'
                            height='0.75rem'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M9 0.5C4.30457 0.5 0.5 4.30457 0.5 9C0.5 13.6954 4.30457 17.5 9 17.5C13.6954 17.5 17.5 13.6954 17.5 9C17.5 4.30457 13.6954 0.5 9 0.5ZM7.68822 12.0118C7.59014 12.1099 7.4512 12.1916 7.32861 12.1916C7.20601 12.1916 7.06707 12.1058 6.9649 12.0077L4.67644 9.71923L5.40385 8.99183L7.33269 10.9207L12.4327 5.78389L13.1478 6.52356L7.68822 12.0118Z'
                              fill='var(--AccentColor)'
                            />
                          </svg>
                          <p>{val}</p>
                        </div>
                      ))}
                    </>
                  )}
                  {data.added_checks.length > 0 && (
                    <>
                      <h1>Existing Checks</h1>
                      {data?.added_checks?.map((val, idx) => (
                        <div
                          className='planNames'
                          key={idx}>
                          <svg
                            width='0.75rem'
                            height='0.75rem'
                            viewBox='0 0 18 18'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'>
                            <path
                              d='M9 0.5C4.30457 0.5 0.5 4.30457 0.5 9C0.5 13.6954 4.30457 17.5 9 17.5C13.6954 17.5 17.5 13.6954 17.5 9C17.5 4.30457 13.6954 0.5 9 0.5ZM7.68822 12.0118C7.59014 12.1099 7.4512 12.1916 7.32861 12.1916C7.20601 12.1916 7.06707 12.1058 6.9649 12.0077L4.67644 9.71923L5.40385 8.99183L7.33269 10.9207L12.4327 5.78389L13.1478 6.52356L7.68822 12.0118Z'
                              fill='var(--AccentColor)'
                            />
                          </svg>
                          <p>{val}</p>
                        </div>
                      ))}
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PlanDetailsModal;
