import React, { useEffect, useState } from 'react'
import './ContactUs.scss'
import Contact from '../../assets/images/business-contact-services.svg'
import { useDispatch, useSelector } from 'react-redux'
import { clientFeedbackSubmit } from '../../redux/actions/CompanyAccountSettingAction'
// import Loader from '../Loader/Loader';
import CustomAlert from '../ReusableComponents/CustomAlert'
import PinLight from '../../assets/images/Pin.png'
import PinDark from '../../assets/images/PinDark.png'
import ButtonSpinner from '../ReusableComponents/ButtonSpinner'

const ContactUs = () => {
  const dispatch = useDispatch()
  const DarkMode = localStorage.getItem('Theme') === 'true' ? true : false
  const clientInfoID = localStorage.getItem("client_info_id");
  const UserName = localStorage.getItem("client_username");
  const UserEmail = localStorage.getItem("useremail");
  const submitLoading = useSelector((state) => state.clientFeedbackSubmit?.loading)
  const [Reveal, setReveal] = useState(false);
  useEffect(() => {
    setReveal(true);
  }, []);

  const Pin = DarkMode ? PinDark : PinLight

  const [formData, setFormData] = useState({
    name: UserName,
    email: UserEmail,
    feedbackType: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };
  const validateForm = () => {
    if (
      !formData.name.trim() ||
      !formData.email.trim() ||
      !formData.feedbackType ||
      !formData.message.trim()
    ) {
      setSuccessFailed('Failed')
      setSuccessFailedMessage('Please fill all the required fields')
      setAlert(true);
      return false;
    }
  
    if (!/\S+@\S+\.\S+/.test(formData.email)) {
      alert('Email is invalid');
      return false;
    }
  
    return true;
  };
  
  const [SuccessFailed, setSuccessFailed] = useState('Success')
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState('')
  const [Alert, setAlert] = useState(false)
  const handleSuccessClose = () => {
    setAlert(false)
    setFormData({
      name: UserName,
      email: UserEmail,
      feedbackType: '',
      message: ''
    })
  }
  const handleFailedClose = () => {
    setAlert(false)
  }


const handleFeedbackSubmit = () => {
    if (validateForm()) {
      const data = {
        client_info_id: clientInfoID,
        client_name: formData.name,
        client_email: formData.email,
        feedback_type: formData.feedbackType,
        message: formData.message
      };
      const successFn = (response) => {
        console.log(response);
        if(response.statusFlag){
          // alert(response.message)
          setSuccessFailed('Success')
          setSuccessFailedMessage(response.message)
          setAlert(true)
        }
        else{
          alert(response.message)
        }
      };
      const failureFn = (res) => {
        console.log("Logged-In Failed", res);
        // alert(res);
        setSuccessFailed('Failed')
        setSuccessFailedMessage(res)
        setAlert(true)
      };
      dispatch(
        clientFeedbackSubmit({ data: data, successFn: successFn, failureFn: failureFn })
      );
    }
  };


  return (
    <main className='ContactUs'>
      <article className='Contact'>
        <img src={Contact} alt='ContactImage' className={`ContactImage ${DarkMode ? 'ContactImageDark' : ''}`}/>
        <h1>Contact us</h1>
        <div className='ConatactDetail'>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 40 40" fill="none">
            <path d="M14.7605 25.96C16.4005 27.48 18.4005 29 20.8005 30.24C20.8005 30.24 24.6805 32.52 30.2405 32.28C31.3205 32.24 32.2405 31.32 32.4005 30.24L33.0005 26.36C33.1205 25.6 32.7205 24.96 32.0405 24.72L26.8405 23.48C26.4405 23.36 25.9605 23.4 25.5205 23.64L22.4005 25.48C22.2405 25.56 22.0805 25.6 21.9605 25.52C20.6405 24.96 19.0805 24.08 17.6005 22.72C16.1205 21.36 15.1205 19.88 14.4405 18.6C14.3605 18.48 14.3605 18.28 14.4405 18.16L16.0005 14.88C16.2005 14.44 16.2405 13.96 16.0805 13.56L14.4005 8.47998C14.1205 7.79998 13.4405 7.47998 12.6805 7.67998L8.8805 8.59998C7.8005 8.87998 7.0005 9.91998 7.0405 11.04C7.2805 16.2 9.8805 20.16 9.8805 20.16C11.3205 22.4 13.0405 24.28 14.6805 25.8" fill="#102C98"/>
          </svg>
          <span>+91 78100 21421</span>
        </div>
        <div className='ConatactDetail'>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 40 40" fill="none">
            <path d="M2.75781 10.2344V29.782L12.5316 20.0082L2.75781 10.2344Z" fill="#102C98"/>
            <path d="M4.70117 8.27734L17.075 20.6511C18.6377 22.2139 21.3632 22.2139 22.926 20.6511L35.2998 8.27734H4.70117Z" fill="#102C98"/>
            <path d="M24.8754 22.5984C23.5747 23.9005 21.8423 24.6191 19.9996 24.6191C18.1568 24.6191 16.4244 23.9005 15.1237 22.5984L14.4823 21.957L4.7168 31.7225H35.2823L25.5168 21.957L24.8754 22.5984Z" fill="#102C98"/>
            <path d="M27.4668 20.0082L37.2406 29.782V10.2344L27.4668 20.0082Z" fill="#102C98"/>
          </svg>
          <a href='mailto:support@valianttinfo.com' className='Mail'>support@valianttinfo.com</a>
        </div>
        <div className='ConatactDetail'>
          <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 40 40" fill="none">
            <path d="M19.9993 3.33203C12.666 3.33203 6.66602 9.33203 6.66602 16.6654C6.66602 25.6654 18.3327 35.832 18.8327 36.332C19.4993 36.832 20.3327 36.832 20.9994 36.332C21.666 35.832 33.3327 25.6654 33.3327 16.6654C33.3327 9.33203 27.3327 3.33203 19.9993 3.33203ZM19.9993 23.332C16.3327 23.332 13.3327 20.332 13.3327 16.6654C13.3327 12.9987 16.3327 9.9987 19.9993 9.9987C23.666 9.9987 26.666 12.9987 26.666 16.6654C26.666 20.332 23.666 23.332 19.9993 23.332Z" fill="#102C98"/>
          </svg>
          <span>B2, B-Block, Greenwoods, Morais City, Sembattu, Tiruchirappalli Airport, Tiruchirappalli - 620007, Tamil Nadu</span>
        </div>
      </article>
      <form className={`FormContainer ${Reveal ? 'Reveal' : ''}`}>
        <h1>Feedback</h1>
        <span>We value your feedback! Please take a moment to share your thoughts with us. Your input helps us improve our services and better meet your needs.</span>
        <section className='InputSection'>
          <div className='LoginInputContainer'>
            <select
              className='EmailInput Select'
              name='feedbackType'
              id='feedbacktype'
              value={formData.feedbackType}
              onChange={handleChange}
              required
            >
              <option value={''} disabled>Select one option</option>
              <option value={'General Feedback'}>General Feedback</option>
              <option value={'Bug Report'}>Bug Report</option>
              <option value={'Feature Request'}>Feature Request</option>
              <option value={'Register Complaint'}>Register Complaint</option>
            </select>
            <label className='EmailLabel' htmlFor='username'>
              Feedback type
            </label>
          </div>
          <div className='LoginInputContainer'>
            <textarea
              className='EmailInput TextArea'
              name='message'
              placeholder=''
              id='message'
              maxLength={1000}
              value={formData.message}
              onChange={handleChange}
              required
            />
            <label className='EmailLabel' htmlFor='message'>
              Message
            </label>
          </div>
          <button className='Button' type="button" onClick={handleFeedbackSubmit} disabled={submitLoading}>
            {submitLoading ? <ButtonSpinner/> : 'Submit'}
          </button>
        </section>
      </form>
      <div className='PinSection' style={{backgroundImage: `url(${Pin})`}}/>
      {Alert &&
        <CustomAlert type={SuccessFailed} message={SuccessFailedMessage} handleClose={SuccessFailed === 'Success' ? handleSuccessClose : SuccessFailed === 'Failed' ? handleFailedClose : setAlert(false)}/>
      }
    </main>
  )
}

export default ContactUs