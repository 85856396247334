import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";

import { apiCall } from "../../api";

const createAccountAsyncThunk = (method, type, contentType, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);
    const token = localStorage.getItem("token");
    const apiRequest = {
      method: method,
      headers: token
        ? {
            "Content-Type": contentType,
            Authorization: "Token " + token,
          }
        : { "Content-Type": contentType },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;
      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      throw error;
    }
  });

export const vendorUserList = createAccountAsyncThunk(
  "post",
  "vendorUserList",
  "application/json",
  "Usermanagement/list_subvendor/"
);

export const registerVendorUser = createAccountAsyncThunk(
  "post",
  "registerVendorUser",
  "application/json",
  "Usermanagement/register_subvendor/"
);

export const viewVendorUser = createAccountAsyncThunk(
  "post",
  "viewVendorUser",
  "application/json",
  "Usermanagement/fetch_subvendor/"
);

export const updateVendorUser = createAccountAsyncThunk(
  "post",
  "updateVendorUser",
  "application/json",
  "Usermanagement/edit_subvendor/"
);

export const deactivateVendorUser = createAccountAsyncThunk(
  "post",
  "deactivateVendorUser",
  "application/json",
  "Usermanagement/deactivate_subvendor/"
);

export const accessPagesList = createAccountAsyncThunk(
  "post",
  "accessPagesList",
  "application/json",
  "ClientUser/fetch_client_navigation_pages/"
);

export const subuserSetPassword = createAccountAsyncThunk(
  "post",
  "subuserSetPassword",
  "application/json",
  "Usermanagement/add_subvendor/"
);