import React, { useEffect, useState } from "react";
import {
  accessPagesList,
  registerVendorUser,
} from "../../redux/actions/UserManagementAction";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../ReusableComponents/CustomAlert";
import FullPageLoader from "../Loader/FullPageLoader";
import ButtonSpinner from "../ReusableComponents/ButtonSpinner";

const AddUserModal = ({ handleClose }) => {
  const dispatch = useDispatch();
  const [FormData, setFormData] = useState({
    UserName: "",
    EmailId: "",
    PhoneNumber: "",
    Options: {},
  });
  const [DisplayNames, setDisplayNames] = useState("");
  const [ModalView, setModalView] = useState(false);
  const accessPagesListLoading = useSelector(
    (state) => state.accessPagesList?.loading
  );
  const registerVendorUserLoading = useSelector(
    (state) => state.registerVendorUser?.loading
  );
  const [CustomAlertMessage, setCustomAlertMessage] = useState({
    type: "",
    message: "",
    handleClose: "",
  });
  console.log(FormData);

  const handleToggleClick = (option, name) => {
    console.log("options", option);
    setFormData((prev) => ({
      ...prev,
      Options: {
        ...prev.Options,
        [option]: { name: name, bool_value: !prev.Options[option]?.bool_value },
      },
    }));
  };

  const handleInputChange = (e, id) => {
    let value = e.target.value;
    if (id === "PhoneNumber") {
      value = value?.replace(/[^0-9]/g, "");
    }
    setFormData((prev) => ({
      ...prev,
      [id]: value,
    }));
  };

  const handleAddVendorUser = () => {
    console.log("Form", FormData);
    const phoneRegex = /^[0-9]{10}$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (
      !FormData.UserName ||
      FormData.UserName.trim() === "" ||
      !FormData.EmailId ||
      !emailRegex.test(FormData.EmailId) ||
      !FormData.PhoneNumber ||
      !phoneRegex.test(FormData.PhoneNumber)
    ) {
      setModalView(true);
      setCustomAlertMessage({
        type: "Failed",
        message: "Please fill all the required fields with valid inputs",
        handleClose: () => setModalView(false),
      });
      return;
    }
    if (
      Object.values(FormData.Options)?.every(
        (value) => value?.bool_value === false
      )
    ) {
      setModalView(true);
      setCustomAlertMessage({
        type: "Failed",
        message: "Enable at least one page access to proceed",
        handleClose: () => setModalView(false),
      });
      return;
    }
    const data = {
      vendor_name: FormData.UserName,
      vendor_mail: FormData.EmailId,
      vendor_phonenumber: FormData.PhoneNumber,
      client_access_control_json: FormData.Options,
    };
    const successFn = (response) => {
      setModalView(true);
      setCustomAlertMessage({
        type: "Success",
        message: "User created successfully",
        handleClose: () => {
          setModalView(false);
          handleClose();
        },
      });
    };
    const failureFn = (error) => {
      alert(error);
      console.log(error);
    };
    dispatch(registerVendorUser({ data, successFn, failureFn }));
  };

  const getaccessPagesList = () => {
    const MasterId = localStorage.getItem("master_id");
    const ClientInfoId = localStorage.getItem("client_info_id");
    const data = {
      master_id: MasterId,
      client_info_id: ClientInfoId,
    };
    const successFn = (response) => {
      console.log(response?.data);
      setFormData((prev) => ({
        ...prev,
        Options: Object.keys(response.data)?.reduce((acc, key) => {
          acc[key] = {
            name: response.data[key],
            bool_value: false,
          };
          return acc;
        }, {}),
      }));
      setDisplayNames(response.data);
    };
    const failureFn = (error) => {
      console.log(error);
    };

    dispatch(accessPagesList({ data, successFn, failureFn }));
  };

  useEffect(() => {
    getaccessPagesList();
  }, []);

  return (
    <form
      className='AddUserModal'
      onSubmit={(e) => {
        e.preventDefault();
      }}>
      <section className='Modal'>
        {accessPagesListLoading && !DisplayNames ? (
          <div className='LoadCont'>
            <FullPageLoader />
          </div>
        ) : (
          <>
            <header>
              <h1>Add User</h1>
              <button onClick={handleClose}>Close</button>
              <button
                className='Save CenterDiv'
                onClick={() => handleAddVendorUser()}
                disabled={registerVendorUserLoading}>
                {registerVendorUserLoading ? <ButtonSpinner /> : "Save"}
              </button>
            </header>
            <article className='InputContainer'>
              <input
                type='text'
                value={FormData.UserName}
                placeholder='Name'
                onChange={(e) => {
                  handleInputChange(e, "UserName");
                }}
              />
              <input
                type='email'
                value={FormData.EmailId}
                placeholder='Email Id'
                onChange={(e) => {
                  handleInputChange(e, "EmailId");
                }}
              />
              <input
                type='text'
                value={FormData.PhoneNumber}
                placeholder='Phone Number'
                onChange={(e) => {
                  handleInputChange(e, "PhoneNumber");
                }}
                maxLength={10}
              />
            </article>
            <h3>Access</h3>
            <article className='ToggleSection'>
              {FormData?.Options &&
                Object.keys(FormData?.Options)?.length > 0 &&
                Object.entries(FormData?.Options).map(([key, value], index) => (
                  <div
                    className='ToggleValue'
                    key={index}>
                    <span>{DisplayNames[key]}</span>
                    <div
                      className={`Toggle ${
                        FormData.Options[key]?.["bool_value"] ? "Active" : ""
                      }`}
                      onClick={() => {
                        handleToggleClick(key, value?.name);
                      }}
                    />
                  </div>
                ))}
            </article>
          </>
        )}
      </section>
      {ModalView && <CustomAlert {...CustomAlertMessage} />}
    </form>
  );
};

export default AddUserModal;
