import React, { useState } from "react";
import "./LoginForm.scss";
import { useDispatch, useSelector } from "react-redux";
import { userLogin } from "../../../redux/actions/LoginAction";
import logo from "../../../assets/images/Valiantt_logo.svg";
import { useParams, useNavigate } from "react-router-dom";
import CustomAlert from "../../ReusableComponents/CustomAlert";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";


function LoginForm() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { component } = useParams();

  const LoginLoading = useSelector((state) => state.userLogin.loading);

  const [userData, setUserData] = useState({
    useremail: "",
    password: "",
  });
  const [showPassword, setShowpassword] = useState(true);
  const [customAlert, setCustomAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    message: "",
    handleClose: () => setCustomAlert(false),
  });

  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const fcm_token = localStorage.getItem("fcmToken");
    const data = {
      username: userData.useremail,  // Extracting user's email
      password: userData.password,  // Extracting user's password
      fcm_token: fcm_token ? fcm_token : "No Fcm Token Received", // Fallback for FCM token
    };
    if (!data.username || !data.password) {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        type: "Failed",
        message: "Please fill in all fields",
      });
      return;
    }
    const successFn = (response) => {
      const IsCandidate = response.data.user_data.user["is_candidate"];
      const IsClient = response.data.user_data.user["is_client"];
      const IsMaster = response.data.user_data.user["is_master"];
      const IsFirstLogin = response.data.user_data.user["first_login"];
      const token = response.data.user_data.token;

      localStorage.setItem("token", token);
      localStorage.setItem(
        "useremail",
        response.data.user_data.user["user_email"]
      );
      localStorage.setItem(
        "IsCandidate",
        response.data.user_data.user["is_candidate"]
      );
      localStorage.setItem(
        "IsFirstLogin",
        response.data.user_data.user["first_login"]
      );
      localStorage.setItem(
        "client_info_id",
        response.data.user_data.user["client_info_id"]
      );
      localStorage.setItem(
        "master_id",
        response.data.user_data.user["master_id"]
      );
      localStorage.setItem("user_id", response.data.user_data.user["user_id"]);
      if (IsCandidate === 1) {
        localStorage.setItem(
          "Concent",
          response.data.user_data.user["first_login"] ? "yes" : "no"
        );
        localStorage.setItem("user_id", response.data.user_data.user.user_id);
        window.location.href = "/";
        localStorage.setItem(
          "service_provider_data",
          JSON.stringify(response.data.service_provider_data.apichecks_name)
        );
      } else if (IsClient === 1) {
        localStorage.setItem(
          "client_username",
          response.data.user_data.user["username"]
        );

        if (IsFirstLogin === 1) {
          window.location.href = "/welcome";
        } else {
          window.location.href = "/company/dashboard";
        }
      } else if (IsMaster === 1) {
        localStorage.setItem(
          "client_username",
          response.data.user_data.user["username"]
        );
        localStorage.setItem(
          "IsMaster",
          response.data.user_data.user["is_master"]
        );
        window.location.href = "/client/dashboard";
      }
    };
    const failureFn = (res) => {
      setCustomAlert(true);
      setAlertData({ ...alertData, message: res, type: "Failed" });
    };

    dispatch(
      userLogin({ data: data, successFn: successFn, failureFn: failureFn })
    );
  };

  return (
    <div
      className={`loginForm ${(!component ||
          component !== 'set-password' ||
          component !== "license-verify" ||
          component !== "resetpassword" ||
          component !== "forgot"
        ) ?
          "active"
          :
          "next"
        }`}
    >
      {customAlert && <CustomAlert {...alertData} />}
      <img src={logo} alt="" className="logo" />
      <form className="formFields" onSubmit={(e) => { handleSubmit(e) }}>
        <h1>Welcome Back</h1>
        <p>Access your dashboard and manage verifications.</p>
        <div className="userCredentials">
          <div className="userName">
            <input
              type="text"
              name="useremail"
              placeholder="Enter Registered Username"
              onChange={handleChange}
              autoComplete="useremail"
            />
          </div>
          <div className="userPassword">
            <input
              type={showPassword ? "password" : "text"}
              name="password"
              placeholder="Enter password"
              onChange={handleChange}
              autoComplete="password"
            />
            {!showPassword ? (
              <div onClick={() => setShowpassword(!showPassword)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.125rem" viewBox="0 0 16 18" fill="none">
                  <path d="M2.75 18H13.25C13.8467 18 14.419 17.7629 14.841 17.341C15.2629 16.919 15.5 16.3467 15.5 15.75V9.75C15.5 9.15326 15.2629 8.58097 14.841 8.15901C14.419 7.73705 13.8467 7.5 13.25 7.5H5V4.5C5 3.70435 5.31607 2.94129 5.87868 2.37868C6.44129 1.81607 7.20435 1.5 8 1.5C8.79565 1.5 9.55871 1.81607 10.1213 2.37868C10.6839 2.94129 11 3.70435 11 4.5C11 4.69891 11.079 4.88968 11.2197 5.03033C11.3603 5.17098 11.5511 5.25 11.75 5.25C11.9489 5.25 12.1397 5.17098 12.2803 5.03033C12.421 4.88968 12.5 4.69891 12.5 4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5V7.5H2.75C2.15326 7.5 1.58097 7.73705 1.15901 8.15901C0.737053 8.58097 0.5 9.15326 0.5 9.75V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18ZM2 9.75C2 9.55109 2.07902 9.36032 2.21967 9.21967C2.36032 9.07902 2.55109 9 2.75 9H13.25C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V15.75C14 15.9489 13.921 16.1397 13.7803 16.2803C13.6397 16.421 13.4489 16.5 13.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V9.75Z" fill="black" />
                  <path d="M7.25 13.29V14.25C7.25 14.4489 7.32902 14.6397 7.46967 14.7803C7.61032 14.921 7.80109 15 8 15C8.19891 15 8.38968 14.921 8.53033 14.7803C8.67098 14.6397 8.75 14.4489 8.75 14.25V13.29C8.97671 13.1591 9.16522 12.9712 9.29679 12.7449C9.42836 12.5186 9.49842 12.2618 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5C7.60218 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.50158 12.2618 6.57164 12.5186 6.70321 12.7449C6.83479 12.9712 7.02329 13.1591 7.25 13.29Z" fill="black" />
                </svg>
              </div>
            ) : (
              <div onClick={() => setShowpassword(!showPassword)}>
                <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.125rem" viewBox="0 0 16 18" fill="none">
                  <path d="M2.75 18H13.25C13.8467 18 14.419 17.7629 14.841 17.341C15.2629 16.919 15.5 16.3467 15.5 15.75V9.75C15.5 9.15326 15.2629 8.58097 14.841 8.15901C14.419 7.73705 13.8467 7.5 13.25 7.5H12.5V4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5V7.5H2.75C2.15326 7.5 1.58097 7.73705 1.15901 8.15901C0.737053 8.58097 0.5 9.15326 0.5 9.75V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18ZM5 4.5C5 3.70435 5.31607 2.94129 5.87868 2.37868C6.44129 1.81607 7.20435 1.5 8 1.5C8.79565 1.5 9.55871 1.81607 10.1213 2.37868C10.6839 2.94129 11 3.70435 11 4.5V7.5H5V4.5ZM2 9.75C2 9.55109 2.07902 9.36032 2.21967 9.21967C2.36032 9.07902 2.55109 9 2.75 9H13.25C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V15.75C14 15.9489 13.921 16.1397 13.7803 16.2803C13.6397 16.421 13.4489 16.5 13.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V9.75Z" fill="black" />
                  <path d="M7.25 13.29V14.25C7.25 14.4489 7.32902 14.6397 7.46967 14.7803C7.61032 14.921 7.80109 15 8 15C8.19891 15 8.38968 14.921 8.53033 14.7803C8.67098 14.6397 8.75 14.4489 8.75 14.25V13.29C8.97671 13.1591 9.16522 12.9712 9.29679 12.7449C9.42836 12.5186 9.49842 12.2618 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5C7.60218 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.50158 12.2618 6.57164 12.5186 6.70321 12.7449C6.83479 12.9712 7.02329 13.1591 7.25 13.29Z" fill="black" />
                </svg>
              </div>
            )}
          </div>
        </div>

        <button type="submit" className="CenterDiv" onClick={handleSubmit} disabled={LoginLoading}>
          {LoginLoading ? <ButtonSpinner size={1} /> : "Log In"}
        </button>
        <div className="remPassword">
          {/* <div>
            <input type="checkbox" />
            <p>Remember Me</p>
          </div> */}
          <p
            className="forgotPassword"
            onClick={() => navigate('/forgot')}
          >
            Forgot Password?
          </p>
        </div>
      </form>
    </div>
  );
}

export default LoginForm;
