import React, { useState } from "react";
import InsufficiencyDetails from "../../Insufficiency/InsufficiencyDetails";
import CandidateInsufficiency from "./CandidateInsufficiency";
import AdditionalCostList from "../../AdditionalCost/AdditionalCostList";
import { useNavigate } from "react-router-dom";

const CandidateRequests = ({ details, additionalData, setTrigger }) => {
  const [Selected, setSelected] = useState("Insufficiency");
  const navigate = useNavigate();

  console.log("details:::::", details);
  return (
    <main className='CandidateRequests'>
      <header className='DetailHeader'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1.5rem'
          height='1.5rem'
          viewBox='0 0 38 38'
          fill='none'
          onClick={() => {
            navigate(-1);
          }}>
          <path
            d='M29.5556 16.2555H13.5112L20.4778 9.28884C21.3223 8.4444 21.3223 7.17773 20.4778 6.33328C19.6334 5.48884 18.3667 5.48884 17.5223 6.33328L6.96671 16.8888C6.12226 17.7333 6.12226 19 6.96671 19.8444L17.5223 30.4C18.3667 31.2444 19.6334 31.2444 20.4778 30.4C21.3223 29.5555 21.3223 28.2888 20.4778 27.4444L13.5112 20.4777H29.5556C30.8223 20.4777 31.6667 19.6333 31.6667 18.3666C31.6667 17.3111 30.8223 16.2555 29.5556 16.2555Z'
            fill='#102C98'
          />
        </svg>
        <h1>Requests Details</h1>
      </header>
      <section className='TabCont'>
        <div className='Tab'>
          <span
            className={`${Selected === "Insufficiency" ? "Active" : ""}`}
            onClick={() => {
              setSelected("Insufficiency");
            }}>
            Insufficiency Details
          </span>
          <span
            className={`${Selected === "AdditionalCost" ? "Active" : ""}`}
            onClick={() => {
              setSelected("AdditionalCost");
            }}>
            Additional Cost
          </span>
          {/* <span className={`${Selected === 'CancelRequest' ? 'Active' : ''}`} onClick={() => {setSelected('CancelRequest')}}>Cancel Requests</span> */}
        </div>
      </section>
      <section className='ContentCont'>
        {Selected === "Insufficiency" && (
          <CandidateInsufficiency
            candidateData={details}
            setTrigger={setTrigger}
          />
        )}
        {Selected === "AdditionalCost" && (
          <AdditionalCostList
            additionalData={additionalData}
            setToggle={setTrigger}
          />
        )}
      </section>
    </main>
  );
};

export default CandidateRequests;
