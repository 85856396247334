import React from "react";
import "./Report.scss";
import { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { FetchCandidateReportsFields } from "../../redux/actions/ReportsAction";
import { useNavigate } from "react-router-dom";
import FullPageLoader from "../Loader/FullPageLoader";
import Empty from "../Empty/Empty";

const Report = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reportTopicsRefs = useRef([]);

  const [reportData, setReportData] = useState([]);
  const [overflowCounts, setOverflowCounts] = useState([]);
  const FetchCandidateReportsFieldsLoading = useSelector(
    (state) => state.FetchCandidateReportsFields?.loading
  );

  const callFetchCandidateReportsFields = () => {
    const clientInfoID = localStorage.getItem("client_info_id");
    const masterID = localStorage.getItem("master_id");
    const clientId = localStorage.getItem("user_id");
    const data = {
      master_id: masterID,
      client_info_id: clientInfoID,
      client_id: clientId,
    };
    const successFn = (res) => {
      console.log(res);
      setReportData(res.data);
    };
    const failureFn = (err) => {
      console.log(err);
    };
    dispatch(FetchCandidateReportsFields({ data, successFn, failureFn }));
  };

  useEffect(() => {
    const checkOverflow = () => {
      const newOverflowCounts = reportData.map((report, index) => {
        const container = reportTopicsRefs.current[index];
        if (container) {
          const totalWidth = Array.from(container.children).reduce(
            (acc, child) => acc + child.offsetWidth,
            0
          );
          const visibleWidth = container.offsetWidth;
          const hiddenItemsCount =
            report?.report_fields?.length -
            Math.floor(
              visibleWidth / (totalWidth / report?.report_fields?.length)
            );

          // Apply or remove the overflowing class based on hiddenItemsCount
          if (hiddenItemsCount + 1 > 0) {
            container.classList.add("overflowing");
          } else {
            container.classList.remove("overflowing");
          }

          return hiddenItemsCount + 1 > 0 ? hiddenItemsCount + 1 : 0;
        }
        return 0;
      });
      setOverflowCounts(newOverflowCounts);
    };

    checkOverflow();
    window.addEventListener("resize", checkOverflow);

    return () => window.removeEventListener("resize", checkOverflow);
  }, [reportData]);

  useEffect(() => {
    callFetchCandidateReportsFields();
  }, []);

  const handleClick = (report) => {
    navigate(`/company/status/${report.report_type}`);
  };

  return (
    <>
      <div className='ReportBg'>
        {FetchCandidateReportsFieldsLoading ? (
          <FullPageLoader />
        ) : (
          (reportData && reportData?.length > 0) ? reportData?.map((report, index) => (
            <div
              className='reportContainer'
              key={index}
              onClick={() => handleClick(report)}>
              <div className='reportHeading'>
                <svg xmlns='http://www.w3.org/2000/svg' width='1.5rem' height='1.5rem' viewBox='0 0 24 30' fill='none'>
                  <path d='M6.75 10.5H9.75C10.1642 10.5 10.5 10.1642 10.5 9.75C10.5 9.33582 10.1642 9 9.75 9H6.75C6.33582 9 6 9.33582 6 9.75C6 10.1642 6.33582 10.5 6.75 10.5ZM23.9417 9.46124C23.9036 9.37015 23.8487 9.28802 23.7798 9.21927L14.7807 0.220184C14.712 0.151337 14.6299 0.0964051 14.5388 0.0583191C14.448 0.0204162 14.3502 0 14.25 0H4.5C2.01581 0.00274665 0.00274665 2.01581 0 4.5V25.5C0.00274665 27.9842 2.01581 29.9973 4.5 30H19.5C21.9842 29.9973 23.9973 27.9842 24 25.5V9.75C24 9.64984 23.9796 9.55197 23.9417 9.46124ZM15 2.56046L21.4395 9H16.9995C15.8956 8.99908 15.0009 8.10443 15 7.00049V2.56046ZM22.5 25.5C22.4979 27.156 21.156 28.4979 19.5 28.5H4.5C2.84399 28.4979 1.50211 27.156 1.5 25.5V4.5C1.50211 2.84399 2.84399 1.50211 4.5 1.5H13.5V7.00049C13.5025 8.93216 15.0678 10.4975 16.9995 10.5H22.5V25.5ZM6 15.75C6 16.1642 6.33582 16.5 6.75 16.5H17.25C17.6642 16.5 18 16.1642 18 15.75C18 15.3358 17.6642 15 17.25 15H6.75C6.33582 15 6 15.3358 6 15.75ZM17.25 21H6.75C6.33582 21 6 21.3358 6 21.75C6 22.1642 6.33582 22.5 6.75 22.5H17.25C17.6642 22.5 18 22.1642 18 21.75C18 21.3358 17.6642 21 17.25 21Z' fill='#102C97'/>
                </svg>
                <div>
                  <h2 title={report.report_name}>{report.report_name}</h2>
                  {/* <p>{report.subTopic}</p> */}
                </div>
              </div>
              <div
                className='reportTopics'
                ref={(el) => (reportTopicsRefs.current[index] = el)}>
                {report?.report_fields?.map((topic, topicIndex) => (
                  <div
                    className='topicItem'
                    style={{textTransform: 'capitalize'}}
                    key={topicIndex}>
                    {topic?.key_name?.replace(/_/g, ' ')}
                  </div>
                ))}
                {overflowCounts[index] > 0 && (
                  <div className='overflowCounter'>
                    <span>{`+${overflowCounts[index]}`}</span>
                  </div>
                )}
              </div>
              {/* <div className='actions'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='1.25rem'
                  height='1.25rem'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <g opacity='0.5'>
                    <path
                      d='M17.25 21H6.75C6.15326 21 5.58097 20.7629 5.15901 20.341C4.73705 19.919 4.5 19.3467 4.5 18.75V12.75C4.5 12.1533 4.73705 11.581 5.15901 11.159C5.58097 10.7371 6.15326 10.5 6.75 10.5H7.5V7.5C7.5 6.30653 7.9741 5.16193 8.81802 4.31802C9.66193 3.47411 10.8065 3 12 3C13.1935 3 14.3381 3.47411 15.182 4.31802C16.0259 5.16193 16.5 6.30653 16.5 7.5V10.5H17.25C17.8467 10.5 18.419 10.7371 18.841 11.159C19.2629 11.581 19.5 12.1533 19.5 12.75V18.75C19.5 19.3467 19.2629 19.919 18.841 20.341C18.419 20.7629 17.8467 21 17.25 21ZM15 7.5C15 6.70435 14.6839 5.94129 14.1213 5.37868C13.5587 4.81607 12.7956 4.5 12 4.5C11.2044 4.5 10.4413 4.81607 9.87868 5.37868C9.31607 5.94129 9 6.70435 9 7.5V10.5H15V7.5ZM18 12.75C18 12.5511 17.921 12.3603 17.7803 12.2197C17.6397 12.079 17.4489 12 17.25 12H6.75C6.55109 12 6.36032 12.079 6.21967 12.2197C6.07902 12.3603 6 12.5511 6 12.75V18.75C6 18.9489 6.07902 19.1397 6.21967 19.2803C6.36032 19.421 6.55109 19.5 6.75 19.5H17.25C17.4489 19.5 17.6397 19.421 17.7803 19.2803C17.921 19.1397 18 18.9489 18 18.75V12.75Z'
                      fill='#102C97'
                    />
                    <path
                      d='M12.75 16.29V17.25C12.75 17.4489 12.671 17.6397 12.5303 17.7803C12.3897 17.921 12.1989 18 12 18C11.8011 18 11.6103 17.921 11.4697 17.7803C11.329 17.6397 11.25 17.4489 11.25 17.25V16.29C11.0233 16.1591 10.8348 15.9712 10.7032 15.7449C10.5716 15.5186 10.5016 15.2618 10.5 15C10.5 14.6022 10.658 14.2206 10.9393 13.9393C11.2206 13.658 11.6022 13.5 12 13.5C12.3978 13.5 12.7794 13.658 13.0607 13.9393C13.342 14.2206 13.5 14.6022 13.5 15C13.4984 15.2618 13.4284 15.5186 13.2968 15.7449C13.1652 15.9712 12.9767 16.1591 12.75 16.29Z'
                      fill='#102C97'
                    />
                  </g>
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='1.25rem'
                  height='1.25rem'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M10.4443 3.85L10.4444 3.85L13.9444 3.85C14.0888 3.85 14.2262 3.90899 14.327 4.01263L10.4443 3.85ZM10.4443 3.85C9.06952 3.85156 7.96262 4.99622 7.96111 6.39984V6.4V7.05H7.33333L7.33316 7.05C5.95841 7.05156 4.85151 8.19622 4.85 9.59984V9.6L4.85 17.6L4.85 17.6002C4.85151 19.0038 5.95841 20.1484 7.33316 20.15H7.33333H13.5556H13.5557C14.9305 20.1484 16.0374 19.0038 16.0389 17.6002V17.6V16.95L16.6667 16.95L16.6668 16.95C18.0416 16.9484 19.1485 15.8038 19.15 14.4002V14.4V9.2C19.15 9.05547 19.0942 8.91607 18.9936 8.81263C18.9936 8.81261 18.9936 8.8126 18.9936 8.81259L14.327 4.01266L10.4443 3.85ZM7.96111 8.15V14.4L7.96111 14.4002C7.96262 15.8038 9.06952 16.9484 10.4443 16.95H10.4444H14.9611V17.5998C14.9601 18.4043 14.3274 19.049 13.5554 19.05H7.33353C6.56149 19.049 5.92877 18.4043 5.92778 17.5998V9.60019C5.92877 8.79568 6.56149 8.15101 7.33353 8.15H7.96111ZM13.4056 4.95L13.4056 7.73359L13.4056 7.73378C13.4069 8.8427 14.2817 9.74857 15.3699 9.75H15.3701H18.0722V14.3998C18.0712 15.2043 17.4385 15.849 16.6665 15.85H10.4446C9.67261 15.849 9.03988 15.2043 9.03889 14.3998V6.40019C9.03988 5.59573 9.67253 4.95108 10.4445 4.95H13.4056ZM14.4833 7.73347V5.73505L17.3173 8.65H15.3702C14.8845 8.64959 14.4837 8.2435 14.4833 7.73347Z'
                    fill='#102C97'
                    stroke='#102C97'
                    strokeWidth='0.3'
                  />
                </svg>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='1.25rem'
                  height='1.25rem'
                  viewBox='0 0 24 24'
                  fill='none'>
                  <path
                    d='M10.2284 2.98828C9.71747 2.99048 9.22811 3.19442 8.86684 3.55569C8.50557 3.91696 8.30164 4.40632 8.29943 4.91723V5.5585H3.64189C3.47113 5.5585 3.30737 5.62633 3.18662 5.74707C3.06588 5.86782 2.99805 6.03158 2.99805 6.20234C2.99805 6.3731 3.06588 6.53686 3.18662 6.6576C3.30737 6.77835 3.47113 6.84618 3.64189 6.84618H4.79436L5.56182 18.2151C5.66483 19.7217 6.8392 21.0145 8.40374 21.0145H15.5941C17.1587 21.0145 18.333 19.7217 18.4361 18.2151L19.2055 6.84618H20.356C20.5268 6.84618 20.6905 6.77835 20.8113 6.6576C20.932 6.53686 20.9998 6.3731 20.9998 6.20234C20.9998 6.03158 20.932 5.86782 20.8113 5.74707C20.6905 5.62633 20.5268 5.5585 20.356 5.5585H15.6985V4.91723C15.6962 4.40632 15.4923 3.91696 15.131 3.55569C14.7698 3.19442 14.2804 2.99048 13.7695 2.98828H10.2284ZM10.2284 4.27596H13.7695C13.8543 4.27366 13.9388 4.28867 14.0176 4.32008C14.0965 4.35149 14.1681 4.39864 14.2281 4.45865C14.2881 4.51867 14.3352 4.59028 14.3667 4.66912C14.3981 4.74797 14.4131 4.83239 14.4108 4.91723V5.5585H9.58196V4.91723C9.58196 4.5496 9.86075 4.27596 10.2284 4.27596ZM6.07754 6.84618H17.9152L17.1535 18.125C17.0904 19.0483 16.4653 19.7269 15.5941 19.7269H8.40374C7.53262 19.7269 6.90745 19.0483 6.84435 18.125L6.07754 6.84618Z'
                    fill='#102C97'
                  />
                  <path
                    d='M10.0692 9.42188C9.89859 9.42252 9.73519 9.49075 9.61478 9.61161C9.49437 9.73248 9.42676 9.89613 9.42676 10.0667V16.5032C9.42611 16.6738 9.4931 16.8377 9.61305 16.959C9.73301 17.0804 9.89615 17.1492 10.0668 17.1505C10.1519 17.1508 10.2362 17.1343 10.3148 17.1019C10.3935 17.0695 10.465 17.0218 10.5252 16.9616C10.5854 16.9015 10.633 16.83 10.6654 16.7513C10.6979 16.6726 10.7144 16.5883 10.7141 16.5032V10.0667C10.7141 9.98185 10.6973 9.8978 10.6647 9.8194C10.6322 9.741 10.5845 9.66981 10.5243 9.60989C10.4642 9.54998 10.3928 9.50254 10.3143 9.47027C10.2358 9.43801 10.1516 9.42156 10.0668 9.42188H10.0692ZM13.9311 9.42188C13.76 9.42188 13.596 9.48982 13.4751 9.61076C13.3541 9.73169 13.2862 9.89571 13.2862 10.0667V16.5032C13.2859 16.5881 13.3023 16.6722 13.3346 16.7507C13.3669 16.8293 13.4143 16.9006 13.4742 16.9608C13.5341 17.0209 13.6053 17.0686 13.6837 17.1012C13.7621 17.1337 13.8462 17.1505 13.9311 17.1505C14.0158 17.1502 14.0995 17.1332 14.1777 17.1005C14.2558 17.0678 14.3267 17.02 14.3863 16.9599C14.446 16.8998 14.4932 16.8285 14.5253 16.7502C14.5575 16.6718 14.5738 16.5879 14.5735 16.5032V10.0667C14.5735 9.89613 14.5059 9.73248 14.3855 9.61161C14.2651 9.49075 14.1017 9.42252 13.9311 9.42188Z'
                    fill='#102C97'
                  />
                </svg>
              </div> */}
            </div>
          ))
          :
          <Empty sign="negative"/>
        )}
      </div>
    </>
  );
};

export default Report;
