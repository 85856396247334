import React, { useMemo, useRef } from "react";
import "./CandidateDashBoard.scss";

import tableData from "./data";
import leftArrow from "./images/left_arrow.svg";
import rightArrow from "./images/right_arrow.svg";
import { useState, useEffect } from "react";
import Progress from "./Progress/Progress";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getValue } from "@mui/system";
import Actions from "./Actions/Actions";
import {
  json,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import ConfirmationModal from "./Actions/ConfirmationModal";
import {
  createCustomtablesInfo,
  fetchCandidateStatusDetailsNew,
} from "../../redux/actions/VendorCandidateDetails";
import { clientCancelOrderDetails } from "../../redux/actions/CompanyBeginVerificationAction";
import { Visibility } from "@mui/icons-material";
import { Typography } from "@mui/material";
import UpdateModal from "./Actions/UpdateModal";
import FullPageLoader from "../Loader/FullPageLoader";
import CustomAlert from "../ReusableComponents/CustomAlert";

function CandidateDashBoard() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { status } = useParams();
  const [selectedStatus, setSelectedStatus] = useState([]);
  const hasMounted = useRef(false);
  const [hiddenColumn, setHiddenColumn] = useState({});
  const [tempHiddenColumn, setTempHiddenColumn] = useState({});
  const [sorting, setSorting] = useState([]);

  const [SuccessFailed, setSuccessFailed] = useState("Success");
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState("");
  const [Alert, setAlert] = useState(false);

  const [selectedClass, setClass] = useState("");
  const [filterationDiv, showFilteration] = useState(false);
  const [checkedValues, setCheckedValues] = useState([]);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [trigger, setTrigger] = useState(0);
  const darkMode = localStorage.getItem("Theme") === "true" ? true : false;
  const { data } = useSelector((state) => state.fetchCandidateStatusDetailsNew);
  const apiData = data?.data?.Over_all_data || [];
  const rowCount =
    useSelector(
      (state) =>
        state.fetchCandidateStatusDetailsNew.data?.data?.Candidate_count
    ) || 0;
  const columnData = useSelector(
    (state) => state.fetchCandidateStatusDetailsNew.data?.data
  );
  const handleView = (data) => {
    navigate(
      `/company/candidate-details/view/${data.username}/${data.client_info}`
    );
  };

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [manualColumnFilters, setManualColumnFilters] = useState([]);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [globalFilter, setGlobalFilter] = useState(query || "");
  const [cancelModal, setCancelModal] = useState(false);
  const [cancelModalTrigger, setCancelModalTrigger] = useState(false);
  const [cancelData, setCancelData] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [updateData, setUpdateData] = useState(false);
  const [candidateId, setCandidateId] = useState("");
  const handleCancelUpdate = () => {
    setUpdateModal(false);
  };
  const columns = useMemo(
    () => [
      {
        accessorKey: "is_do_it_by_yourself",
        header: false,
        enableSorting: false,
        enableColumnActions: false,
        enableColumnOrdering: false,
        enableHiding: false,
        enablePinning: false,
        enableResizing: false,
        enableColumnFilter: false,
        maxSize: 25,
        Cell: ({ row }) => {
          const doitYourself = row.original.is_do_it_by_yourself;
          const isPreOnboarding = row.original.is_pre_onboarding;
          return (
            <>
              {(doitYourself === 1 || isPreOnboarding === 1) && (
                <div
                  className={`${
                    doitYourself === 1 ? `flagContainerActive` : `flagContainer`
                  }`}>
                  {isPreOnboarding === 1 && (
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='1.25rem'
                      height='1.25rem'
                      viewBox='0 0 20 20'
                      fill='none'
                      style={{ paddingLeft: "0.25rem" }}>
                      <path
                        d='M16.3092 18.6948C15.6428 19.1695 11.3667 16.1605 10.5477 16.1538C9.7287 16.1473 5.40445 19.0871 4.74579 18.6017C4.08713 18.1164 5.6356 13.1313 5.38883 12.3526C5.14207 11.5739 1.00185 8.38139 1.2612 7.60677C1.5206 6.83214 6.75372 6.76019 7.42015 6.2855C8.08658 5.81087 9.85209 0.897991 10.6711 0.904548C11.49 0.911161 13.1758 5.85181 13.8344 6.33716C14.4931 6.82245 19.7244 6.97866 19.9713 7.75734C20.218 8.53601 16.0268 11.6615 15.7674 12.4361C15.508 13.2108 16.9756 18.2201 16.3092 18.6948Z'
                        fill='url(#paint0_linear_13706_73465)'
                      />
                      <defs>
                        <linearGradient
                          id='paint0_linear_13706_73465'
                          x1='1.24954'
                          y1='9.82507'
                          x2='19.9817'
                          y2='9.82507'
                          gradientUnits='userSpaceOnUse'>
                          <stop stopColor='#FCD635' />
                          <stop
                            offset='1'
                            stopColor='#F7A928'
                          />
                        </linearGradient>
                      </defs>
                    </svg>
                  )}
                </div>
              )}
            </>
          );
        },
      },
      {
        accessorKey: "job_seeker_id",
        header: "Job Seeker id",
      },
      {
        accessorKey: "full_name",
        header: "Candidate Name",
        size: 225,
        enableHiding: false,
      },
      {
        accessorKey: "request_number",
        header: "Request Number",
      },
      {
        accessorKey: "initiated_time",
        header: "Created at",
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const date = dayjs(value, "DD-MM-YYYY HH:mm:ss Z");
          return date.isValid() ? date.format("MM/DD/YYYY") : "Invalid Date";
        },
        size: 340,
      },
      {
        accessorKey: "case_pending_time",
        header: "Initiated Date",
        // filterVariant: "date-range",
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const date = dayjs(value);
          return date.isValid() ? date.format("MM/DD/YYYY") : "No data";
        },
        size: 340,
      },
      {
        accessorKey: "bgv_code",
        header: "Pack Name",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          return Array.isArray(value) ? value.join(", ") : value;
        },
      },
      {
        accessorKey: "candidate_status",
        header: "Current Status",
        filterVariant: "multi-select",
        enableSorting: false,
        filterSelectOptions: [
          { label: "Invitation", value: "invitation" },
          { label: "Accepted", value: "accepted" },
          { label: "Re-initiated", value: "re-initiated" },
          { label: "In Progress", value: "inprogress" },
          { label: "Pending", value: "pending" },
          { label: "Cancelled", value: "cancelled" },
          { label: "Completed", value: "completed" },
        ],
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <>
              <div className={`status ${status}`}>{status}</div>
            </>
          );
        },
      },
      {
        accessorKey: "user_email",
        header: "Email",
      },
      {
        accessorKey: "user_phone",
        header: "Phone",
      },
      {
        id: "mrt-row-actions",
        header: "Actions",
        enableHiding: false,
        enablePinning: true,
        enableColumnOrdering: false,
        size: 150,
        Cell: ({ row }) => (
          <Actions
            data={row.original}
            showUpdate={data?.data?.update_checks}
            handleView={handleView}
            handleCancel={handleCancelModal}
            handleUpdate={handleUpdateModal}
          />
        ),
      },
    ],
    []
  );

  console.log("columns", columns);

  const getMRTTheme = (darkMode) => ({
    baseBackgroundColor: darkMode ? "#F5F5F5" : "#f4f6f8",
    draggingBorderColor: darkMode ? "#444444" : "#cccccc",
    matchHighlightColor: darkMode ? "#ffeb3b" : "#ffeb3b",
    menuBackgroundColor: darkMode ? "#2c2c2c" : "#ffffff",
    pinnedRowBackgroundColor: darkMode ? "#3c3c3c" : "#e0e0e0",
    selectedRowBackgroundColor: darkMode ? "#424242" : "#d1eaff",
  });

  const table = useMaterialReactTable({
    columns,
    data: apiData,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableColumnPinning: true,
    paginationDisplayMode: "pages",
    getRowId: (row) => row.user_id,
    initialState: {
      density: "compact",
      columnPinning: {
        right: ["mrt-row-actions"], // Pin the actions column to the right
      },
      columnOrder: columnOrder,
    },
    muiCircularProgressProps: {
      Component: <FullPageLoader />,
    },
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 150,
        enablePinning: true,
        grow: false,
      },
    },

    enableDensityToggle: true,
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: (newSorting) => {
      setSorting(newSorting);
      setPagination({ ...pagination, pageIndex: 0 });
    },
    muiTableContainerProps: { sx: { height: "calc(100% - 8rem)" } },
    onColumnOrderChange: (newOrder) => {
      const modifiedOrder = newOrder.map((col) => {
        const specificData = columnData?.Custom_tables?.find(
          (withVis) => withVis.key_name === col
        );
        return { ...specificData, key_name: col };
      });
      if (hasMounted.current) {
        createCustomtablesInfoFn(modifiedOrder);
      }
      // createCustomtablesInfoFn(newOrder);
      // setColumnOrder(newOrder); // Update the state with the new column order
      // console.log("New column order:", newOrder); // Log the new order
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    enableColumnResizing: true,
    // onColumnFiltersChange: setColumnFilters,
    // onGlobalFilterChange: setGlobalFilter,
    onPaginationChange: setPagination,
    // onSortingChange: setSorting,
    rowCount,
    positionActionsColumn: "last",
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      columnFilters,
      globalFilter,
      sorting,
      // showProgressBars: isRefetching,
      // showLoadingOverlay: true,
      columnOrder,
      columnVisibility: hiddenColumn,
      // sorting,
    },
    muiTopToolbarProps: {
      sx: {
        background: darkMode ? "#333333" : "#ffffff",
        color: darkMode ? "#ffffff" : "#333333",
      },
    },
    mrtTheme: getMRTTheme(darkMode),
    // onColumnVisibilityChange: setTempHiddenColumn,
    onColumnVisibilityChange: (newColumnVisibility) => {
      // Example to handle column visibility
      setTempHiddenColumn(newColumnVisibility);
      console.log(newColumnVisibility); // Log the visibility state for debugging
    },

    // (newColumnVisibility, column, isVisible) => {
    //   if (!isVisible) {
    //     // Perform your function when a column is hidden
    //     console.log(column, newColumnVisibility);
    //   }
    //   console.log(column, newColumnVisibility, isVisible);
    // },
    localization: {
      filterFuzzy: "Filtering by",
    },
  });

  const handleCheckboxChange = (event) => {
    const { value, checked } = event.target;

    if (checked) {
      setCheckedValues([...checkedValues, value]);
    } else {
      setCheckedValues(checkedValues.filter((item) => item !== value));
    }
  };

  // useEffect(() => {
  //   setCurrentPage(1);
  //   if (selectedClass === "All") {
  //     setCurrentData(tableData);
  //   } else if (selectedClass) {
  //     setCurrentData(tableData.filter((val) => val.status === selectedClass));
  //   }
  // }, [selectedClass]);

  const fetchCandidateDashboard = () => {
    setIsLoading(true);
    setIsError(false);
    const clientInfoID = localStorage.getItem("client_info_id");
    const masterID = localStorage.getItem("master_id");
    const clientId = localStorage.getItem("user_id");

    const data = {
      master_id: masterID,
      client_info_id: clientInfoID,
      client_id: clientId,
      module_name: "candidate_details",
      // defaulttable_info_id: "1",
      // user_status: status === "all" ? "" : status,
      search_query: globalFilter,
      user_status: selectedStatus,
      sorting_query: sorting?.[0]?.id,
      sorting_type: sorting?.[0]?.desc ? "False" : "True",
      filter_queries: manualColumnFilters,
      offset: pagination.pageIndex * pagination.pageSize,
      limits: pagination.pageSize,
    };

    const successFn = (response) => {
      setIsLoading(false);
      console.log(
        "recent search Data fetched successfully",
        response.data.Over_all_data
      );
      // setCandidateInformation(response.data.Over_all_data);
      // setTotalNoOfPages(
      //   Math.ceil(response.data.Candiadte_count / itemsPerPage)
      // );
    };

    const failureFn = (error) => {
      setIsLoading(false);
      setIsError(true);
      console.error("Logged-in failed", error);
      alert(error);
    };

    dispatch(fetchCandidateStatusDetailsNew({ data, successFn, failureFn }));
  };

  const createCustomtablesInfoFn = (newOrder) => {
    const clientInfoID = localStorage.getItem("client_info_id");
    const masterID = localStorage.getItem("master_id");
    const clientId = localStorage.getItem("user_id");

    const data = {
      custom_table_name: newOrder,
      client: clientId,
      master: masterID,
      client_info: clientInfoID,
      // defaulttable_info: "1",
      module_name: "candidate_details",
    };

    const successFn = (response) => {
      console.log(
        "recent search Data fetched successfully",
        response.data.Over_all_data
      );
      setTrigger(trigger + 1);
      // setCandidateInformation(response.data.Over_all_data);
      // setTotalNoOfPages(
      //   Math.ceil(response.data.Candiadte_count / itemsPerPage)
      // );
    };

    const failureFn = (error) => {
      // setIsLoading(false);
      // setIsError(true);
      // console.error("Logged-in failed", error);
      alert(error);
    };

    dispatch(createCustomtablesInfo({ data, successFn, failureFn }));
  };
  useEffect(() => {
    console.log("manualColumnFilters", manualColumnFilters);
    fetchCandidateDashboard();
  }, [
    pagination,
    trigger,
    manualColumnFilters,
    selectedStatus,
    globalFilter,
    sorting,
  ]);
  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 });

    const formattedData = columnFilters
      .map((item) => {
        if (
          (item.id === "initiated_time" || item.id === "case_pending_time") &&
          Array.isArray(item.value)
        ) {
          const isValidDate = (date) => {
            if (!(date instanceof Date) || isNaN(date.getTime())) {
              return false;
            }
            const year = date.getFullYear();
            return year.toString().length === 4;
          };

          const [startDateStr, endDateStr] = item.value;
          const startDate = startDateStr ? new Date(startDateStr) : null;
          const endDate = endDateStr ? new Date(endDateStr) : null;
          if (!isValidDate(startDate) && !isValidDate(endDate)) {
            return null;
          }
          console.log("startDate", startDate);
          return {
            ...item,
            value: {
              start_date: isValidDate(startDate)
                ? startDate.toISOString().split("T")[0]
                : null,
              end_date: isValidDate(endDate)
                ? endDate.toISOString().split("T")[0]
                : null,
            },
          };
        } else if (item.id === "candidate_status") {
          setSelectedStatus(item.value);
          return;
        }
        return item;
      })
      .filter(Boolean); // Filter out invalid date entries or undefined items

    const hasCandidateStatus = columnFilters.some(
      (item) => item.id === "candidate_status"
    );
    if (!hasCandidateStatus) {
      setSelectedStatus([]);
    }

    // Only set manualColumnFilters if we have valid filters
    if (formattedData.length > 0) {
      setManualColumnFilters(formattedData);
    } else setManualColumnFilters([]);
    console.log("columnFilters", columnFilters, formattedData);
  }, [columnFilters]);

  // useEffect(() => {
  //   setPagination({ ...pagination, pageIndex: 0 });
  //   setSelectedStatus(status);
  //   setTrigger(trigger + 1);
  // }, [status]);

  useEffect(() => {
    if (query) {
      setGlobalFilter(query);
    } else {
      setGlobalFilter("");
    }
  }, [searchParams]);

  const statusClass = (status) => {
    switch (status) {
      case "Completed":
        return "completed";
      case "Pending":
        return "pending";
      case "InProgress":
        return "inProgress";
      case "Accepted":
        return "accepted";
      case "Reinitiated":
        return "reInitiated";
      case "Invited":
        return "invited";
      case "Cancelled":
        return "cancelled";
      default:
        return "";
    }
  };
  const handleCancelModal = (data) => {
    setCancelModal(true);
    setCancelData(data);
  };

  const handleCancelClick = (cancelData) => {
    const ClientID = localStorage.getItem("user_id");
    const clientInfoID = localStorage.getItem("client_info_id");
    console.log(cancelData);
    const data = {
      candidate_id:
        cancelData.is_do_it_by_yourself === 1
          ? cancelData?.user_id
          : cancelData.candidate_status === "invitation"
          ? cancelData?.candidate_id
          : cancelData?.user_id,
      client_info_id: clientInfoID,
      client_id: ClientID,
    };

    const successFn = (response) => {
      setCancelModal(false);
      setSuccessFailed("Success");
      setSuccessFailedMessage(response.message);
      setAlert(true);
    };

    const failureFn = (error) => {
      setSuccessFailed("Failed");
      setSuccessFailedMessage(error);
      setAlert(true);
    };

    dispatch(clientCancelOrderDetails({ data, successFn, failureFn }));
  };

  useEffect(() => {
    if (!Alert) {
      fetchCandidateDashboard();
    }
  }, [Alert]);

  const handleUpdateModal = (data) => {
    setUpdateModal(true);
    setUpdateData(data);
  };

  useEffect(() => {
    const newDefaultOrder = columnData?.Default_tables?.map(
      (column) => column?.key_name
    );
    const newCustomOrder = columnData?.Custom_tables?.map(
      (column) => column?.key_name
    );
    setColumnOrder(
      newCustomOrder?.length === 0
        ? //  newDefaultOrder : newDefaultOrder

          newDefaultOrder
        : newCustomOrder
    );
    const visibilityChanges = columnData?.Custom_tables?.reduce((acc, item) => {
      acc[item.key_name] = item.visibility === false ? false : true;
      return acc;
    }, {});
    setHiddenColumn(visibilityChanges);
  }, [columnData]);

  useEffect(() => {
    const updatedColumnData = columnData?.Custom_tables?.map((column) => {
      if (tempHiddenColumn.hasOwnProperty(column.key_name)) {
        return {
          ...column,
          visibility: tempHiddenColumn[column.key_name],
        };
      }
      return column;
    });

    if (hasMounted.current) {
      createCustomtablesInfoFn(updatedColumnData);
    }
  }, [tempHiddenColumn]);

  useEffect(() => {
    hasMounted.current = true;
  }, []);

  return (
    <div className='dashBoarBg'>
      {cancelModal && (
        <ConfirmationModal
          data={cancelData}
          handleConfirm={() => handleCancelClick(cancelData)}
          handleReject={() => {
            setCancelModal(false);
          }}
        />
      )}
      {updateModal && (
        <UpdateModal
          cid={
            updateData.is_do_it_by_yourself === 1
              ? updateData?.user_id
              : updateData.candidate_status === "invitation"
              ? updateData?.candidate_id
              : updateData?.user_id
          }
          handleCancel={handleCancelUpdate}
          fetchDashboard={fetchCandidateDashboard}
        />
      )}
      <div className='datafilteration'>
        <div className='sortingBtns'>
          <div>{/* <img src={sort} alt="" /> */}</div>
        </div>
        <button
          onClick={() =>
            navigate("/company/candidate-details/begin-verification/")
          }>
          Begin Verification
        </button>
      </div>
      {/* <Progress
        setClass={setClass}
        selectedClass={selectedClass}
      /> */}

      {!data ? (
        <div className='fullPageLoaderCont'>
          <FullPageLoader />
        </div>
      ) : (
        <div className='table'>
          <div className='tableBox'>
            <div className='tableContainer'>
              {<MaterialReactTable table={table} />}
            </div>
          </div>
        </div>
      )}
      {Alert && (
        <CustomAlert
          type={SuccessFailed}
          message={SuccessFailedMessage}
          handleClose={() => {
            setSuccessFailed("");
            setSuccessFailedMessage("");
            setAlert(false);
            setCancelModalTrigger(true);
          }}
        />
      )}
    </div>
  );
}

export default CandidateDashBoard;
