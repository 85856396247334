import React, { useState, useEffect } from "react";
import "./Package.scss";

function Package({ packName, data, activePack, setActivePack }) {
  const [selectedServiceProviders, setSelectedServiceProviders] = useState([]);
  const [totalAmount, setTotalAmount] = useState(0);

  useEffect(() => {
    if (packName === "Custom package") {
      const total = data.reduce((sum, item) => {
        if (selectedServiceProviders.includes(item.serviceprovider_id)) {
          return sum + item.valiant_rate_tax;
        }
        return sum;
      }, 0);
      setTotalAmount(total);
    } else {
      setTotalAmount(data.total_amount);
    }
  }, [selectedServiceProviders, data, packName]);

  const handleCheckboxChange = (serviceprovider_id, rate_tax) => {
    setSelectedServiceProviders(prevSelected => {
      if (prevSelected.includes(serviceprovider_id)) {
        return prevSelected.filter(id => id !== serviceprovider_id);
      } else {
        return [...prevSelected, serviceprovider_id];
      }
    });
  };

  const itemsToDisplay = packName === "Custom package" ? data : data?.checks || [];

  return (
    <div
      className={`packageContainer ${activePack === packName && packName !== "Custom package" ? "active" : ""}`}
      onClick={() => {
        if (packName !== "Custom package") {
          setActivePack(packName);
        }
      }}
      
    >
      <div className="pricingDiv">
        <p className="packageName">{packName}</p>
        <p className="princing">₹ {totalAmount.toFixed(2)} /-</p>
        <p>* Inclusive of all taxes</p>
      </div>

      <div className="checksList">
        {itemsToDisplay.map((val, idx) => (
          <div key={idx}>
            <p>{val.display_name}</p>
            <input
              type="checkbox"
              checked={packName === "Custom package" ? selectedServiceProviders.includes(val.serviceprovider_id) : true}
              onChange={() => packName === "Custom package" && handleCheckboxChange(val.serviceprovider_id, val.valiant_rate_tax)}
              disabled={packName !== "Custom package"} // Disable checkboxes for non-custom packages
            />
          </div>
        ))}
      </div>
    </div>
  );
}

export default Package;
