import React, { useEffect } from 'react';
import { pdfjs } from "react-pdf";
import PdfViewer from '../../../BeginVerification/PdfViewer/PdfViewer';
import createImageViewer from '../../../ReusableComponents/ImagePreview';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

// Utility function to check if the file is an image
const isImage = (fileUrl) => {
  return fileUrl.startsWith('data:image/') || /\.(jpg|jpeg|png|gif)$/i.test(fileUrl);
};

// Utility function to check if the file is a PDF
const isPDF = (fileUrl) => {
  return fileUrl.startsWith('data:application/pdf') || /\.pdf$/i.test(fileUrl);
};

const IdInformation = ({details}) => {
  useEffect(() => {
    createImageViewer()
  },[])
  const Details = details && details
  return (
    <main className='IdInformation'>
      {Details &&
        <section className='Content'>
          {Details?.length > 0 && Details?.map((data, index) => (
            <div className='IDCheck' key={index}>
              <h3>{data?.document_name && data?.document_name.replace(' Check', '')}</h3>
              <span>{data?.document_no}</span>
              <div className='IDCheckContainer'>
                {isImage(data?.document_url) ? (
                  <img src={data?.document_url} alt={`Document ${index}`} draggable={false} />
                ) : isPDF(data?.document_url) ? (
                  <div className='pdfViewer'>
                    <PdfViewer pdf={data?.document_url} showPagination={false}/>
                    <a href={data?.document_url} target="_blank" rel="noopener noreferrer" download>
                      Download {data?.document_name}
                    </a>
                  </div>
                ) : null}
              </div>
            </div>
          ))}
        </section>
      }
    </main>
  )
}

export default IdInformation