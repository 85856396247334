import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api/index";

const createAccountAsyncThunk = (method, type, contentType, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);
    const token = localStorage.getItem("token");
    const apiRequest = {
      method: method,
      headers: token
        ? {
            "Content-Type": contentType,
            Authorization: "Token " + token,
          }
        : { "Content-Type": contentType },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
    
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      throw error;
    }
  });

export const userLogin = createAccountAsyncThunk(
  "post",
  "userLogin",
  "application/json",
  "ClientUser/client_login/"
);
export const forgotPassword = createAccountAsyncThunk(
  "post",
  "forgotPassword",
  "application/json",
  "ClientUser/forgot_password/"
);

export const setNewPassword = createAccountAsyncThunk(
  "post",
  "setNewPassword",
  "application/json",
  "ClientUser/set_new_password/"
);

export const getClientAccessControl = createAccountAsyncThunk(
  "post",
  "getClientAccessControl",
  "application/json",
  "ClientUser/get_client_access_control/"
);


export const userLogout = createAccountAsyncThunk(
  "post",
  "userLogout",
  "application/json",
  "ClientUser/logout/"
);

export const licenseVerification = createAccountAsyncThunk(
  "post",
  "licenseVerification",
  "application/json",
  "ClientUser/license_key/"
);

export const setPassword = createAccountAsyncThunk(
  "post",
  "setPassword",
  "application/json",
  "ClientUser/add_client/"
);