// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.pagination {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.6rem;
  background-color: var(--Foreground);
  padding: 0.6rem 1.25rem;
  border-radius: 0.5rem;
}
.pagination button {
  padding: 0.18rem 0.3rem;
  border-radius: 0.3rem;
  color: var(--TextColor);
  font-size: 0.7rem;
  background-color: var(--Background);
}
.pagination .pageButton {
  background-color: var(--AccentColor);
  color: white;
}
.pagination .pageButton:disabled {
  cursor: not-allowed;
  opacity: 0.4;
}
.pagination .dataPerpage {
  margin-left: 2rem;
}
.pagination .dataPerpage select {
  border: 1px solid var(--AccentColor);
  border-radius: 0.25rem;
  color: var(--TextColor);
  background-color: var(--Foreground);
  font-size: 0.7rem;
  height: 1.5rem;
  width: 3rem;
  text-align: center;
}
.pagination .dataPerpage p {
  font-size: 0.8rem;
  color: var(--TextColor);
}
.pagination div {
  display: flex;
  align-items: center;
  gap: 0.6rem;
}`, "",{"version":3,"sources":["webpack://./src/Components/ReusableComponents/Pagination.scss"],"names":[],"mappings":"AAAA;EACI,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,WAAA;EACA,mCAAA;EACA,uBAAA;EACA,qBAAA;AACJ;AAAI;EACI,uBAAA;EACA,qBAAA;EACA,uBAAA;EACA,iBAAA;EACA,mCAAA;AAER;AACI;EACI,oCAAA;EACA,YAAA;AACR;AACQ;EACI,mBAAA;EACA,YAAA;AACZ;AAII;EACI,iBAAA;AAFR;AAGQ;EACI,oCAAA;EACA,sBAAA;EACA,uBAAA;EACA,mCAAA;EACA,iBAAA;EACA,cAAA;EACA,WAAA;EACA,kBAAA;AADZ;AAGQ;EACI,iBAAA;EACA,uBAAA;AADZ;AAKI;EACI,aAAA;EACA,mBAAA;EACA,WAAA;AAHR","sourcesContent":[".pagination{\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    gap: .6rem;\n    background-color: var(--Foreground);\n    padding: .6rem 1.25rem;\n    border-radius: 0.5rem;\n    button{\n        padding: .18rem 0.3rem;\n        border-radius: .3rem;\n        color: var(--TextColor);\n        font-size: 0.7rem;\n        background-color: var(--Background);\n    }\n    \n    .pageButton{\n        background-color: var(--AccentColor);      \n        color: white; \n\n        &:disabled{\n            cursor: not-allowed;\n            opacity: .4;\n        }\n        \n    }\n\n    .dataPerpage{\n        margin-left: 2rem;\n        select{\n            border: 1px solid var(--AccentColor);\n            border-radius: 0.25rem;\n            color: var(--TextColor);\n            background-color: var(--Foreground);\n            font-size: 0.7rem;\n            height: 1.5rem;\n            width: 3rem;\n            text-align: center;\n        }\n        p{\n            font-size: .8rem;\n            color: var(--TextColor);\n        }\n    }\n    \n    div{\n        display: flex;\n        align-items: center;\n        gap: .6rem;\n        // p{\n        //     font-size: 13px;\n        // }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
