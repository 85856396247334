import { createSlice } from "@reduxjs/toolkit";
import { userLogin, forgotPassword, setNewPassword, getClientAccessControl, userLogout, licenseVerification, setPassword } from "../actions/LoginAction";

const generateExtraReducers = (builder, asyncThunk) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // Call the success callback if provided
      if (typeof action.meta.arg.successFn === "function") {
        action.meta.arg.successFn(action.payload);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      // Call the failure callback if provided
      if (typeof action.meta.arg.failureFn === "function") {
        action.meta.arg.failureFn(action.error.message);
      }
    });
};

const createAsyncSlice = (name, asyncThunk) => createSlice({
  name,
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => generateExtraReducers(builder, asyncThunk)
});



const userLoginSlice = createAsyncSlice("userLogin", userLogin);
const forgotPasswordSlice = createAsyncSlice("forgotPassword", forgotPassword);
const setNewPasswordSlice = createAsyncSlice("setNewPassword", setNewPassword);
const getClientAccessControlSlice = createAsyncSlice("getClientAccessControl", getClientAccessControl);
const userLogoutSlice = createAsyncSlice("userLogout", userLogout);
const licenseVerificationSlice = createAsyncSlice("licenseVerification", licenseVerification);
const setPasswordSlice = createAsyncSlice("setPassword", setPassword);


export const reducers = {
    userLogin: userLoginSlice.reducer,
    forgotPassword: forgotPasswordSlice.reducer,
    setNewPassword: setNewPasswordSlice.reducer,
    getClientAccessControl: getClientAccessControlSlice.reducer,
    userLogout: userLogoutSlice.reducer,
    licenseVerification: licenseVerificationSlice.reducer,
    setPassword: setPasswordSlice.reducer,
};