import React, { useEffect, useMemo } from "react";
import "./BillingDetails.scss";
import { useState } from "react";
import { inVoiceData, transactionData } from "./BillingDatas";
import { useDispatch, useSelector } from "react-redux";
import { clientInvoiceTransactionList } from "../../redux/actions/CompanyBillingDetailsAction";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { DatePicker } from "@mui/x-date-pickers";
import dayjs from "dayjs";
import InvoiceModal from "./Modals/InvoiceModal";
import FullPageLoader from "../Loader/FullPageLoader";
function BillingDetails() {
  const dispatch = useDispatch();
  const darkMode = localStorage.getItem("Theme") === "true" ? true : false;
  const [selectedOption, setSelectedOptions] = useState("Invoice");
  const [currentPage, setCurrentPage] = useState(1);
  const [dataPerPage, setDataPerPage] = useState(10);
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10,
  });
  const [sorting, setSorting] = useState([]);
  const [dateRange, setDateRange] = useState({ start: null, end: null });
  const [customFilter, setCustomFilter] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [globalFilter, setGlobalFilter] = useState("");
  const [status, setStatus] = useState("");
  const [viewRecord, setViewRecord] = useState(false);
  const [viewRecordData, setViewRecordData] = useState([]);

  const rowCount =
    useSelector(
      (state) => state.clientInvoiceTransactionList.data?.billing_details_count
    ) || 0;
  const isLoading = useSelector(
    (state) => state.clientInvoiceTransactionList.loading
  );
  const [transactionList, setTransactionList] = useState([]);
  const data =
    selectedOption === "Invoice"
      ? inVoiceData
      : selectedOption === "Transaction"
      ? transactionData
      : null;
  const columns = useMemo(
    () => [
      {
        accessorKey: "client_invoice_id",
        header: "Invoice ID",
        sortDescFirst: false,
      },
      {
        accessorKey: "created_at",
        header: "Invoice Date",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const date = value?.slice(0, 10);
          return date ? date : "-";
        },
        sortDescFirst: false,
      },
      {
        accessorKey: "transaction_details.payment_method",
        header: "Payment Method",
        sortDescFirst: false,
      },
      {
        accessorKey: "client_checks.total_amount",
        header: "Total Amount",
        sortDescFirst: false,
      },
      {
        accessorKey: "is_paid",
        header: "Status",
        // enableSorting: false,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <>
              <div className={`Status ${status === 0 ? "Unpaid" : "Paid"}`}>
                {status === 0 ? "Unpaid" : "Paid"}
              </div>
            </>
          );
        },
        enableColumnActions: false,
        sortDescFirst: false,
      },
    ],
    []
  );
  const transactionColumns = useMemo(
    () => [
      {
        accessorKey: "order_id",
        header: "Order ID",
        sortDescFirst: false,
      },
      {
        accessorKey: "created_at",
        header: "Transaction Date",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const date = value?.slice(0, 10);
          return date ? date : "-";
        },
        sortDescFirst: false,
      },
      // {
      //   accessorKey: "transaction_details.payment_method",
      //   header: "Payment Method",
      // },
      {
        accessorKey: "total_amount",
        header: "Total Amount",
        sortDescFirst: false,
      },
      {
        accessorKey: "payment_status",
        header: "Status",
        // enableSorting: false,
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <>
              <div className={`Status ${status ? status : ""}`}>{status}</div>
            </>
          );
        },
        enableColumnActions: false,
        sortDescFirst: false,
      },
    ],
    []
  );
  const globalDateFilterFn = (rows, _columnIds, filterValue) => {
    if (dateRange.start || dateRange.end) {
      const { start, end } = filterValue;
      if (!start || !end) return rows;

      return rows.filter((row) => {
        const rowDate = dayjs(row.original.transactionDate);
        return rowDate.isAfter(dayjs(start)) && rowDate.isBefore(dayjs(end));
      });
    }
  };

  const getMRTTheme = (darkMode) => ({
    baseBackgroundColor: darkMode ? "#2b2b2b" : "#f5f5f5",
    draggingBorderColor: darkMode ? "#444444" : "#cccccc",
    matchHighlightColor: darkMode ? "#ffeb3b" : "#ffeb3b",
    menuBackgroundColor: darkMode ? "#2c2c2c" : "#ffffff",
    pinnedRowBackgroundColor: darkMode ? "#3c3c3c" : "#e0e0e0",
    selectedRowBackgroundColor: darkMode ? "#424242" : "#d1eaff",
  });
  const table = useMaterialReactTable({
    columns: selectedOption === "Transaction" ? transactionColumns : columns,
    data: transactionList,
    enableColumnFilters: false,
    enableHiding: false,
    enableStickyHeader: true,
    muiTableContainerProps: {
      sx: { maxHeight: "calc(100% - 7rem)", height: "calc(100% - 8rem)" },
    },
    enableRowActions: true,
    initialState: {
      columnPinning: {
        right: ["mrt-row-actions"], // Pin the actions column to the right
      },
    },
    muiCircularProgressProps: {
      Component: <FullPageLoader />,
    },
    paginationDisplayMode: "pages",
    manualPagination: true,
    onPaginationChange: setPagination,
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,
    muiSearchTextFieldProps: {
      placeholder: "Search by Invoice Id & Amount",
      style: { width: "22rem" },
    },
    rowCount,
    manualSorting: true,
    onSortingChange: setSorting,
    renderRowActions: ({ row }) => (
      <div className='actions'>
        <svg
          onClick={() => {
            setViewRecord(true);
            console.log(row);
            setViewRecordData(row.original);
          }}
          xmlns='http://www.w3.org/2000/svg'
          width='1.25rem'
          height='1.25rem'
          viewBox='0 0 21 20'
          fill='none'>
          <path
            d='M10.5009 4.6875C11.9974 4.68853 13.4645 5.10285 14.7405 5.88477C16.0165 6.66669 17.0518 7.78582 17.7321 9.11875C17.871 9.39175 17.9434 9.69371 17.9434 10C17.9434 10.3063 17.871 10.6082 17.7321 10.8812C17.0503 12.2121 16.0144 13.329 14.7385 14.109C13.4626 14.8889 11.9962 15.3016 10.5009 15.3016C9.00548 15.3016 7.53911 14.8889 6.26322 14.109C4.98734 13.329 3.95144 12.2121 3.26961 10.8812C3.13074 10.6082 3.05836 10.3063 3.05836 10C3.05836 9.69371 3.13074 9.39175 3.26961 9.11875C3.94995 7.78582 4.9852 6.66669 6.2612 5.88477C7.53719 5.10285 9.00435 4.68853 10.5009 4.6875ZM4.38211 10.3125C4.95905 11.4386 5.83558 12.3837 6.91517 13.0436C7.99476 13.7036 9.23554 14.0528 10.5009 14.0528C11.7662 14.0528 13.007 13.7036 14.0866 13.0436C15.1661 12.3837 16.0427 11.4386 16.6196 10.3125C16.6745 10.2175 16.7033 10.1097 16.7033 10C16.7033 9.89029 16.6745 9.78251 16.6196 9.6875C16.0427 8.56136 15.1661 7.61629 14.0866 6.95636C13.007 6.29642 11.7662 5.94723 10.5009 5.94723C9.23554 5.94723 7.99476 6.29642 6.91517 6.95636C5.83558 7.61629 4.95905 8.56136 4.38211 9.6875C4.32725 9.78251 4.29838 9.89029 4.29838 10C4.29838 10.1097 4.32725 10.2175 4.38211 10.3125Z'
            fill='var(--AccentColor)'
          />
          <path
            d='M10.5 7.1875C11.0563 7.1875 11.6 7.35245 12.0625 7.66149C12.5251 7.97053 12.8855 8.40979 13.0984 8.9237C13.3113 9.43762 13.367 10.0031 13.2585 10.5487C13.1499 11.0943 12.8821 11.5954 12.4887 11.9887C12.0954 12.3821 11.5943 12.6499 11.0487 12.7585C10.5031 12.867 9.93762 12.8113 9.4237 12.5984C8.90978 12.3855 8.47053 12.0251 8.16149 11.5625C7.85245 11.1 7.6875 10.5563 7.6875 10C7.68915 9.25459 7.986 8.54017 8.51308 8.01309C9.04017 7.486 9.75458 7.18915 10.5 7.1875ZM10.5 11.5625C10.809 11.5625 11.1111 11.4709 11.3681 11.2992C11.625 11.1275 11.8253 10.8835 11.9436 10.5979C12.0618 10.3124 12.0928 9.99827 12.0325 9.69517C11.9722 9.39208 11.8234 9.11367 11.6049 8.89515C11.3863 8.67663 11.1079 8.52781 10.8048 8.46752C10.5017 8.40723 10.1876 8.43818 9.90206 8.55644C9.61655 8.6747 9.37252 8.87497 9.20083 9.13192C9.02914 9.38887 8.9375 9.69097 8.9375 10C8.9375 10.4144 9.10212 10.8118 9.39514 11.1049C9.68817 11.3979 10.0856 11.5625 10.5 11.5625Z'
            fill='var(--AccentColor)'
          />
        </svg>
        {selectedOption === "Invoice" && (
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1.25rem'
            height='1.25rem'
            viewBox='0 0 21 20'
            fill='none'
            onClick={() => handleDownload(row.original.client_invoice_url)}>
            <path
              d='M15.4 11.2C15.2409 11.2 15.0883 11.2632 14.9757 11.3757C14.8632 11.4883 14.8 11.6409 14.8 11.8V14.2C14.8 14.3591 14.7368 14.5117 14.6243 14.6243C14.5117 14.7368 14.3591 14.8 14.2 14.8H5.8C5.64087 14.8 5.48826 14.7368 5.37574 14.6243C5.26321 14.5117 5.2 14.3591 5.2 14.2V11.8C5.2 11.6409 5.13679 11.4883 5.02426 11.3757C4.91174 11.2632 4.75913 11.2 4.6 11.2C4.44087 11.2 4.28826 11.2632 4.17574 11.3757C4.06321 11.4883 4 11.6409 4 11.8V14.2C4 14.6774 4.18964 15.1352 4.52721 15.4728C4.86477 15.8104 5.32261 16 5.8 16H14.2C14.6774 16 15.1352 15.8104 15.4728 15.4728C15.8104 15.1352 16 14.6774 16 14.2V11.8C16 11.6409 15.9368 11.4883 15.8243 11.3757C15.7117 11.2632 15.5591 11.2 15.4 11.2ZM9.574 12.226C9.63106 12.2806 9.69835 12.3234 9.772 12.352C9.84382 12.3837 9.92148 12.4001 10 12.4001C10.0785 12.4001 10.1562 12.3837 10.228 12.352C10.3017 12.3234 10.3689 12.2806 10.426 12.226L12.826 9.826C12.939 9.71302 13.0025 9.55978 13.0025 9.4C13.0025 9.24022 12.939 9.08698 12.826 8.974C12.713 8.86102 12.5598 8.79755 12.4 8.79755C12.2402 8.79755 12.087 8.86102 11.974 8.974L10.6 10.354V4.6C10.6 4.44087 10.5368 4.28826 10.4243 4.17574C10.3117 4.06321 10.1591 4 10 4C9.84087 4 9.68826 4.06321 9.57574 4.17574C9.46321 4.28826 9.4 4.44087 9.4 4.6V10.354L8.026 8.974C7.97006 8.91806 7.90364 8.87368 7.83055 8.8434C7.75746 8.81313 7.67912 8.79755 7.6 8.79755C7.52088 8.79755 7.44254 8.81313 7.36945 8.8434C7.29636 8.87368 7.22994 8.91806 7.174 8.974C7.11806 9.02994 7.07368 9.09636 7.0434 9.16945C7.01313 9.24254 6.99755 9.32088 6.99755 9.4C6.99755 9.47912 7.01313 9.55746 7.0434 9.63055C7.07368 9.70364 7.11806 9.77006 7.174 9.826L9.574 12.226Z'
              fill='var(--AccentColor)'
            />
          </svg>
        )}
      </div>
    ),
    positionActionsColumn: "last",
    displayColumnDefOptions: {
      "mrt-row-actions": {
        size: 100,
        grow: false,
      },
    },
    mrtTheme: getMRTTheme(darkMode),
    state: { pagination, sorting, isLoading },
  });

  const paginationComp = () => {
    const len = Math.ceil(data.length / dataPerPage);
    return (
      <>
        {Array.from({ length: len }).map((_, index) => (
          <button
            key={index}
            className={`${currentPage === index + 1 ? "pageButton" : ""}`}
            onClick={() => setCurrentPage(index + 1)}>
            {index + 1}
          </button>
        ))}
      </>
    );
  };
  const fetchBillingDetails = () => {
    // setIsLoading(true);
    // setIsError(false);
    const clientInfoID = localStorage.getItem("client_info_id");
    const masterID = localStorage.getItem("master_id");
    const clientId = localStorage.getItem("user_id");

    const data = {
      // master_id: masterID,
      client_info_id: clientInfoID,
      // client_id: clientId,
      billing_method:
        selectedOption === "Transaction" ? "pre_paid" : "post_paid",
      payment_status: status,
      offset: pagination.pageIndex * pagination.pageSize,
      limit: pagination.pageSize,
      sort_field: !!sorting.length
        ? sorting[0].id.split(".")?.length > 0
          ? sorting[0].id.replace(".", "__")
          : sorting[0].id
        : "",
      order_by: !!sorting.length
        ? `${sorting[0].desc ? "descending" : "ascending"}`
        : "",
      start_date: dateRange.start ? dateRange.start : "",
      end_date: dateRange.end ? dateRange.end : "",
      search_query: globalFilter,
    };

    const successFn = (response) => {
      // setIsLoading(false);
      console.log(
        "recent search Data fetched successfully",
        response.data.Over_all_data
      );
      setTransactionList(
        selectedOption === "Transaction"
          ? response?.data?.transaction_details
          : response?.data?.invoice_details
      );
    };

    const failureFn = (error) => {
      // setIsLoading(false);
      // setIsError(true);
      console.error("Logged-in failed", error);
      alert(error);
    };

    dispatch(clientInvoiceTransactionList({ data, successFn, failureFn }));
  };
  useEffect(() => {
    fetchBillingDetails();
  }, [selectedOption, pagination, sorting, trigger, globalFilter]);

  const handleDownload = (url) => {
    const link = document.createElement("a");
    link.href = url; // URL is now passed as an attribute
    link.setAttribute("download", "filename.ext"); // Optionally specify the filename
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link after the download
  };

  return (
    <div className='billingBg'>
      <div className='billingHeader'>
        <div className='billingTab'>
          <p
            className={selectedOption === "Invoice" ? "active" : "inActive"}
            onClick={() => {
              setSelectedOptions("Invoice");
              setSorting([]);
            }}>
            Invoice Details
          </p>
          <p
            className={selectedOption === "Transaction" ? "active" : "inActive"}
            onClick={() => {
              setSelectedOptions("Transaction");
              setSorting([]);
            }}>
            Transaction Details
          </p>
        </div>
        <div className='filterIcon'>
          <button
            className='filterIconButton'
            onClick={() => setCustomFilter(!customFilter)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.25rem'
              height='1.25rem'
              viewBox='0 0 22 22'
              fill='none'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M8.13809 5.84742C8.13809 7.55792 6.72642 8.94575 4.98475 8.94575C3.24492 8.94483 1.83325 7.557 1.83325 5.8465C1.83325 4.13692 3.24492 2.75 4.98567 2.75C6.72734 2.75 8.13809 4.13692 8.13809 5.84742ZM18.7852 4.49075C19.5469 4.49075 20.1666 5.0985 20.1666 5.84742C20.1666 6.59633 19.5469 7.20408 18.7852 7.20408H12.7581C11.9954 7.20408 11.3758 6.59633 11.3758 5.84742C11.3758 5.0985 11.9954 4.49075 12.7581 4.49075H18.7852ZM3.21559 14.6272H9.24267C10.0053 14.6272 10.625 15.2359 10.625 15.9848C10.625 16.7338 10.0063 17.3433 9.24267 17.3433H3.21559C2.45292 17.3433 1.83325 16.7347 1.83325 15.9857C1.83325 15.2368 2.45292 14.6282 3.21559 14.6282V14.6272ZM17.0142 19.0456C18.7558 19.0456 20.1666 17.6587 20.1666 15.9482C20.1666 14.2368 18.7558 12.8498 17.0142 12.8498C15.2734 12.8498 13.8618 14.2377 13.8618 15.9482C13.8618 17.6587 15.2725 19.0456 17.0142 19.0456Z'
                fill='var(--AccentColor)'
              />
            </svg>
            Filters
          </button>
          <div className={`filterContainer ${customFilter ? "active" : ""}`}>
            <div className='applyBtnContainer'>
              <button
                className='applyBtn'
                onClick={() => {
                  setTrigger(trigger + 1);
                  setCustomFilter(false);
                }}>
                Apply
              </button>
              <button
                className='clearAllBtn'
                onClick={() => {
                  setDateRange({ start: null, end: null });
                  setStatus("");
                }}>
                Clear all
              </button>
            </div>
            <div className='content'>
              <div className='dateContaniner'>
                <DatePicker
                  label='Start date'
                  sx={{ scale: 0.9 }}
                  value={dateRange.start && dayjs(dateRange.start)}
                  onChange={(date) =>
                    setDateRange({
                      ...dateRange,
                      start: date ? dayjs(date).format("YYYY-MM-DD") : null,
                    })
                  }
                />
                <DatePicker
                  label='End date'
                  sx={{ scale: 0.9 }}
                  value={dateRange.end && dayjs(dateRange.end)}
                  onChange={(date) =>
                    setDateRange({
                      ...dateRange,
                      end: date ? dayjs(date).format("YYYY-MM-DD") : null,
                    })
                  }
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='bodyContainer'>
        <div className='tableContainer'>
          {<MaterialReactTable table={table} />}
          {viewRecord && (
            <InvoiceModal
              selectedOption={selectedOption}
              data={viewRecordData}
              setOpenModal={setViewRecord}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default BillingDetails;
