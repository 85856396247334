import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";

import { apiCall } from "../../api";

const createAccountAsyncThunk = (method, type, contentType, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);
    const token = localStorage.getItem("token");
    const apiRequest = {
      method: method,
      headers: token
        ? {
            "Content-Type": contentType,
            Authorization: "Token " + token,
          }
        : { "Content-Type": contentType },
      url: `${baseUrl}${url}`,
      data,
    };

    try {
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;
      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      throw error;
    }
  });

export const GetPacksListClient = createAccountAsyncThunk(
  "post",
  "GetPacksListClient",
  "application/json",
  "master_dashboard/get_bgv_plan_details/"
);

export const GetStatsClient = createAccountAsyncThunk(
  "post",
  "GetStatsClient",
  "application/json",
  "master_dashboard/get_clientmaster_details/"
);

export const GetDashboardInteractionClient = createAccountAsyncThunk(
  "post",
  "GetDashboardInteractionClient",
  "application/json",
  "master_dashboard/get_master_candidate_details/"
);

export const ClientCandidateSearch = createAccountAsyncThunk(
  "post",
  "ClientCandidateSearch",
  "application/json",
  "master_dashboard/fetch_master_candidate_status_details_new/"
);
