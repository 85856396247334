import React, { useEffect } from 'react';
import './Info.scss';
import logo from '../../../assets/images/Company_logo.svg';
import { useState } from 'react';
import active1 from '../../../assets/images/active1.svg';
import active2 from '../../../assets/images/active2.svg';
import active3 from '../../../assets/images/active3.svg';
import inactive2 from '../../../assets/images/inactive2.svg';
import inactive3 from '../../../assets/images/inactive3.svg';
import completedImg from '../../../assets/images/completedImg.svg';
import CompanyInfo from './Forms/CompanyInfo';
import BusinessOverView from './Forms/BusinessOverView';
import BusinessDetails from './Forms/BusinessDetails';
import { useParams, useNavigate } from "react-router-dom";


function Info() {
  const { component } = useParams();
  const navigate = useNavigate();
  console.log(component)
  const [currentForm, setCurrentForm] = useState({companyInfo: 'active', businessOverview: 'inactive', businessDetails: 'inactive'})
  const [activeForm, setActiveForm] = useState('companyInfo');

  useEffect(()=>{
if(component !== "information" && component !== "overview" && component !== "details"){
  navigate("/company-register/information")
}
  },[component])

  return (
    <>
   {(component === "information" || component === "overview" || component === "details") && <div className="setupBg">
    <div className='leftBarComp'>
    <svg xmlns="http://www.w3.org/2000/svg" width="13rem" height="5rem" viewBox="0 0 251 66" fill="none">
  <path d="M63.6472 52.2551C64.4196 52.2551 65.1921 52.2735 65.9645 52.3103V50.5448C65.9645 47.6758 64.3645 46.2413 61.1645 46.2413C59.73 46.2413 58.6817 46.3701 58.0196 46.6275C56.8426 47.0689 55.9967 47.4551 55.4817 47.7862C54.9668 48.0804 54.5622 48.2275 54.2679 48.2275C53.9737 48.2275 53.6978 48.1172 53.4403 47.8965C53.1829 47.6758 53.0541 47.3816 53.0541 47.0138C53.0541 46.7195 53.2196 46.4253 53.5507 46.131C55.2794 44.4758 57.8357 43.6482 61.2196 43.6482C65.1185 43.6482 67.6197 44.954 68.7231 47.5655C69.0909 48.485 69.2748 49.5517 69.2748 50.7655L69.1645 63.5103C69.1645 63.9885 68.999 64.3563 68.6679 64.6138C68.3369 64.8712 67.9691 65 67.5645 65C67.1599 65 66.7921 64.8896 66.461 64.6689C66.13 64.4482 65.9645 64.0804 65.9645 63.5655V61.5793C65.0817 63.4919 63.5001 64.6873 61.2196 65.1655C60.5208 65.3494 59.73 65.4413 58.8472 65.4413C57.9645 65.4413 57.0817 65.3126 56.199 65.0551C55.353 64.7977 54.599 64.3931 53.9369 63.8413C52.576 62.7011 51.8955 61.1563 51.8955 59.2069C51.8955 54.5724 55.8127 52.2551 63.6472 52.2551ZM65.9645 54.7379C65.376 54.8115 64.6955 54.8482 63.9231 54.8482C63.1507 54.8482 62.3599 54.8666 61.5507 54.9034C60.7415 54.9034 59.9691 54.9954 59.2334 55.1793C58.4978 55.3264 57.8357 55.5655 57.2472 55.8965C55.9967 56.5586 55.3346 57.6436 55.261 59.1517C55.261 60.3287 55.7392 61.2482 56.6955 61.9103C57.4311 62.4988 58.4794 62.8115 59.8403 62.8482C61.4955 62.8482 62.93 62.3149 64.1438 61.2482C65.2472 60.2184 65.8541 58.6735 65.9645 56.6138V54.7379Z" fill="#001979"/>
  <path d="M75.6068 36.6413C75.6068 36.0528 75.7907 35.6298 76.1585 35.3724C76.5264 35.1149 76.8574 34.9862 77.1516 34.9862C77.4459 34.9862 77.685 35.0046 77.8689 35.0413C78.0528 35.0781 78.2367 35.1885 78.4206 35.3724C78.8252 35.6298 79.0275 36.0528 79.0275 36.6413V63.3448C79.0275 63.8965 78.8436 64.3195 78.4758 64.6138C78.108 64.8712 77.7034 65 77.262 65C76.8206 65 76.4344 64.8712 76.1034 64.6138C75.7723 64.3195 75.6068 63.8965 75.6068 63.3448V36.6413Z" fill="#001979"/>
  <path d="M89.5167 36.7517C89.5167 37.3402 89.3144 37.8184 88.9098 38.1862C88.5052 38.554 87.9351 38.7379 87.1995 38.7379C86.4639 38.7379 85.8937 38.554 85.4892 38.1862C85.0846 37.8184 84.8822 37.3402 84.8822 36.7517C84.8822 36.2367 85.0846 35.7954 85.4892 35.4275C85.8937 35.0597 86.4639 34.8758 87.1995 34.8758C87.9719 34.8758 88.542 35.0597 88.9098 35.4275C89.3144 35.7954 89.5167 36.2367 89.5167 36.7517ZM85.5443 45.8C85.5443 45.2482 85.7282 44.8253 86.096 44.531C86.4639 44.2 86.7949 44.0344 87.0891 44.0344C87.3834 44.0344 87.6225 44.0896 87.8064 44.2C87.9903 44.2735 88.1742 44.4023 88.3581 44.5862C88.7627 44.8436 88.965 45.2482 88.965 45.8V63.3448C88.965 63.8965 88.7811 64.3195 88.4133 64.6138C88.0455 64.8712 87.6409 65 87.1995 65C86.7581 65 86.3719 64.8712 86.0409 64.6138C85.7098 64.3195 85.5443 63.8965 85.5443 63.3448V45.8Z" fill="#001979"/>
  <path d="M105.413 52.2551C106.185 52.2551 106.958 52.2735 107.73 52.3103V50.5448C107.73 47.6758 106.13 46.2413 102.93 46.2413C101.496 46.2413 100.447 46.3701 99.7853 46.6275C98.6083 47.0689 97.7623 47.4551 97.2473 47.7862C96.7324 48.0804 96.3278 48.2275 96.0335 48.2275C95.7393 48.2275 95.4634 48.1172 95.206 47.8965C94.9485 47.6758 94.8197 47.3816 94.8197 47.0138C94.8197 46.7195 94.9853 46.4253 95.3163 46.131C97.045 44.4758 99.6014 43.6482 102.985 43.6482C106.884 43.6482 109.385 44.954 110.489 47.5655C110.857 48.485 111.04 49.5517 111.04 50.7655L110.93 63.5103C110.93 63.9885 110.765 64.3563 110.434 64.6138C110.103 64.8712 109.735 65 109.33 65C108.925 65 108.558 64.8896 108.227 64.6689C107.896 64.4482 107.73 64.0804 107.73 63.5655V61.5793C106.847 63.4919 105.266 64.6873 102.985 65.1655C102.286 65.3494 101.496 65.4413 100.613 65.4413C99.7301 65.4413 98.8473 65.3126 97.9646 65.0551C97.1186 64.7977 96.3646 64.3931 95.7025 63.8413C94.3416 62.7011 93.6611 61.1563 93.6611 59.2069C93.6611 54.5724 97.5784 52.2551 105.413 52.2551ZM107.73 54.7379C107.142 54.8115 106.461 54.8482 105.689 54.8482C104.916 54.8482 104.125 54.8666 103.316 54.9034C102.507 54.9034 101.735 54.9954 100.999 55.1793C100.263 55.3264 99.6014 55.5655 99.0129 55.8965C97.7623 56.5586 97.1002 57.6436 97.0266 59.1517C97.0266 60.3287 97.5048 61.2482 98.4611 61.9103C99.1968 62.4988 100.245 62.8115 101.606 62.8482C103.261 62.8482 104.696 62.3149 105.909 61.2482C107.013 60.2184 107.62 58.6735 107.73 56.6138V54.7379Z" fill="#001979"/>
  <path d="M117.372 45.6344C117.372 45.1563 117.556 44.7701 117.924 44.4758C118.292 44.1816 118.66 44.0344 119.028 44.0344C119.8 44.0344 120.297 44.3287 120.517 44.9172C120.738 45.5057 120.793 46.3885 120.683 47.5655C121.124 46.462 122.08 45.5057 123.552 44.6965C124.839 43.9977 126.108 43.6482 127.359 43.6482C128.646 43.6482 129.731 43.8138 130.614 44.1448C131.497 44.439 132.306 44.9172 133.041 45.5793C134.513 46.977 135.248 49.0367 135.248 51.7586V63.4551C135.248 63.9701 135.046 64.3563 134.641 64.6138C134.274 64.8712 133.887 65 133.483 65C133.041 65 132.655 64.8712 132.324 64.6138C131.993 64.3195 131.828 63.9333 131.828 63.4551V51.5379C131.828 49.8092 131.349 48.485 130.393 47.5655C129.51 46.6827 128.407 46.2413 127.083 46.2413C125.317 46.2413 123.809 46.7563 122.559 47.7862C121.308 48.8896 120.683 50.5816 120.683 52.862V63.4551C120.683 63.9701 120.499 64.3563 120.131 64.6138C119.763 64.8712 119.359 65 118.917 65C118.476 65 118.108 64.8712 117.814 64.6138C117.52 64.3195 117.372 63.9333 117.372 63.4551V45.6344Z" fill="#001979"/>
  <path d="M152.735 63.7862C152.735 64.8896 151.429 65.4413 148.818 65.4413C146.868 65.4413 145.489 64.908 144.68 63.8413C143.797 62.7011 143.319 60.8253 143.245 58.2138V46.6275H140.818C140.376 46.6275 140.027 46.4988 139.77 46.2413C139.512 45.9471 139.383 45.6528 139.383 45.3586C139.383 45.0643 139.494 44.7701 139.714 44.4758C139.935 44.1816 140.303 44.0344 140.818 44.0344H143.466L143.687 38.0758C143.76 37.6344 143.963 37.3034 144.294 37.0827C144.625 36.8252 144.974 36.6965 145.342 36.6965C145.71 36.6965 146.022 36.8069 146.28 37.0275C146.537 37.2482 146.666 37.5977 146.666 38.0758V44.0344H150.473C150.914 44.0344 151.245 44.1816 151.466 44.4758C151.724 44.7701 151.852 45.0643 151.852 45.3586C151.852 45.7264 151.724 46.039 151.466 46.2965C151.245 46.5172 150.914 46.6275 150.473 46.6275H146.666V56.3931C146.666 59.1149 146.758 60.7701 146.942 61.3586C147.126 61.9103 147.42 62.2965 147.825 62.5172C148.229 62.7379 148.726 62.8482 149.314 62.8482L151.466 62.6827C151.871 62.6827 152.183 62.8115 152.404 63.0689C152.625 63.3264 152.735 63.5655 152.735 63.7862Z" fill="#001979"/>
  <path d="M167.501 63.7862C167.501 64.8896 166.195 65.4413 163.583 65.4413C161.634 65.4413 160.255 64.908 159.446 63.8413C158.563 62.7011 158.085 60.8253 158.011 58.2138V46.6275H155.583C155.142 46.6275 154.793 46.4988 154.535 46.2413C154.278 45.9471 154.149 45.6528 154.149 45.3586C154.149 45.0643 154.259 44.7701 154.48 44.4758C154.701 44.1816 155.068 44.0344 155.583 44.0344H158.232L158.452 38.0758C158.526 37.6344 158.728 37.3034 159.059 37.0827C159.39 36.8252 159.74 36.6965 160.108 36.6965C160.475 36.6965 160.788 36.8069 161.046 37.0275C161.303 37.2482 161.432 37.5977 161.432 38.0758V44.0344H165.239C165.68 44.0344 166.011 44.1816 166.232 44.4758C166.489 44.7701 166.618 45.0643 166.618 45.3586C166.618 45.7264 166.489 46.039 166.232 46.2965C166.011 46.5172 165.68 46.6275 165.239 46.6275H161.432V56.3931C161.432 59.1149 161.524 60.7701 161.708 61.3586C161.891 61.9103 162.186 62.2965 162.59 62.5172C162.995 62.7379 163.491 62.8482 164.08 62.8482L166.232 62.6827C166.636 62.6827 166.949 62.8115 167.17 63.0689C167.39 63.3264 167.501 63.5655 167.501 63.7862Z" fill="#001979"/>
  <path d="M186.314 36.7517C186.314 37.3402 186.111 37.8184 185.707 38.1862C185.302 38.554 184.732 38.7379 183.996 38.7379C183.261 38.7379 182.691 38.554 182.286 38.1862C181.881 37.8184 181.679 37.3402 181.679 36.7517C181.679 36.2367 181.881 35.7954 182.286 35.4275C182.691 35.0597 183.261 34.8758 183.996 34.8758C184.769 34.8758 185.339 35.0597 185.707 35.4275C186.111 35.7954 186.314 36.2367 186.314 36.7517ZM182.341 45.8C182.341 45.2482 182.525 44.8253 182.893 44.531C183.261 44.2 183.592 44.0344 183.886 44.0344C184.18 44.0344 184.419 44.0896 184.603 44.2C184.787 44.2735 184.971 44.4023 185.155 44.5862C185.56 44.8436 185.762 45.2482 185.762 45.8V63.3448C185.762 63.8965 185.578 64.3195 185.21 64.6138C184.842 64.8712 184.438 65 183.996 65C183.555 65 183.169 64.8712 182.838 64.6138C182.507 64.3195 182.341 63.8965 182.341 63.3448V45.8Z" fill="#001979"/>
  <path d="M192.279 45.6344C192.279 45.1563 192.463 44.7701 192.83 44.4758C193.198 44.1816 193.566 44.0344 193.934 44.0344C194.706 44.0344 195.203 44.3287 195.424 44.9172C195.644 45.5057 195.699 46.3885 195.589 47.5655C196.03 46.462 196.987 45.5057 198.458 44.6965C199.745 43.9977 201.014 43.6482 202.265 43.6482C203.552 43.6482 204.637 43.8138 205.52 44.1448C206.403 44.439 207.212 44.9172 207.948 45.5793C209.419 46.977 210.155 49.0367 210.155 51.7586V63.4551C210.155 63.9701 209.952 64.3563 209.548 64.6138C209.18 64.8712 208.794 65 208.389 65C207.948 65 207.561 64.8712 207.23 64.6138C206.899 64.3195 206.734 63.9333 206.734 63.4551V51.5379C206.734 49.8092 206.256 48.485 205.299 47.5655C204.417 46.6827 203.313 46.2413 201.989 46.2413C200.224 46.2413 198.715 46.7563 197.465 47.7862C196.214 48.8896 195.589 50.5816 195.589 52.862V63.4551C195.589 63.9701 195.405 64.3563 195.037 64.6138C194.67 64.8712 194.265 65 193.824 65C193.382 65 193.014 64.8712 192.72 64.6138C192.426 64.3195 192.279 63.9333 192.279 63.4551V45.6344Z" fill="#001979"/>
  <path d="M221.793 46.5724V63.3448C221.793 63.8965 221.609 64.3195 221.241 64.6138C220.91 64.8712 220.579 65 220.248 65C219.917 65 219.641 64.9816 219.421 64.9448C219.237 64.8712 219.071 64.7425 218.924 64.5586C218.556 64.3011 218.391 63.8965 218.428 63.3448V46.5724H215.503C215.062 46.5724 214.731 46.4436 214.51 46.1862C214.29 45.9287 214.179 45.6344 214.179 45.3034C214.179 45.0459 214.29 44.7701 214.51 44.4758C214.731 44.1816 215.062 44.0344 215.503 44.0344H218.428V41.7172C218.428 37.9287 219.439 35.6115 221.462 34.7655C222.124 34.508 222.786 34.3793 223.448 34.3793C224.625 34.3793 225.821 34.5816 227.034 34.9862C227.586 35.2069 227.862 35.5747 227.862 36.0896C227.862 36.3839 227.77 36.6781 227.586 36.9724C227.402 37.2299 227.145 37.3586 226.814 37.3586C226.519 37.3586 226.28 37.3586 226.096 37.3586C225.949 37.3218 225.802 37.2666 225.655 37.1931C225.251 37.1195 224.791 37.0827 224.276 37.0827C223.798 37.0827 223.393 37.1931 223.062 37.4138C222.731 37.5977 222.455 37.9103 222.234 38.3517C221.94 39.0505 221.793 40.7241 221.793 43.3724V44.0344H225.379C225.821 44.0344 226.152 44.1632 226.372 44.4207C226.63 44.6781 226.759 44.9724 226.759 45.3034C226.759 45.6344 226.63 45.9287 226.372 46.1862C226.152 46.4436 225.821 46.5724 225.379 46.5724H221.793Z" fill="#001979"/>
  <path d="M229.742 54.5724C229.742 53.2482 229.944 51.9241 230.349 50.6C230.79 49.2758 231.453 48.0804 232.335 47.0138C234.174 44.7701 236.786 43.6482 240.17 43.6482C243.554 43.6482 246.165 44.7701 248.004 47.0138C249.733 49.1471 250.597 51.6482 250.597 54.5172C250.597 57.423 249.733 59.9241 248.004 62.0207C246.165 64.3011 243.554 65.4413 240.17 65.4413C236.786 65.4413 234.174 64.3011 232.335 62.0207C230.607 59.9609 229.742 57.4781 229.742 54.5724ZM233.328 54.5724C233.328 55.4551 233.439 56.3931 233.659 57.3862C233.917 58.3793 234.358 59.2988 234.984 60.1448C236.234 61.9471 237.963 62.8482 240.17 62.8482C242.487 62.8482 244.216 61.9471 245.356 60.1448C246.423 58.4528 246.956 56.577 246.956 54.5172C246.956 52.3839 246.423 50.5448 245.356 49C244.179 47.1609 242.45 46.2413 240.17 46.2413C237.963 46.2413 236.234 47.1609 234.984 49C233.88 50.508 233.328 52.3655 233.328 54.5724Z" fill="#001979"/>
  <path d="M21.2402 20.6367L36.308 46.1139L31.1944 54.8693L0.40625 0H51.6641L35.3824 30.8336L29.2369 20.6367H21.2402Z" fill="url(#paint0_linear_10197_47463)"/>
  <path d="M70.313 0L36.6115 64.52L31.1944 54.8693L36.308 46.1139L60.2678 0H70.313Z" fill="url(#paint1_linear_10197_47463)"/>
  <path d="M36.308 46.1139L31.1944 54.8693L0.40625 0H9.0858L21.2402 20.6367L36.308 46.1139Z" fill="url(#paint2_linear_10197_47463)"/>
  <defs>
    <linearGradient id="paint0_linear_10197_47463" x1="2286.42" y1="-2.10366" x2="2277.62" y2="162.596" gradientUnits="userSpaceOnUse">
      <stop stop-color="#08A6E0"/>
      <stop offset="0.34" stop-color="#0990DF"/>
      <stop offset="0.61" stop-color="#0149D6"/>
      <stop offset="1" stop-color="#1B1464"/>
    </linearGradient>
    <linearGradient id="paint1_linear_10197_47463" x1="295.435" y1="55.7298" x2="295.99" y2="-26.6336" gradientUnits="userSpaceOnUse">
      <stop stop-color="#08A6E0"/>
      <stop offset="0.49" stop-color="#0990DF"/>
      <stop offset="0.69" stop-color="#0149D6"/>
      <stop offset="0.99" stop-color="#1B1464"/>
    </linearGradient>
    <linearGradient id="paint2_linear_10197_47463" x1="0.40625" y1="31.2585" x2="426.842" y2="31.2585" gradientUnits="userSpaceOnUse">
      <stop stop-color="#08A6E0"/>
      <stop offset="0.49" stop-color="#0990DF"/>
      <stop offset="0.82" stop-color="#0149D6"/>
      <stop offset="1" stop-color="#1B1464"/>
    </linearGradient>
  </defs>
</svg>
      <h1>Company Registration</h1>
      <p className='cmpRegContent'>To get started, please complete the following steps to register your company</p>
      <div className='formCompletion'>
        <div>
          <img className={`${component === 'information' ? '' : (component === 'details' || component === 'overview') ? 'completedImg': ''} `} src={`${component === 'information' ? active1 : (component === 'details' || component === 'overview') ? completedImg : ''} `} alt="" />
          <p className={`${component === 'information' ? 'active' : (component === 'details' || component === 'overview') ? 'completed': ''} `}>Company Information</p>
        </div>
        <div>
          <img className={`${component === 'overview' ? '' : component === 'details' ? 'completedImg' : ''} `} src={`${component === 'overview' ? active2 : component === 'details' ? completedImg : inactive2} `} alt="" />
          <p className={`${component === 'overview' ? 'active' : component === 'details' ? 'completed' : 'inactive'} `}>Business Overview</p>
        </div>
        <div>
          <img src={`${component === 'details' ? active3 : (component === 'information' || component === 'overview') ? inactive3 : ''} `} alt="" />
          <p className={`${component === 'details' ? 'active' : 'inactive'} `}>Business Details</p>
        </div>
      </div>
    </div>
    <div className="animationComp">
      <CompanyInfo/>
      <BusinessOverView/>
      <BusinessDetails/>
    </div>
    </div>}
    </>
  )
}

export default Info;
