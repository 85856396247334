import { Details } from "@mui/icons-material";
import React from "react";
import Empty from "../../Empty/Empty";
import { useNavigate } from "react-router-dom";

const CandidateHistory = ({ details }) => {
  const Data = details && details;
  const navigate = useNavigate();

  return (
    <main className='CandidateHistory'>
      <header className='DetailHeader'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1.5rem'
          height='1.5rem'
          viewBox='0 0 38 38'
          fill='none'
          onClick={() => {
            navigate(-1);
          }}>
          <path
            d='M29.5556 16.2555H13.5112L20.4778 9.28884C21.3223 8.4444 21.3223 7.17773 20.4778 6.33328C19.6334 5.48884 18.3667 5.48884 17.5223 6.33328L6.96671 16.8888C6.12226 17.7333 6.12226 19 6.96671 19.8444L17.5223 30.4C18.3667 31.2444 19.6334 31.2444 20.4778 30.4C21.3223 29.5555 21.3223 28.2888 20.4778 27.4444L13.5112 20.4777H29.5556C30.8223 20.4777 31.6667 19.6333 31.6667 18.3666C31.6667 17.3111 30.8223 16.2555 29.5556 16.2555Z'
            fill='#102C98'
          />
        </svg>
        <h1>History</h1>
      </header>
      <section className='ContentCont'>
        {Data ? (
          <>
            <div className='TableHeader'>
              <span>#</span>
              <span className='Ellipsis'>Activity</span>
              <span>Status</span>
              <span>Date and Time</span>
            </div>
            {Data?.length > 0 &&
              Data?.map((data, index) => (
                <div
                  className='TableRow'
                  key={index}>
                  <span>{index + 1}</span>
                  <span className='Ellipsis' title={data?.Activity}>{data?.Activity}</span>
                  <span>{data?.status}</span>
                  <span>{data?.time?.slice(0, 10)}</span>
                </div>
              ))}
          </>
        ) : (
          <Empty />
        )}
      </section>
    </main>
  );
};

export default CandidateHistory;
