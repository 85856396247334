import { createSlice } from "@reduxjs/toolkit";
import { addCompanyInformation, addCompanyOverview, addCompanyDetails } from "../actions/CompanyRegisterationAction";

const generateExtraReducers = (builder, asyncThunk) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // Call the success callback if provided
      if (typeof action.meta.arg.successFn === "function") {
        action.meta.arg.successFn(action.payload);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      // Call the failure callback if provided
      if (typeof action.meta.arg.failureFn === "function") {
        action.meta.arg.failureFn(action.error.message);
      }
    });
};

const createAsyncSlice = (name, asyncThunk) => createSlice({
  name,
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => generateExtraReducers(builder, asyncThunk)
});



const addCompanyInformationSlice = createAsyncSlice("addCompanyInformation", addCompanyInformation);
const addCompanyOverviewSlice = createAsyncSlice("addCompanyOverview", addCompanyOverview);
const addCompanyDetailsSlice = createAsyncSlice("addCompanyDetails", addCompanyDetails);
export const reducers = {
    addCompanyInformation: addCompanyInformationSlice.reducer,
    addCompanyOverview: addCompanyOverviewSlice.reducer,
    addCompanyDetails: addCompanyDetailsSlice.reducer
};