import React from "react";
import "./Progress.scss";
import { useNavigate, useParams } from "react-router-dom";

function Progress({ selectedClass, setClass }) {
  const navigate = useNavigate();
  const { status } = useParams();
  return (
    <div className='progress'>
      <div
        className={`${!status ? "all" : ""}`}
        onClick={() => navigate("/company/candidate-details")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.75 0H80.75V75C80.75 77.7614 78.5114 80 75.75 80H5.75C2.98858 80 0.75 77.7614 0.75 75V0Z'
            fill='var(--Foreground)'
          />
          <path
            d='M55.45 24H26.05C22.5721 24.0037 19.7538 26.6877 19.75 30V50C19.7538 53.3123 22.5721 55.9963 26.05 56H55.45C58.9279 55.9963 61.7462 53.3123 61.75 50V30C61.7462 26.6877 58.9279 24.0037 55.45 24ZM26.05 26H55.45C56.2271 26.001 56.9455 26.2163 57.5697 26.5671L42.9771 40.4648C41.7455 41.6329 39.7543 41.6329 38.5227 40.4648L23.9301 26.5671C24.5543 26.2163 25.2729 26.001 26.05 26ZM59.65 50C59.647 52.208 57.7684 53.9972 55.45 54H26.05C23.7316 53.9972 21.8529 52.208 21.85 50V30C21.851 29.2599 22.0771 28.5757 22.4455 27.9812L37.0381 41.8789C38.0217 42.8175 39.3576 43.344 40.75 43.3418C42.1424 43.3439 43.4782 42.8175 44.4618 41.8789L59.0545 27.9811C59.4229 28.5756 59.649 29.2599 59.65 30V50Z'
            fill='var(--AccentColor)'
          />
        </svg>
        <p className='count'>1922</p>
        <p>All</p>
      </div>
      <div
        className={`${status === "invitation" ? "invited" : ""}`}
        onClick={() => navigate("/company/candidate-details/invitation")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.25 0H80.25V75C80.25 77.7614 78.0114 80 75.25 80H5.25C2.48858 80 0.25 77.7614 0.25 75V0Z'
            fill='var(--Invitation)'
          />
          <path
            d='M54.95 24H25.55C22.0721 24.0037 19.2538 26.6877 19.25 30V50C19.2538 53.3123 22.0721 55.9963 25.55 56H54.95C58.4279 55.9963 61.2462 53.3123 61.25 50V30C61.2462 26.6877 58.4279 24.0037 54.95 24ZM25.55 26H54.95C55.7271 26.001 56.4455 26.2163 57.0697 26.5671L42.4771 40.4648C41.2455 41.6329 39.2543 41.6329 38.0227 40.4648L23.4301 26.5671C24.0543 26.2163 24.7729 26.001 25.55 26ZM59.15 50C59.147 52.208 57.2684 53.9972 54.95 54H25.55C23.2316 53.9972 21.3529 52.208 21.35 50V30C21.351 29.2599 21.5771 28.5757 21.9455 27.9812L36.5381 41.8789C37.5217 42.8175 38.8576 43.344 40.25 43.3418C41.6424 43.3439 42.9782 42.8175 43.9618 41.8789L58.5545 27.9811C58.9229 28.5756 59.149 29.2599 59.15 30V50Z'
            fill='#58A5E5'
          />
        </svg>
        <p className='count'>196</p>
        <p>Invited</p>
      </div>
      <div
        className={`${status === "accepted" ? "accepted" : ""}`}
        onClick={() => navigate("/company/candidate-details/accepted")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.75 0H80.75V75C80.75 77.7614 78.5114 80 75.75 80H5.75C2.98858 80 0.75 77.7614 0.75 75V0Z'
            fill='var(--Accepted)'
          />
          <path
            d='M61.4165 33.6796L56.015 29.1656V22.7615C56.015 22.2086 55.5331 21.7864 54.9708 21.7864H47.1095L43.957 19.1524C42.0996 17.6142 39.4088 17.6142 37.5515 19.1624L34.3989 21.7864H26.4372C25.885 21.7864 25.393 22.1986 25.393 22.7615V29.2661L20.0919 33.6796C19.871 33.8706 19.7405 34.1621 19.7505 34.4537H19.7706V56.9632C19.7706 59.738 21.9995 62 24.7605 62H56.7379C59.5089 62 61.7278 59.7279 61.7278 56.9632V34.4537H61.7478C61.7679 34.1521 61.6474 33.8706 61.4165 33.6796ZM59.1776 34.4135L56.015 36.7659V31.7795L59.1776 34.4135ZM38.8366 20.7006C39.951 19.7757 41.5674 19.7857 42.6819 20.7107L43.957 21.7864H37.5414L38.8366 20.7006ZM54.007 23.797V38.2639L42.5714 46.7892C41.4871 47.5834 40.0213 47.5834 38.947 46.7892L27.401 38.1835V23.797H54.007ZM25.393 31.8699V36.6855L22.3308 34.4034L25.393 31.8699ZM56.7479 59.9893H24.7605C23.1039 59.9793 21.7686 58.6221 21.7786 56.9632V36.4543L37.7623 48.4078C39.5394 49.7248 41.969 49.7248 43.7461 48.4078L59.7298 36.4543V56.9532C59.7398 58.6221 58.4045 59.9793 56.7479 59.9893Z'
            fill='#9685FD'
          />
          <path
            d='M34.7222 33.2857H39.9722C40.5069 33.2857 40.9444 32.7714 40.9444 32.1429C40.9444 31.5143 40.5069 31 39.9722 31H34.7222C34.1875 31 33.75 31.5143 33.75 32.1429C33.75 32.7714 34.1875 33.2857 34.7222 33.2857ZM34.7222 39H46.7778C47.3125 39 47.75 38.4857 47.75 37.8571C47.75 37.2286 47.3125 36.7143 46.7778 36.7143H34.7222C34.1875 36.7143 33.75 37.2286 33.75 37.8571C33.75 38.4857 34.1875 39 34.7222 39Z'
            fill='var(--Accepted)'
          />
        </svg>
        <p className='count'>447</p>
        <p>Accepted</p>
      </div>
      <div
        className={`${status === "inprogress" ? "inProgress" : ""}`}
        onClick={() => navigate("/company/candidate-details/inprogress")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.25 0H80.25V75C80.25 77.7614 78.0114 80 75.25 80H5.25C2.48858 80 0.25 77.7614 0.25 75V0Z'
            fill='var(--Inprogress)'
          />
          <path
            d='M58.9327 52.2461L53.6894 46.9223C53.6894 46.9221 53.6896 46.9224 53.6894 46.9223C53.2664 46.493 52.5804 46.4927 52.1576 46.9223L44.4008 54.7984C44.1975 55.0047 44.0834 55.2844 44.0834 55.5762V60.9012C44.0838 61.5084 44.5687 62.0004 45.1667 62H50.4099C50.6973 62 50.9727 61.8841 51.1759 61.6777L58.9327 53.8016C58.9326 53.8017 58.9327 53.8014 58.9327 53.8016C59.3555 53.372 59.3556 52.6754 58.9327 52.2461ZM49.9614 59.8H46.2501V56.0316L52.9236 49.2553L56.635 53.0238L49.9614 59.8ZM30 48.8C29.4018 48.8 28.9167 49.2925 28.9167 49.9C28.9167 50.5075 29.4018 51 30 51H36.5C37.0983 51 37.5834 50.5075 37.5834 49.9C37.5834 49.2925 37.0983 48.8 36.5 48.8H30ZM46.2501 41.1C46.2501 40.4925 45.765 40 45.1667 40H30C29.4018 40 28.9167 40.4925 28.9167 41.1C28.9167 41.7075 29.4018 42.2 30 42.2H45.1667C45.765 42.2 46.2501 41.7075 46.2501 41.1ZM30 33.4H34.3334C34.9316 33.4 35.4167 32.9075 35.4167 32.3C35.4167 31.6925 34.9316 31.2 34.3334 31.2H30C29.4018 31.2 28.9167 31.6925 28.9167 32.3C28.9167 32.9075 29.4018 33.4 30 33.4ZM38.6667 59.8H26.75C24.358 59.7969 22.4197 57.8288 22.4167 55.4V24.6C22.4197 22.1712 24.358 20.2031 26.75 20.2H39.7501V28.2674C39.7536 31.1005 42.0147 33.3964 44.8049 33.4H52.7501V41.1C52.7501 41.7075 53.2352 42.2 53.8334 42.2C54.4317 42.2 54.9168 41.7075 54.9168 41.1V32.3C54.9168 32.0082 54.8026 31.7285 54.5994 31.5223L41.5993 18.3223C41.3962 18.1159 41.1208 18 40.8334 18H26.75C23.1617 18.004 20.254 20.9565 20.25 24.6V55.4C20.254 59.0435 23.1617 61.996 26.75 62H38.6667C39.265 62 39.7501 61.5075 39.7501 60.9C39.7501 60.2925 39.265 59.8 38.6667 59.8ZM41.9167 21.7553L51.2183 31.2H44.8049C43.2103 31.1987 41.9181 29.8865 41.9167 28.2674V21.7553Z'
            fill='#EEBC0C'
          />
        </svg>
        <p className='count'>826</p>
        <p>In progress</p>
      </div>
      <div
        className={`${status === "pending" ? "pending" : ""}`}
        onClick={() => navigate("/company/candidate-details/pending")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.75 0H80.75V75C80.75 77.7614 78.5114 80 75.75 80H5.75C2.98858 80 0.75 77.7614 0.75 75V0Z'
            fill='var(--Pending)'
          />
          <path
            d='M43.75 58H30.75C28.542 57.9972 26.7528 56.208 26.75 54V26C26.7528 23.792 28.542 22.0028 30.75 22H42.75V29.334C42.7533 31.9095 44.8405 33.9967 47.416 34H54.75V47C54.75 47.5522 55.1978 48 55.75 48C56.3022 48 56.75 47.5522 56.75 47V33C56.75 32.7347 56.6447 32.4805 56.457 32.293L44.457 20.293C44.2695 20.1053 44.0153 20 43.75 20H30.75C27.4377 20.0037 24.7537 22.6877 24.75 26V54C24.7537 57.3123 27.4377 59.9963 30.75 60H43.75C44.3022 60 44.75 59.5522 44.75 59C44.75 58.4478 44.3022 58 43.75 58ZM44.75 23.4139L53.3361 32H47.416C45.9441 31.9988 44.7512 30.8059 44.75 29.334V23.4139ZM33.75 32C33.1978 32 32.75 32.4478 32.75 33C32.75 33.5522 33.1978 34 33.75 34H37.75C38.3022 34 38.75 33.5522 38.75 33C38.75 32.4478 38.3022 32 37.75 32H33.75ZM43.75 48H33.75C33.1978 48 32.75 48.4478 32.75 49C32.75 49.5522 33.1978 50 33.75 50H43.75C44.3022 50 44.75 49.5522 44.75 49C44.75 48.4478 44.3022 48 43.75 48ZM47.75 40H33.75C33.1978 40 32.75 40.4478 32.75 41C32.75 41.5522 33.1978 42 33.75 42H47.75C48.3022 42 48.75 41.5522 48.75 41C48.75 40.4478 48.3022 40 47.75 40Z'
            fill='#2CB3F1'
          />
          <path
            d='M49.6062 60C49.1334 60 48.75 59.545 48.75 58.9837L48.75 51.0163C48.75 50.455 49.1334 50 49.6062 50C50.079 50 50.4623 50.455 50.4623 51.0163L50.4623 58.9837C50.4623 59.545 50.079 60 49.6062 60Z'
            fill='#2CB3F1'
          />
          <path
            d='M52.8938 60C52.421 60 52.0377 59.545 52.0377 58.9837V51.0163C52.0377 50.455 52.421 50 52.8938 50C53.3666 50 53.75 50.455 53.75 51.0163V58.9837C53.75 59.545 53.3666 60 52.8938 60Z'
            fill='#2CB3F1'
          />
        </svg>
        <p className='count'>426</p>
        <p>Pending</p>
      </div>
      <div
        className={`${status === "completed" ? "completed" : ""}`}
        onClick={() => navigate("/company/candidate-details/completed")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.25 0H80.25V75C80.25 77.7614 78.0114 80 75.25 80H5.25C2.48858 80 0.25 77.7614 0.25 75V0Z'
            fill='var(--Completed)'
          />
          <path
            d='M42.756 57.1H30.094C27.9434 57.0973 26.2007 55.3976 26.198 53.3V26.7C26.2007 24.6024 27.9434 22.9027 30.094 22.9H41.782V29.8673C41.7852 32.3141 43.8181 34.2969 46.3267 34.3H53.47V46.65C53.47 47.1746 53.9061 47.6 54.444 47.6C54.9819 47.6 55.418 47.1746 55.418 46.65V33.35C55.418 33.098 55.3154 32.8564 55.1326 32.6783L43.4446 21.2783C43.262 21.1001 43.0144 21 42.756 21H30.094C26.8679 21.0035 24.2536 23.5534 24.25 26.7V53.3C24.2536 56.4466 26.8679 58.9965 30.094 59H42.756C43.2939 59 43.73 58.5746 43.73 58.05C43.73 57.5254 43.2939 57.1 42.756 57.1ZM43.73 24.2432L52.0928 32.4H46.3267C44.893 32.3988 43.7312 31.2656 43.73 29.8673V24.2432ZM33.016 32.4C32.4781 32.4 32.042 32.8254 32.042 33.35C32.042 33.8746 32.4781 34.3 33.016 34.3H36.912C37.4499 34.3 37.886 33.8746 37.886 33.35C37.886 32.8254 37.4499 32.4 36.912 32.4H33.016ZM60.9765 49.8016C60.6028 49.4243 59.9862 49.4138 59.5993 49.7783L52.496 56.7066L49.2885 53.5784C48.9111 53.2228 48.3126 53.2228 47.9352 53.5784C47.5482 53.9429 47.5376 54.5443 47.9113 54.9217L51.8073 58.7217C51.9897 58.9002 52.2376 59.0003 52.496 59C52.7543 59.0002 53.0021 58.9002 53.1845 58.7217L60.9765 51.1217C61.3412 50.7535 61.3412 50.1698 60.9765 49.8016ZM42.756 47.6H33.016C32.4781 47.6 32.042 48.0254 32.042 48.55C32.042 49.0746 32.4781 49.5 33.016 49.5H42.756C43.2939 49.5 43.73 49.0746 43.73 48.55C43.73 48.0254 43.2939 47.6 42.756 47.6ZM46.652 40H33.016C32.4781 40 32.042 40.4254 32.042 40.95C32.042 41.4746 32.4781 41.9 33.016 41.9H46.652C47.1899 41.9 47.626 41.4746 47.626 40.95C47.626 40.4254 47.1899 40 46.652 40Z'
            fill='#3CF745'
          />
        </svg>
        <p className='count'>154</p>
        <p>Completed</p>
      </div>
      <div
        className={`${status === "cancelled" ? "cancelled" : ""}`}
        onClick={() => navigate("/company/candidate-details/cancelled")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.75 0H80.75V75C80.75 77.7614 78.5114 80 75.75 80H5.75C2.98858 80 0.75 77.7614 0.75 75V0Z'
            fill='var(--Cancelled)'
          />
          <path
            d='M42.5557 60.8808H27.2244C24.6204 60.8775 22.5104 58.8021 22.5071 56.2408V23.7608C22.5104 21.1995 24.6204 19.124 27.2244 19.1208H41.3764V27.6282C41.3802 30.6159 43.8417 33.037 46.8791 33.0408H55.5283V48.1208C55.5283 48.7614 56.0564 49.2808 56.7076 49.2808C57.3589 49.2808 57.887 48.7614 57.887 48.1208V31.8808C57.887 31.5731 57.7627 31.2781 57.5415 31.0606L43.3895 17.1406C43.1684 16.923 42.8685 16.8008 42.5557 16.8008H27.2244C23.3182 16.805 20.1528 19.9186 20.1484 23.7608V56.2408C20.1528 60.083 23.3182 63.1965 27.2244 63.2008H42.5557C43.207 63.2008 43.735 62.6814 43.735 62.0408C43.735 61.4002 43.207 60.8808 42.5557 60.8808ZM43.735 20.761L53.8608 30.7208H46.8791C45.1432 30.7194 43.7365 29.3356 43.735 27.6282V20.761ZM30.7624 30.7208C30.1111 30.7208 29.5831 31.2402 29.5831 31.8808C29.5831 32.5214 30.1111 33.0408 30.7624 33.0408H35.4797C36.131 33.0408 36.659 32.5214 36.659 31.8808C36.659 31.2402 36.131 30.7208 35.4797 30.7208H30.7624ZM64.6173 51.9691C64.1648 51.5083 63.4182 51.4956 62.9498 51.9406L54.349 60.4005L50.4653 56.5808C50.0084 56.1465 49.2837 56.1465 48.8268 56.5808C48.3582 57.0258 48.3454 57.7602 48.7978 58.2209L53.5152 62.8609C53.736 63.0789 54.0362 63.2012 54.349 63.2008C54.6618 63.2011 54.9618 63.0789 55.1827 62.8609L64.6173 53.5809C65.0588 53.1314 65.0588 52.4187 64.6173 51.9691ZM42.5557 49.2808H30.7624C30.1111 49.2808 29.5831 49.8002 29.5831 50.4408C29.5831 51.0814 30.1111 51.6008 30.7624 51.6008H42.5557C43.207 51.6008 43.735 51.0814 43.735 50.4408C43.735 49.8002 43.207 49.2808 42.5557 49.2808ZM47.273 40.0008H30.7624C30.1111 40.0008 29.5831 40.5202 29.5831 41.1608C29.5831 41.8014 30.1111 42.3208 30.7624 42.3208H47.273C47.9243 42.3208 48.4523 41.8014 48.4523 41.1608C48.4523 40.5202 47.9243 40.0008 47.273 40.0008Z'
            fill='#FC3D3D'
          />
        </svg>
        <p className='count'>426</p>
        <p>Canceled</p>
      </div>
      <div
        className={`${status === "re-initiated" ? "reinitiated" : ""}`}
        onClick={() => navigate("/company/candidate-details/re-initiated")}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='3rem'
          height='3rem'
          viewBox='0 0 81 80'
          fill='none'>
          <path
            d='M0.25 0H80.25V75C80.25 77.7614 78.0114 80 75.25 80H5.25C2.48858 80 0.25 77.7614 0.25 75V0Z'
            fill='var(--Reinitiated)'
          />
          <path
            d='M54.95 24H25.55C22.0721 24.0037 19.2538 26.6877 19.25 30V50C19.2538 53.3123 22.0721 55.9963 25.55 56H54.95C58.4279 55.9963 61.2462 53.3123 61.25 50V30C61.2462 26.6877 58.4279 24.0037 54.95 24ZM25.55 26H54.95C55.7271 26.001 56.4455 26.2163 57.0697 26.5671L42.4771 40.4648C41.2455 41.6329 39.2543 41.6329 38.0227 40.4648L23.4301 26.5671C24.0543 26.2163 24.7729 26.001 25.55 26ZM59.15 50C59.147 52.208 57.2684 53.9972 54.95 54H25.55C23.2316 53.9972 21.3529 52.208 21.35 50V30C21.351 29.2599 21.5771 28.5757 21.9455 27.9812L36.5381 41.8789C37.5217 42.8175 38.8576 43.344 40.25 43.3418C41.6424 43.3439 42.9782 42.8175 43.9618 41.8789L58.5545 27.9811C58.9229 28.5756 59.149 29.2599 59.15 30V50Z'
            fill='#23DBAF'
          />
        </svg>
        <p className='count'>426</p>
        <p>Reinitiated</p>
      </div>
    </div>
  );
}

export default Progress;
