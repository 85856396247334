import React from 'react'
import { candidateDetails } from './datas';
import { useRef, useEffect } from 'react';

function DropDownComp({handleYearChange,data,setSelectedValue, chartName,closeDropDown, handleSelectChange}) {
    const dropDownRef = useRef(null);

    useEffect(() => {
        const handleClickOutside = (event) => {
            if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
                closeDropDown();
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [closeDropDown]);

    const handleClick = (value) => {
        
        handleSelectChange(value, chartName);
        closeDropDown();
        if(chartName === 'candidate_details'){
            setSelectedValue((prev) => ({
                ...prev,
                candidateDetails:value
            }))
        }
        else if(chartName === 'status_count'){
            setSelectedValue((prev) => ({
                ...prev,
                status:value
            }))
        }
        else if(chartName === 'pack_details'){
            setSelectedValue((prev) => ({
                ...prev,
                packDetails:value
            }))
        }
        else if(chartName === 'tat_overview'){
            setSelectedValue((prev) => ({
                ...prev,
                TAT:value
            }))
        }
        else{

        }
        
    }

    const handleYear = (value) => {
        handleYearChange(value)
        setSelectedValue((prev) => ({
            ...prev,
            AmountSpent:value
        }))
    }

    const handleSelection = (value) => {
        if(chartName !== 'Amount_spent'){
            handleClick(value)
        }
        else if(chartName === 'Amount_spent'){
            handleYear(value);
            closeDropDown();
        }
        closeDropDown();
    }

  return (
    <div className="dropDownContainer" ref={dropDownRef}>
        {data.map((value, index) => (
            <p key={index} onClick={() => handleSelection(value)}>
                {value}
            </p>
        ))}
    </div>
  )
}

export default DropDownComp;
