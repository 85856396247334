import { createSlice } from "@reduxjs/toolkit";
import {fetchCandidateDetails, getApiClient, overallTatPercentage, candidateStatuscountAmountSpent, candidateChecksCount,
  getMonthlyBgvCount, getBgvCount, getCheckAverageDetails, CheckInteractivity, clientFeedbackSubmit, getClientSearchHistory
} from "../actions/CompanyDashboardAction";

const generateExtraReducers = (builder, asyncThunk) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // Call the success callback if provided
      if (typeof action.meta.arg.successFn === "function") {
        action.meta.arg.successFn(action.payload);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      // Call the failure callback if provided
      if (typeof action.meta.arg.failureFn === "function") {
        action.meta.arg.failureFn(action.error.message);
      }
    });
};

const createAsyncSlice = (name, asyncThunk) => createSlice({
  name,
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => generateExtraReducers(builder, asyncThunk)
});



const fetchCandidateDetailsSlice = createAsyncSlice("fetchCandidateDetails", fetchCandidateDetails);
const getApiClientSlice = createAsyncSlice("getApiClient", getApiClient);
const overallTatPercentageSlice = createAsyncSlice("overallTatPercentage", overallTatPercentage);
const candidateStatuscountAmountSpentSlice = createAsyncSlice("candidateStatuscountAmountSpent", candidateStatuscountAmountSpent);
const candidateChecksCountSlice = createAsyncSlice("candidateChecksCount", candidateChecksCount);
const getMonthlyBgvCountSlice = createAsyncSlice("getMonthlyBgvCount", getMonthlyBgvCount);
const getBgvCountSlice = createAsyncSlice("getBgvCount", getBgvCount);
const getCheckAverageDetailsSlice = createAsyncSlice("getCheckAverageDetails", getCheckAverageDetails);
const CheckInteractivitySlice = createAsyncSlice("CheckInteractivity", CheckInteractivity);
const getClientSearchHistorySlice = createAsyncSlice("getClientSearchHistory", getClientSearchHistory);

export const reducers = {
    fetchCandidateDetails: fetchCandidateDetailsSlice.reducer,
    getApiClient: getApiClientSlice.reducer,
    overallTatPercentage: overallTatPercentageSlice.reducer,
    candidateStatuscountAmountSpent: candidateStatuscountAmountSpentSlice.reducer,
    candidateChecksCount: candidateChecksCountSlice.reducer,
    getMonthlyBgvCount: getMonthlyBgvCountSlice.reducer,
    getBgvCount: getBgvCountSlice.reducer,
    getCheckAverageDetails: getCheckAverageDetailsSlice.reducer,
    CheckInteractivity: CheckInteractivitySlice.reducer,
    getClientSearchHistory: getClientSearchHistorySlice.reducer,
};