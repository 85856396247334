import React, { useState,useRef } from 'react';
import './BusinessDetails.scss';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from '../../../ReusableComponents/CustomAlert';
import indianStates from '../../../../constant/options';
import { addCompanyDetails } from '../../../../redux/actions/CompanyRegisterationAction';
import ButtonSpinner from '../../../ReusableComponents/ButtonSpinner';

function BusinessDetails() {
    const { component } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isChecked, setIsChecked] = useState(false);
  const [formData, setFormData] = useState({
    client_info_id: localStorage.getItem("client_info_id"),
    pan_number: "",
    pan_owners_name: "",
    gst_number: "",
    state: "",
    client_address: "",
    postal_code: "",
    city: "",
    country: "India",
    cin_file: null,
    pan_file: null,
    gst_file: null,
    client_logo: null,
  });
  const submitLoading = useSelector(
    (state) => state.addCompanyDetails.loading
  );

  const [filePreview, setFilePreview] = useState({
    cin_file: null,
    pan_file: null,
    gst_file: null,
    client_logo: null,
  });

  const [SuccessFailed, setSuccessFailed] = useState("Success");
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState("");
  const [Alert, setAlert] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [imageSrc, setImageSrc] = useState("");

  const handleImageClick = (src) => {
    console.log("Image source:", src); // Log the image source
    setImageSrc(src);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setImageSrc("");
  };

  const handleClose = () => {
    setAlert(false);
    navigate("/company/dashboard");
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    const emojiRegex =
      /[\u{1F000}-\u{1F9FF}\u{1F300}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F100}-\u{1F1FF}\u{1F200}-\u{1F2FF}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}\u{1FC00}-\u{1FCFF}\u{1FD00}-\u{1FDFF}\u{1FE00}-\u{1FEFF}\u{1FF00}-\u{1FFFF}\u{20000}-\u{2FFFF}\u{30000}-\u{3FFFF}\u{40000}-\u{4FFFF}\u{50000}-\u{5FFFF}\u{60000}-\u{6FFFF}\u{70000}-\u{7FFFF}\u{80000}-\u{8FFFF}\u{90000}-\u{9FFFF}\u{A0000}-\u{AFFFF}\u{B0000}-\u{BFFFF}\u{C0000}-\u{CFFFF}\u{D0000}-\u{DFFFF}\u{E0000}-\u{EFFFF}]/gu;

    // Check if the value contains emojis
    if (emojiRegex.test(value)) {
      // If emojis are found, do not update the state
      return;
    }
    switch (key) {
      case "gst_number":
      case "pan_number":
        // Regular expression to allow only uppercase letters, digits, and certain special characters
        const gstRegex = /^[A-Za-z0-9]*$/;
        if (gstRegex.test(value)) {
          // Convert the value to uppercase before updating the state
          const gstval = value.toUpperCase();
          setFormData((prev) => ({
            ...prev,
            [key]: gstval,
          }));
        }
        break;
      case "pan_owners_name":
        // Regular expression to match only alphabetic characters and spaces
        const nameRegex = /^[A-Za-z\s]*$/;
        if (nameRegex.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [key]: value,
          }));
        }
        break;
      case "postal_code":
        // Regular expression to match only numeric characters with exactly 6 digits
        const postalCodeRegex = /^[0-9]{0,6}$/;
        if (postalCodeRegex.test(value)) {
          setFormData((prev) => ({
            ...prev,
            [key]: value,
          }));
        }
        break;
      default:
        setFormData((prev) => ({
          ...prev,
          [key]: value,
        }));
    }
  };

  const handleFileChange = (e, key) => {
    const file = e.target.files[0];

    // Check if a file is selected
    if (!file) {
      return; // No file selected, abort further execution
    }

    // // Check if the file size exceeds 200kb
    // if (file.size > 200000) {
    //   alert("File size should not exceed 200kb.");
    //   return; // Abort further execution
    // }

    // Update state with the selected file
    setFormData((prev) => ({
      ...prev,
      [key]: file,
    }));

    // Display preview for images only
    if (file.type.startsWith("image/")) {
      const reader = new FileReader();
      reader.onload = (event) => {
        // Set the file preview in state
        setFilePreview((prev) => ({
          ...prev,
          [key]: event.target.result,
        }));
      };
      reader.readAsDataURL(file);
    } else {
      // For non-image files, store the file name as the preview
      setFilePreview((prev) => ({
        ...prev,
        [key]: file.name,
      }));
    }
  };

  const handleCheckboxChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    console.log("##############ienfike", formData);

    let isEmptyField = false;

    for (const key in formData) {
      if (formData[key] === "" || formData[key] === null) {
        // Skip validation for cin_file, pan_file, and gst_file
        if (
          key !== "cin_file" &&
          key !== "pan_file" &&
          key !== "gst_file" &&
          key !== "client_logo"
        ) {
          isEmptyField = true;
          break;
        }
      }
    }

    if (isEmptyField) {
      alert("Please fill all the fields.");
      return;
    }
    if (formData.postal_code.length < 6) {
      alert("Pin Code need to be 6 characters.");
      return;
    }

    // Check if cin_file, pan_file, and gst_file are all null
    if (
      formData.cin_file === null ||
      formData.pan_file === null ||
      formData.gst_file === null
    ) {
      alert("Please upload your documents.");
      return;
    }

    // Validation
    if (!isChecked) {
      alert("Please ensure all documents are clear and valid.");
      return;
    }

    // Convert form data to FormData
    const formDataToSend = new FormData();
    for (const key in formData) {
      if (formData[key] !== null && formData[key] !== "") {
        formDataToSend.append(key, formData[key]);
      }
    }

    // Dispatch API call
    const successFn = (response) => {
      console.log("Response:", response);
      if (response.statusFlag) {
        // alert(response.message)
        // handleNext('overview');
        // navigate('/company/dashboard');
        setSuccessFailed("Success");
        setSuccessFailedMessage(response.message);
        setAlert(true);
      } else {
        // alert(response.message)
        setSuccessFailed("Success");
        setSuccessFailedMessage(response.message);
        setAlert(true);
      }
    };
    const failureFn = () => {
      console.log("Logged-In Failed");
    };

    dispatch(
        addCompanyDetails({
        data: formDataToSend,
        successFn: successFn,
        failureFn: failureFn,
      })
    );
  };



  return (
    <div className={`detailsBg ${component === 'details' ? 'active' : component === 'overview' ? 'next' : 'next'}`}>
      <h1>Business Details</h1>
      <form onSubmit={handleFormSubmit}>
  <div className="uploadsContainer">

    <input
      type="text"
      placeholder="Business Pan"
      id="Business Pan"
      value={formData.pan_number}
      maxLength={10}
      onChange={(e) => handleInputChange(e, "pan_number")}
      required
    />

    <input
      type="text"
      placeholder="Pan Owners Name"
      id="Pan Owners Name"
      value={formData.pan_owners_name}
      maxLength={60}
      onChange={(e) => handleInputChange(e, "pan_owners_name")}
      required
    />

    <input
      type="text"
      placeholder="GSTIN"
      id="GSTIN"
      value={formData.gst_number}
      maxLength={15}
      onChange={(e) => handleInputChange(e, "gst_number")}
      required
    />

    <input
      type="text"
      placeholder="Address"
      id="Address"
      onChange={(e) => handleInputChange(e, "client_address")}
      required
    />

    <input
      type="text"
      placeholder="City"
      id="City"
      onChange={(e) => handleInputChange(e, "city")}
      required
    />

    <select
      id="State"
      value={formData.state}
      onChange={(e) => handleInputChange(e, "state")}
      required
    >
      <option disabled value="">
        -- Select your state --
      </option>
      {indianStates.map((state, index) => (
        <option key={index} value={state}>
          {state}
        </option>
      ))}
    </select>

    <input
      type="text"
      placeholder="Pin Code"
      id="Pin Code"
      value={formData.postal_code}
      onChange={(e) => handleInputChange(e, "postal_code")}
      maxLength={6}
      required
    />

    <input
      type="text"
      value={formData.country}
      placeholder="Country"
      id="Country"
      onChange={(e) => handleInputChange(e, "country")}
      readOnly
    />

    <h3>Add Documents</h3>
    <span>Please submit your documents to finalize company registration</span>
    <section className="AddDocumentsContainer">
      {Object.keys(filePreview).map((key) => (
        <article className="AddDocument" key={key}>
          {!filePreview[key] && (
            <div className="TitleHint">
              <h3
                className="Ellipsis"
                title={
                  key === "cin_file"
                    ? "Add CIN *"
                    : key === "pan_file"
                    ? "Add PAN *"
                    : key === "gst_file"
                    ? "Add GST *"
                    : "Add Company Logo"
                }
              >
                {key === "cin_file"
                  ? "Add CIN *"
                  : key === "pan_file"
                  ? "Add PAN *"
                  : key === "gst_file"
                  ? "Add GST *"
                  : "Add Company Logo"}
              </h3>
              <p>
                {key === "client_logo"
                  ? "(allowed formats : .jpg, .jpeg, .png)"
                  : "(allowed formats : .pdf, .jpg, .jpeg, .png)"}
              </p>
            </div>
          )}
          {filePreview[key] && (
            <div className='TitleHint'>
              <span
                className="Ellipsis"
                title={
                  key === "cin_file"
                    ? "CIN Document*"
                    : key === "pan_file"
                    ? "PAN Document*"
                    : key === "gst_file"
                    ? "GST Document*"
                    : "Company Logo"
                }
              >
                {key === "cin_file"
                  ? "CIN Document*"
                  : key === "pan_file"
                  ? "PAN Document*"
                  : key === "gst_file"
                  ? "GST Document*"
                  : "Company Logo"}
              </span>
              <span className="FileName">
                {filePreview[key].startsWith("data:image/") ? (
                  <a
                    href="#"
                    title={filePreview[key].filename}
                    onClick={(e) => {
                      e.preventDefault();
                      handleImageClick(filePreview[key]);
                    }}
                  >
                    {filePreview[key].filename || "Preview Image"}
                  </a>
                ) : (
                  filePreview[key] ?
                  <>
                    {filePreview[key]}
                    <p>No Preview Available</p>
                  </>
                  :
                  <div>
                    <p>{key}</p>
                  </div>
                )}
              </span>
            </div>
          )}
          <label className="UploadButton CenterDiv">
            <input
              type="file"
              onChange={(e) => handleFileChange(e, key)}
              accept={
                key === "cin_file" || key === "pan_file" || key === "gst_file"
                  ? ".pdf,.png,.jpg,.jpeg"
                  : ".jpeg,.png,.jpg"
              }
            />
            Upload
          </label>
        </article>
      ))}
    </section>

    <label className="Ensure">
      <input
        type="checkbox"
        onChange={handleCheckboxChange}
        checked={isChecked}
        required
      />
      <div>
        I ensured all documents are clear and valid.
        <span>
          <strong>Note:</strong> Your information is securely handled in
          accordance with our <a href="">privacy policy</a>
        </span>
      </div>
    </label>
  </div>

  <button type="submit" disabled={submitLoading}>
    {submitLoading ? <ButtonSpinner /> : "Next"}
  </button>
</form>

      {Alert && (
        <CustomAlert
          type={SuccessFailed}
          message={SuccessFailedMessage}
          handleClose={handleClose}
        />
      )}

      {isModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <span className="close" onClick={closeModal}>
              &times;
            </span>
            <img src={imageSrc} alt="Preview" />
          </div>
        </div>
      )}
    </div>
  )
}

export default BusinessDetails;
