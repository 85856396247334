// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accessDenialBg {
  position: fixed;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(5px);
  z-index: 10;
}
.accessDenialBg .modalContent {
  background-color: var(--Foreground);
  width: 50%;
  padding: 0rem 1.25rem;
  border-radius: 1.25rem;
  display: flex;
  gap: 2rem;
}
.accessDenialBg .modalContent .paymentMsg {
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  justify-content: center;
}
.accessDenialBg .modalContent .paymentMsg h1 {
  font-size: 1.25rem;
  font-weight: 600;
  color: var(--TextColor);
}
.accessDenialBg .modalContent .paymentMsg p {
  font-size: 0.9rem;
  color: var(--TextColor);
}
.accessDenialBg .modalContent .paymentMsg button {
  width: 8rem;
  text-align: center;
  border-radius: 0.3rem;
  outline: none;
  padding: 0.6rem 0rem;
  background-color: var(--AccentColor);
  color: white;
  border: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/ReusableComponents/AccessDenial.scss"],"names":[],"mappings":"AAAA;EACI,eAAA;EACA,YAAA;EACA,aAAA;EACA,MAAA;EACA,OAAA;EACA,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,8BAAA;EAEA,0BAAA;EACA,WAAA;AAAJ;AACI;EACI,mCAAA;EACA,UAAA;EACA,qBAAA;EACA,sBAAA;EACA,aAAA;EACA,SAAA;AACR;AAAQ;EACI,aAAA;EACA,sBAAA;EACA,YAAA;EACA,uBAAA;AAEZ;AADY;EACI,kBAAA;EACA,gBAAA;EACA,uBAAA;AAGhB;AADY;EACI,iBAAA;EACA,uBAAA;AAGhB;AAAY;EACI,WAAA;EACA,kBAAA;EACA,qBAAA;EACA,aAAA;EACA,oBAAA;EACA,oCAAA;EACA,YAAA;EACA,YAAA;AAEhB","sourcesContent":[".accessDenialBg{\n    position: fixed;\n    width: 100vw;\n    height: 100vh;\n    top: 0;\n    left: 0;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    background: rgba(0,0,0,.7);\n    // background: #2C2C2C50;\n    backdrop-filter: blur(5px);\n    z-index: 10;\n    .modalContent{\n        background-color: var(--Foreground);\n        width: 50%;\n        padding: 0rem 1.25rem;\n        border-radius: 1.25rem;\n        display: flex;\n        gap: 2rem;\n        .paymentMsg{\n            display: flex;\n            flex-direction: column;\n            gap: 1.25rem;\n            justify-content: center;\n            h1{\n                font-size: 1.25rem;\n                font-weight: 600;\n                color: var(--TextColor);\n            }\n            p{\n                font-size: .9rem;\n                color: var(--TextColor);\n            }\n\n            button{\n                width: 8rem;\n                text-align: center;\n                border-radius: .3rem;\n                outline: none;\n                padding: .6rem 0rem;\n                background-color: var(--AccentColor);\n                color: white;\n                border: none;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
