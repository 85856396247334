import React, { useState, useEffect } from 'react';
import './CompanyInfo.scss';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from '../../../ReusableComponents/CustomAlert';
import { addCompanyInformation } from '../../../../redux/actions/CompanyRegisterationAction';
import ButtonSpinner from '../../../ReusableComponents/ButtonSpinner';

function CompanyInfo() {
  const { component } = useParams();
  console.log(component)
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const clientInfoID = localStorage.getItem("client_info_id");
  const clientUsername = localStorage.getItem("client_username");
  const useremail = localStorage.getItem("useremail");
  const submitLoading = useSelector((state) => state.addCompanyInformation.loading);


    const deletedClass = component === "overview" || component === "details";

    const [data, setData] = useState({
      client_info_id: clientInfoID,
      client_name: "",
      client_phone: "",
      client_email: useremail,
      client_website: "",
      client_username: clientUsername
    });

    const [SuccessFailed, setSuccessFailed] = useState("Success");
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState("");
  const [Alert, setAlert] = useState(false);
  const handleSuccessClose = () => {
    setAlert(false);
    navigate("/company-register/overview");
  };
  const handleFailedClose = () => {
    setAlert(false);
  };

  const handleChange = (e, key) => {
    const value = e.target.value;
    let updatedValue = value;

    switch (key) {
      case "client_name":
        // Regular expression to match only alphabetic characters and spaces
        const nameRegex = /^[A-Za-z\s]+$/;
        if (value === "" || nameRegex.test(value)) {
          setData((prev) => ({ ...prev, [key]: value }));
        }
        break;
      case "client_phone":
        // Remove any non-digit characters from the input
        updatedValue = value.replace(/\D/g, "");
        if (updatedValue.length <= 16) {
          setData((prev) => ({ ...prev, [key]: updatedValue }));
        }
        break;
      case "client_website":
        // Regular expression pattern to validate website format
        const websiteRegex = /^[a-z.\/:]+$/;
        if (value === "" || websiteRegex.test(value)) {
          setData((prev) => ({ ...prev, [key]: value }));
        }
        break;
      default:
        break;
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Perform validation
    if (!data.client_name || !data.client_phone || !data.client_website) {
      alert("Please fill out all fields");
      return;
    }

    if (data.client_phone.length < 10) {
      alert("Phone number must be atleast 10 digits long");
      return;
    }

    // Website validation (simplified)
    const websitePattern =
      /^(http(s)?:\/\/)?([\w-]+\.)+[\w-]+(\/[\w- .\/?%&=]*)?$/;
    if (!websitePattern.test(data.client_website)) {
      alert("Invalid website format.");
      return;
    }

    const successFn = (response) => {
      console.log("Response:", response);
      if (response.statusFlag) {
        // alert(response.message)
        // handleNext('overview');
        // navigate('/companyregistration/overview');
        setSuccessFailed("Success");
        setSuccessFailedMessage(response.message);
        setAlert(true);
      } else {
        // alert(response.message)
        setSuccessFailed("Success");
        setSuccessFailedMessage(response.message);
        setAlert(true);
      }
    };
    const failureFn = (error) => {
      console.log("Logged-In Failed");
        setSuccessFailed("Failed");
        setSuccessFailedMessage(error);
        setAlert(true);
    };

    dispatch(
      addCompanyInformation({ data: data, successFn: successFn, failureFn: failureFn })
    );
  };

  return (
    <div className={`${deletedClass ? 'deletedComp' : 'companyInfoBg'} ${component === 'information' ? 'active' : ''}`}>
      <h1>Company Information</h1>
      <form onSubmit={handleFormSubmit}>
  <div className="inputContainers">
    <input
      type="text"
      placeholder="Company Name"
      id="Company Name"
      value={data.client_name}
      onChange={(e) => handleChange(e, "client_name")}
      maxLength={60}
      required
    />
    <input
      type="text"
      placeholder="Company Mobile number"
      id="Company Mobile number"
      value={data.client_phone}
      onChange={(e) => handleChange(e, "client_phone")}
      maxLength={16}
      required
    />
    <input
      type="text"
      placeholder="Company Website"
      id="Company Website"
      value={data.client_website}
      onChange={(e) => handleChange(e, "client_website")}
      required
    />
  </div>
  <button type="submit" disabled={submitLoading}>
    {submitLoading ? <ButtonSpinner /> : "Next"}
  </button>
</form>

      {Alert && (
        <CustomAlert
          type={SuccessFailed}
          message={SuccessFailedMessage}
          handleClose={SuccessFailed === 'Success' ? handleSuccessClose : handleFailedClose}
        />
      )}
    </div>
  )
}

export default CompanyInfo;
