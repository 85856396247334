import React, { useState, useEffect } from "react";
import "./PaymentProgress.scss";
import PaymentModal from "./PaymentModal";
import { useDispatch } from "react-redux"; // Import dispatch
import { fetchOrderDetails } from "../../redux/actions/CompanyBeginVerificationAction";

function PaymentProgress({ orderId, processModal, submitFunction }) {
  const dispatch = useDispatch();
  const [paymentStatus, setPaymentStatus] = useState("");
  const [timerView, setTimerView] = useState(true);
  const totalTime = 15 * 60 * 1000;
  const [time, setTime] = useState(totalTime);

  const getOrderStatus = () => {
    if (!orderId || !timerView) return; // Stop API calls if timerView is false

    const successFn = (response) => {
      console.log("orderData::::::::::", response?.data);
      if (response?.data[0]?.payment_status === "Failure") {
        setTimerView(false);
        setPaymentStatus("Failed");
        console.log("1 exec");
      } else if (response?.data[0]?.payment_status === "Success") {
        setTimerView(false);
        setPaymentStatus("Success");
        console.log("2 exec");
      }
      else if (response?.data[0]?.payment_status === "Aborted") {
        setTimerView(false);
        setPaymentStatus("Aborted");
        console.log("2 exec");
      }
      
      //  else {
      //   setPaymentStatus(response?.data[0]?.payment_status);
      //   console.log("3 exec");
      // }
    };

    const failureFn = (error) => {
      alert(error);
    };

    const params = { order_id: orderId };
    dispatch(fetchOrderDetails({ params, successFn, failureFn }));
  };

  console.log(paymentStatus)

  useEffect(() => {
    if (time > 0 && timerView) {
      const timer = setTimeout(() => {
        setTime(time - 1000);
      }, 1000);
      return () => clearTimeout(timer);
    } else if (time <= 0) {
      // When time ends, fail the payment
      setPaymentStatus("Failed");
      setTimerView(false);
      processModal(false);
    }
  }, [time, timerView]);

 // Call getOrderStatus every 20 seconds
 useEffect(() => {
    if (timerView) {
      const interval = setInterval(() => {
        getOrderStatus();
      }, 20000); // Every 20 seconds
      return () => clearInterval(interval); // Cleanup interval on component unmount
    }
  }, [orderId, timerView]);

  const getTime = () => {
    let total_seconds = parseInt(Math.floor(time / 1000));
    let total_minutes = parseInt(Math.floor(total_seconds / 60));
    let seconds = String(total_seconds % 60).padStart(2, "0");
    let minutes = String(total_minutes % 60).padStart(2, "0");

    return (
      <div className="backGroundImg">
        <div className="timerCont">
          <p>{`${minutes} : ${seconds}`}</p>
        </div>
        <div className="timerContText">
          <p>Minute Remaining</p>
        </div>
      </div>
    );
  };

  const progressPercentage = ((totalTime - time) / totalTime) * 100;

  return (
   <div className="PaymentProgressBg">
      <div className="paymentContainer">
        {timerView && <div className="timer">{getTime()}</div>}

        {timerView && (
          <div className="progressBarContainer">
            <div
              className="progressBar"
              style={{ width: `${progressPercentage}%` }}
            />
          </div>
        )}

        <h1>Don’t Quit! Almost Done! ⏳</h1>
        <p>Hold tight, your action is in progress. Don’t close or go back just yet!</p>
      </div>

      {paymentStatus !== "" && (
        <PaymentModal
          response={paymentStatus}
          closeModal={() => processModal(false)}
          submitFunction={submitFunction}
        />
      )}
    </div>
  );
}

export default PaymentProgress;
