import React from "react";
import "./ConfirmationModal.scss";
import CancelInvite from "../images/ConfirmationImage.svg";
import ButtonLoader from "../../Loader/ButtonLoader";
import { useSelector } from "react-redux";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";

const ConfirmationModal = ({ data, handleConfirm, handleReject }) => {
  const loading = useSelector(state => state.clientCancelOrderDetails?.loading);

  return (
    <section className='VeriSavedContainerCancel'>
      <div className='VeriSaved'>
        {/* <div className='SuccessBeginVerification'>
          <img
            src={CancelInvite}
            alt='SuccessBeginVerification'
          />
        </div> */}
        <h1>Cancel Invitation</h1>
        <span className='Hint'>
          Hey, are you sure you want to cancel this invite of{" "}
          <b>{data?.full_name}</b>?
          <br />
          Once it's gone, it's gone forever! Make your decision wisely!
        </span>
        <div className='ButtonContainer'>
          <button
            className='Cancel'
            onClick={handleReject}
            disabled={loading}>
            Keep Invitation
          </button>
          <button
            className='Done CenterDiv'
            onClick={handleConfirm}
            disabled={loading}>
            {loading ? <ButtonSpinner /> : "Cancel Invitation"}
          </button>
        </div>
      </div>
    </section>
  );
};

export default ConfirmationModal;
