import React from "react";
import "./Actions.scss";

const Actions = ({
  data,
  showUpdate,
  handleView,
  handleCancel,
  handleUpdate,
}) => {
  return (
    <div className='Actions'>
      <svg
        onClick={() => handleView(data)}
        xmlns='http://www.w3.org/2000/svg'
        width='1.25rem'
        height='0.9rem'
        viewBox='0 0 15 9'
        fill='none'>
        <path
          fillRule='evenodd'
          clipRule='evenodd'
          d='M1.08398 4.5C1.08398 4.5 3.41732 0.5 7.50065 0.5C11.584 0.5 13.9173 4.5 13.9173 4.5C13.9173 4.5 11.584 8.5 7.50065 8.5C3.41732 8.5 1.08398 4.5 1.08398 4.5Z'
          stroke='var(--TextColor)'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M7.50065 6C8.46715 6 9.25065 5.32843 9.25065 4.5C9.25065 3.67157 8.46715 3 7.50065 3C6.53415 3 5.75065 3.67157 5.75065 4.5C5.75065 5.32843 6.53415 6 7.50065 6Z'
          stroke='var(--TextColor)'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </svg>

      {data.cancel_time === 1 && data.candidate_status !== "cancelled" && (
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1rem'
          height='1rem'
          viewBox='0 0 16 16'
          fill='none'
          onClick={() => handleCancel(data)}>
          <path
            d='M8 2C11.3137 2 14 4.68629 14 8C14 11.3137 11.3137 14 8 14C4.68629 14 2 11.3137 2 8C2 4.68629 4.68629 2 8 2ZM8 3C5.23858 3 3 5.23858 3 8C3 10.7614 5.23858 13 8 13C10.7614 13 13 10.7614 13 8C13 5.23858 10.7614 3 8 3ZM5.83859 5.96569L5.89645 5.89645C6.07001 5.72288 6.33944 5.7036 6.53431 5.83859L6.60355 5.89645L8 7.293L9.39645 5.89645C9.57001 5.72288 9.83944 5.7036 10.0343 5.83859L10.1036 5.89645C10.2771 6.07001 10.2964 6.33944 10.1614 6.53431L10.1036 6.60355L8.707 8L10.1036 9.39645C10.2771 9.57001 10.2964 9.83944 10.1614 10.0343L10.1036 10.1036C9.92999 10.2771 9.66056 10.2964 9.46569 10.1614L9.39645 10.1036L8 8.707L6.60355 10.1036C6.42999 10.2771 6.16056 10.2964 5.96569 10.1614L5.89645 10.1036C5.72288 9.92999 5.7036 9.66056 5.83859 9.46569L5.89645 9.39645L7.293 8L5.89645 6.60355C5.72288 6.42999 5.7036 6.16056 5.83859 5.96569Z'
            fill='var(--TextColor)'
          />
        </svg>
      )}
      {(data.candidate_status === "pending" ||
        data.candidate_status === "completed" ||
        data.candidate_status === "invitation") &&
        !!showUpdate && (
          <span
            className='Update'
            onClick={() => {
              handleUpdate(data);
            }}>
            Update
          </span>
        )}
    </div>
  );
};

export default Actions;
