import { Suspense, useEffect } from "react";
import "./App.css";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";
import AppRouter from "./Routes/AppRouter";
import { createTheme, ThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import FullPageLoader from "./Components/Loader/FullPageLoader";
import { generateToken, messaging } from "./constant/firebase";
import { onMessage } from "firebase/messaging";

const lightTheme = createTheme({
  palette: {
    mode: "light",
    primary: {
      main: "#102C97", // Light mode primary color
    },
    secondary: {
      main: "#ff4081", // Example secondary color, adjust as needed
    },
    error: {
      main: "#f44336",
    },
    warning: {
      main: "#ffa726",
    },
    info: {
      main: "#29b6f6",
    },
    success: {
      main: "#66bb6a",
    },
    background: {
      default: "#f4f6f8",
      paper: "#ffffff",
    },
    text: {
      primary: "#333333", // Example text color, adjust as needed
      secondary: "#666666",
    },
    divider: "#e0e0e0",
    action: {
      active: "#102C97",
      hover: "#f4f6f8",
      selected: "#f4f6f8",
      disabled: "#cccccc",
      disabledBackground: "#e0e0e0",
    },
    grey: {
      50: "#fafafa",
      100: "#f5f5f5",
      200: "#eeeeee",
      300: "#e0e0e0",
      400: "#bdbdbd",
      500: "#9e9e9e",
      600: "#757575",
      700: "#616161",
      800: "#424242",
      900: "#212121",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#33333390", // Update to use theme color
          color: "#ffffff", // Update to use theme color
        },
        arrow: {
          color: "#333333", // Update to use theme color
        },
      },
    },
  },

  contrastThreshold: 3,
  tonalOffset: 0.2,
});

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#09A5E0", // Dark mode primary color
    },
    secondary: {
      main: "#ff4081", // Example secondary color, adjust as needed
    },
    error: {
      main: "#ef5350",
    },
    warning: {
      main: "#ffb74d",
    },
    info: {
      main: "#4fc3f7",
    },
    success: {
      main: "#81c784",
    },
    background: {
      default: "#121212",
      paper: "#1d1d1d",
    },
    text: {
      primary: "#ffffff",
      secondary: "#bbbbbb",
    },
    divider: "#424242",
    action: {
      active: "#09A5E0",
      hover: "#333333",
      selected: "#424242",
      disabled: "#666666",
      disabledBackground: "#333333",
    },
    grey: {
      50: "#212121",
      100: "#424242",
      200: "#616161",
      300: "#757575",
      400: "#9e9e9e",
      500: "#bdbdbd",
      600: "#e0e0e0",
      700: "#eeeeee",
      800: "#f5f5f5",
      900: "#fafafa",
    },
  },
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  components: {
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          backgroundColor: "#ffffff",
          color: "#333333",
        },
        arrow: {
          color: "#333333",
        },
      },
    },
  },
  contrastThreshold: 3,
  tonalOffset: 0.2,
});

const filterEmojis = (value) => {
  // const emojiRegex = /([\u2700-\u27BF]|[\uE000-\uF8FF]|[\uD800-\uDBFF][\uDC00-\uDFFF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDE4F]|\uD83D[\uDE80-\uDEFF])/g;
  const emojiRegex = /(\p{Emoji_Presentation}|\p{Emoji}\uFE0F)/gu;
  return value.replace(emojiRegex, '');
};

function App() {
  const darkMode = localStorage.getItem("Theme") === "true" ? true : false;

  useEffect(() => {
    const handleInput = (event) => {
      if (event.target.tagName === 'INPUT' || event.target.tagName === 'TEXTAREA') {
        const filteredValue = filterEmojis(event.target.value);
        if (event.target.value !== filteredValue) {
          event.target.value = filteredValue;
        }
      }
    };

    document.addEventListener('input', handleInput);

    return () => {
      document.removeEventListener('input', handleInput);
    };
  }, []);

  useEffect(()=>{
    generateToken()
    onMessage(messaging,(payload) => {
        console.log("notification::::::::::",payload)
    })
   })

  return (
    <Provider store={store}>
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
          <Router>
            <Suspense fallback={<FullPageLoader/>}>
              <AppRouter />
            </Suspense>
          </Router>
        </ThemeProvider>
      </LocalizationProvider>
    </Provider>
  );
}

export default App;
