import React from 'react'
import './MainLoader.scss'

const MainLoader = ({scale = 1}) => {
  return (
    <div className='MainLoaderContainer' style={{scale:`${scale}`}}>
      <div className="MainLoader">
        <div className="item1"/>
        <div className="item2"/>
        <div className="item3"/>
      </div>
    </div>
  )
}

export default MainLoader