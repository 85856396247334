// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.progress {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(7.5rem, 1fr));
  gap: 0.6rem;
  overflow-x: hidden;
}
.progress::-webkit-scrollbar {
  display: none;
}
.progress div {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: var(--Foreground);
  padding-bottom: 0.6rem;
  border-radius: 0.6rem;
  cursor: pointer;
}
.progress div .count {
  font-size: clamp(0.8rem, 1.3vw, 1.25rem);
  font-weight: 600;
  color: var(--TextColor);
}
.progress div p {
  color: var(--TextColor);
  font-size: 0.8rem;
  font-weight: 400;
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.progress .all {
  background-color: var(--Foreground);
}
.progress .invited {
  background-color: var(--Invitation);
}
.progress .accepted {
  background-color: var(--Accepted);
}
.progress .inProgress {
  background-color: var(--Inprogress);
}
.progress .pending {
  background-color: var(--Pending);
}
.progress .completed {
  background-color: var(--Completed);
}
.progress .cancelled {
  background-color: var(--Cancelled);
}
.progress .reinitiated {
  background-color: var(--Reinitiated);
}`, "",{"version":3,"sources":["webpack://./src/Components/CandidateDetails/Progress/Progress.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EAEA,aAAA;EACA,4DAAA;EACA,WAAA;EAEA,kBAAA;AADF;AAEE;EACE,aAAA;AAAJ;AAEE;EAEE,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,mCAAA;EAGA,sBAAA;EACA,qBAAA;EACA,eAAA;AAHJ;AAII;EAEE,wCAAA;EACA,gBAAA;EACA,uBAAA;AAHN;AAKI;EACE,uBAAA;EACA,iBAAA;EACA,gBAAA;EACA,iBAAA;EACA,gBAAA;EACA,uBAAA;AAHN;AAME;EACE,mCAAA;AAJJ;AAME;EACE,mCAAA;AAJJ;AAME;EACE,iCAAA;AAJJ;AAME;EACE,mCAAA;AAJJ;AAME;EACE,gCAAA;AAJJ;AAME;EACE,kCAAA;AAJJ;AAME;EACE,kCAAA;AAJJ;AAME;EACE,oCAAA;AAJJ","sourcesContent":[".progress {\n  width: 100%;\n  // display: flex;\n  display: grid;\n  grid-template-columns: repeat(auto-fit, minmax(7.5rem, 1fr));\n  gap: 0.6rem;\n  // overflow-x: auto;\n  overflow-x: hidden;\n  &::-webkit-scrollbar {\n    display: none;\n  }\n  div {\n    // flex: 1;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    background-color: var(--Foreground);\n    // width: 140px;\n    // padding: 0rem 1.25rem .6rem 1.25rem;\n    padding-bottom: 0.6rem;\n    border-radius: 0.6rem;\n    cursor: pointer;\n    .count {\n      // font-size: 1.25rem;\n      font-size: clamp(0.8rem, 1.3vw, 1.25rem);\n      font-weight: 600;\n      color: var(--TextColor);\n    }\n    p {\n      color: var(--TextColor);\n      font-size: 0.8rem;\n      font-weight: 400;\n      text-wrap: nowrap;\n      overflow: hidden;\n      text-overflow: ellipsis;\n    }\n  }\n  .all {\n    background-color: var(--Foreground);\n  }\n  .invited {\n    background-color: var(--Invitation);\n  }\n  .accepted {\n    background-color: var(--Accepted);\n  }\n  .inProgress {\n    background-color: var(--Inprogress);\n  }\n  .pending {\n    background-color: var(--Pending);\n  }\n  .completed {\n    background-color: var(--Completed);\n  }\n  .cancelled {\n    background-color: var(--Cancelled);\n  }\n  .reinitiated {\n    background-color: var(--Reinitiated);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
