import CustomAlert from "./CustomAlert";
// const createImageViewer = (pdf=null) => {
//     const images = document.querySelectorAll('img');
  
//     images.forEach((img) => {
//       const imgUrl = img.src;
//   const imageLayer = document.createElement('div');
//   imageLayer.style.width = `${img.width}px`; 
//   imageLayer.style.height = `${img.height}px`;
//   imageLayer.style.backgroundImage = `url(${imgUrl})`;
//   imageLayer.style.backgroundSize = 'cover';
//   imageLayer.style.backgroundPosition = 'center';
//   imageLayer.style.position = 'relative';
//   img.parentNode.replaceChild(imageLayer, img);
//       imageLayer.addEventListener('click', () => {
//         const viewer = document.createElement('div');
//         viewer.style.position = 'fixed';
//         viewer.style.top = 0;
//         viewer.style.left = 0;
//         viewer.style.width = '100vw';
//         viewer.style.height = '100vh';
//         viewer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
//         viewer.style.display = 'flex';
//         viewer.style.alignItems = 'center';
//         viewer.style.justifyContent = 'center';
//         viewer.style.zIndex = 1000;
  
//         const imgElement = document.createElement('div');
//         imgElement.style.maxWidth = '90%';
//         imgElement.style.minWidth = '50%';
//         imgElement.style.height = '90%';
//         imgElement.style.backgroundImage = `url(${imgUrl})`;
//         imgElement.style.backgroundRepeat = 'no-repeat';
//         imgElement.style.backgroundSize = 'contain';
//         imgElement.style.backgroundPosition = 'center';

//         viewer.appendChild(imgElement);
  
//         const closeButton = document.createElement('button');
//         closeButton.innerText = 'X';
//         closeButton.style.position = 'absolute';
//         closeButton.style.top = '20px';
//         closeButton.style.right = '20px';
//         closeButton.style.fontSize = '1.25rem';
//         closeButton.style.color = 'white';
//         closeButton.style.background = 'none';
//         closeButton.style.border = 'none';
//         closeButton.style.cursor = 'pointer';
//         viewer.appendChild(closeButton);
  
//         document.body.appendChild(viewer);
  
//         viewer.style.display = 'flex';
  
//         closeButton.addEventListener('click', () => {
//           viewer.style.display = 'none';
//           document.body.removeChild(viewer); 
//         });
  
      
//         viewer.addEventListener('click', (e) => {
//           if (e.target === viewer) {
//             viewer.style.display = 'none';
//             document.body.removeChild(viewer);
//           }
//         });
//       });
//     });
//   };

const createImageViewer = (pdf = null) => {
  const images = document.querySelectorAll('img');

  images.forEach((img) => {
    const imgUrl = img.src;

    const imageLayer = document.createElement('div');
    imageLayer.style.width = `100%`;
    imageLayer.style.minHeight = `10rem`;
    imageLayer.style.height = `${img.height}px`;
    imageLayer.style.backgroundImage = `url(${imgUrl})`;
    imageLayer.style.backgroundSize = 'cover';
    imageLayer.style.backgroundPosition = 'center';
    imageLayer.style.position = 'relative';
    img.parentNode.replaceChild(imageLayer, img);

    imageLayer.addEventListener('click', () => {
      const viewer = document.createElement('div');
      viewer.style.position = 'fixed';
      viewer.style.top = 0;
      viewer.style.left = 0;
      viewer.style.width = '100vw';
      viewer.style.height = '100vh';
      viewer.style.backgroundColor = 'rgba(0, 0, 0, 0.8)';
      viewer.style.display = 'flex';
      viewer.style.alignItems = 'center';
      viewer.style.justifyContent = 'center';
      viewer.style.backdropFilter = 'blur(8px)';
      viewer.style.zIndex = 1000;

      const imgElement = document.createElement('div');
      imgElement.style.maxWidth = '90%';
      imgElement.style.minWidth = '50%';
      imgElement.style.height = '90%';
      imgElement.style.backgroundImage = `url(${imgUrl})`;
      imgElement.style.backgroundRepeat = 'no-repeat';
      imgElement.style.backgroundSize = 'contain';
      imgElement.style.backgroundPosition = 'center';
      viewer.appendChild(imgElement);

      const closeButton = document.createElement('button');
      closeButton.innerText = 'X';
      closeButton.style.position = 'absolute';
      closeButton.style.top = '20px';
      closeButton.style.right = '20px';
      closeButton.style.fontSize = '1.25rem';
      closeButton.style.color = 'white';
      closeButton.style.background = 'none';
      closeButton.style.border = 'none';
      closeButton.style.cursor = 'pointer';
      viewer.appendChild(closeButton);

      document.body.appendChild(viewer);
      viewer.style.display = 'flex';

      const addBackdropOnPrintScreen = (e) => {
        if (e.keyCode === 44) {  
          imgElement.style.filter = 'blur(8px)'; 
        }
      };

      document.addEventListener('keyup', addBackdropOnPrintScreen);

      closeButton.addEventListener('click', () => {
        viewer.style.display = 'none';
        document.body.removeChild(viewer);
        document.removeEventListener('keyup', addBackdropOnPrintScreen);
      });

      viewer.addEventListener('click', (e) => {
        if (e.target === viewer) {
          viewer.style.display = 'none';
          document.body.removeChild(viewer);
          document.removeEventListener('keyup', addBackdropOnPrintScreen);
        }
      });
    });
  });
};

export default createImageViewer;
  