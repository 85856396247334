export const data = [
    {
      checkName: "Education Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "PAN Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "Adhar Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "UAN Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "License Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "Address Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "Drug Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "Employment Check",
      pending: 12,
      completed: 12,
    },
    {
      checkName: "Education Check",
      pending: 12,
      completed: 12,
    },
];
  
export const pieChartData = [
    {
      "id": "Invited",
      "label": "sass",
      "value": 319,
      "color": "#D7EDFF"
    },
    {
      "id": "Accepted",
      "label": "Accepted",
      "value": 170,
      "color": "#DAD4FF"
    },
    {
      "id": "Pending",
      "label": "Pending",
      "value": 508,
      "color": "#B2E7FF"
    },
    {
      "id": "Cancelled",
      "label": "Cancelled",
      "value": 225,
      "color": "#FFBDBD"
    },
    {
      "id": "Reinitiated",
      "label": "Reinitiated",
      "value": 145,
      "color": "#DAFFF6"
    },
    {
      "id": "Inprogress",
      "label": "Inprogress",
      "value": 98,
      "color": "#F8E7AB"
    },
    {
      "id": "Completed",
      "label": "Completed",
      "value": 105,
      "color": "#C0FFC3"
    }
]

export const candidateDetails = [
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
  {
    seekerName: "Vignesh",
    id: "23223",
    initiatedDate: "23/12/23",
    status: "Initiated",
  },
];

export const areaChartData = [
  {
    "id": "japan",
    "color": "hsl(131, 70%, 50%)",
    "data": [
      {
        "x": "jan",
        "y": 122
      },
      {
        "x": "feb",
        "y": 220
      },
      {
        "x": "mar",
        "y": 134
      },
      {
        "x": "apr",
        "y": 184
      },
      {
        "x": "may",
        "y": 5
      },
      {
        "x": "jun",
        "y": 107
      },
      {
        "x": "jul",
        "y": 208
      },
      {
        "x": "aug",
        "y": 257
      },
      {
        "x": "sep",
        "y": 76
      },
      {
        "x": "oct",
        "y": 50
      },
      {
        "x": "nov",
        "y": 175
      },
      {
        "x": "dec",
        "y": 200
      }
    ]
  },
];

export const radialBarData = [
  {
    "id": "Supermarket",
    "data": [
      {
        "x": "Vegetables",
        "y": 20
      },
    ]
  },
  {
    "id": "Combini",
    "data": [
      {
        "x": "Meat",
        "y": 40
      },    
    ]
  },
]

export const barChartData = [
  {
    "country": "AD",
    "hot dog": 176,
    "hot dogColor": "hsl(64, 70%, 50%)",
  },
  {
    "country": "AE",
    "hot dog": 76,
    "hot dogColor": "hsl(311, 70%, 50%)",
  },
  {
    "country": "AF",
    "hot dog": 196,
    "hot dogColor": "hsl(108, 70%, 50%)",
  },
  {
    "country": "AG",
    "hot dog": 186,
    "hot dogColor": "hsl(201, 70%, 50%)",
  },
  {
    "country": "AI",
    "hot dog": 103,
    "hot dogColor": "hsl(22, 70%, 50%)",
  },
  {
    "country": "AL",
    "hot dog": 110,
    "hot dogColor": "hsl(3, 70%, 50%)",
  },
  {
    "country": "AM",
    "hot dog": 102,
    "hot dogColor": "hsl(82, 70%, 50%)",
  },
  {
    "country": "AI",
    "hot dog": 183,
    "hot dogColor": "hsl(22, 70%, 50%)",
  },
  {
    "country": "AL",
    "hot dog": 130,
    "hot dogColor": "hsl(3, 70%, 50%)",
  },
  {
    "country": "AM",
    "hot dog": 32,
    "hot dogColor": "hsl(82, 70%, 50%)",
  }
]

export const verticalBarChartData = [
  {
    "country": "AD",
    "hot dog": 2,
    "hot dogColor": "hsl(295, 70%, 50%)",
    "burger": 110,
    "burgerColor": "hsl(334, 70%, 50%)",
    "sandwich": 24,
    "sandwichColor": "hsl(330, 70%, 50%)",
    "kebab": 19,
    "kebabColor": "hsl(93, 70%, 50%)",
    "fries": 69,
    "friesColor": "hsl(52, 70%, 50%)",
    "donut": 161,
    "donutColor": "hsl(241, 70%, 50%)"
  },
  {
    "country": "AE",
    "hot dog": 163,
    "hot dogColor": "hsl(215, 70%, 50%)",
    "burger": 91,
    "burgerColor": "hsl(28, 70%, 50%)",
    "sandwich": 2,
    "sandwichColor": "hsl(297, 70%, 50%)",
    "kebab": 88,
    "kebabColor": "hsl(3, 70%, 50%)",
    "fries": 13,
    "friesColor": "hsl(84, 70%, 50%)",
    "donut": 197,
    "donutColor": "hsl(325, 70%, 50%)"
  }
]
