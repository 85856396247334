import React from 'react'
import Lottie from 'react-lottie';
import FullLoader from '../../assets/json/loader.json'

const FullPageLoader = ({height=150, width=150}) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: FullLoader,
    renderer: 'svg'
  }
  return (
    <div style={{position: 'absolute',
      width: '100%',
      height: '100%',
      alignContent: 'center',
      background: 'var(--Background)',
      zIndex: '10'}}>
      <Lottie
        options={defaultOptions}
        height={height}
        width={width}
      />
    </div>
  )
}

export default FullPageLoader