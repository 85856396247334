import React, { useEffect } from 'react'
import createImageViewer from '../../../ReusableComponents/ImagePreview'

const EmploymentInformation = ({details, renderPreview}) => {
  useEffect(() => {
  createImageViewer()
  },[])
  const Details = details
  return (
    <main className='EducationInformation'>
      {Details &&
        Details.map((data, index) => (
          Object.keys(data)[0] === 'currentEmp' ?
          (<section className='Education' key={index}>
            <h3 className='EduHeader'>Current Employer</h3>
            <div className='EduContent'>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Name of Company</label>
                  <span className='Value'>{data[Object.keys(data)[0]] ? data[Object.keys(data)[0]]?.company_name : "-"}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Country</label>
                  <span className='Value'>{data[Object.keys(data)[0]] ? data[Object.keys(data)[0]]?.country : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>City</label>
                  <span className='Value'>{data[Object.keys(data)[0]] ? data[Object.keys(data)[0]]?.city : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>State of Territory</label>
                  <span className='Value'>{data[Object.keys(data)[0]] ? data[Object.keys(data)[0]]?.state : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Official Job Title Held Currently</label>
                  <span className='Value'>{data?.[Object.keys(data)?.[0]]?.official_job_title}</span>
                </div>
              </div>
              <h3>Does this employer issue your paycheck?</h3>
              <div className='RadioOption'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <circle cx="13" cy="13" r="6" fill={data?.[Object.keys(data)?.[0]]?.is_paycheck_issued === '1' ? "#4DCC00" : 'transparent'}/>
                  <circle cx="13" cy="13" r="11.5" stroke={data?.[Object.keys(data)?.[0]]?.is_paycheck_issued === '1' ? "#4DCC00" : 'var(--TextColor)'} strokeWidth="3"/>
                </svg>
                <span className={data[Object.keys(data)[0]].is_paycheck_issued === '1' ? "Yes" : ''}>Yes, it is issued by this employer or affiliate</span>
              </div>
              <div className='RadioOption'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <circle cx="13" cy="13" r="6" fill={data?.[Object.keys(data)?.[0]]?.is_paycheck_issued === '0' ? "#FB4848" : 'transparent'}/>
                  <circle cx="13" cy="13" r="11.5" stroke={data?.[Object.keys(data)?.[0]]?.is_paycheck_issued === '0' ? "#FB4848" : 'var(--TextColor)'} strokeWidth="3"/>
                </svg>
                <span className={data[Object.keys(data)[0]].is_paycheck_issued === '0' ? "No" : ''}>No, it is issued by a different company</span>
              </div>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Start Date</label>
                  <div className='Value'>
                    <span>{data?.[Object.keys(data)?.[0]]?.start_date_month}-</span>
                    <span>{data?.[Object.keys(data)?.[0]]?.start_date_year}</span>
                  </div>
                </div>
                {(data?.[Object.keys(data)?.[0]]?.end_date_month && data?.[Object.keys(data)?.[0]]?.end_date_year) &&
                  <div className='LabelValue'>
                    <label className='Label'>End Date</label>
                    <div className='Value'>
                      <span>{data?.[Object.keys(data)?.[0]]?.end_date_month}-</span>
                      <span>{data?.[Object.keys(data)?.[0]]?.end_date_year}</span>
                    </div>
                  </div>
                }
              </div>
              <h3>Reference Details 1</h3>
              <div className="DetailsContainer">
                {/* {data[Object.keys(data)[0]].reference_details.map((val, idx) => (
                  <>
                <div className='LabelValue'>
                  <label className='Label'>Name</label>
                  <span className='Value'>{val?.name && val?.name !== '' ? val?.name : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Contact Number</label>
                  <span className='Value'>{val?.contact_number && val?.contact_number !== '' ? val?.contact_number : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Email Address</label>
                  <span className='Value'>{val?.email_address && val?.email_address !== '' ? val?.email_address : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Designation</label>
                  <span className='Value'>{val?.designation && val?.designation !== '' ? val?.designation : '--'}</span>
                </div>
                </>
                ))} */}
                {data[Object.keys(data)[0]]?.reference_details && 
                <>
                <div className='LabelValue'>
                  <label className='Label'>Name</label>
                  <span className='Value'>{data[0]?.reference_details[0]?.name && data[0]?.reference_details[0]?.name !== '' ? data[0]?.reference_details[0]?.name : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Contact Number</label>
                  <span className='Value'>{data[0]?.reference_details[0]?.contact_number && data[0]?.reference_details[0]?.contact_number !== '' ? data[0]?.reference_details[0]?.contact_number : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Email Address</label>
                  <span className='Value'>{data[0]?.reference_details[0]?.email_address && data[0]?.reference_details[0]?.email_address !== '' ? data[0]?.reference_details[0]?.email_address : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Designation</label>
                  <span className='Value'>{data[0]?.reference_details[0]?.designation && data[0]?.reference_details[0]?.designation !== '' ? data[0]?.reference_details[0]?.designation : '-'}</span>
                </div>
                </>
                }
                
              </div>
              <h3>Reference Details 2</h3>
              <div className="DetailsContainer">
                {data[Object.keys(data)[0]]?.reference_details && 
                <>
                <div className='LabelValue'>
                  <label className='Label'>Name</label>
                  <span className='Value'>{data[0]?.reference_details[1]?.name && data[0]?.reference_details[1]?.name !== '' ? data[0]?.reference_details[1]?.name : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Contact Number</label>
                  <span className='Value'>{data[0]?.reference_details[1]?.contact_number && data[0]?.reference_details[1]?.contact_number !== '' ? data[0]?.reference_details[1]?.contact_number : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Email Address</label>
                  <span className='Value'>{data[0]?.reference_details[1]?.email_address && data[0]?.reference_details[1]?.email_address !== '' ? data[0]?.reference_details[1]?.email_address : '-'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Designation</label>
                  <span className='Value'>{data[0]?.reference_details[1]?.designation && data[0]?.reference_details[1]?.designation !== '' ? data[0]?.reference_details[1]?.designation : '-'}</span>
                </div>
                </>
                }
                
              </div>
              
              {data?.[Object.keys(data)?.[0]]?.paycheck_docs?.length > 0 && <h3>Paychecks</h3>}
              {data?.[Object.keys(data)?.[0]]?.paycheck_docs?.length > 0 && data?.[Object.keys(data)?.[0]]?.paycheck_docs?.map((doc, index) => (
                <div className='Document documentPrev' key={index}>
                  {renderPreview(doc)}
                  {/* <label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                      <path d="M11.8838 6H16.2838L10.8838 0.6V5C10.8838 5.6 11.2838 6 11.8838 6ZM11.8838 8C10.1838 8 8.88379 6.7 8.88379 5V0H3.88379C2.18379 0 0.883789 1.3 0.883789 3V17C0.883789 18.7 2.18379 20 3.88379 20H13.8838C15.5838 20 16.8838 18.7 16.8838 17V8H11.8838ZM5.88379 6H6.88379C7.48379 6 7.88379 6.4 7.88379 7C7.88379 7.6 7.48379 8 6.88379 8H5.88379C5.28379 8 4.88379 7.6 4.88379 7C4.88379 6.4 5.28379 6 5.88379 6ZM11.8838 16H5.88379C5.28379 16 4.88379 15.6 4.88379 15C4.88379 14.4 5.28379 14 5.88379 14H11.8838C12.4838 14 12.8838 14.4 12.8838 15C12.8838 15.6 12.4838 16 11.8838 16ZM11.8838 12H5.88379C5.28379 12 4.88379 11.6 4.88379 11C4.88379 10.4 5.28379 10 5.88379 10H11.8838C12.4838 10 12.8838 10.4 12.8838 11C12.8838 11.6 12.4838 12 11.8838 12Z" fill="#102C98"/>
                    </svg>
                    <a href={doc} target='_blank'>{doc.split('/')[doc.split('/').length - 1]}</a>
                  </label> */}
                </div>
              ))}
              <h3>Type of Employment</h3>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.employment_type}</span>
                </div>
              </div>
              <h3>Do we have permission to contact this employer?</h3>
              <div className='RadioOption'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="6" fill={(data[Object.keys(data)[0]].is_paycheck_issued === '1' || data[Object.keys(data)[0]].is_paycheck_issued === 'true') ? "#4DCC00" : 'transparent'}/>
                    <circle cx="13" cy="13" r="11.5" stroke={(data[Object.keys(data)[0]].is_paycheck_issued === '1' || data[Object.keys(data)[0]].is_paycheck_issued === 'true') ? "#4DCC00" : 'var(--TextColor)'} strokeWidth="3"/>
                  </svg>
                  <span className={(data[Object.keys(data)[0]].is_paycheck_issued === '1' || data[Object.keys(data)[0]].is_paycheck_issued === 'true') ? "Yes" : ''}>Yes</span>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="6" fill={(data[Object.keys(data)[0]].is_paycheck_issued === '0' || data[Object.keys(data)[0]].is_paycheck_issued === 'false') ? "#FB4848" : 'transparent'}/>
                    <circle cx="13" cy="13" r="11.5" stroke={(data[Object.keys(data)[0]].is_paycheck_issued === '0' || data[Object.keys(data)[0]].is_paycheck_issued === 'false') ? "#FB4848" : 'var(--TextColor)'} strokeWidth="3"/>
                  </svg>
                  <span className={(data[Object.keys(data)[0]].is_paycheck_issued === '0' || data[Object.keys(data)[0]].is_paycheck_issued === 'false') ? "No" : ''}>No</span>
                </div>
              </div>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Phone Number</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.employer_phone}</span>
                </div>
              </div>
            </div>
          </section>)
          :
          (<section className='Education' key={index}>
            <h3 className='EduHeader'>Previous Employer</h3>
            <div className='EduContent'>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Name of Company</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.company_name}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Country</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.country}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>City</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.city}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>State of Territory</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.state}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Official Job Title Held</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.official_job_title}</span>
                </div>
              </div>
              {data[Object.keys(data)[1]]?.reference_details && 
              <>
              <h3>Reference Details 1</h3>
              <div className="DetailsContainer">

                <div className='LabelValue'>
                  <label className='Label'>Name</label>
                  <span className='Value'>{data[1]?.reference_details[0]?.name && data[1]?.reference_details[0]?.name !== '' ? data[1]?.reference_details[0]?.name : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Contact Number</label>
                  <span className='Value'>{data[1]?.reference_details[0]?.contact_number && data[1]?.reference_details[0]?.contact_number !== '' ? data[1]?.reference_details[0]?.contact_number : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Email Address</label>
                  <span className='Value'>{data[1]?.reference_details[0]?.email_address && data[1]?.reference_details[0]?.email_address !== '' ? data[1]?.reference_details[0]?.email_address : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Designation</label>
                  <span className='Value'>{data[1]?.reference_details[0]?.designation && data[1]?.reference_details[0]?.designation !== '' ? data[1]?.reference_details[0]?.designation : '--'}</span>
                </div>
                
              </div>
              </>
            }
              {data[Object.keys(data)[1]]?.reference_details && 
              <>
              <h3>Reference Details 2</h3>
              <div className="DetailsContainer">
                
                
                <div className='LabelValue'>
                  <label className='Label'>Name</label>
                  <span className='Value'>{data[1]?.reference_details[1]?.name && data[1]?.reference_details[1]?.name !== '' ? data[1]?.reference_details[1]?.name : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Contact Number</label>
                  <span className='Value'>{data[1]?.reference_details[1]?.contact_number && data[1]?.reference_details[1]?.contact_number !== '' ? data[1]?.reference_details[1]?.contact_number : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Email Address</label>
                  <span className='Value'>{data[1]?.reference_details[1]?.email_address && data[1]?.reference_details[1]?.email_address !== '' ? data[1]?.reference_details[1]?.email_address : '--'}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Designation</label>
                  <span className='Value'>{data[1]?.reference_details[1]?.designation && data[1]?.reference_details[1]?.designation !== '' ? data[1]?.reference_details[1]?.designation : '--'}</span>
                </div>
                
              </div>
              </>
}
              <h3>Does this employer issue your paycheck?</h3>
              <div className='RadioOption'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <circle cx="13" cy="13" r="6" fill={(data[Object.keys(data)[0]].is_paycheck_issued === '1' || data[Object.keys(data)[0]].is_paycheck_issued === 'true') ? "#4DCC00" : 'transparent'}/>
                  <circle cx="13" cy="13" r="11.5" stroke={(data[Object.keys(data)[0]].is_paycheck_issued === '1' || data[Object.keys(data)[0]].is_paycheck_issued === 'true') ? "#4DCC00" : 'var(--TextColor)'} strokeWidth="3"/>
                </svg>
                <span className={(data[Object.keys(data)[0]].is_paycheck_issued === '1' || data[Object.keys(data)[0]].is_paycheck_issued === 'true') ? "Yes" : ''}>Yes, it was issued by this employer or affiliate</span>
              </div>
              <div className='RadioOption'>
                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                  <circle cx="13" cy="13" r="6" fill={(data[Object.keys(data)[0]].is_paycheck_issued === '0' || data[Object.keys(data)[0]].is_paycheck_issued === 'false') ? "#FB4848" : 'transparent'}/>
                  <circle cx="13" cy="13" r="11.5" stroke={(data[Object.keys(data)[0]].is_paycheck_issued === '0' || data[Object.keys(data)[0]].is_paycheck_issued === 'false') ? "#FB4848" : 'var(--TextColor)'} strokeWidth="3"/>
                </svg>
                <span className={(data[Object.keys(data)[0]].is_paycheck_issued === '0' || data[Object.keys(data)[0]].is_paycheck_issued === 'false') ? "No" : ''}>No, it was issued by a different company</span>
              </div>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Start Date</label>
                  <div className='Value'>
                    <span>{data?.[Object.keys(data)?.[0]]?.start_date_month}-</span>
                    <span>{data?.[Object.keys(data)?.[0]]?.start_date_year}</span>
                  </div>
                </div>
                {(data?.[Object.keys(data)?.[0]]?.end_date_month && data?.[Object.keys(data)?.[0]]?.end_date_year) &&
                  <div className='LabelValue'>
                    <label className='Label'>End Date</label>
                    <div className='Value'>
                      <span>{data?.[Object.keys(data)?.[0]]?.end_date_month}-</span>
                      <span>{data?.[Object.keys(data)?.[0]]?.end_date_year}</span>
                    </div>
                  </div>
                }
              </div>
              {data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.paycheck_docs.length > 0 && <h3>Paychecks</h3>}
              {data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.paycheck_docs.length > 0 && data[Object.keys(data)[0]]?.paycheck_docs?.map((doc, index) => (
                <div className='Document documentPrev' key={index}>
                  {/* <label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="17" height="20" viewBox="0 0 17 20" fill="none">
                      <path d="M11.8838 6H16.2838L10.8838 0.6V5C10.8838 5.6 11.2838 6 11.8838 6ZM11.8838 8C10.1838 8 8.88379 6.7 8.88379 5V0H3.88379C2.18379 0 0.883789 1.3 0.883789 3V17C0.883789 18.7 2.18379 20 3.88379 20H13.8838C15.5838 20 16.8838 18.7 16.8838 17V8H11.8838ZM5.88379 6H6.88379C7.48379 6 7.88379 6.4 7.88379 7C7.88379 7.6 7.48379 8 6.88379 8H5.88379C5.28379 8 4.88379 7.6 4.88379 7C4.88379 6.4 5.28379 6 5.88379 6ZM11.8838 16H5.88379C5.28379 16 4.88379 15.6 4.88379 15C4.88379 14.4 5.28379 14 5.88379 14H11.8838C12.4838 14 12.8838 14.4 12.8838 15C12.8838 15.6 12.4838 16 11.8838 16ZM11.8838 12H5.88379C5.28379 12 4.88379 11.6 4.88379 11C4.88379 10.4 5.28379 10 5.88379 10H11.8838C12.4838 10 12.8838 10.4 12.8838 11C12.8838 11.6 12.4838 12 11.8838 12Z" fill="#102C98"/>
                    </svg>
                    <a href={doc} target='_blank'>{doc.split('/')[doc.split('/').length - 1]}</a>
                  </label> */}
                  {renderPreview(doc)}
                </div>
              ))}
              <h3>Type of Employment</h3>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <span className='Value'>{data?.[Object.keys(data)?.[0]]?.employment_type}</span>
                </div>
              </div>
              <h3>Do we have permission to contact this employer?</h3>
              <div className='RadioOption'>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="6" fill={data[Object.keys(data)[0]].is_paycheck_issued === '1' ? "#4DCC00" : 'transparent'}/>
                    <circle cx="13" cy="13" r="11.5" stroke={data[Object.keys(data)[0]].is_paycheck_issued === '1' ? "#4DCC00" : 'var(--TextColor)'} strokeWidth="3"/>
                  </svg>
                  <span className={data[Object.keys(data)[0]].is_paycheck_issued === '1' ? "Yes" : ''}>Yes</span>
                </div>
                <div>
                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 26 26" fill="none">
                    <circle cx="13" cy="13" r="6" fill={data[Object.keys(data)[0]].is_paycheck_issued === '0' ? "#FB4848" : 'transparent'}/>
                    <circle cx="13" cy="13" r="11.5" stroke={data[Object.keys(data)[0]].is_paycheck_issued === '0' ? "#FB4848" : 'var(--TextColor)'} strokeWidth="3"/>
                  </svg>
                  <span className={data[Object.keys(data)[0]].is_paycheck_issued === '0' ? "No" : ''}>No</span>
                </div>
              </div>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Phone Number</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.employer_phone}</span>
                </div>
              </div>
            </div>
          </section>)
        ))
      }
    </main>
  )
}

export default EmploymentInformation