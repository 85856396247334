import React, { useState } from "react";
import "./Sidebar.scss";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ConfirmLogoutModal from "./ConfirmLogoutModal";
import { userLogout } from "../../redux/actions/LoginAction";

const Sidebar = ({ changeTheme, ViewMobNav, handleViewMobileNav }) => {
  const DarkMode = localStorage.getItem("Theme") === "true" ? true : false;
  const location = useLocation();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const IsMaster = localStorage.getItem("IsMaster") === "1" ? true : false;
  const UserName = localStorage.getItem("client_username");
  const UserEmail = localStorage.getItem("useremail");

  const accessData = useSelector((state) => state.getClientAccessControl?.data);
  const clientAccess = accessData?.data?.client_access_json;
  const SidebarOrder = [
    "dashboard",
    "candidate-details",
    "pack-details",
    "insufficiency",
    "additional-cost",
    "billing-details",
    "status",
    "user-management",
    "account-settings",
    "contact-us",
  ];

  const handleLogout = () => {
    const data = {
      username: UserName,
    };

    const successFn = (response) => {
      console.log(response);
      Object.keys(localStorage)?.map((val) => {
        if (val !== "Theme") {
          localStorage.removeItem(val);
        }
      });
      navigate("/");
      window.location.reload();
    };
    const failureFn = (res) => {
      console.error(res);
    };
    dispatch(
      userLogout({ data: data, successFn: successFn, failureFn: failureFn })
    );
  };

  return (
    <>
      <aside className={`Sidebar ${ViewMobNav ? "MobNav" : ""}`}>
        <ConfirmLogoutModal
          isOpen={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          onConfirm={handleLogout}
        />
        <header>
          <span
            className='Icon'
            // onClick={() => handleViewMobileNav(false)}
          >
            {UserName?.[0]?.toUpperCase()}
          </span>
          <div className='CompanyNameEmail'>
            <span
              className='CompanyName Ellipsis'
              title={UserName}>
              {UserName}
            </span>
            <span
              className='CompanyEmail Ellipsis'
              title={UserEmail}>
              {UserEmail}
            </span>
          </div>
        </header>
        <nav className='Navigation'>
          {clientAccess &&
            Object.keys(clientAccess)?.length > 0 &&
            SidebarOrder?.map(
              (option, index) =>
                clientAccess[option]?.bool_value && (
                  <NavLink
                    to={`/company/${option}`}
                    key={index}
                    className={({ isActive }) =>
                      option === "dashboard"
                        ? isActive || location.pathname === "/"
                          ? "Active"
                          : "NavLink"
                        : isActive
                        ? "Active"
                        : "NavLink"
                    }>
                    {clientAccess[option]?.name}
                  </NavLink>
                )
            )}
          {IsMaster && (
            <>
              <NavLink
                to='/client/dashboard'
                className={({ isActive }) => (isActive ? "Active" : "NavLink")}>
                Dashboard
              </NavLink>
              <NavLink
                to='/client/candidatesearch'
                className={({ isActive }) => (isActive ? "Active" : "NavLink")}>
                Candidate Reports
              </NavLink>
              <NavLink
                to='/client/pack-details'
                className={({ isActive }) => (isActive ? "Active" : "NavLink")}>
                Pack Details
              </NavLink>
            </>
          )}
        </nav>
        <footer>
          <div
            className='ToggleTheme'
            onClick={() => {
              changeTheme();
            }}>
            <div className={`ToggleIndicator ${DarkMode ? "Right" : "Left"}`} />
            <div className={`ToggleLight ${DarkMode ? "Opacity" : ""}`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='1rem'
                height='1rem'
                viewBox='0 0 15 15'
                fill='none'>
                <path
                  d='M7.5 4.0625C5.625 4.0625 4.0625 5.625 4.0625 7.5C4.0625 9.375 5.625 10.9375 7.5 10.9375C9.375 10.9375 10.9375 9.375 10.9375 7.5C10.9375 5.625 9.375 4.0625 7.5 4.0625ZM7.5 3.125C7.875 3.125 8.125 2.875 8.125 2.5V1.875C8.125 1.5 7.875 1.25 7.5 1.25C7.125 1.25 6.875 1.5 6.875 1.875V2.5C6.875 2.875 7.125 3.125 7.5 3.125ZM7.5 11.875C7.125 11.875 6.875 12.125 6.875 12.5V13.125C6.875 13.5 7.125 13.75 7.5 13.75C7.875 13.75 8.125 13.5 8.125 13.125V12.5C8.125 12.125 7.875 11.875 7.5 11.875ZM13.125 6.875H12.5C12.125 6.875 11.875 7.125 11.875 7.5C11.875 7.875 12.125 8.125 12.5 8.125H13.125C13.5 8.125 13.75 7.875 13.75 7.5C13.75 7.125 13.5 6.875 13.125 6.875ZM3.125 7.5C3.125 7.125 2.875 6.875 2.5 6.875H1.875C1.5 6.875 1.25 7.125 1.25 7.5C1.25 7.875 1.5 8.125 1.875 8.125H2.5C2.875 8.125 3.125 7.875 3.125 7.5ZM11.5 10.625C11.25 10.375 10.875 10.375 10.625 10.625C10.375 10.875 10.375 11.25 10.625 11.5L11.0625 11.9375C11.1875 12.0625 11.3125 12.125 11.5 12.125C11.6875 12.125 11.8125 12.0625 11.9375 11.9375C12.1875 11.6875 12.1875 11.3125 11.9375 11.0625L11.5 10.625ZM3.5 4.375C3.625 4.5 3.75 4.5625 3.9375 4.5625C4.125 4.5625 4.25 4.5 4.375 4.375C4.625 4.125 4.625 3.75 4.375 3.5L3.9375 3.0625C3.6875 2.8125 3.3125 2.8125 3.0625 3.0625C2.8125 3.3125 2.8125 3.6875 3.0625 3.9375L3.5 4.375ZM3.5 10.625L3.0625 11.0625C2.9375 11.1875 2.875 11.3125 2.875 11.5C2.875 11.875 3.125 12.125 3.5 12.125C3.6875 12.125 3.8125 12.0625 3.9375 11.9375L4.375 11.5C4.625 11.25 4.625 10.875 4.375 10.625C4.125 10.375 3.75 10.375 3.5 10.625ZM11.0625 4.5625C11.25 4.5625 11.375 4.5 11.5 4.375L11.9375 3.9375C12.1875 3.6875 12.1875 3.3125 11.9375 3.0625C11.6875 2.8125 11.3125 2.8125 11.0625 3.0625L10.625 3.5C10.5 3.625 10.4375 3.75 10.4375 3.9375C10.4375 4.3125 10.6875 4.5625 11.0625 4.5625Z'
                  fill='#9B9B9B'
                />
              </svg>
              <span>Light</span>
            </div>
            <div className={`ToggleDark ${DarkMode ? "" : "Opacity"}`}>
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='1rem'
                height='1rem'
                viewBox='0 0 16 15'
                fill='none'>
                <path
                  d='M14.0937 8.97657C14.0138 8.91898 13.9179 8.88799 13.8195 8.88799C13.721 8.88799 13.6251 8.91898 13.5452 8.97657C12.7494 9.5483 11.7923 9.85201 10.8124 9.84375C9.56923 9.84375 8.37695 9.34989 7.49787 8.47082C6.61879 7.59174 6.12493 6.39946 6.12493 5.15625C6.12075 4.53019 6.24316 3.90973 6.48482 3.33217C6.72647 2.75461 7.08239 2.23185 7.53118 1.79532C7.60129 1.7266 7.64805 1.6376 7.66487 1.54088C7.68169 1.44417 7.66772 1.34461 7.62493 1.25626C7.58313 1.16973 7.51574 1.09814 7.43189 1.05119C7.34805 1.00423 7.2518 0.984185 7.15618 0.993755C5.98986 1.14431 4.88542 1.60551 3.95839 2.32911C3.03137 3.05271 2.31581 4.01212 1.88658 5.10699C1.45735 6.20186 1.33022 7.39196 1.51847 8.55279C1.70673 9.71363 2.20346 10.8025 2.95671 11.7056C3.70996 12.6088 4.69205 13.2929 5.80024 13.6864C6.90844 14.0799 8.10203 14.1685 9.25616 13.9427C10.4103 13.7169 11.4825 13.1852 12.3608 12.4031C13.239 11.621 13.8909 10.6172 14.2484 9.49688C14.2797 9.40482 14.2817 9.30528 14.254 9.21205C14.2263 9.11883 14.1703 9.03653 14.0937 8.97657Z'
                  fill='#212121'
                />
              </svg>
              <span>Dark</span>
            </div>
          </div>
          <div
            className='Logout CenterDiv'
            onClick={() => setIsModalOpen(true)}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='0.75rem'
              height='1rem'
              viewBox='0 0 12 15'
              fill='none'>
              <path
                d='M0.00210207 12.8333L0.00210301 2.16668C-0.0141075 1.65432 0.173122 1.15638 0.522886 0.781629C0.87265 0.406882 1.35652 0.185798 1.86877 0.166677L6.66877 0.166677C6.84558 0.166677 7.01515 0.236914 7.14018 0.361939C7.2652 0.486963 7.33544 0.656533 7.33544 0.833344C7.33544 1.01016 7.2652 1.17972 7.14018 1.30475C7.01515 1.42977 6.84558 1.50001 6.66877 1.50001L1.86877 1.50001C1.71139 1.52165 1.56832 1.60287 1.46908 1.72692C1.36984 1.85097 1.322 2.00838 1.33544 2.16668L1.33544 12.8333C1.322 12.9916 1.36984 13.149 1.46908 13.2731C1.56832 13.3971 1.71139 13.4784 1.86877 13.5L6.66877 13.5C6.84558 13.5 7.01515 13.5702 7.14017 13.6953C7.2652 13.8203 7.33544 13.9899 7.33544 14.1667C7.33544 14.3435 7.2652 14.5131 7.14017 14.6381C7.01515 14.7631 6.84558 14.8333 6.66877 14.8333L1.86877 14.8333C1.35651 14.8142 0.872648 14.5931 0.522884 14.2184C0.173121 13.8436 -0.0141085 13.3457 0.00210207 12.8333ZM9.72877 6.83334L4.0021 6.83334C3.82529 6.83334 3.65572 6.90358 3.5307 7.02861C3.40567 7.15363 3.33544 7.3232 3.33544 7.50001C3.33544 7.67682 3.40567 7.84639 3.5307 7.97141C3.65572 8.09644 3.82529 8.16668 4.0021 8.16668L9.72877 8.16668L8.7821 9.10668C8.65657 9.23221 8.58604 9.40248 8.58604 9.58001C8.58604 9.75754 8.65657 9.92781 8.7821 10.0533C8.90764 10.1789 9.0779 10.2494 9.25544 10.2494C9.43297 10.2494 9.60323 10.1789 9.72877 10.0533L11.8088 7.97334C11.8695 7.90994 11.917 7.83518 11.9488 7.75334C12.0154 7.59104 12.0154 7.40898 11.9488 7.24668C11.917 7.16484 11.8695 7.09008 11.8088 7.02668L9.72877 4.94668C9.66648 4.88489 9.5926 4.83601 9.51138 4.80283C9.43015 4.76965 9.34318 4.75284 9.25544 4.75334C9.1677 4.75284 9.08072 4.76965 8.9995 4.80283C8.91827 4.83601 8.8444 4.88489 8.7821 4.94668C8.71962 5.00865 8.67002 5.08239 8.63618 5.16363C8.60233 5.24487 8.58491 5.332 8.58491 5.42001C8.58491 5.50802 8.60233 5.59516 8.63618 5.6764C8.67002 5.75763 8.71962 5.83137 8.7821 5.89334L9.72877 6.83334Z'
                fill='#212121'
              />
            </svg>
          </div>
        </footer>
      </aside>
      {ViewMobNav && (
        <div
          className={`${ViewMobNav ? "ClickOutside" : ""}`}
          onClick={() => {
            handleViewMobileNav(false);
          }}
        />
      )}
    </>
  );
};

export default Sidebar;
