// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Empty {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  -webkit-user-select: none;
          user-select: none;
}
.Empty span, .Empty p {
  color: var(--TextColor);
  font-size: clamp(0.5rem, 2vw, 1rem);
  font-weight: 700;
  opacity: 0.2;
  text-align: center;
  text-wrap: balance;
  -webkit-user-select: none;
          user-select: none;
}
.Empty p {
  font-size: clamp(0.625rem, 2vw, 0.875rem);
  font-weight: 400;
}`, "",{"version":3,"sources":["webpack://./src/Components/Empty/Empty.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,SAAA;EACA,yBAAA;UAAA,iBAAA;AACF;AAAE;EACE,uBAAA;EACA,mCAAA;EACA,gBAAA;EACA,YAAA;EACA,kBAAA;EACA,kBAAA;EACA,yBAAA;UAAA,iBAAA;AAEJ;AAAE;EACE,yCAAA;EACA,gBAAA;AAEJ","sourcesContent":[".Empty{\n  width: 100%;\n  height: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  gap: 1rem;\n  user-select: none;\n  span, p{\n    color: var(--TextColor);\n    font-size: clamp(0.5rem, 2vw, 1rem);\n    font-weight: 700;\n    opacity: 0.2;\n    text-align: center;\n    text-wrap: balance;\n    user-select: none;\n  }\n  p{\n    font-size: clamp(0.625rem, 2vw, 0.875rem);\n    font-weight: 400;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
