import React, { useState } from "react";
import PdfViewer from "../../BeginVerification/PdfViewer/PdfViewer";
import Empty from "../../Empty/Empty";
import { useNavigate } from "react-router-dom";
// import ImageAndPdfViewer from '../../ReusableComponents/ImageAndPdfViewer/ImageAndPdfViewer';

const CandidateReportOverall = ({
  overallReport,
  courtReport,
  viewOverallReport,
}) => {
  const navigate = useNavigate();
  const OverallReport = overallReport;
  const CourtReport = courtReport;
  const ViewOverallReport = viewOverallReport;

  const [Selected, setSelected] = useState("Court");
  // const [showImage, setImage] = useState(false);
  // const [imageViewData, setImageViewerData] = useState({currentUrl:null, urlType:''})
  // const handleClick = (url, type) => {
  //   setImage((prev) => !prev)
  //   if(url){
  //     setImageViewerData((prev) => ({
  //       ...prev,
  //       currentUrl: url,
  //       urlType:type
  //     }))
  //   }
  // }
  return (
    <div className='CandidateReportOverall'>
      <header className='DetailHeader'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1.5rem'
          height='1.5rem'
          viewBox='0 0 38 38'
          fill='none'
          onClick={() => {
            navigate(-1);
          }}>
          <path
            d='M29.5556 16.2555H13.5112L20.4778 9.28884C21.3223 8.4444 21.3223 7.17773 20.4778 6.33328C19.6334 5.48884 18.3667 5.48884 17.5223 6.33328L6.96671 16.8888C6.12226 17.7333 6.12226 19 6.96671 19.8444L17.5223 30.4C18.3667 31.2444 19.6334 31.2444 20.4778 30.4C21.3223 29.5555 21.3223 28.2888 20.4778 27.4444L13.5112 20.4777H29.5556C30.8223 20.4777 31.6667 19.6333 31.6667 18.3666C31.6667 17.3111 30.8223 16.2555 29.5556 16.2555Z'
            fill='#102C98'
          />
        </svg>
        <h1>Report Details</h1>
      </header>
      <section className='TabCont'>
        <div className='Tab'>
          <span
            className={`${Selected === "Court" ? "Active" : ""}`}
            onClick={() => {
              setSelected("Court");
            }}>
            Court Report
          </span>
          <span
            className={`${Selected === "Overall" ? "Active" : ""}`}
            onClick={() => {
              setSelected("Overall");
            }}>
            Overall Report
          </span>
        </div>
        {Selected === "Overall" && OverallReport && ViewOverallReport === 1 && (
          <a
            href={OverallReport}
            target='_blank'
            download>
            Download Overall Report
          </a>
        )}
        {Selected === "Court" && CourtReport?.length > 0 && (
          <a
            href={CourtReport[0]}
            target='_blank'
            download>
            Download Court Report
          </a>
        )}
      </section>
      <section className='ContentCont'>
        {Selected === "Court" && CourtReport?.length > 0 ? (
          <div
            className='pdfViewer' /*onClick={() => handleClick(CourtReport[0], 'pdf')} */
          >
            <PdfViewer
              pdf={CourtReport[0]}
              showPagination={false}
            />
          </div>
        ) : (
          Selected === "Court" && (
            <Empty
              sign='negative'
              size={10}
            />
          )
        )}
        {Selected === "Overall" && OverallReport && ViewOverallReport === 1 ? (
          <div
            className='pdfViewer' /*onClick={() => handleClick(OverallReport, 'pdf')}*/
          >
            <PdfViewer
              pdf={OverallReport}
              showPagination={false}
            />
          </div>
        ) : (
          Selected === "Overall" && (
            <Empty
              sign='negative'
              size={10}
            />
          )
        )}
      </section>
      {/* {showImage && <ImageAndPdfViewer setImage={setImage} imageViewData={imageViewData} />} */}
    </div>
  );
};

export default CandidateReportOverall;
