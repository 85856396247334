import React, { useEffect, useState } from "react";
import "./CompanyDashboard.scss";
import Sidebar from "../Sidebar/Sidebar";
import { useDispatch } from "react-redux";
import Topbar from "../Topbar/Topbar";
import { useParams } from "react-router-dom";
import UserManagement from "../UserManagement/UserManagement";
import BeginVerification from "../BeginVerification/BeginVerification";
import DashBoard from "../DashBoard/DashBoard";
import AccountSettings from "../AccountSettings/AccountSettings";
import PackDetails from "../PackDetails/PackDetails";
import CustomizePack from "../PackDetails/CustomizePack";
import InsufficiencyDetails from "../Insufficiency/InsufficiencyDetails";
import AdditionalCostList from "../AdditionalCost/AdditionalCostList";
import ContactUs from "../ContactUs/ContactUs";
import BillingDetails from "../Billing Details/BillingDetails";
import CandidateDashBoard from "../CandidateDetails/CandidateDashBoard";
import { getClientAccessControl } from "../../redux/actions/LoginAction";
import CandidateDetail from "../CandidateDetails/CandidateDetail";
import Report from "../Report/Reports";
import ReportsTable from "../Report/ReportsTable";
import AccessDenial from "../ReusableComponents/AccessDenial";

const CompanyDashboard = ({ changeTheme }) => {
  const { component, subcomponent } = useParams();
  const clientInfoID = localStorage.getItem("client_info_id");
  const masterID = localStorage.getItem("master_id");
  const userID = localStorage.getItem("user_id");
  const dispatch = useDispatch();
  const [clientAccessResp, setClientAccessResp] = useState({});
  const [MobNavView, setMobNavView] = useState(false)

  const handleViewMobNav = (option) => {
    setMobNavView(option)
  }

  useEffect(() => {
    setMobNavView(false)
  },[component])

  useEffect(() => {
    if (!clientInfoID || !masterID || !userID) {
      return;
    }
    const payload = {
      client_info_id: clientInfoID,
      master_id: masterID,
      client_id: userID,
    };

    const successFn = (response) => {
      console.log(response);
      setClientAccessResp(response.data?.client_access_json || {});
    };

    const failureFn = (error) => {
      console.error("Failed to save data", error);
    };

    dispatch(getClientAccessControl({ data: payload, successFn, failureFn }));
  }, [clientInfoID, masterID, userID, component]);

  const renderComponent = () => {
    // Check if the clientAccessResp[component] exists and is true
    if (clientAccessResp.hasOwnProperty(component)) {
      // If the key exists, check if it is true
      if (!clientAccessResp[component]?.bool_value) {
        return <AccessDenial/>;
      }
    }

    switch (component) {
      case "dashboard":
        return <DashBoard />;
      case "candidate-details":
        if (!subcomponent) {
          return <CandidateDashBoard />;
        } else if (subcomponent === "begin-verification") {
          return <BeginVerification />;
        } else if (subcomponent === "view") {
          return <CandidateDetail />;
        }

      case "pack-details":
        if(!subcomponent) {
          return <PackDetails />;
        }
        else if (subcomponent === 'customizepack') {
          return <CustomizePack />;
        }
      case "billing-details":
        return <BillingDetails />;
      case "account-settings":
        return <AccountSettings />;
      case "contact-us":
        return <ContactUs />;
      case "insufficiency":
        return <InsufficiencyDetails />;
      case "user-management":
        return <UserManagement />;
      case "additional-cost":
        return (
          <AdditionalCostList
            additionalData={null}
            setToggle={null}
          />
        );
      case "status":
        if (!subcomponent) {
          return <Report />;
        } else if (subcomponent) {
          return <ReportsTable />;
        }
      default:
        return <DashBoard />;
    }
  };

  return (
    <main className='CompanyDashboard'>
      <Sidebar changeTheme={changeTheme} ViewMobNav={MobNavView} handleViewMobileNav={handleViewMobNav}/>
      <section className='TopBarContent'>
        <Topbar handleViewMobileNav={handleViewMobNav}/>
        <article className='ContentSection'>{renderComponent()}</article>
      </section>
    </main>
  );
};

export default CompanyDashboard;
