import React, { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import Empty from "../../Empty/Empty";

const CandidateReportSummary = ({ details }) => {
  const Details = details;
  const [CancelCheck, setCancelCheck] = useState("");
  const DarkMode = localStorage.getItem("Theme") === "true" ? true : false;
  const DropZoneRef = useRef(null);
  const navigate = useNavigate();

  const handleCancelClick = (checkName, status) => {
    if (CancelCheck === checkName || status === "In-progress") {
      setCancelCheck("");
    } else {
      setCancelCheck(checkName);
    }
  };

  const onDragEnter = (e) => {
    e.preventDefault();
    e.target.classList.add("DraggingOver");
  };

  const onDragLeave = (e) => {
    e.preventDefault();
    e.target.classList.remove("DraggingOver");
  };

  const onDrop = (e) => {
    e.preventDefault();
    e.target.classList.remove("DraggingOver");
  };

  return (
    <main className='CandidateReportSummary'>
      <header className='DetailHeader'>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='1.5rem'
          height='1.5rem'
          viewBox='0 0 38 38'
          fill='none'
          onClick={() => {navigate(-1)}}
          >
          <path
            d='M29.5556 16.2555H13.5112L20.4778 9.28884C21.3223 8.4444 21.3223 7.17773 20.4778 6.33328C19.6334 5.48884 18.3667 5.48884 17.5223 6.33328L6.96671 16.8888C6.12226 17.7333 6.12226 19 6.96671 19.8444L17.5223 30.4C18.3667 31.2444 19.6334 31.2444 20.4778 30.4C21.3223 29.5555 21.3223 28.2888 20.4778 27.4444L13.5112 20.4777H29.5556C30.8223 20.4777 31.6667 19.6333 31.6667 18.3666C31.6667 17.3111 30.8223 16.2555 29.5556 16.2555Z'
            fill='#102C98'
          />
        </svg>
        <h1>Check Details</h1>
      </header>
      <section className='ContentCont'>
        {Details ? (
          Details.map((data, index) => (
            <article
              className='Check'
              key={index}>
              <div className='Details'>
                <span>{index + 1}</span>
                <span className='Name Ellipsis'>{data?.check_name}</span>
                <span className='Status Ellipsis'>{data?.status}</span>
                {/* <div className='Cancel'>
                  <div onClick={() => {handleCancelClick(data.check_name, data.status)}} className={data?.status === 'In-progress' ? 'NotAllowed' : ''}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 17 17" fill="none">
                      <path d="M8.5 0C3.80375 0 0 3.80375 0 8.5C0 13.1963 3.80375 17 8.5 17C13.1963 17 17 13.1963 17 8.5C17 3.80375 13.1963 0 8.5 0ZM1.7 8.5C1.7 4.743 4.743 1.7 8.5 1.7C10.0725 1.7 11.5133 2.23975 12.665 3.13225L3.13225 12.665C2.23975 11.5133 1.7 10.0725 1.7 8.5ZM8.5 15.3C6.9275 15.3 5.48675 14.7603 4.335 13.8678L13.8678 4.335C14.7603 5.48675 15.3 6.9275 15.3 8.5C15.3 12.257 12.257 15.3 8.5 15.3Z" fill="black"/>
                    </svg>
                  </div>
                </div> */}
              </div>
              {data?.check_name === "Education Check" &&
                data.candidate_data &&
                Object.keys(data.candidate_data).length > 0 && (
                  <div className='SubDetails'>
                    {Object.values(data.candidate_data)?.[0] &&
                      Object.entries(
                        data.candidate_data[Object.keys(data.candidate_data)[0]]
                      ).map(([key, value], index) => {
                        if (key === "UG" || key === "PG") {
                          const degreeType = Object.keys(
                            data.candidate_data[
                              Object.keys(data.candidate_data)?.[0]
                            ]?.[key]
                          );
                          return Object.values(value).map((item, index) => (
                            <div
                              className='NameStatus'
                              key={index}>
                              <div className='LabelName'>
                                <span className='Ellipsis'>
                                  {key === "PG"
                                    ? "Post Graduation"
                                    : "Under Graduation"}{" "}
                                  - {degreeType?.[index]?.toUpperCase()}
                                </span>
                                <span className='Ellipsis'>
                                  {item?.institution_name}
                                </span>
                              </div>
                              {data?.response_data &&
                                Object.keys(data?.response_data)?.length > 0 &&
                                data?.response_data?.[key]?.[
                                  degreeType?.[index]
                                ]?.status && (
                                  <span
                                    className={`Status ${
                                      data?.response_data?.[key]?.[
                                        degreeType?.[index]
                                      ]?.status
                                    }`}>
                                    {
                                      data?.response_data?.[key]?.[
                                        degreeType?.[index]
                                      ]?.status
                                    }
                                  </span>
                                )}
                            </div>
                          ));
                        } else {
                          return (
                            <div
                              className='NameStatus'
                              key={index}>
                              <div className='LabelName'>
                                <span className='Ellipsis'>{key}</span>
                                <span className='Ellipsis'>
                                  {value?.institution_name}
                                </span>
                              </div>
                              {data?.response_data &&
                                Object.keys(data?.response_data)?.length > 0 &&
                                data?.response_data?.[key]?.status && (
                                  <span
                                    className={`Status ${data?.response_data?.[key]?.status}`}>
                                    {data?.response_data?.[key]?.status}
                                  </span>
                                )}
                            </div>
                          );
                        }
                      })}
                  </div>
                )}
              {data?.check_name === "Employment Check" &&
                data.candidate_data &&
                Object.keys(data.candidate_data).length > 0 && (
                  <div className='SubDetails'>
                    {Object.values(data?.candidate_data)?.[0] &&
                      Object.entries(
                        data.candidate_data[Object.keys(data.candidate_data)[0]]
                      ).map(([key, value], index) => {
                        const employmentData =
                          data?.response_data?.[
                            key === "currentEmp" ? key : "previousEmp"
                          ];

                        const status = Array.isArray(employmentData)
                          ? employmentData.find(
                              (item) =>
                                Object.keys(item)?.[0] ===
                                  value?.company_name &&
                                item[Object.keys(item)?.[0]]
                                  ?.start_date_month ===
                                  value?.start_date_month &&
                                item[Object.keys(item)?.[0]]
                                  ?.start_date_year === value?.start_date_year
                            )?.[value?.company_name]?.[
                              value?.official_job_title
                            ]?.status
                          : null;

                        return (
                          <div
                            className='NameStatus'
                            key={index}>
                            <div className='LabelName'>
                              <span className='Ellipsis'>
                                {key !== "currentEmp"
                                  ? "Previous Employment"
                                  : "Current Employment"}
                              </span>
                              <span className='Ellipsis'>
                                {value?.company_name} {value?.start_date_month}-
                                {value?.start_date_year}
                              </span>
                            </div>
                            {status && (
                              <span className={`Status ${status}`}>
                                {status}
                              </span>
                            )}
                          </div>
                        );
                      })}
                  </div>
                )}
            </article>
          ))
        ) : (
          <Empty />
        )}
      </section>
      {CancelCheck && (
        <section className='ModalContainer'>
          <form
            className='Modal'
            onSubmit={(e) => {
              console.log(e);
            }}>
            <h1 className='Header'>Cancel Check?</h1>
            <span className='Hint'>
              Are you sure you want to cancel <strong>{CancelCheck}</strong>? If
              yes, please provide a reason with respective document below.
            </span>
            <div
              className='DropZone'
              ref={DropZoneRef}
              onDragEnter={onDragEnter}
              onDragLeave={onDragLeave}
              onDrop={onDrop}>
              {DarkMode ? (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='6rem'
                  height='6rem'
                  viewBox='0 0 126 126'
                  fill='none'
                  ref={DropZoneRef}>
                  <g opacity='0.5'>
                    <path
                      d='M123.24 47.1732V104.408C123.24 109.647 118.993 113.894 113.754 113.894H13.8906C20.0543 113.894 25.0392 108.909 25.0392 102.762V47.1732C25.0392 41.9343 29.286 37.6875 34.5248 37.6875H113.754C118.993 37.6875 123.24 41.9343 123.24 47.1732Z'
                      fill='#0DB2FC'
                      fill-opacity='0.3'
                    />
                    <path
                      d='M61.4006 68.7782L73.8871 52.115C74.7905 50.9096 76.5983 50.9096 77.5017 52.115L89.9883 68.7782C91.1041 70.2671 90.0417 72.3909 88.181 72.3909H84.3722C83.125 72.3909 82.1138 73.4021 82.1138 74.6493V96.2413C82.1138 97.4885 81.1026 98.4997 79.8554 98.4997H71.5335C70.2863 98.4997 69.2751 97.4885 69.2751 96.2413V74.6493C69.2751 73.4021 68.2639 72.3909 67.0167 72.3909H63.2079C61.3472 72.3909 60.2848 70.2671 61.4006 68.7782Z'
                      fill='white'
                    />
                    <path
                      d='M113.754 36.4059H111.329V30.2646C111.329 24.3274 106.499 19.4971 100.561 19.4971H61.4206C58.7032 19.4971 56.1672 18.1558 54.6382 15.909L54.3816 15.533C52.3744 12.5845 49.0475 10.8242 45.4808 10.8242H12.2434C6.30664 10.8242 1.47656 15.6545 1.47656 21.5918V102.763C1.47656 109.633 7.04812 115.176 13.89 115.176H113.754C119.691 115.176 124.522 110.346 124.522 104.409V47.1735C124.522 41.236 119.691 36.4059 113.754 36.4059ZM13.89 112.613C8.4735 112.613 4.03988 108.221 4.03988 102.762V21.5916C4.03988 17.0679 7.71971 13.3875 12.2434 13.3875H45.4811C48.1984 13.3875 50.7337 14.7287 52.2627 16.9756L52.5194 17.3516C54.5265 20.3001 57.8539 22.0604 61.4206 22.0604H100.561C105.085 22.0604 108.765 25.7409 108.765 30.2644V36.4057H34.5245C28.5872 36.4057 23.7569 41.236 23.7569 47.1733V102.762C23.7569 108.194 19.3304 112.613 13.89 112.613ZM121.958 104.409C121.958 108.932 118.278 112.613 113.754 112.613H21.4471C24.4295 110.32 26.3205 106.645 26.3205 102.762V47.1735C26.3205 42.6498 30.001 38.9695 34.5245 38.9695H113.754C118.278 38.9695 121.958 42.65 121.958 47.1735V104.409Z'
                      fill='#09A5E0'
                    />
                    <path
                      d='M78.5293 51.3471C77.1118 49.4578 74.2812 49.4564 72.863 51.3478L60.3769 68.0108C58.6289 70.3428 60.295 73.674 63.2095 73.674H67.0183C67.5572 73.674 67.9953 74.112 67.9953 74.6502V96.2422C67.9953 98.1942 69.5831 99.7825 71.5351 99.7825H79.8575C81.8095 99.7825 83.3973 98.1942 83.3973 96.2422V74.6502C83.3973 74.112 83.8353 73.674 84.3743 73.674H88.1831C91.0968 73.674 92.7641 70.3438 91.0164 68.0108L78.5293 51.3471ZM88.1828 71.1106H84.374C82.422 71.1106 80.8337 72.6984 80.8337 74.6505V96.2425C80.8337 96.7814 80.3957 97.2195 79.8575 97.2195H71.5351C70.9969 97.2195 70.5588 96.7814 70.5588 96.2425V74.6505C70.5588 72.6984 68.9705 71.1106 67.0185 71.1106H63.2097C62.404 71.1106 61.9453 70.1934 62.4286 69.5479L74.9147 52.8857C75.3038 52.3642 76.0878 52.3635 76.4781 52.8849L88.9649 69.5479C89.4473 70.1925 88.9893 71.1106 88.1828 71.1106Z'
                      fill='#09A5E0'
                    />
                  </g>
                </svg>
              ) : (
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='6rem'
                  height='6rem'
                  viewBox='0 0 126 126'
                  fill='none'
                  ref={DropZoneRef}>
                  <g opacity='0.5'>
                    <path
                      d='M123.24 47.1732V104.408C123.24 109.647 118.993 113.894 113.754 113.894H13.8906C20.0543 113.894 25.0392 108.909 25.0392 102.762V47.1732C25.0392 41.9343 29.286 37.6875 34.5248 37.6875H113.754C118.993 37.6875 123.24 41.9343 123.24 47.1732Z'
                      fill='#B2E7FF'
                    />
                    <path
                      d='M61.4006 68.7782L73.8871 52.115C74.7905 50.9096 76.5983 50.9096 77.5017 52.115L89.9883 68.7782C91.1041 70.2671 90.0417 72.3909 88.181 72.3909H84.3722C83.125 72.3909 82.1138 73.4021 82.1138 74.6493V96.2413C82.1138 97.4885 81.1026 98.4997 79.8554 98.4997H71.5335C70.2863 98.4997 69.2751 97.4885 69.2751 96.2413V74.6493C69.2751 73.4021 68.2639 72.3909 67.0167 72.3909H63.2079C61.3472 72.3909 60.2848 70.2671 61.4006 68.7782Z'
                      fill='white'
                    />
                    <path
                      d='M113.754 36.4059H111.329V30.2646C111.329 24.3274 106.499 19.4971 100.561 19.4971H61.4206C58.7032 19.4971 56.1672 18.1558 54.6382 15.909L54.3816 15.533C52.3744 12.5845 49.0475 10.8242 45.4808 10.8242H12.2434C6.30664 10.8242 1.47656 15.6545 1.47656 21.5918V102.763C1.47656 109.633 7.04812 115.176 13.89 115.176H113.754C119.691 115.176 124.522 110.346 124.522 104.409V47.1735C124.522 41.236 119.691 36.4059 113.754 36.4059ZM13.89 112.613C8.4735 112.613 4.03988 108.221 4.03988 102.762V21.5916C4.03988 17.0679 7.71971 13.3875 12.2434 13.3875H45.4811C48.1984 13.3875 50.7337 14.7287 52.2627 16.9756L52.5194 17.3516C54.5265 20.3001 57.8539 22.0604 61.4206 22.0604H100.561C105.085 22.0604 108.765 25.7409 108.765 30.2644V36.4057H34.5245C28.5872 36.4057 23.7569 41.236 23.7569 47.1733V102.762C23.7569 108.194 19.3304 112.613 13.89 112.613ZM121.958 104.409C121.958 108.932 118.278 112.613 113.754 112.613H21.4471C24.4295 110.32 26.3205 106.645 26.3205 102.762V47.1735C26.3205 42.6498 30.001 38.9695 34.5245 38.9695H113.754C118.278 38.9695 121.958 42.65 121.958 47.1735V104.409Z'
                      fill='#102C97'
                    />
                    <path
                      d='M78.5293 51.3471C77.1118 49.4578 74.2812 49.4564 72.863 51.3478L60.3769 68.0108C58.6289 70.3428 60.295 73.674 63.2095 73.674H67.0183C67.5572 73.674 67.9953 74.112 67.9953 74.6502V96.2422C67.9953 98.1942 69.5831 99.7825 71.5351 99.7825H79.8575C81.8095 99.7825 83.3973 98.1942 83.3973 96.2422V74.6502C83.3973 74.112 83.8353 73.674 84.3743 73.674H88.1831C91.0968 73.674 92.7641 70.3438 91.0164 68.0108L78.5293 51.3471ZM88.1828 71.1106H84.374C82.422 71.1106 80.8337 72.6984 80.8337 74.6505V96.2425C80.8337 96.7814 80.3957 97.2195 79.8575 97.2195H71.5351C70.9969 97.2195 70.5588 96.7814 70.5588 96.2425V74.6505C70.5588 72.6984 68.9705 71.1106 67.0185 71.1106H63.2097C62.404 71.1106 61.9453 70.1934 62.4286 69.5479L74.9147 52.8857C75.3038 52.3642 76.0878 52.3635 76.4781 52.8849L88.9649 69.5479C89.4473 70.1925 88.9893 71.1106 88.1828 71.1106Z'
                      fill='#102C97'
                    />
                  </g>
                </svg>
              )}
              <span
                className='Upload'
                ref={DropZoneRef}>
                <label>
                  Upload
                  <input type='file' />
                </label>{" "}
                <span>or Drag and Drop</span>
              </span>
            </div>
            <textarea
              placeholder='Write your comments here...'
              required
            />
            <div className='ButtonCont'>
              <button
                className='CenterDiv'
                onClick={() => {
                  setCancelCheck("");
                }}>
                Back
              </button>
              <button className='Proceed CenterDiv'>Proceed</button>
            </div>
          </form>
        </section>
      )}
    </main>
  );
};

export default CandidateReportSummary;
