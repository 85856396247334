import React from 'react';
import './AccessDenial.scss';
import AccessDenialImg from '../../assets/images/AccessDenial.svg';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

function AccessDenial() {
  const navigate = useNavigate()
  const accessData = useSelector((state) => state.getClientAccessControl?.data);
  const clientAccess = accessData?.data?.client_access_json;
  const SidebarOrder = [
    'dashboard',
    'candidate-details',
    'pack-details',
    'insufficiency',
    'additional-cost',
    'billing-details',
    'reports',
    'user-management',
    'account-settings',
    'contact-us'
  ]

  const handleAccessDenial = () => {
    if (clientAccess) {
      SidebarOrder.forEach(option => {
        if (clientAccess[option]?.bool_value) {
          navigate(`/company/${option}`);
          return
        }
      });
    }
  };

  return (
    <div className="accessDenialBg">
      <form className="modalContent" onSubmit={handleAccessDenial}>
        <img src={AccessDenialImg} alt="" />
        <div className="paymentMsg">
          <h1>Oops! Access Denied 🚫</h1>
          <p> It looks like you don’t have permission to view this screen. No worries, just hit "Go Back" and return to where you left off.</p>
          <button type='submit'>Go Back</button>
        </div>
      </form>
    </div>
  )
}

export default AccessDenial;
