// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Actions {
  height: 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.75rem;
}
.Actions svg {
  cursor: pointer;
}
.Actions svg:hover {
  stroke: var(--AccentColor);
}
.Actions svg:hover path {
  stroke: var(--AccentColor);
}
.Actions .Update {
  font-size: 0.75rem;
  color: var(--AccentColor);
  cursor: pointer;
}`, "",{"version":3,"sources":["webpack://./src/Components/CandidateDetails/Actions/Actions.scss"],"names":[],"mappings":"AAAA;EACE,YAAA;EACA,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,YAAA;AACF;AAAE;EACE,eAAA;AAEJ;AADI;EAIE,0BAAA;AAAN;AAHM;EACE,0BAAA;AAKR;AAAE;EACE,kBAAA;EACA,yBAAA;EACA,eAAA;AAEJ","sourcesContent":[".Actions {\n  height: 40px;\n  display: flex;\n  flex-direction: row;\n  align-items: center;\n  gap: 0.75rem;\n  svg {\n    cursor: pointer;\n    &:hover {\n      path {\n        stroke: var(--AccentColor);\n      }\n      stroke: var(--AccentColor);\n    }\n  }\n  .Update {\n    font-size: 0.75rem;\n    color: var(--AccentColor);\n    cursor: pointer;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
