import React, { useEffect, useMemo, useRef, useState } from "react";
import "./ReportTable.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  FetchCandidateReportsFields,
  generateCandidateCheckReportXlsx,
  getOverallCheckReportResponse,
} from "../../redux/actions/ReportsAction";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import FullPageLoader from "../Loader/FullPageLoader";
import { createCustomtablesInfo } from "../../redux/actions/VendorCandidateDetails";
import { MRT_Localization_EN } from "material-react-table/locales/en";

function ReportsTable() {
  const { subcomponent } = useParams();
  const hasMounted = useRef(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const reports = useSelector(
    (state) => state.FetchCandidateReportsFields.data?.data
  );
  const reportData = useSelector(
    (state) => state.getOverallCheckReportResponse.data?.data
  );
  const downloadUrl = useSelector(
    (state) => state.generateCandidateCheckReportXlsx.data?.data
  );
  const isLoading = useSelector(
    (state) => state.getOverallCheckReportResponse.loading
  );
  const filteredValue = reports?.filter(
    (report) => report?.report_type === subcomponent
  );
  const [groupedColumnMode, setGroupedColumnMode] = useState(false);
  const [trigger, setTrigger] = useState(0);
  const [columnOrder, setColumnOrder] = useState([]);
  const [hiddenColumn, setHiddenColumn] = useState({});
  const [tempHiddenColumn, setTempHiddenColumn] = useState({});
  const currentReportValue = filteredValue?.[0];
  const clientInfoID = localStorage.getItem("client_info_id");
  const masterID = localStorage.getItem("master_id");
  const clientId = localStorage.getItem("user_id");
  const handleDownload = () => {
    const fileUrl = downloadUrl.S3_url;
    const link = document.createElement("a");
    link.href = fileUrl;
    link.setAttribute("download", "filename.ext"); // Specify the filename if needed
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up the link after the download
  };

  const callFetchCandidateReportsFields = () => {
    const data = {
      master_id: masterID,
      client_info_id: clientInfoID,
      client_id: clientId,
    };
    const successFn = (res) => {
      console.log(res);
    };
    const failureFn = (err) => {
      console.log(err);
    };
    dispatch(FetchCandidateReportsFields({ data, successFn, failureFn }));
  };
  useEffect(() => {
    if (!reports) callFetchCandidateReportsFields();
    callGetOverallCheckReportResponse();
  }, [trigger]);
  const createCustomtablesInfoFn = (newOrder) => {
    const clientInfoID = localStorage.getItem("client_info_id");
    const masterID = localStorage.getItem("master_id");
    const clientId = localStorage.getItem("user_id");
    const data = {
      custom_table_name: newOrder,
      client: clientId,
      master: masterID,
      client_info: clientInfoID,
      // defaulttable_info: "1",
      module_name: subcomponent,
    };

    const successFn = (response) => {
      console.log(
        "recent search Data fetched successfully",
        response.data.Over_all_data
      );
      setTrigger(trigger + 1);
      // setCandidateInformation(response.data.Over_all_data);
      // setTotalNoOfPages(
      //   Math.ceil(response.data.Candiadte_count / itemsPerPage)
      // );
    };

    const failureFn = (error) => {
      // setIsLoading(false);
      // setIsError(true);
      // console.error("Logged-in failed", error);
      alert(error);
    };

    dispatch(createCustomtablesInfo({ data, successFn, failureFn }));
  };

  const callGetOverallCheckReportResponse = () => {
    const data = {
      master_id: masterID,
      client_info_id: clientInfoID,
      year: "2024",
      module_name: subcomponent,
    };
    const successFn = (res) => {
      console.log(res);
    };
    const failureFn = (err) => {
      console.log(err);
    };
    dispatch(getOverallCheckReportResponse({ data, successFn, failureFn }));
    dispatch(generateCandidateCheckReportXlsx({ data, successFn, failureFn }));
  };

  const pendingCandidateAddressReportColumn = useMemo(
    () => [
      { accessorKey: "month", header: "Month", filterFn: "contains" },
      {
        accessorKey: "candidate_name",
        header: "Candidate Name",
        enableGrouping: false,
        filterFn: "contains",
      },
      {
        accessorKey: "request_number",
        header: "Request Number",
        enableGrouping: false,
        filterFn: "contains",
      },
      {
        accessorKey: "permanent_address",
        header: "Permanent Address",
        enableGrouping: false,
        filterFn: "contains",
      },
      {
        accessorKey: "temporary_address",
        header: "Temporary Address",
        enableGrouping: false,
        filterFn: "contains",
      },
      {
        accessorKey: "status",
        header: "Status",
        enableGrouping: false,
        filterFn: "contains",
      },
    ],
    []
  );

  const candidatePendingReportColumn = useMemo(
    () => [
      { accessorKey: "month", header: "Month", filterFn: "contains" },
      {
        accessorKey: "candidate_name",
        header: "Candidate Name",
        enableGrouping: false,
        filterFn: "contains",
      },
      {
        accessorKey: "request_number",
        header: "Request Number",
        enableGrouping: false,
        filterFn: "contains",
      },
      {
        accessorKey: "status",
        header: "Status",
        enableGrouping: false,
        filterFn: "contains",
      },
    ],
    []
  );
  const candidateOverallStatusReportColumn = useMemo(
    () => [
      { accessorKey: "check_name", header: "Check Name", filterFn: "contains" },
      {
        accessorKey: "total_initiated_cases",
        header: "Total Initiated Cases",
        filterFn: "contains",
      },
      {
        accessorKey: "pending_from_valianttinfo",
        header: "Pending from Valianttinfo",
        filterFn: "contains",
      },
      { accessorKey: "completed", header: "Completed", filterFn: "contains" },
      { accessorKey: "canceled", header: "Canceled", filterFn: "contains" },
      {
        accessorKey: "canceled_but_verified",
        header: "Canceled but verified",
        minSize: 300,
        filterFn: "contains",
      },
      // { accessorKey: "amount", header: "Amount", filterFn: "contains" },
      // { accessorKey: "invoiced", header: "Invoiced", filterFn: "contains" },
      // {
      //   accessorKey: "not_invoiced",
      //   header: "Not Invoiced",
      //   filterFn: "contains",
      // },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns:
      subcomponent === "pending_candidate_address_report"
        ? pendingCandidateAddressReportColumn
        : subcomponent === "candidate_pending_report"
        ? candidatePendingReportColumn
        : candidateOverallStatusReportColumn,
    data: reportData?.over_all_report_data || [],
    enableGrouping:
      subcomponent === "pending_candidate_address_report" ||
      subcomponent === "candidate_pending_report",
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { height: "calc(100% - 8rem)" } },
    enableColumnOrdering: true,
    onColumnOrderChange: (newOrder) => {
      console.log("newOrder", newOrder);
      const modifiedOrder = Array.isArray(newOrder)
        ? newOrder.map((col) => {
            const specificData = reportData?.custom_fields?.find(
              (withVis) => withVis.key_name === col
            );
            return { ...specificData, key_name: col };
          })
        : [];

      // Ensure the grouping column is preserved or handled correctly
      const groupingColumnIndex = modifiedOrder.findIndex(
        (col) => col.accessorKey === "month"
      );
      if (groupingColumnIndex !== -1) {
        // Optionally move it to the front if needed
        const groupingColumn = modifiedOrder.splice(groupingColumnIndex, 1)[0];
        modifiedOrder.unshift(groupingColumn);
      }

      if (hasMounted.current) {
        console.log("oncolumnchange");
        createCustomtablesInfoFn(modifiedOrder);
      }
    },
    onColumnVisibilityChange: setTempHiddenColumn,
    groupedColumnMode,
    initialState: {
      grouping:
        subcomponent === "pending_candidate_address_report" ||
        subcomponent === "candidate_pending_report"
          ? ["month"]
          : null,
    },
    state: {
      isLoading,
      columnOrder,
      columnVisibility: hiddenColumn,
    },
    paginationDisplayMode: "pages",
    muiCircularProgressProps: {
      Component: <FullPageLoader />,
    },
    globalFilterFn: "contains",
    muiTableBodyCellProps: ({ cell }) => ({
      sx: {
        textTransform: "capitalize",
      },
    }),
    enableHiding: false,
    localization: {
      ...MRT_Localization_EN,
      showHideColumns: "Customize Columns",
    },
  });
  console.log("hiddenColumn", hiddenColumn);

  useEffect(() => {
    const newDefaultOrder = reportData?.default_fields?.map(
      (column) => column?.key_name
    );
    const newCustomOrder = reportData?.custom_fields?.map(
      (column) => column?.key_name
    );
    setColumnOrder(
      newCustomOrder?.length === 0 ? newDefaultOrder : newCustomOrder
    );
    const visibilityChanges = reportData?.custom_fields?.reduce((acc, item) => {
      acc[item.key_name] = item.visibility === false ? false : true;
      return acc;
    }, {});
    console.log("visibilityChanges", visibilityChanges);
    setHiddenColumn(visibilityChanges);
  }, [reportData]);

  // useEffect(() => {
  //   hasMounted.current = true;
  // }, []);
  useEffect(() => {
    const updatedColumnData = reportData?.custom_fields?.map((column) => {
      if (tempHiddenColumn.hasOwnProperty(column.key_name)) {
        return {
          ...column,
          visibility: tempHiddenColumn[column.key_name],
        };
      }
      return column;
    });
    if (hasMounted.current) {
      console.log("updatedColumnData::::::::::::", updatedColumnData);
      createCustomtablesInfoFn(updatedColumnData);
    }
    if (!hasMounted.current) {
      hasMounted.current = true;
    }
  }, [tempHiddenColumn]);

  return (
    <div className='reportTableBg'>
      <div className='reportTableHeading'>
        <div
          className='Back'
          onClick={() => {
            navigate(-1);
          }}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='1.5rem'
            height='1.5rem'
            viewBox='0 0 38 38'
            fill='none'>
            <path
              d='M29.5556 16.2555H13.5112L20.4778 9.28884C21.3223 8.4444 21.3223 7.17773 20.4778 6.33328C19.6334 5.48884 18.3667 5.48884 17.5223 6.33328L6.96671 16.8888C6.12226 17.7333 6.12226 19 6.96671 19.8444L17.5223 30.4C18.3667 31.2444 19.6334 31.2444 20.4778 30.4C21.3223 29.5555 21.3223 28.2888 20.4778 27.4444L13.5112 20.4777H29.5556C30.8223 20.4777 31.6667 19.6333 31.6667 18.3666C31.6667 17.3111 30.8223 16.2555 29.5556 16.2555Z'
              fill='#102C98'
            />
          </svg>
          <span style={{ cursor: "pointer", color: "var(--AccentColor)" }}>
            Back
          </span>
        </div>
        <h1 style={{ textTransform: "capitalize" }}>
          {currentReportValue?.report_name?.replace(/_/g, " ")}
        </h1>
        <div className='reportActions'>
          {/* <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.25rem'
              height='1.25rem'
              viewBox='0 0 18 14'
              fill='none'>
              <path
                fill-rule='evenodd'
                clip-rule='evenodd'
                d='M5.76954 14C4.88478 14 4.1676 13.3294 4.1676 12.5021V6.6503C4.1676 6.60031 4.10292 6.57529 4.06512 6.61069L2.75826 7.83449C2.1276 8.42504 1.10424 8.42537 0.473103 7.83527C-0.149998 7.25263 -0.158699 6.31059 0.45348 5.71796L6.14874 0.204333C6.5931 -0.225816 7.37148 0.0683376 7.37148 0.666351V3.51447V12.5021C7.37148 13.3294 6.65424 14 5.76954 14ZM12.3222 2.69215e-06C13.1929 2.69215e-06 13.8987 0.660011 13.8987 1.47414V7.34812C13.8987 7.39839 13.9639 7.4233 14.0015 7.38745L15.2856 6.16556C15.9062 5.57495 16.9133 5.57461 17.5344 6.16477C18.1476 6.74736 18.1562 7.6894 17.5538 8.28208L11.949 13.7957C11.5118 14.2258 10.7458 13.9317 10.7458 13.3336L10.7457 10.4856V1.47414C10.7457 0.660011 11.4515 2.69215e-06 12.3222 2.69215e-06Z'
                fill='#09A5E0'
              />
            </svg>
            <p>Sort by</p>
          </div>
          <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.25rem'
              height='1.25rem'
              viewBox='0 0 22 22'
              fill='none'>
              <path
                fill-rule='evenodd'
                clipRule='evenodd'
                d='M8.13809 5.84742C8.13809 7.55792 6.72642 8.94575 4.98475 8.94575C3.24492 8.94483 1.83325 7.557 1.83325 5.8465C1.83325 4.13692 3.24492 2.75 4.98567 2.75C6.72734 2.75 8.13809 4.13692 8.13809 5.84742ZM18.7852 4.49075C19.5469 4.49075 20.1666 5.0985 20.1666 5.84742C20.1666 6.59633 19.5469 7.20408 18.7852 7.20408H12.7581C11.9954 7.20408 11.3758 6.59633 11.3758 5.84742C11.3758 5.0985 11.9954 4.49075 12.7581 4.49075H18.7852ZM3.21559 14.6272H9.24267C10.0053 14.6272 10.625 15.2359 10.625 15.9848C10.625 16.7338 10.0063 17.3433 9.24267 17.3433H3.21559C2.45292 17.3433 1.83325 16.7347 1.83325 15.9857C1.83325 15.2368 2.45292 14.6282 3.21559 14.6282V14.6272ZM17.0142 19.0456C18.7558 19.0456 20.1666 17.6587 20.1666 15.9482C20.1666 14.2368 18.7558 12.8498 17.0142 12.8498C15.2734 12.8498 13.8618 14.2377 13.8618 15.9482C13.8618 17.6587 15.2725 19.0456 17.0142 19.0456Z'
                fill='#09A5E0'
              />
            </svg>
            <p>Filters</p>
          </div> */}
          <button
            className='exportBtn'
            onClick={handleDownload}>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.25rem'
              height='1.25rem'
              viewBox='0 0 24 24'
              fill='none'>
              <path
                fillRule='evenodd'
                clipRule='evenodd'
                d='M7.5 4C7.5 3.44771 7.90295 3 8.4 3H20.1C20.597 3 21 3.44771 21 4V20C21 20.5523 20.597 21 20.1 21H8.4C7.90295 21 7.5 20.5523 7.5 20V17H3.9C3.40295 17 3 16.5523 3 16V8C3 7.4477 3.40295 7 3.9 7H7.5V4ZM14 7V4H8.5V7H14ZM15 4V7H20V4H15ZM14 8H12V11.5H14V8ZM15 11.5V8H20V11.5H15ZM14 12.5H12V16H14V12.5ZM15 16V12.5H20V16H15ZM14 17H8.5V20H14V17ZM15 20V17H20V20H15ZM11 8V16H3.9V8H11ZM5.6129 9.5H6.6866L7.5578 11.377L8.4785 9.5H9.48155L8.0996 12L9.51305 14.5H8.4551L7.5056 12.535L6.56015 14.5H5.48645L6.9233 11.991L5.6129 9.5Z'
                fill='white'
              />
            </svg>
            <p>Export</p>
          </button>
          {/* <div>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1rem'
              height='1rem'
              viewBox='0 0 20 24'
              fill='none'>
              <path
                d='M17.7473 0H2.25153C1.65476 0.00111524 1.08276 0.238554 0.660779 0.660319C0.2388 1.08208 0.0012409 1.6538 0.000125093 2.25026V21.7422C-0.00461771 22.1887 0.125604 22.6261 0.373746 22.9974C0.621887 23.3686 0.976386 23.6563 1.39084 23.8228C1.66854 23.939 1.96644 23.9992 2.26748 24C2.56159 23.9991 2.8526 23.9398 3.12362 23.8256C3.39463 23.7114 3.64027 23.5446 3.84628 23.3348L9.40209 17.7818C9.48053 17.7031 9.57373 17.6407 9.67636 17.5981C9.77898 17.5555 9.88901 17.5336 10.0001 17.5336C10.1112 17.5336 10.2213 17.5555 10.3239 17.5981C10.4265 17.6407 10.5197 17.7031 10.5982 17.7818L16.154 23.3348C16.4689 23.6506 16.8705 23.8658 17.308 23.9531C17.7454 24.0404 18.199 23.9958 18.611 23.825C19.0231 23.6543 19.3751 23.365 19.6225 22.9939C19.8698 22.6228 20.0012 22.1867 20.0001 21.7408V2.25026C19.999 1.65356 19.7613 1.08163 19.339 0.659823C18.9167 0.238018 18.3443 0.000743446 17.7473 0Z'
                fill='#09A5E0'
              />
            </svg>
            <p>Save as New Report</p>
          </div> */}
        </div>
      </div>
      {isLoading ? (
        <FullPageLoader />
      ) : (
        <div className='reportTableContainer'>
          <MaterialReactTable table={table} />
        </div>
      )}
    </div>
  );
}

export default ReportsTable;
