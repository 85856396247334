import React, { useRef, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./BeginVerification.scss";
import "./Package/Package.scss";

import info from "../../assets/images/info.svg";
import rightArrow from "../../assets/images/Vector.svg";
import searchBar from "../../assets/images/searchIcon.svg";
import Package from "./Package/Package";
import cancelIcon from "../../assets/images/Close.svg";
import resumeIcon from "../../assets/images/resume.svg";
import PlanDetailsModal from "./Modal/PlanDetailsModal";
import { candidateBeginVerificationUpdateOld, clientPaymentLinkGenerate, fetchExistingUser, getPlan } from "../../redux/actions/CompanyBeginVerificationAction";
import { candidateBeginVerificationOld, clientCancelOrderDetails } from "../../redux/actions/CompanyBeginVerificationAction";
import UpdateConfirmationModal from "./UpdateConfirmationModal";
import CustomAlert from "../ReusableComponents/CustomAlert";
import PaymentProgress from "../ReusableComponents/PaymentProgress";
import PaymentModal from "../ReusableComponents/PaymentModal";
import { fetchOrderDetails } from "../../redux/actions/CompanyBeginVerificationAction";
import preOnboardingImg from '../../assets/images/preonBoarding-tooltip.svg';
import doItByMyself from '../../assets/images/doItByMyself.svg';
import FullPageLoader from "../Loader/FullPageLoader";
import ButtonSpinner from "../ReusableComponents/ButtonSpinner";

function BeginVerification() {
  const useremail = localStorage.getItem("useremail");
  const client_username = localStorage.getItem("client_username");
  const clientInfoID = localStorage.getItem("client_info_id");
  const [planDetailsResp, setPlanDetailsResp] = useState({});
  const [filteredPlans, setFilteredPlans] = useState({});
  const [selectedPlan, setSelectedPlan] = useState(null);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedCustomItems, setSelectedCustomItems] = useState([]);
  const [customAmount, setCustomAmount] = useState(0);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [referId, setReferId] = useState("");
  const [candidateId, setCandidateId] = useState("");
  const [orderId, setOrderId] = useState("");
  const getPlanLoading = useSelector(state => state.getPlan?.loading)

  const [paymentFailed, setPaymentFailed] = useState(false);
  const navigate = useNavigate();
  const BeginVerificationLoading = useSelector(
    (state) => state.candidateBeginVerificationOld.loading
  );

  const getQueryParam = (param) => {
    const queryParams = new URLSearchParams(window.location.search);
    return queryParams.get(param);
  };
  
  // Extract encResp and orderNo
  const encResp = getQueryParam('encResp');
  const orderNo = getQueryParam('orderNo');
  
  // const CancelVerificationLoading = useSelector((state) => state.CancelbeginVerification.loading)
  const [VeriSaved, setVeriSaved] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [processModal, setProcessModal] = useState(false);
  const [isOnboardingChecked, setIsOnboardingChecked] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [selectedDisplayName, setSelectedDisplayName] = useState([]);
  const [bgvCode, setBgvCode] = useState("");
  const [tooltipPos, setTooltipPos] = useState([{doItMyself:false,coordinates:{x:'',y:''}},{onBoarding:false,coordinates:{x:'',y:''}}])
  // const [uploadedFileName, setUploadedFileName] = useState('');

  const uploadRef = useRef();

  useEffect(() => {
    setFilteredPlans(
      Object.keys(planDetailsResp)
        .filter((key) => key.toLowerCase().includes(searchTerm.toLowerCase()))
        .reduce((obj, key) => {
          obj[key] = planDetailsResp[key];
          return obj;
        }, {})
    );
  }, [planDetailsResp, searchTerm]);

  const clientCancelOrderDetailsLoading = useSelector(state => state.clientCancelOrderDetails?.loading)
  const filteredKeys = Object.keys(planDetailsResp).filter((key) =>
    key.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const dispatch = useDispatch();
  const [formData, setFormData] = useState({
    candidate_email: "",
    first_name: "",
    last_name: "",
    resume_url: null,
    client_email: useremail,
    candidate_phonenumber: "",
    // send_copy: '',
    service_providers_api_list: [],
    selected_display_name: [],
    added_checks:[],
    resumeFileName: "",
    total_amount: "",
    job_seeker_id: "",
    bgv_code: "",
    workorder_id: "",
    is_existing_user: 1,
  });

  useEffect(() => {
    fetchPlanDetails();
  }, [clientInfoID]);

  const fetchPlanDetails = () => {
    const data = {
      client_info_id: clientInfoID,
    };

    const successFn = (response) => {
      console.log("Plan fetched successfully", response.data);
      setPlanDetailsResp(response.data);
      // setCustomPlan(response.data.Custompackage)
    };

    const failureFn = (error) => {
      console.error("Logged-in failed", error);
    };

    dispatch(getPlan({ data, successFn, failureFn }));
  };

  const handleInputChange = (e, key) => {
    const value = e.target.value;

    switch (key) {
      case "first_name" || "last_name":
        // Regular expression to match only alphabetic characters and spaces
        const nameRegex = /^[A-Za-z\s]+$/;
        if (value === "" || nameRegex.test(value)) {
          setFormData((prev) => ({ ...prev, [key]: value }));
        }
        break;
      case "candidate_email":
        // Regular expression to allow only letters, dot, and underscore
        const emailRegex = /^[a-zA-Z0-9._\s@]+$/;

        if (value === "" || emailRegex.test(value)) {
          setFormData((prev) => ({ ...prev, [key]: value }));
        }
        break;
      case "candidate_phonenumber":
        // Remove any non-digit characters from the input
        const updatedValue = value.replace(/\D/g, "");
        if (updatedValue.length <= 10) {
          setFormData((prev) => ({ ...prev, [key]: updatedValue }));
        }
        break;
      default:
        setFormData((prevState) => ({
          ...prevState,
          [key]: value,
        }));
        break;
    }
  };

  const [CancelCandidateId, setCancelCandidateId] = useState("");
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
  
    if (file && allowedTypes.includes(file.type)) {
      setFormData((prevState) => ({
        ...prevState,
        resume_url: file,
        resumeFileName: file.name,
      }));
    } else {
      alert("Please upload a valid file type (PDF, DOC, or DOCX).");
    }
  };

  const handlePlanSectionClick = (planName) => {
    const planItems = [];
    const planNames = [];

    const TotalValue = planDetailsResp[planName].total_amount;
    // Check if the planName is not 'Custom_package'
    if (planName !== "Custom package") {
      const plan = planDetailsResp[planName];
      planItems.push(...plan.checks.map((item) => item.serviceprovider_id));
      planNames.push(...plan.checks.map((item) => item.display_name));
      setCustomAmount(0);

    }
    else{
      setCustomAmount(0);
    }
    setBgvCode(planName);
    setSelectedCustomItems([]);
    setSelectedItems(planItems);
    setSelectedDisplayName(planNames);
    setSelectedPlan(planName);
    setFormData((prev) => ({ ...prev, total_amount: TotalValue }));

  };


  useEffect(() => {
    setFormData((prev) => ({ ...prev, total_amount: customAmount }));
  }, [customAmount]);

  const handleCustomPackageCheckboxChange = (
    e,
    serviceprovider_id,
    valiant_rate_tax,
    display_name
  ) => {
    const isChecked = e.target.checked;
    if (selectedPlan !== "Custom package") {
      setSelectedDisplayName([]);
      setSelectedPlan("Custom package");
    }
    setBgvCode("Custom package");
    // setSelectedDisplayName([]);
    if (isChecked) {
      setSelectedCustomItems((prevItems) => [...prevItems, serviceprovider_id]);
      setSelectedDisplayName((prevItems) => [...prevItems, display_name]);
      setCustomAmount((prev) => prev + valiant_rate_tax);
    } else {
      setSelectedCustomItems((prevItems) =>
        prevItems.filter((id) => id !== serviceprovider_id)
      );
      setSelectedDisplayName((prevItems) =>
        prevItems.filter((id) => id !== display_name)
      );
      setCustomAmount((prev) => prev - valiant_rate_tax);
    }
  };

  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    const value = isChecked ? 1 : 0;
    setFormData((prevState) => ({
      ...prevState,
      send_copy: value,
    }));
  };

  const [UpdateConfirmation, setUpdateConfirmation] = useState(false);
  const [UpdateConfirmationData, setUpdateConfirmationData] = useState("");

  const handleSkipAndContinue = () => {
    setFormData((prev) => ({
      ...prev,
      is_existing_user: 1,
    }));
    setShowModal(true);
    setUpdateConfirmation(false);
  };

  const ModalClose = () => {
    UpdateSuccessClose();
    
  };


  const handlePreviewClose = () => {
    setShowModal(false);
    setFormData((prev) => ({
      ...prev,
      total_amount: 0,
    }));
    setSelectedItems([]);
    setSelectedCustomItems([]);
    setCustomAmount(0);
    setSelectedPlan("");
    setBgvCode("");
  };

  // useEffect(() => {
  //   if (formData.is_existing_user === 1) {
  //     // setShowModal(true);
  //     setUpdateConfirmation(true);
  //     // handleVerify();
  //     // UpdateSuccessClose();
  //   }
  // }, [formData?.is_existing_user]);

  const UpdateSuccessClose = () => {
    // setUpdateConfirmation(false);
    setFormData({
      candidate_email: "",
      first_name: "",
      last_name: "",
      resume_url: "",
      client_email: useremail,
      candidate_phonenumber: "",
      service_providers_api_list: [],
      selected_display_name: [],
      resumeFileName: "",
      total_amount: "",
      job_seeker_id: "",
      bgv_code: "",
      workorder_id: "",
      is_existing_user: 1,
    });
    setSelectedItems([]);
    setSelectedCustomItems([]);
    setCustomAmount(0);
    // setUpdateConfirmation(false);
  };

  const handleSubmit = () => {
    setOrderId("");
    // Null validation for form fields
    if (
      !formData.candidate_email ||
      !formData.first_name ||
      !formData.last_name ||
      !formData.candidate_phonenumber ||
      !formData.job_seeker_id
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    if (!emailRegex.test(formData.candidate_email)) {
      // If email format is invalid, throw an error or handle it accordingly
      alert("Please enter a valid email address.");
      return;
    }

    if (formData.candidate_phonenumber.length != 10) {
      alert("Invalid phone number.");
      return;
    }

    if (
      !formData.resume_url // Check if resume_url is null
    ) {
      alert("Please attach candidate's resume.");
      return;
    }
    if (selectedPlan === "Custom package") {
      if (selectedCustomItems.length < 1) {
        alert("Please select any plan.");
        return;
      }
    }
    if (!selectedPlan || selectedPlan == null) {
      alert("Please select any plan.");
      return;
    }
    setCandidateId("");
    setReferId("");

    let serviceProvidersList = [];
    if (selectedPlan === "Custom package") {
      serviceProvidersList = selectedCustomItems;
    } else {
      serviceProvidersList = selectedItems;
    }

    var checked;
    if (isChecked) {
      checked = 1;
    } else {
      checked = 0;
    }

    // Prepare form data
    const data = new FormData();
    if(formData.key !== "selected_display_name" || formData.key !== "added_checks"){
      // data.append("client_email", formData.client_email);
      data.append("client_username", client_username);
      data.append("billing_amount", formData.total_amount);
      data.append("customer_identifier", 1);
      data.append("redirect_url", "http://15.206.70.176:3201/valiantinfo_app/client_payment_service/client_payment_response/");

      data.append("cancel_url", "http://15.206.70.176:3201/valiantinfo_app/client_payment_service/client_payment_response/");

      data.append("candidate_email", formData.candidate_email);
      data.append("first_name", formData.first_name);
      data.append("last_name", formData.last_name);
      data.append("resume", formData.resume_url);
      data.append("candidate_phone_number", formData.candidate_phonenumber);
      // data.append('company_email', localStorage.getItem('useremail'));
      // data.append('cv_language', formData.cv_language);
      // data.append('send_copy', formData.send_copy ? 'true' : 'false');
      data.append(
        "service_apicheck",
        JSON.stringify(serviceProvidersList)
      );
      data.append("job_seeker_id", formData.job_seeker_id);
      data.append("work_order_id", formData.workorder_id);
      data.append("bgv_code", bgvCode);
      data.append("do_it_by_yourself", checked);
      data.append("is_pre_onboarding", isOnboardingChecked ? 1 : 0);
      data.append("is_existing_user", formData.is_existing_user);
      data.append("is_prepaid", 1 );
      data.append("candidate_username", formData.first_name + formData.last_name ); 
      if(formData.is_existing_user === 0){
        data.append("candidate_username", formData.first_name + formData.last_name ); 
      }

    }


    console.log(data);
    // Dispatch action with form data
    const successFn = (response) => {
      console.log("candidate verification", response.data);


      if(response.statusFlag){
        const responseData = response.data;
        const { cc_url, encryption, access_code, order_id } = responseData;
        redirectToPaymentGateway(cc_url, encryption, access_code, order_id);
        setOrderId(order_id);
        setProcessModal(true);
      }
      else{
        alert(response.message);
      }
      // if (response.statusFlag) {
      //   // alert(response.message)
      //   // navigate('/company/dashboard')
      //   if (response.response_code === 201) {
      //     setUpdateConfirmation(true);
      //     setUpdateConfirmationData(response?.data);
      //   } else {
      //     setCancelCandidateId(response.data.candidate_id);
      //     console.log("refer id ", response.data.reference_id);
      //     if (response.data.do_it_by_yourself) {
      //       setReferId(response.data.reference_id);
      //       setCandidateId(response.data.candidate_id);
      //     } else {
      //       setReferId("");
      //     }
      //     setVeriSaved(true);
      //   }
      // } else {
      //   alert(response.message);
      // }
    };
    const failureFn = (err) => {
      console.log("Logged-In Failed");
      alert(err);
    };
    dispatch(clientPaymentLinkGenerate({ data, successFn, failureFn }));
  };

  const redirectToPaymentGateway = (cc_url, encryption, access_code, order_id) => {
    // Create an iframe element dynamically
    const iframe = document.createElement("iframe");
    iframe.style.display = "none";
    document.body.appendChild(iframe);
  
    // Create a form element dynamically
    const form = document.createElement("form");
    form.method = "POST";
    form.name = "redirect";
    form.action = cc_url;
    form.target = iframe.name;  // Set target to the iframe name
  
    // Create hidden input for the encRequest parameter
    const encRequestInput = document.createElement("input");
    encRequestInput.type = "hidden";
    encRequestInput.name = "encRequest";
    encRequestInput.value = encryption;
    form.appendChild(encRequestInput);
  
    // Create hidden input for the access_code parameter
    const accessCodeInput = document.createElement("input");
    accessCodeInput.type = "hidden";
    accessCodeInput.name = "access_code";
    accessCodeInput.value = access_code;
    form.appendChild(accessCodeInput);
  
   // Open a new window
const newWindow = window.open('','_blank','width=800,height=600');

// Append the form to the new window's document
newWindow.document.body.appendChild(form);
  
    // Submit the form programmatically
    form.submit();

    // const checkWindowClosed = setInterval(() => {
    //   if (newWindow.closed) {
    //     clearInterval(checkWindowClosed);
    //     getOrderStatus(order_id);
    //     // You can add any additional logic here, e.g., redirect the user, log the event, etc.
    //   }
    // }, 500); // Check every 500ms
  
    // Clean up: Remove the iframe from the body
    setTimeout(() => document.body.removeChild(iframe), 1000);  // Adjust timeout as needed
  };


  const getOrderStatus = (order_id) => {
    
    console.log("inside order")

    if (!order_id) return; // Stop API calls if timerView is false
console.log("inside order",order_id)
    const successFn = (response) => {
      
      console.log("orderData::::::::::", response?.data);
      if (response?.data[0]?.payment_status === "Failure") {
        setPaymentStatus("Failed");
        console.log("1 exec");
      } else if (response?.data[0]?.payment_status === "Success") {
        setPaymentStatus("Success");
        console.log("2 exec");
      }
      else if (response?.data[0]?.payment_status === "Aborted") {
        setPaymentStatus("Aborted");
        console.log("2 exec");
      }
      else if (response?.data[0]?.payment_status === "initiated") {
        setPaymentStatus("initiated");
        console.log("2 exec");
      }
      setProcessModal(false);
      
      //  else {
      //   setPaymentStatus(response?.data[0]?.payment_status);
      //   console.log("3 exec");
      // }
    };

    const failureFn = (error) => {
      alert(error);
    };

    const params = { order_id: order_id };
    dispatch(fetchOrderDetails({ params, successFn, failureFn }));
  };


  const handleBeginSubmit = () => {
    // Null validation for form fields
    if (!formData.candidate_email || !formData.first_name || !formData.last_name || !formData.candidate_phonenumber || !formData.job_seeker_id) {
      alert('Please fill in all required fields.');
      return;
    }
    // const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.candidate_email)) {
      // If email format is invalid, throw an error or handle it accordingly
      alert('Please enter a valid email address.');
      return;
    }

    if(formData.candidate_phonenumber.length != 10){
      alert('Invalid phone number.');
      return;
    }

    if (
      !formData.resume_url // Check if resume_url is null
    ) {
      alert(`Please attach candidate's resume.`);
      return;
    }
    if (selectedPlan === 'Custom package') {
      if (selectedCustomItems.length < 1) {
        alert('Please select any plan.');
        return;
      }
    }
    if (!selectedPlan || selectedPlan == null ) {
      alert('Please select any plan.');
      return;
    }
    setCandidateId("")
    setReferId("")

    let serviceProvidersList = [];
    if (selectedPlan === 'Custom package') {
      serviceProvidersList = selectedCustomItems;
    } else {
      serviceProvidersList = selectedItems;
    }
  
    var checked;
    if (isChecked){
      checked = 1;
    }
    else{
      checked = 0;
    }
    // Prepare form data
    const data = new FormData();
    data.append('client_email', formData.client_email);
    data.append('client_username', client_username);

    data.append('first_name', formData.first_name);
    data.append('last_name', formData.last_name);
    data.append('candidate_email', formData.candidate_email);
    data.append('resume_url', formData.resume_url);
    data.append('candidate_phonenumber', formData.candidate_phonenumber);
    // data.append('company_email', localStorage.getItem('useremail'));
    // data.append('cv_language', formData.cv_language);
    // data.append('send_copy', formData.send_copy ? 'true' : 'false');
    data.append('service_providers_api_list',JSON.stringify(serviceProvidersList));
    data.append('total_amount',formData.total_amount);
    data.append('job_seeker_id',formData.job_seeker_id);
    data.append('bgv_code',bgvCode);
    data.append('do_it_by_yourself',checked);
    data.append('workorder_id ',checked);
    data.append('is_existing_user', formData.is_existing_user)

    data.append("is_pre_onboarding", isOnboardingChecked ? 1 : 0);
    data.append("is_prepaid", 0 );
    data.append("candidate_username", formData.candidate_username );
    if(formData.is_existing_user === 0){
      data.append("candidate_id", candidateId);
    }

    // Dispatch action with form data
    const successFn = (response) => {
      console.log("candidate verification", response.data);
      if(response.statusFlag){
        // alert(response.message)
        // navigate('/company/dashboard')
        // if(response.response_code === 201) {
        //   setUpdateConfirmation(true)
        //   setUpdateConfirmationData(response?.data)
        // }
        // else {
        //   setCancelCandidateId(response.data.candidate_id)
        //   console.log("refer id ",response.data.reference_id)
        //   if(response.data.do_it_by_yourself){
        //     setReferId(response.data.reference_id)
        //     setCandidateId(response.data.candidate_id)
        //   }
        //   else{
        //     setReferId("")
        //   }
          
        // }

          setCancelCandidateId(response.data.candidate_id)
          console.log("refer id ",response.data.reference_id)
          if(response.data.do_it_by_yourself){
            setReferId(response.data.reference_id)
            setCandidateId(response.data.candidate_id)
          }
          else{
            setReferId("")
          }
      }
      else{
        alert(response.message)
      }

      setVeriSaved(true)
    };
    const failureFn = (err) => {
      console.log("Logged-In Failed");
      alert(err)

    };
    dispatch(candidateBeginVerificationUpdateOld({ data, successFn, failureFn }));
  };

  const fetchExistingUserLoading = useSelector(state => state.fetchExistingUser?.loading)


  const handleVerify = () => {
    // Null validation for form fields
    if (
      !formData.candidate_email ||
      !formData.first_name ||
      !formData.last_name ||
      !formData.candidate_phonenumber ||
      !formData.job_seeker_id
    ) {
      alert("Please fill in all required fields.");
      return;
    }
    const emailRegex = /^[a-zA-Z0-9._]+@[a-zA-Z0-9]+\.[A-Za-z]+$/;
    if (!emailRegex.test(formData.candidate_email)) {
      // If email format is invalid, throw an error or handle it accordingly
      alert("Please enter a valid email address.");
      return;
    }

    if (formData.candidate_phonenumber.length != 10) {
      alert("Invalid phone number.");
      return;
    }

    if (
      !formData.resume_url // Check if resume_url is null
    ) {
      alert(`Please attach candidate's resume.`);
      return;
    }
    if (selectedPlan === "Custom package") {
      if (selectedCustomItems.length < 1) {
        alert("Please select any plan.");
        return;
      }
    }
    if (!selectedPlan || selectedPlan == null) {
      alert("Please select any plan.");
      return;
    }



    if (selectedPlan === "Custom package") {
      formData.service_providers_api_list = selectedCustomItems;
      formData.selected_display_name = selectedDisplayName;
    } else {
      formData.service_providers_api_list = selectedItems;
      formData.selected_display_name = selectedDisplayName;
    }

    const data = {
      job_seeker_id: formData.job_seeker_id,
      candidate_phonenumber: formData.candidate_phonenumber,
      first_name: formData.first_name,
      last_name: formData.last_name,
      service_providers_api_list: JSON.stringify(formData.service_providers_api_list)
    };

    const successFn = (response) => {
      console.log("candidate verification", response.data);
      if (response.statusFlag) {
        // alert(response.message)
        // navigate('/company/dashboard')
        if (response.response_code === 201) {
          setUpdateConfirmation(true);
          setUpdateConfirmationData(response?.data);
        }
        else {
          setCancelCandidateId(response.data.candidate_id);
          console.log("refer id ", response.data.reference_id);
          if (response.data.do_it_by_yourself) {
            setReferId(response.data.reference_id);
            setCandidateId(response.data.candidate_id);
          } else {
            setReferId("");
          }
          // setVeriSaved(true);
          setShowModal(true);
        }
      } else {
        alert(response.message);
      }
    };
    const failureFn = (err) => {
      console.log("Logged-In Failed");
      alert(err);
    };
    dispatch(fetchExistingUser({ data, successFn, failureFn }));

    // setShowModal(true);
  };

  const toggleOpen = () => {
    setShowModal(!showModal);
  };

  const handleChange = (event) => {
    setIsChecked(event.target.checked);
  };

  const handleOnboardingChange = (event) => {
    setIsOnboardingChecked(event.target.checked);
  };

  const [SuccessFailed, setSuccessFailed] = useState("Success");
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState("");
  const [Alert, setAlert] = useState(false);

  const handleCopy = () => {
    const link = `http://localhost:3005/candidate-details-submission/${referId}/${candidateId}`;

    // Check if navigator.clipboard is available and has writeText method
    if (navigator.clipboard && navigator.clipboard.writeText) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          console.log("Link copied to clipboard:", link);
        })
        .catch((err) => {
          console.error("Failed to copy link:", err);
        });
    } else {
      // Fallback for browsers that do not support the Clipboard API
      console.warn(
        "Clipboard API not supported. Falling back to alternative method."
      );
      // You can provide a fallback method here, such as using document.execCommand or a custom approach
    }
  };

  const handleSuccessClose = () => {
    setAlert(false);
    setVeriSaved(false);
    navigate(-1);
  };
  const handleFailedClose = () => {
    setAlert(false);
  };

  const cancelVerification = () => {
    const ClientID = localStorage.getItem("user_id");
    const data = {
      candidate_id: CancelCandidateId,
      client_info_id: clientInfoID,
      client_id: ClientID,
    };

    const successFn = (response) => {
      console.log("CancelSuccess", response.data);
      setSuccessFailed("Success");
      setSuccessFailedMessage(response.message);
      setAlert(true);
    };

    const failureFn = (error) => {
      console.error("Logged-in failed", error);
      // alert(error)
      setSuccessFailed("Failed");
      setSuccessFailedMessage(error);
      setAlert(true);
    };

    dispatch(clientCancelOrderDetails({ data, successFn, failureFn }));
  };

  const handleDoIt = (e) => {
    const svgRect = e.currentTarget.getBoundingClientRect();
    
    const tooltipX = svgRect.left + (svgRect.width / 2);
    const tooltipY = svgRect.top - 10; 

    setTooltipPos((prev) => {
        const newState = [...prev];
        newState[0] = {
            ...newState[0],
            doItMyself: true,
            coordinates: {
                x: tooltipX - 30,
                y: tooltipY + 5
            }
        };
        return newState;
    });
  }
  const handleOnBoarding = (e) => {
    
    const svgRect = e.currentTarget.getBoundingClientRect();
    
    const tooltipX = svgRect.left + (svgRect.width / 2);
    const tooltipY = svgRect.top - 10; 

    setTooltipPos((prev) => {
        const newState = [...prev];
        newState[1] = {
            ...newState[1],
            onBoarding: true,
            coordinates: {
                x: tooltipX - 30,
                y: tooltipY + 5
            }
        };
        return newState;
    });
};

  const handleLeave = () => {
    setTooltipPos(([{doItMyself:false,coordinates:{x:'',y:''}},{onBoarding:false,coordinates:{x:'',y:''}}]));
    // console.log('HI');
  }

  return (
    <div className="planDetails">
      <div className="cDetails">
        <div className="Back" onClick={() => {navigate('/company/candidate-details')}}>
          <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1rem" viewBox="0 0 38 38" fill="none">
            <path d="M29.5556 16.2555H13.5112L20.4778 9.28884C21.3223 8.4444 21.3223 7.17773 20.4778 6.33328C19.6334 5.48884 18.3667 5.48884 17.5223 6.33328L6.96671 16.8888C6.12226 17.7333 6.12226 19 6.96671 19.8444L17.5223 30.4C18.3667 31.2444 19.6334 31.2444 20.4778 30.4C21.3223 29.5555 21.3223 28.2888 20.4778 27.4444L13.5112 20.4777H29.5556C30.8223 20.4777 31.6667 19.6333 31.6667 18.3666C31.6667 17.3111 30.8223 16.2555 29.5556 16.2555Z" fill="#102C98"/>
          </svg>
          <span>Back</span>
        </div>
        <div className="detailsForm">
          <h1>Candidate Details</h1>
          <div className="textForm">
            <div className="inputCont">
            <input
              type="text"
              placeholder="First Name"
              name="firstName"
              maxLength={50}
              value={formData.first_name}
              onChange={(e) => handleInputChange(e, "first_name")}
            />
            <label htmlFor="firstName">First Name</label>
            </div>

            <div className="inputCont">

            <input
              type="text"
              placeholder="Last Name"
              maxLength={50}
              name="lastName"
              value={formData.last_name}
              onChange={(e) => handleInputChange(e, "last_name")}
            />
            <label htmlFor="lastName">Last Name</label>
            </div>

            <div className="inputCont">

            <input
              type="text"
              placeholder="Email"
              name="email"
              value={formData.candidate_email}
              onChange={(e) => handleInputChange(e, "candidate_email")}
            />
            <label htmlFor="email">Email</label>
            </div>

            <div className="inputCont">

            <input
              type="text"
              placeholder="Mobile Number"
              maxLength={10}
              name="mobileNumber"
              value={formData.candidate_phonenumber}
              onChange={(e) => handleInputChange(e, "candidate_phonenumber")}
            />
            <label htmlFor="mobileNuber">Mobile Number</label>
            </div>

            <div className="inputCont">

            <input
              type="text"
              placeholder="Job Seeker ID"
              name="jobSeekerId"
              value={formData.job_seeker_id}
              onChange={(e) => handleInputChange(e, "job_seeker_id")}
              onInput={(e) => {
                const value = e.target.value;
                const validRegex = /^[A-Za-z0-9_-]*$/;
            
                if (!validRegex.test(value)) {
                  e.target.value = value.replace(/[^A-Za-z0-9_-]/g, "");
                }
              }}
            />
            <label htmlFor="jobSeekerId">Job Seeker Id</label>
            </div>

            <div className="inputCont">

            <input
              type="text"
              placeholder="Work Order Id"
              name="workOrderId"
              value={formData.workorder_id}
              onChange={(e) => handleInputChange(e, "workorder_id")}
            />
            <label htmlFor="workOrderId">Work Order Id</label>
            </div>
          </div>
          <div className="checkBox">
            <label>
              <input
                type="checkbox"
                checked={isChecked}
                onChange={handleChange}
              />
              <p>Do it by Myself</p>
            </label>
              <svg onMouseEnter={handleDoIt} onMouseLeave={handleLeave}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M11 8.07925C10.887 8.0797 10.7751 8.05779 10.6706 8.01476C10.5661 7.97173 10.4712 7.90845 10.3913 7.82856C10.3114 7.74867 10.2481 7.65375 10.2051 7.54928C10.1621 7.44481 10.1402 7.33286 10.1406 7.21987C10.1412 7.16332 10.147 7.10695 10.1578 7.05144C10.1789 6.94079 10.2222 6.83556 10.285 6.74206C10.3167 6.69554 10.3524 6.65184 10.3916 6.61144C10.5565 6.45701 10.774 6.37109 11 6.37109C11.226 6.37109 11.4435 6.45701 11.6084 6.61144C11.7679 6.77396 11.8579 6.99217 11.8594 7.21987C11.8644 7.27591 11.8644 7.33228 11.8594 7.38831C11.8383 7.49896 11.795 7.60419 11.7322 7.69769C11.6996 7.74355 11.664 7.78719 11.6256 7.82831C11.5431 7.90936 11.4453 7.97325 11.3379 8.01632C11.2305 8.05939 11.1157 8.08078 11 8.07925ZM11 15.4699C10.8177 15.4699 10.6428 15.3974 10.5139 15.2685C10.3849 15.1396 10.3125 14.9647 10.3125 14.7824V10.3136H9.96875C9.78641 10.3136 9.61155 10.2412 9.48261 10.1123C9.35368 9.98333 9.28125 9.80846 9.28125 9.62612C9.28125 9.44379 9.35368 9.26892 9.48261 9.13999C9.61155 9.01106 9.78641 8.93862 9.96875 8.93862H11C11.1823 8.93862 11.3572 9.01106 11.4861 9.13999C11.6151 9.26892 11.6875 9.44379 11.6875 9.62612V14.7824C11.6875 14.9647 11.6151 15.1396 11.4861 15.2685C11.3572 15.3974 11.1823 15.4699 11 15.4699Z"
                  fill="var(--AccentColor)"
                />
                <path
                  d="M12.1004 15.4688H9.90039C9.71805 15.4688 9.54319 15.3963 9.41425 15.2674C9.28532 15.1385 9.21289 14.9636 9.21289 14.7812C9.21289 14.5989 9.28532 14.424 9.41425 14.2951C9.54319 14.1662 9.71805 14.0938 9.90039 14.0938H12.1004C12.2827 14.0938 12.4576 14.1662 12.5865 14.2951C12.7155 14.424 12.7879 14.5989 12.7879 14.7812C12.7879 14.9636 12.7155 15.1385 12.5865 15.2674C12.4576 15.3963 12.2827 15.4688 12.1004 15.4688Z"
                  fill="var(--AccentColor)"
                />
                <path
                  d="M13.2067 19.3942H8.79297C7.15194 19.3942 5.57813 18.7423 4.41775 17.5819C3.25736 16.4216 2.60547 14.8477 2.60547 13.2067V8.79297C2.60547 7.15194 3.25736 5.57813 4.41775 4.41775C5.57813 3.25736 7.15194 2.60547 8.79297 2.60547H13.2067C14.8477 2.60547 16.4216 3.25736 17.5819 4.41775C18.7423 5.57813 19.3942 7.15194 19.3942 8.79297V13.2067C19.3942 14.8477 18.7423 16.4216 17.5819 17.5819C16.4216 18.7423 14.8477 19.3942 13.2067 19.3942ZM8.79297 3.98047C7.51661 3.98047 6.29254 4.4875 5.39002 5.39002C4.4875 6.29254 3.98047 7.51661 3.98047 8.79297V13.2067C3.98047 14.4831 4.4875 15.7072 5.39002 16.6097C6.29254 17.5122 7.51661 18.0192 8.79297 18.0192H13.2067C14.4831 18.0192 15.7072 17.5122 16.6097 16.6097C17.5122 15.7072 18.0192 14.4831 18.0192 13.2067V8.79297C18.0192 7.51661 17.5122 6.29254 16.6097 5.39002C15.7072 4.4875 14.4831 3.98047 13.2067 3.98047H8.79297Z"
                  fill="var(--AccentColor)"
                />
              </svg>
              {tooltipPos[0].doItMyself && 
                <div className="tooltip" style={{left: `${tooltipPos[0].coordinates.x}px`, top: `${tooltipPos[0].coordinates.y}px`}}>
                  <img src={doItByMyself} alt="" />
                  <div>
                    <h3>Do it by Myself</h3>
                    <p>Select this option if you'd like to personally fill out the BGV form for the candidate</p>
                  </div>
                </div>
              }
          </div>
          <div className="checkBox">
            <label>
              <input
                type="checkbox"
                checked={isOnboardingChecked}
                onChange={handleOnboardingChange}
              />
              <p>Pre-Onboarding</p>
            </label>
            <svg onMouseEnter={handleOnBoarding} onMouseLeave={handleLeave}
                xmlns="http://www.w3.org/2000/svg"
                width="22"
                height="22"
                viewBox="0 0 22 22"
                fill="none"
              >
                <path
                  d="M11 8.07925C10.887 8.0797 10.7751 8.05779 10.6706 8.01476C10.5661 7.97173 10.4712 7.90845 10.3913 7.82856C10.3114 7.74867 10.2481 7.65375 10.2051 7.54928C10.1621 7.44481 10.1402 7.33286 10.1406 7.21987C10.1412 7.16332 10.147 7.10695 10.1578 7.05144C10.1789 6.94079 10.2222 6.83556 10.285 6.74206C10.3167 6.69554 10.3524 6.65184 10.3916 6.61144C10.5565 6.45701 10.774 6.37109 11 6.37109C11.226 6.37109 11.4435 6.45701 11.6084 6.61144C11.7679 6.77396 11.8579 6.99217 11.8594 7.21987C11.8644 7.27591 11.8644 7.33228 11.8594 7.38831C11.8383 7.49896 11.795 7.60419 11.7322 7.69769C11.6996 7.74355 11.664 7.78719 11.6256 7.82831C11.5431 7.90936 11.4453 7.97325 11.3379 8.01632C11.2305 8.05939 11.1157 8.08078 11 8.07925ZM11 15.4699C10.8177 15.4699 10.6428 15.3974 10.5139 15.2685C10.3849 15.1396 10.3125 14.9647 10.3125 14.7824V10.3136H9.96875C9.78641 10.3136 9.61155 10.2412 9.48261 10.1123C9.35368 9.98333 9.28125 9.80846 9.28125 9.62612C9.28125 9.44379 9.35368 9.26892 9.48261 9.13999C9.61155 9.01106 9.78641 8.93862 9.96875 8.93862H11C11.1823 8.93862 11.3572 9.01106 11.4861 9.13999C11.6151 9.26892 11.6875 9.44379 11.6875 9.62612V14.7824C11.6875 14.9647 11.6151 15.1396 11.4861 15.2685C11.3572 15.3974 11.1823 15.4699 11 15.4699Z"
                  fill="var(--AccentColor)"
                />
                <path
                  d="M12.1004 15.4688H9.90039C9.71805 15.4688 9.54319 15.3963 9.41425 15.2674C9.28532 15.1385 9.21289 14.9636 9.21289 14.7812C9.21289 14.5989 9.28532 14.424 9.41425 14.2951C9.54319 14.1662 9.71805 14.0938 9.90039 14.0938H12.1004C12.2827 14.0938 12.4576 14.1662 12.5865 14.2951C12.7155 14.424 12.7879 14.5989 12.7879 14.7812C12.7879 14.9636 12.7155 15.1385 12.5865 15.2674C12.4576 15.3963 12.2827 15.4688 12.1004 15.4688Z"
                  fill="var(--AccentColor)"
                />
                <path
                  d="M13.2067 19.3942H8.79297C7.15194 19.3942 5.57813 18.7423 4.41775 17.5819C3.25736 16.4216 2.60547 14.8477 2.60547 13.2067V8.79297C2.60547 7.15194 3.25736 5.57813 4.41775 4.41775C5.57813 3.25736 7.15194 2.60547 8.79297 2.60547H13.2067C14.8477 2.60547 16.4216 3.25736 17.5819 4.41775C18.7423 5.57813 19.3942 7.15194 19.3942 8.79297V13.2067C19.3942 14.8477 18.7423 16.4216 17.5819 17.5819C16.4216 18.7423 14.8477 19.3942 13.2067 19.3942ZM8.79297 3.98047C7.51661 3.98047 6.29254 4.4875 5.39002 5.39002C4.4875 6.29254 3.98047 7.51661 3.98047 8.79297V13.2067C3.98047 14.4831 4.4875 15.7072 5.39002 16.6097C6.29254 17.5122 7.51661 18.0192 8.79297 18.0192H13.2067C14.4831 18.0192 15.7072 17.5122 16.6097 16.6097C17.5122 15.7072 18.0192 14.4831 18.0192 13.2067V8.79297C18.0192 7.51661 17.5122 6.29254 16.6097 5.39002C15.7072 4.4875 14.4831 3.98047 13.2067 3.98047H8.79297Z"
                  fill="var(--AccentColor)"
                />
            </svg>
            {tooltipPos[1].onBoarding && 
    <div className="tooltip" style={{left: `${tooltipPos[1].coordinates.x}px`, top: `${tooltipPos[1].coordinates.y}px`}}>
        <img src={preOnboardingImg} alt="" />
        <div>
            <h3>Pre Onboarding</h3>
            <p>Check this box if the candidate is already onboarded, and you're conducting the background verification post-hire.</p>
        </div>
    </div>
}
            
          </div>
          <div className="uploadsCont">
            <h1>Upload CV/Resume</h1>
            <span>(allowed formats are .pdf, .doc, .docx)</span>
            <div className="upload" onClick={() => uploadRef.current.click()}>
              {formData.resumeFileName === '' ?
                'Upload'
                :
                <div className="uploadedFileName" onClick={(e) => e.stopPropagation()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" viewBox="0 0 28 29" fill="none">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M24.2082 10.3165C24.2082 9.77516 23.9935 9.256 23.6097 8.87333C22.422 7.68566 18.481 3.74466 17.2933 2.557C16.9107 2.17316 16.3915 1.9585 15.8502 1.9585C14.3685 1.9585 9.8185 1.9585 6.99984 1.9585C6.14934 1.9585 5.33267 2.29683 4.73067 2.89766C4.12984 3.49966 3.7915 4.31633 3.7915 5.16683C3.7915 9.6375 3.7915 19.3628 3.7915 23.8335C3.7915 24.684 4.12984 25.5007 4.73067 26.1027C5.33267 26.7035 6.14934 27.0418 6.99984 27.0418H20.9998C22.772 27.0418 24.2082 25.6057 24.2082 23.8335V10.3165ZM22.4582 10.3165V23.8335C22.4582 24.6385 21.8048 25.2918 20.9998 25.2918H6.99984C6.6125 25.2918 6.24267 25.1378 5.9685 24.8648C5.6955 24.5907 5.5415 24.2208 5.5415 23.8335V5.16683C5.5415 4.7795 5.6955 4.40966 5.9685 4.1355C6.24267 3.8625 6.6125 3.7085 6.99984 3.7085H15.8502C15.9272 3.7085 16.0018 3.73883 16.0567 3.79366L22.373 10.11C22.4278 10.1648 22.4582 10.2395 22.4582 10.3165Z" fill="var(--TextColor)"/>
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M15.4584 3.4165V7.49984C15.4584 9.272 16.8946 10.7082 18.6668 10.7082H22.7501C23.2331 10.7082 23.6251 10.3162 23.6251 9.83317C23.6251 9.35017 23.2331 8.95817 22.7501 8.95817H18.6668C17.8618 8.95817 17.2084 8.30484 17.2084 7.49984V3.4165C17.2084 2.9335 16.8164 2.5415 16.3334 2.5415C15.8504 2.5415 15.4584 2.9335 15.4584 3.4165ZM14.0001 11.2915C12.2291 11.2915 10.7918 12.7288 10.7918 14.4998C10.7918 16.2708 12.2291 17.7082 14.0001 17.7082C15.7711 17.7082 17.2084 16.2708 17.2084 14.4998C17.2084 12.7288 15.7711 11.2915 14.0001 11.2915ZM14.0001 13.0415C14.8051 13.0415 15.4584 13.6948 15.4584 14.4998C15.4584 15.3048 14.8051 15.9582 14.0001 15.9582C13.1951 15.9582 12.5418 15.3048 12.5418 14.4998C12.5418 13.6948 13.1951 13.0415 14.0001 13.0415ZM10.7159 21.9385C11.3716 20.8045 12.5978 20.0415 14.0001 20.0415C15.4024 20.0415 16.6286 20.8045 17.2843 21.9385C17.5269 22.3562 18.0624 22.4985 18.4801 22.257C18.8978 22.0155 19.0413 21.4788 18.7986 21.0612C17.8408 19.4057 16.0488 18.2915 14.0001 18.2915C11.9514 18.2915 10.1594 19.4057 9.2016 21.0612C8.95893 21.4788 9.10243 22.0155 9.5201 22.257C9.93777 22.4985 10.4733 22.3562 10.7159 21.9385Z" fill="var(--TextColor)"/>
                  </svg>
                  <p>{formData.resumeFileName}</p>
                  <svg onClick={() => {
                    setFormData((prev) => ({
                      ...prev,
                      resume_url:null,
                      resumeFileName:''
                    }))
                  }} xmlns="http://www.w3.org/2000/svg" width="1.3rem" height="1.3rem" viewBox="0 0 30 31" fill="none">
                    <path d="M16.7624 15.5002L24.6374 7.63773C24.8728 7.40235 25.005 7.08311 25.005 6.75023C25.005 6.41735 24.8728 6.09811 24.6374 5.86273C24.402 5.62735 24.0828 5.49512 23.7499 5.49512C23.417 5.49512 23.0978 5.62735 22.8624 5.86273L14.9999 13.7377L7.13742 5.86273C6.90204 5.62735 6.5828 5.49512 6.24992 5.49512C5.91705 5.49512 5.5978 5.62735 5.36242 5.86273C5.12704 6.09811 4.99481 6.41735 4.99481 6.75023C4.99481 7.08311 5.12704 7.40235 5.36242 7.63773L13.2374 15.5002L5.36242 23.3627C5.24526 23.4789 5.15227 23.6172 5.08881 23.7695C5.02535 23.9218 4.99268 24.0852 4.99268 24.2502C4.99268 24.4152 5.02535 24.5786 5.08881 24.731C5.15227 24.8833 5.24526 25.0215 5.36242 25.1377C5.47863 25.2549 5.61688 25.3479 5.7692 25.4113C5.92153 25.4748 6.08491 25.5075 6.24992 25.5075C6.41494 25.5075 6.57832 25.4748 6.73064 25.4113C6.88297 25.3479 7.02122 25.2549 7.13742 25.1377L14.9999 17.2627L22.8624 25.1377C22.9786 25.2549 23.1169 25.3479 23.2692 25.4113C23.4215 25.4748 23.5849 25.5075 23.7499 25.5075C23.9149 25.5075 24.0783 25.4748 24.2306 25.4113C24.383 25.3479 24.5212 25.2549 24.6374 25.1377C24.7546 25.0215 24.8476 24.8833 24.911 24.731C24.9745 24.5786 25.0072 24.4152 25.0072 24.2502C25.0072 24.0852 24.9745 23.9218 24.911 23.7695C24.8476 23.6172 24.7546 23.4789 24.6374 23.3627L16.7624 15.5002Z" fill="var(--TextColor)"/>
                  </svg>
                </div>
              }
            </div>
            <input
              ref={uploadRef}
              type="file"
              accept=".pdf,.doc,.docx"
              onChange={handleFileChange}
            />
          </div>
        </div>
        <button className="proceedBtn" onClick={() => handleVerify(true)} disabled={fetchExistingUserLoading}>
          {fetchExistingUserLoading ?
            <ButtonSpinner size={1}/>
            :
            <>
              Proceed
              <svg width="1rem" height="1rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 9.66537L11.6667 1.33203C11 0.665365 10 0.665365 9.33333 1.33203C8.66667 1.9987 8.66667 2.9987 9.33333 3.66536L14.8333 9.16537H2.16667C1.16667 9.16537 0.5 9.83203 0.5 10.832C0.5 11.832 1.16667 12.4987 2.16667 12.4987H14.8333L9.33333 17.9987C8.66667 18.6654 8.66667 19.6654 9.33333 20.332C10 20.9987 11 20.9987 11.6667 20.332L20 11.9987C20.6667 11.4987 20.6667 10.332 20 9.66537Z" fill="white"/>
              </svg>
            </>
          }
        </button>
      </div>
      <div className="planContainer">
        <div className="planInnerContainer">
        <div className="searchBox">
          <input
            type="text"
            placeholder="Search"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <img src={searchBar} alt="" />
        </div>
        <div className="planTypesContainer">
          <div className="planTypesWrapper">
            <div className="planTypes">
              {getPlanLoading ?
                <FullPageLoader/>
                :
                <>
                  <div
                    className={`packageContainer ${
                      selectedCustomItems.length > 0 ? "active" : ""
                    }`}
                    onClick={() => {selectedCustomItems.length > 0 && setSelectedPlan("Custom package")}}
                  >
                    <div className="pricingDiv">
                      <p className="packageName">Custom Package</p>
                      <p className="princing">₹ {customAmount.toFixed(2)} /-</p>
                      <p>* Inclusive of all taxes</p>
                    </div>
                    <div className="checksList">
                      {planDetailsResp["Custom package"]?.map((data, dataIndex) => (
                        <label className="Checks" key={dataIndex}>
                          <input
                            type="checkbox"
                            onChange={(e) =>
                              handleCustomPackageCheckboxChange(
                                e,
                                data.serviceprovider_id,
                                data.valiant_rate_tax,
                                data.display_name
                              )
                            }
                            checked={selectedCustomItems.includes(
                              data.serviceprovider_id
                            )}
                          />
                          <span>{(data.display_name === 'Express Court Check (Results Expected in 8 Hours)' || data.display_name === 'Express Court Check (8 Hours)') ? <b>{data.display_name}</b> : data.display_name}</span>
                        </label>
                      ))}
                    </div>
                  </div>
                  {Object.entries(filteredPlans)
                    .filter(([key, _]) => key !== "Custom package") // Exclude 'Custom package' from iteration
                    .map(([key, values], index) => (
                      <div
                        className={`packageContainer ${
                          selectedPlan === key ? "active" : ""
                        }`}
                        onClick={() => handlePlanSectionClick(key)}
                      >
                        <div className="pricingDiv">
                          <p className="packageName">{key}</p>
                          <p className="princing">₹ {values.total_amount} /-</p>
                          <p>* Inclusive of all taxes</p>
                        </div>
                        <div className="checksList">
                          {values.checks.map((data, dataIndex) => (
                            <div className="Checks" key={dataIndex}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="0.875rem" height="0.875rem" viewBox="0 0 28 28" fill="none">
                                <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V23C0 25.7614 2.23858 28 5 28H23C25.7614 28 28 25.7614 28 23V5C28 2.23858 25.7614 0 23 0H5ZM11.3877 16.8182L19.812 8L21 9.26276L11.9749 18.6913C11.8062 18.8597 11.5834 19 11.3809 19C11.1784 19 10.9489 18.8597 10.7801 18.6843L7 14.7557L8.20154 13.507L11.3877 16.8182Z" fill="var(--AccentColor)"/>
                              </svg>
                              <span>{data.display_name}</span>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))
                  }
                </>
              }
            </div>
          </div>
        </div>
        </div>
        <button className="proceedButton" onClick={() => handleVerify(true)} disabled={fetchExistingUserLoading}>
          {fetchExistingUserLoading ?
            <ButtonSpinner size={1}/>
            :
            <>
              Proceed
              <svg width="1rem" height="1rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 9.66537L11.6667 1.33203C11 0.665365 10 0.665365 9.33333 1.33203C8.66667 1.9987 8.66667 2.9987 9.33333 3.66536L14.8333 9.16537H2.16667C1.16667 9.16537 0.5 9.83203 0.5 10.832C0.5 11.832 1.16667 12.4987 2.16667 12.4987H14.8333L9.33333 17.9987C8.66667 18.6654 8.66667 19.6654 9.33333 20.332C10 20.9987 11 20.9987 11.6667 20.332L20 11.9987C20.6667 11.4987 20.6667 10.332 20 9.66537Z" fill="white"/>
              </svg>
            </>
          }
        </button>
      </div>
      {showModal &&
        <PlanDetailsModal
          checkedValue={(isChecked && isOnboardingChecked) ? 'BOTH' : isOnboardingChecked ? 'PRE' : isChecked ? 'DIY' : ''}
          planName={selectedPlan}
          data={formData}
          submitFunction={handleSubmit}
          beginOld={handleBeginSubmit}
          closeModal={handlePreviewClose}
        />
      }

      {VeriSaved &&
        <section className='VeriSavedContainer'>
          <div className='VeriSaved'>
            <div className='SuccessBeginVerification'>
              {/* <img src={SuccessBeginVerification} alt='SuccessBeginVerification'/> */}
            </div>
            <h1>🎉 Success! Background Check Invite on its Way! 💌</h1>
            <span className='Hint'>
              Great news! Your background check form has been sent to the candidate. Just a heads-up, you have 24 hours to cancel this invitation if needed.
            </span>
            <div className='ButtonContainer'>
              <button className='Cancel CenterDiv' onClick={() => {cancelVerification()}}>{clientCancelOrderDetailsLoading ? <ButtonSpinner color="var(--AccentColor)"/> : 'Cancel Invite'}</button>
              <button className='Done' onClick={() => {setVeriSaved(false); navigate(-1)}}>Done</button>
            </div>
          </div>
        </section>
      }

      {Alert &&
        <CustomAlert type={SuccessFailed} message={SuccessFailedMessage} handleClose={SuccessFailed === 'Success' ? handleSuccessClose : handleFailedClose}/>
      }
      {UpdateConfirmation &&
        <UpdateConfirmationModal data={UpdateConfirmationData} modelClose={() =>setUpdateConfirmation(false)} setUpdateData={setFormData} setCandidateId={setCandidateId} handleSkipContinue={handleSkipAndContinue} showPreview={toggleOpen}/>
      }


      {(processModal && (orderId !== "")) &&
      <PaymentProgress orderId={orderId} processModal={setProcessModal} submitFunction={handleSubmit}/>
      }

      {paymentStatus !== "" && (
        <PaymentModal
          response={paymentStatus}
          closeModal={() => [setPaymentStatus(''),setProcessModal(false),setOrderId(''),UpdateSuccessClose(), setSelectedPlan(''),setBgvCode(''), setShowModal(false)]}
          submitFunction={handleSubmit}
        />
      )}
    </div>
  );
}

export default BeginVerification;
