import React, { useEffect, useState } from 'react'
import '../Components/CompanyDashboard/CompanyDashboard.scss'
import Sidebar from '../Components/Sidebar/Sidebar'
import Topbar from '../Components/Topbar/Topbar'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import DashboardClient from './DashboardClient'
import ClientPackList from './ClientPackList'
import ClientCandidateReport from './ClientCandidateReport'
import CandidateDetail from '../Components/CandidateDetails/CandidateDetail'

const ClientDashboard = ({ changeTheme }) => {
  const { clientComponent, subcomponent } = useParams()
  const [candidate, setCandidate] = useState('')
  const [clientInfo, setClientInfo] = useState('')
  const handleViewClick = (id, info) => {
    setCandidate(id)
    setClientInfo(info)
    localStorage.setItem('client_info_id', info)
  }
  const navigate = useNavigate()
  const location = useLocation();
  const currentURL = location.pathname;

  useEffect(() => {
    if(currentURL === '/'){
      navigate('/client/dashboard')
    }
  },[])
  const renderComponent = () => {
    switch (clientComponent) {
      case "dashboard":
        if(!subcomponent) {
          return <DashboardClient handleView={handleViewClick}/>;
        }
        else if (subcomponent === 'report') {
          return <CandidateDetail/>;
        }
      case "candidatesearch":
        if(!subcomponent){
          return <ClientCandidateReport handleView={handleViewClick}/>;
        }
        else if(subcomponent === "report"){
          return <CandidateDetail/>;
        }
      case "pack-details":
        return <ClientPackList />;
      default:
        return <div>No Pages found !!</div>;
    }
  };

  return (
    <main className='CompanyDashboard'>
      <Sidebar changeTheme={changeTheme} />
      <section className='TopBarContent'>
        <Topbar/>
        <article className='ContentSection'>{renderComponent()}</article>
      </section>
    </main>
  )
}

export default ClientDashboard