export const inVoiceData = [
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "overdue",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "unpaid",
  },
  {
    invoiceId: "09834",
    inVoiceDate: "20-JUN-2019",
    dueDate: "21-JUL-2022",
    paymentSpan: "monthly",
    status: "paid",
  },
];

export const transactionData = [
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Success',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Pending',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Failed',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Success',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Pending',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Failed',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Success',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Pending',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Failed',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Success',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Pending',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Failed',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Success',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Pending',
    },
    {
        transactionId: '28374',
        transactionDate:'20-JUN-23',
        paymentMethod:'credit card',
        totalAmount: '29388',
        status:'Failed',
    },

]