// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CompanyDashboard {
  width: 100dvw;
  height: 100dvh;
  padding: 0.5rem;
  background: var(--Background);
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 0.3s ease;
}
.CompanyDashboard .TopBarContent {
  flex: 1 1;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
  height: 100%;
  overflow: auto;
}
.CompanyDashboard .ContentSection {
  flex: 1 1;
  width: 100%;
  overflow: auto;
}`, "",{"version":3,"sources":["webpack://./src/Components/CompanyDashboard/CompanyDashboard.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,cAAA;EACA,eAAA;EACA,6BAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EACA,yBAAA;AACF;AAAE;EACE,SAAA;EACA,aAAA;EACA,sBAAA;EACA,YAAA;EACA,YAAA;EACA,cAAA;AAEJ;AAAE;EACE,SAAA;EACA,WAAA;EACA,cAAA;AAEJ","sourcesContent":[".CompanyDashboard{\n  width: 100dvw;\n  height: 100dvh;\n  padding: 0.5rem;\n  background: var(--Background);\n  display: flex;\n  align-items: center;\n  gap: 0.75rem;\n  transition: all 0.3s ease;\n  .TopBarContent{\n    flex: 1;\n    display: flex;\n    flex-direction: column;\n    gap: 0.75rem;\n    height: 100%;\n    overflow: auto;\n  }\n  .ContentSection{\n    flex: 1;\n    width: 100%;\n    overflow: auto;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
