import React,{ useState, useEffect } from 'react'
import './PackDetails.scss'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { DeletePlan, companyPlans } from '../../redux/actions/CompanyPackDetailsAction'
import CustomAlert from '../ReusableComponents/CustomAlert'
import FullPageLoader from '../Loader/FullPageLoader'

const PackDetails = () => {
  const DarkMode = localStorage.getItem('Theme') === 'true' ? true : false
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const clientInfoID = localStorage.getItem("client_info_id");
  const companyPlansLoading = useSelector((state) => state.companyPlans?.loading)
  const DeletePlanLoading = useSelector((state) => state.DeletePlan?.loading)
  const [PlanName, setPlanName] = useState('')
  const [RecallPlans, setRecallPlans] = useState(false)


  const [searchTerm, setSearchTerm] = useState('');
  const [planDetailsResp, setPlanDetailsResp] = useState({});
  const [filteredPlans, setFilteredPlans] = useState({});

  useEffect(() => {
    setFilteredPlans(
      Object.keys(planDetailsResp)
        .filter(key => key !== 'Custom package' && key.toLowerCase().includes(searchTerm.toLowerCase()))
        .reduce((obj, key) => {
          obj[key] = planDetailsResp[key];
          return obj;
        }, {})
    );
  }, [planDetailsResp, searchTerm]);


  const filteredKeys = Object.keys(planDetailsResp)
    .filter(key => key.toLowerCase().includes(searchTerm.toLowerCase()));


  const [SuccessFailed, setSuccessFailed] = useState('Success')
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState('')
  const [Alert, setAlert] = useState(false)
  const handleSuccessClose = () => {
    setAlert(false)
  }



  useEffect(() => {
    fetchPlanDetails();
  }, [clientInfoID]);

  const fetchPlanDetails = () => {
    const data = {
      client_info_id: clientInfoID
    };

    const successFn = (response) => {
      console.log('Plan fetched successfully', response.data);
      setPlanDetailsResp(response.data)
      setRecallPlans(false)
    };

    const failureFn = (error) => {
      console.error('Logged-in failed', error);
    };

    dispatch(companyPlans({ data, successFn, failureFn }));
  };

  const handleCustomizeClick = () => {
    navigate('/company/pack-details/customizepack')
  }


  useEffect(()=> {
    if(RecallPlans) {
      fetchPlanDetails()
    }
  },[RecallPlans])


  const Deletepack = () => {
    const data = {
      "client_info_id": clientInfoID,
      "plan_name": PlanName
    };

    const successFn = (response) => {
      setRecallPlans(true)
      setSuccessFailed('Success')
      setSuccessFailedMessage(response.message)
      setAlert(true)
    };

    const failureFn = (error) => {
      console.error('Logged-in failed', error);
      setSuccessFailed('Failed')
      setSuccessFailedMessage(error)
      setAlert(true)
    };

    dispatch(DeletePlan({ data, successFn, failureFn }));
  };



  const handleDeleteClick = (planName) => {
    setPlanName(planName)
  }

  useEffect(()=> {
    if(PlanName !== ''){
      Deletepack();
    }
  },[PlanName])

  return (
    <main className='PackDetails'>
      {(companyPlansLoading || DeletePlanLoading) &&
        <section className='LoaderContainer'>
          <FullPageLoader/>
        </section>
      }
      <header className='Header'>
        <button onClick={()=> {handleCustomizeClick()}}>
          Customize pack
          <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 11 12" fill="none">
            <path d="M10.725 5.26849L6.14167 0.685156C5.775 0.31849 5.225 0.31849 4.85833 0.685156C4.49167 1.05182 4.49167 1.60182 4.85833 1.96849L7.88333 4.99349H0.916667C0.366667 4.99349 0 5.36016 0 5.91016C0 6.46016 0.366667 6.82682 0.916667 6.82682H7.88333L4.85833 9.85182C4.49167 10.2185 4.49167 10.7685 4.85833 11.1352C5.225 11.5018 5.775 11.5018 6.14167 11.1352L10.725 6.55182C11.0917 6.27682 11.0917 5.63516 10.725 5.26849Z" fill="white"/>
          </svg>
        </button>
      </header>
      <div className='PackContainerWrapper'>
        {(filteredPlans && Object.keys(filteredPlans).length > 0) ? (
          <section className='PackContainer'>
            {Object.keys(filteredPlans)?.map((key, index) => (
              <div className='Pack' key={index}>
                <h3 title={key}>{key}</h3>
                <h1>₹ {filteredPlans[key].total_amount}/-</h1>
                {filteredPlans[key].checks.map((item, idx) => (
                  <div className='Checks' key={idx}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.875rem" height="0.875rem" viewBox="0 0 28 28" fill="none">
                      <path fill-rule="evenodd" clip-rule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V23C0 25.7614 2.23858 28 5 28H23C25.7614 28 28 25.7614 28 23V5C28 2.23858 25.7614 0 23 0H5ZM11.3877 16.8182L19.812 8L21 9.26276L11.9749 18.6913C11.8062 18.8597 11.5834 19 11.3809 19C11.1784 19 10.9489 18.8597 10.7801 18.6843L7 14.7557L8.20154 13.507L11.3877 16.8182Z" fill="#102C97"/>
                    </svg>
                    <span>{item?.display_name}</span>
                  </div>
                ))}
                <svg xmlns="http://www.w3.org/2000/svg" width="1.25rem" height="1.25rem" viewBox="0 0 24 24" fill="none" id="delete" className='Delete' onClick={() => {handleDeleteClick(key)}}>
                  <path fill="#200E32" fill-rule="evenodd" d="M15.94 3.39C15.651 2.546 14.907 2 14.063 2H9.936l-.157.006a1.993 1.993 0 0 0-1.756 1.5l-.248 1.262-.029.111a.878.878 0 0 1-.828.61H3.73l-.099.007A.744.744 0 0 0 3 6.239c0 .414.327.75.731.75H20.27l.099-.007A.744.744 0 0 0 21 6.24a.74.74 0 0 0-.731-.75h-3.187l-.112-.007a.885.885 0 0 1-.746-.714l-.237-1.216-.048-.162Zm-1.026 2.1a2.442 2.442 0 0 1-.086-.262l-.038-.166-.226-1.17a.521.521 0 0 0-.414-.385l-.086-.007H9.936a.525.525 0 0 0-.463.278l-.026.068-.237 1.216c-.03.148-.071.291-.124.427h5.828Zm4.064 3.23c.37.03.652.337.672.706l-.01.206-.314 3.851-.33 3.758c-.07.75-.132 1.384-.186 1.882-.188 1.736-1.314 2.81-3.013 2.841a191.08 191.08 0 0 1-7.663-.005c-1.65-.035-2.76-1.12-2.944-2.83l-.128-1.259-.222-2.443-.228-2.68-.26-3.22a.744.744 0 0 1 .67-.807c.37-.03.696.226.773.587l.03.295.245 3.017.267 3.127c.12 1.354.223 2.45.306 3.219.105.974.618 1.475 1.521 1.494 2.452.053 4.978.054 7.606.005.958-.018 1.479-.514 1.586-1.507l.127-1.252c.037-.386.076-.813.118-1.276l.268-3.075.322-3.946a.74.74 0 0 1 .688-.69l.1.002Z" clip-rule="evenodd"></path>
                </svg>
              </div>
              ))}
          </section>
        )  : (
          <div className='NoData'>
            {searchTerm ?
              <span>No packs found</span>
              :
              !companyPlansLoading &&
              <>
                <span>No packs are created yet </span>
                <span>Try customizing a new pack by clicking the <b>Customize pack</b> button in the upright corner</span>
              </>
            }
          </div>
          )
        }
      </div>
      {Alert &&
        <CustomAlert type={SuccessFailed} message={SuccessFailedMessage} handleClose={handleSuccessClose}/>
      }
    </main>
  )
}

export default PackDetails