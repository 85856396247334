import React from "react";
import "./PaymentModal.scss";
import success from "../../assets/images/Success.svg";
import unsuccess from "../../assets/images/Unsuccess.svg";
import progress from "../../assets/images/Inprogress.svg";
import { useNavigate } from "react-router-dom";

function PaymentModal({ response, closeModal, submitFunction }) {
  const navigate = useNavigate();

  const paymentFunction = () => {
    if (response === "Success") {
      return (
        <>
          <img
            src={success}
            alt=''
          />
          <div className='paymentMsg'>
            <h1>Payment Success! 🎉</h1>
            <p>
              All done! Your payment’s through, and the background check form is
              sent. You have 24 hours to make any changes. Check Financial
              Management for details!
            </p>
            <div>
              {/* <button onClick={closeModal}>Cancel Invite</button> */}
              <button onClick={() => navigate(-1)}>Done</button>
            </div>
          </div>
        </>
      );
    } else if (response === "Failed" || response === "Aborted") {
      return (
        <>
          <img
            src={unsuccess}
            alt=''
          />
          <div className='paymentMsg'>
            <h1>Payment Failed 🚫</h1>
            <p>
              Oops! Something went wrong with your payment. Please try again or
              check your Financial Management section for details.
            </p>
            <div>
              <button onClick={closeModal}>Cancel</button>
              <button
                onClick={() => {
                  closeModal();
                  submitFunction(); // Trigger submitFunction on "Try Again"
                }}>
                Try Again
              </button>
            </div>
          </div>
        </>
      );
    } else if (response === "inProgress") {
      return (
        <>
          <img
            src={progress}
            alt=''
          />
          <div className='paymentMsg'>
            <h1>Payment in Progress ⏳</h1>
            <p>
              Your payment is being processed. Please check back shortly or
              visit your Financial Management section for updates.
            </p>
            <div>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </>
      );
    } else if (response === "initiated") {
      return (
        <>
          <img
            src={progress}
            alt=''
          />
          <div className='paymentMsg'>
            <h1>Payment not Completed ⏳</h1>
            <p>
              Your payment is not being completed. Please initiate to proceed
              for payment.
            </p>
            <div>
              <button onClick={closeModal}>Cancel</button>
            </div>
          </div>
        </>
      );
    }
  };

  return (
    <div className='paymentModalBg'>
      <div className='modalCont'>{paymentFunction()}</div>
    </div>
  );
}

export default PaymentModal;
