import React,{useState} from 'react';
import './UpdateConfirmationModal.scss'
import { useDispatch, useSelector } from 'react-redux';
import ButtonLoader from '../Loader/ButtonLoader';
import { updateServiceProviderChecks } from '../../redux/actions/CompanyBeginVerificationAction';
import CustomAlert from '../ReusableComponents/CustomAlert';

const UpdateConfirmationModal = ({data,  modelClose, setUpdateData,setCandidateId, handleSkipContinue, showPreview}) => {
  const dispatch = useDispatch();
  const dataArray = data;
  const [selectedItem, setSelectedItem] = useState(null);
  const [calculatedAmount, setCalculatedAmount] = useState(null);
  const [formData, setFormData] = useState({
    candidate_id: '',
    client_info_id: '',
    update_check_id: dataArray?.selected_checks,
    bgv_code: ''
  });

  const updateChecksLoading = useSelector((state) => state.updateServiceProviderChecks?.loading)
  const [Modal, setModal] = useState(false)
  const [CustomAlertMessage, setCustomAlertMessage] = useState({
    type: '',
    message: '',
    handleClose: ''
  })


  const handleSectionClick = (item) => {
    const selectedChecks = JSON.parse(dataArray?.selected_checks || '[]');
    if(
      Array.isArray(selectedChecks) && selectedChecks?.every(check => item?.service_providers_api_list?.some(checkitem => checkitem?.check_id === check))
    ) {
      setModal(true);
      setCustomAlertMessage({
        type: 'Failed',
        message: `The checks you've selected are already included for this candidate. Please select different checks or proceed with "Skip and Continue".`,
        handleClose: () => setModal(false)
      })
    }
    else {
      if(item?.candidate_status === 'inprogress' || item?.candidate_status === 'accepted') {
        alert('Update is not allowed while the candidate is filling the form.')
      }
      else{
        setSelectedItem(item);
        setCandidateId(item?.candidate_id);
        setFormData({
          candidate_id: item?.candidate_id,
          client_info_id: item?.client_info_id,
          update_check_id: dataArray?.selected_checks,
          bgv_code: item?.bgv_code
        });
      }
    }
  };

  console.log('tocheck:::::::::::::::::', selectedItem)


  const handleUpdateCheck = () => {
    if (!selectedItem) {
      alert('Please select a candidate to update the checks');
      return;
    }
    setUpdateData((prevState) => {
      // Extract check_ids and display_names from the selectedItem
      const existingCheckIds = selectedItem.service_providers_api_list.map((provider) => provider.check_id);
      const existingNames = selectedItem.service_providers_api_list.map((provider) => provider.display_name);
    
      // Filter out prevState service_providers_api_list that are not in existingCheckIds
      const uniquePrevCheckIds = prevState.service_providers_api_list.filter(
        (check_id) => !existingCheckIds.includes(check_id)
      );
    
      // Filter out prevState selected_display_name that are not in existingNames
      const uniquePrevDisplayNames = prevState.selected_display_name.filter(
        (name) => !existingNames.includes(name)
      );
      
    
      // Prepare added_checks with unique serviceProviderNames
      const addedChecks = Array.from(new Set([...existingNames]));
    
      // Calculate the total valiant_rate_tax to be subtracted for matching check_ids
      const totalTaxToSubtract = selectedItem.service_providers_api_list
        .filter(provider => prevState.service_providers_api_list.includes(provider.check_id)) // Get matching providers
        .reduce((sum, provider) => sum + provider.valiant_rate_tax, 0);  // Sum up their valiant_rate_tax
    
        console.log(prevState.total_amount , totalTaxToSubtract);
      // Return the updated state with only the unique values
      return {
        ...prevState,
        first_name: selectedItem.first_name,
        last_name: selectedItem.last_name,
        job_seeker_id: selectedItem.job_seeker_id,
        candidate_phonenumber: selectedItem.phone_number,
        candidate_email: selectedItem.user_email,
        is_existing_user: 0,
        workorder_id: selectedItem.workorder_id,
        candidate_username: selectedItem.username,
        // Append prevState values that aren't in existingCheckIds + Append existingCheckIds
        service_providers_api_list: [
          ...uniquePrevCheckIds,   // Keep unique values from prevState
          ...existingCheckIds      // Append the new check_ids from selectedItem
        ],
        
        // Append prevState values that aren't in existingNames + Append existingNames
        selected_display_name: [
          ...uniquePrevDisplayNames,  // Keep unique values from prevState
          ...existingNames            // Append the new display_names from selectedItem
        ],
        
        // Subtract the calculated total tax from total_amount
        total_amount: prevState.total_amount - totalTaxToSubtract,
        
        // Add the new key `added_checks` with unique serviceProviderNames
        added_checks: addedChecks
      };
    });
    showPreview(true);
    modelClose();
    // Adjust the delay as needed
    
    // const { candidate_id, client_info_id, update_check_id, bgv_code } = formData;

    
    // const data = {
    //   candidate_id,
    //   client_info_id,
    //   update_check_id,
    //   bgv_code
    // };
    
    // const successFn = (response) => {
    //   console.log("checks updated", response.data);
    //   if(response.statusFlag){
    //     alert('Checks Updated')
    //     modelClose();
    //   }
    // };

    // const failureFn = (err) => {
    //   console.log("Logged-In Failed");
      
    // };
    // dispatch(updateServiceProviderChecks({ data, successFn, failureFn }));
  };


  return (
    <main className='UpdateConfirmationModal'>
      <section className='Modal'>
        <h1>Update Confirmation</h1>
        <span>Heads Up! We have found existing details that match your provided data. <br/>Please update the information if necessary, or click the <b>Skip & Continue</b> button to proceed as new entry.</span>
        <article className='CandidatesList'>
          {dataArray && dataArray?.Existing_user && dataArray?.Existing_user?.map((item, index) => (
            <div className={`Candidate ${selectedItem?.candidate_id === item?.candidate_id ? 'Selected' : ''} ${(item?.candidate_status === 'inprogress' || item?.candidate_status === 'accepted') ? 'NotAllowed' : ''}`} key={index} onClick={() => handleSectionClick(item)}>
              <div className='Values'>
                <div className='LabelValue'>
                  <span className='Label'>Name</span>
                  <span className='Value'>{item?.candidate_name}</span>
                </div>
                <div className='LabelValue'>
                  <span className='Label'>Jobseeker Id</span>
                  <span className='Value'>{item?.job_seeker_id}</span>
                </div>
                <div className='LabelValue'>
                  <span className='Label'>Phone Number</span>
                  <span className='Value'>{item?.phone_number}</span>
                </div>
                <div className='LabelValue'>
                  <span className='Label'>Pack Name</span>
                  <span className='Value Ellipsis'>{item?.bgv_code}</span>
                </div>
                <div className='LabelValue'>
                  <span className='Label'>Status</span>
                  <span className={`Value Status ${item?.candidate_status}`}>{item?.candidate_status}</span>
                </div>
              </div>
              <div className='ChecksCont'>
                <h3>Check details</h3>
                {item?.service_providers_api_list && item?.service_providers_api_list?.map((data, index) => (
                  <div className='Check' key={index}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 28 28" fill="none">
                      <path fillRule="evenodd" clipRule="evenodd" d="M5 0C2.23858 0 0 2.23858 0 5V23C0 25.7614 2.23858 28 5 28H23C25.7614 28 28 25.7614 28 23V5C28 2.23858 25.7614 0 23 0H5ZM11.3877 16.8182L19.812 8L21 9.26276L11.9749 18.6913C11.8062 18.8597 11.5834 19 11.3809 19C11.1784 19 10.9489 18.8597 10.7801 18.6843L7 14.7557L8.20154 13.507L11.3877 16.8182Z" fill="#102C97"/>
                    </svg>
                    <span title={data?.display_name}>
                      {data?.display_name}
                    </span>
                  </div>
                ))}
              </div>
              {item?.is_do_it_by_yourself === 1 && <span className='DIMyself'>Do it by myself</span>}
            </div>
          ))}
        </article>
        <article className='ButtonContainer'>
          <button className='Skip' onClick={handleSkipContinue}>Skip & Continue</button>
          <button onClick={handleUpdateCheck} disabled={!selectedItem}>
            {updateChecksLoading ? <ButtonLoader/> : 'Update Checks'}
          </button>
        </article>
      </section>
      {Modal && <CustomAlert {...CustomAlertMessage}/>}
    </main>
  )
}

export default UpdateConfirmationModal