import React, { useState } from 'react';
import './BusinessOverView.scss';
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from '../../../ReusableComponents/CustomAlert';
import { addCompanyOverview } from '../../../../redux/actions/CompanyRegisterationAction';
import ButtonSpinner from '../../../ReusableComponents/ButtonSpinner';

function BusinessOverView() {
  const { component } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const deletedClass =  component === "details";
  const clientInfoID = localStorage.getItem("client_info_id");
  const [data, setData] = useState({
    client_info_id: clientInfoID,
    type_of_client: '',
    client_category: '',
    client_description: ''
  })
  const submitLoading = useSelector((state) => state.addCompanyOverview.loading)
  const [SuccessFailed, setSuccessFailed] = useState('Success')
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState('')
  const [Alert, setAlert] = useState(false)
  const handleClose = () => {
    setAlert(false)
    navigate("/company-register/details")
  }
  
  const CompanyCategory = {
    'Technology': ['Software', 'Hardware', 'Internet Services', 'Telecommunications'],
    'Retail': ['E-commerce', 'Brick-and-mortar', 'Speciality', 'Department Store'],
    'Finance': ['Banking', 'Investment', 'Insurance', 'Fintech'],
    'Healthcare': ['Hospitals', 'Pharmaceuticals', 'Biotechnology', 'Medical Devices'],
    'Manufacturing': ['Automotive', 'Aerospace', 'Consumer Goods', 'Industrial Equipment'],
    'Transportation': ['Airlines', 'Shipping', 'Logistics', 'Public Transit'],
    'Hospitality': ['Hotels', 'Restaurants', 'Tourism', 'Event Management'],
    'Energy': ['Oil and Gas', 'Renewable Energy', 'Utilities', 'Energy Services'],
    'Education': ['Schools and Universities', 'EdTech', 'Training and Development', 'Publishing'],
    'Media and Entertainment': ['Broadcasting', 'Film and Television', 'Gaming', 'Social Media'],
    // 'Others': ['Others'],
  }

  const handleInputChange = (e, key) => {
    const value = e.target.value;
    if (key === 'client_category') {
      // Reset type_of_client when client_category changes
      setData((prev) => ({
        ...prev,
        [key]: value,
        type_of_client: '', // Reset type_of_client
        showTypeOfCompanyInput: value === 'Others', // Show input if Others is selected
      }));
    } else if (key === 'type_of_client' && value === 'Others') {
      // If Others is selected in Type of Company, show input box for Type of Company
      setData((prev) => ({
        ...prev,
        [key]: value,
        showTypeOfCompanyInput: true,
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [key]: value,
        showTypeOfCompanyInput: false,
      }));
    }
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    
    // Perform validation
    if (!data.type_of_client || !data.client_category || !data.client_description) {
      alert('Please fill out all fields.');
      return;
    }


    const successFn = (response) => {
      console.log("Response:", response);
      if(response.statusFlag){
        // alert(response.message)
        // handleNext('overview');
        // navigate('/companyregistration/businessdetails');
        setSuccessFailed('Success')
        setSuccessFailedMessage(response.message)
        setAlert(true)
      }
      else{
        // alert(response.message)
        setSuccessFailed('Success')
        setSuccessFailedMessage(response.message)
        setAlert(true)
      }
    };
    const failureFn = () => {
      console.log("Logged-In Failed");
    };

    dispatch(addCompanyOverview({ data: data, successFn: successFn, failureFn: failureFn }));


  };



  return (
    <div className={`${deletedClass ? 'deletedComp' : 'overviewBg'} ${component === 'overview' ? 'active' : 'next'}`}>
      <h1>Business OverView</h1>
      <form onSubmit={handleFormSubmit}>
  <div className="inputContainers">
    <div>
      <label htmlFor="Company Category">Company Category</label>
      <select
        id="Company Category"
        value={data.client_category}
        onChange={(e) => handleInputChange(e, 'client_category')}
        required
      >
        <option value={''} disabled>--Select one option--</option>
        {Object.keys(CompanyCategory).map((key, index) => (
          <option key={index} value={key}>
            {key}
          </option>
        ))}
        <option value="Others">Others</option>
      </select>
    </div>

    <div>
      <label htmlFor="Type of Company">Type of Company</label>
      {data.client_category === "Others" ? (
        <div>
          <input
            type="text"
            placeholder="Specify Type"
            value={data.type_of_client}
            onChange={(e) => handleInputChange(e, 'type_of_client')}
            required
          />
        </div>
      ) : (
        <select
          id="Type of Company"
          value={data.type_of_client}
          onChange={(e) => handleInputChange(e, 'type_of_client')}
          required
        >
          <option value={''} disabled>
            --Select one option--
          </option>
          {data.client_category &&
            CompanyCategory[data.client_category].map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
        </select>
      )}
    </div>

    <input
      type="text"
      placeholder="Company Description"
      id="Company Description"
      value={data.client_description}
      onChange={(e) => handleInputChange(e, 'client_description')}
      required
    />
  </div>

  <button type="submit" disabled={submitLoading}>
  {submitLoading ? <ButtonSpinner/> : 'Next'}
  </button>
</form>
      {Alert &&
        <CustomAlert type={SuccessFailed} message={SuccessFailedMessage} handleClose={handleClose}/>
      }
    </div>
  )
}

export default BusinessOverView;
