import React, { useEffect, useMemo, useRef, useState } from "react";
import "./ClientCandidateReport.scss";
import { ClientCandidateSearch } from "../redux/actions/ClientAction";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import FullPageLoader from "../Components/Loader/FullPageLoader";
import dayjs from "dayjs";
import {
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { createCustomtablesInfo } from "../redux/actions/VendorCandidateDetails";

const ClientCandidateReport = ({ handleView }) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [hiddenColumn, setHiddenColumn] = useState({});
  const [tempHiddenColumn, setTempHiddenColumn] = useState({});
  const [sorting, setSorting] = useState([]);
  const [columnOrder, setColumnOrder] = useState([]);
  const [columnFilters, setColumnFilters] = useState([]);
  const [manualColumnFilters, setManualColumnFilters] = useState([]);
  const [searchParams] = useSearchParams();
  const query = searchParams.get("query");
  const [globalFilter, setGlobalFilter] = useState(query || "");
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [pagination, setPagination] = useState({
    pageIndex: 1,
    pageSize: 25,
  });
  const [selectedStatus, setSelectedStatus] = useState("");
  const [trigger, setTrigger] = useState(0);
  const [columnData, setColumnData] = useState([]);
  const [rowCount, setRowCount] = useState(1);

  const dispatch = useDispatch();
  const hasMounted = useRef(false);

  const SearchLoading = useSelector(
    (state) => state.ClientCandidateSearch?.loading
  );

  const [Details, setDetails] = useState([]);
  console.log(
    "SearchLoading",
    SearchLoading,
    Details.length,
    SearchLoading && !!Details.length
  );
  const navigate = useNavigate();
  const darkMode = localStorage.getItem("Theme") === "true" ? true : false;
  const [TotalNoOfPages, setTotalNoOfPages] = useState("");
  const [itemsPerPage, setitemsPerPage] = useState(25);
  const [RequestPage, setRequestPage] = useState(1);
  const Offset = RequestPage * itemsPerPage - itemsPerPage;
  const ellipsis = "...";
  const maxVisiblePages = 5;
  const totalPages = Math.ceil(TotalNoOfPages);
  const getMRTTheme = (darkMode) => ({
    baseBackgroundColor: darkMode ? "#F5F5F5" : "#f4f6f8",
    draggingBorderColor: darkMode ? "#444444" : "#cccccc",
    matchHighlightColor: darkMode ? "#ffeb3b" : "#ffeb3b",
    menuBackgroundColor: darkMode ? "#2c2c2c" : "#ffffff",
    pinnedRowBackgroundColor: darkMode ? "#3c3c3c" : "#e0e0e0",
    selectedRowBackgroundColor: darkMode ? "#424242" : "#d1eaff",
  });

  let pageNumbers = [];
  if (totalPages <= maxVisiblePages) {
    pageNumbers = Array.from({ length: totalPages }, (_, i) => i + 1);
  } else {
    const middlePage = Math.ceil(maxVisiblePages / 2);
    const startPage = Math.max(1, RequestPage - middlePage);
    const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);
    pageNumbers = Array.from(
      { length: endPage - startPage + 1 },
      (_, i) => startPage + i
    );
    if (startPage > 1) {
      pageNumbers.unshift(1, ellipsis);
    }
    if (endPage < totalPages) {
      pageNumbers.push(ellipsis, totalPages);
    }
  }

  const handleItemsPerPageChange = (event) => {
    const selectedValue = parseInt(event.target.value, 10);
    setRequestPage(1);
    setitemsPerPage(selectedValue);
  };

  const createCustomtablesInfoFn = (newOrder) => {
    // const clientInfoID = localStorage.getItem("client_info_id");
    const masterID = localStorage.getItem("user_id");
    // const clientId = localStorage.getItem("user_id");

    const data = {
      custom_table_name: newOrder,
      client: masterID,
      master: masterID,
      client_info: "",
      // defaulttable_info: "1",
      module_name: "report_details",
    };

    const successFn = (response) => {
      console.log(
        "recent search Data fetched successfully",
        response.data.Over_all_data
      );
      setTrigger(trigger + 1);
    };

    const failureFn = (error) => {
      // setIsLoading(false);
      // setIsError(true);
      // console.error("Logged-in failed", error);
      alert(error);
    };

    dispatch(createCustomtablesInfo({ data, successFn, failureFn }));
  };

  const columns = useMemo(
    () => [
      {
        accessorKey: "full_name",
        header: "Candidate Name",
        size: 225,
        enableHiding: false,
      },
      {
        accessorKey: "job_seeker_id",
        header: "Job Seeker id",
      },

      {
        accessorKey: "request_number",
        header: "Request Number",
      },
      {
        accessorKey: "initiated_time",
        header: "Created at",
        filterVariant: "date-range",
        Cell: ({ cell, row }) => {
          // console.log(row.original);
          const value = cell.getValue();
          const date = dayjs(value, "DD-MM-YYYY HH:mm:ss Z");
          return date.isValid() ? date.format("MM/DD/YYYY") : "Invalid Date";
        },
        size: 340,
      },
      {
        accessorKey: "case_pending_time",
        header: "Initiated Date",
        // filterVariant: "date-range",
        filterVariant: "date-range",
        Cell: ({ cell }) => {
          const value = cell.getValue();
          const date = dayjs(value);
          return date.isValid() ? date.format("MM/DD/YYYY") : "No data";
        },
        size: 340,
      },
      {
        accessorKey: "bgv_code",
        header: "Pack Name",
      },
      {
        accessorKey: "candidate_status",
        header: "Current Status",
        filterVariant: "multi-select",
        enableSorting: false,
        filterSelectOptions: [
          // { label: "Invitation", value: "invitation" },
          { label: "Accepted", value: "accepted" },
          { label: "Re-initiated", value: "re-initiated" },
          { label: "In Progress", value: "inprogress" },
          { label: "Pending", value: "pending" },
          { label: "Cancelled", value: "cancelled" },
          { label: "Completed", value: "completed" },
        ],
        Cell: ({ cell }) => {
          const status = cell.getValue();
          return (
            <>
              <div className={`status ${status}`}>{status}</div>
            </>
          );
        },
      },
      {
        accessorKey: "client_name",
        header: "Vendor Name",
        enableSorting: false,
      },
      {
        accessorKey: "user_email",
        header: "Email",
      },
      {
        accessorKey: "user_phone",
        header: "Phone",
      },
      {
        id: "mrt-row-actions",
        header: "Actions",
        enableHiding: false,
        enablePinning: true,
        enableColumnOrdering: false,
        size: 150,
        Cell: ({ row }) => (
          <div className='Actions'>
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='21'
              height='20'
              viewBox='0 0 21 20'
              fill='none'
              onClick={() => {
                handleView(row.original.username, row.original.client_info);
                navigate(
                  `/client/dashboard/report/${row.original.username}/${row.original.client_info}`
                );
              }}>
              <path
                d='M10.4999 4.6875C11.9964 4.68853 13.4636 5.10285 14.7395 5.88477C16.0155 6.66669 17.0508 7.78582 17.7311 9.11875C17.87 9.39175 17.9424 9.69371 17.9424 10C17.9424 10.3063 17.87 10.6082 17.7311 10.8812C17.0493 12.2121 16.0134 13.329 14.7375 14.109C13.4616 14.8889 11.9953 15.3016 10.4999 15.3016C9.00451 15.3016 7.53813 14.8889 6.26225 14.109C4.98637 13.329 3.95047 12.2121 3.26863 10.8812C3.12977 10.6082 3.05739 10.3063 3.05739 10C3.05739 9.69371 3.12977 9.39175 3.26863 9.11875C3.94897 7.78582 4.98422 6.66669 6.26022 5.88477C7.53621 5.10285 9.00337 4.68853 10.4999 4.6875ZM4.38113 10.3125C4.95807 11.4386 5.8346 12.3837 6.91419 13.0436C7.99379 13.7036 9.23456 14.0528 10.4999 14.0528C11.7652 14.0528 13.006 13.7036 14.0856 13.0436C15.1652 12.3837 16.0417 11.4386 16.6186 10.3125C16.6735 10.2175 16.7024 10.1097 16.7024 10C16.7024 9.89029 16.6735 9.78251 16.6186 9.6875C16.0417 8.56136 15.1652 7.61629 14.0856 6.95636C13.006 6.29642 11.7652 5.94723 10.4999 5.94723C9.23456 5.94723 7.99379 6.29642 6.91419 6.95636C5.8346 7.61629 4.95807 8.56136 4.38113 9.6875C4.32628 9.78251 4.2974 9.89029 4.2974 10C4.2974 10.1097 4.32628 10.2175 4.38113 10.3125Z'
                fill='#102C97'
              />
              <path
                d='M10.5 7.1875C11.0563 7.1875 11.6 7.35245 12.0625 7.66149C12.5251 7.97053 12.8855 8.40979 13.0984 8.9237C13.3113 9.43762 13.367 10.0031 13.2585 10.5487C13.1499 11.0943 12.8821 11.5954 12.4887 11.9887C12.0954 12.3821 11.5943 12.6499 11.0487 12.7585C10.5031 12.867 9.93762 12.8113 9.4237 12.5984C8.90978 12.3855 8.47053 12.0251 8.16149 11.5625C7.85245 11.1 7.6875 10.5563 7.6875 10C7.68915 9.25459 7.986 8.54017 8.51308 8.01309C9.04017 7.486 9.75458 7.18915 10.5 7.1875ZM10.5 11.5625C10.809 11.5625 11.1111 11.4709 11.3681 11.2992C11.625 11.1275 11.8253 10.8835 11.9436 10.5979C12.0618 10.3124 12.0928 9.99827 12.0325 9.69517C11.9722 9.39208 11.8234 9.11367 11.6049 8.89515C11.3863 8.67663 11.1079 8.52781 10.8048 8.46752C10.5017 8.40723 10.1876 8.43818 9.90206 8.55644C9.61655 8.6747 9.37252 8.87497 9.20083 9.13192C9.02914 9.38887 8.9375 9.69097 8.9375 10C8.9375 10.4144 9.10212 10.8118 9.39514 11.1049C9.68817 11.3979 10.0856 11.5625 10.5 11.5625Z'
                fill='#102C97'
              />
            </svg>
            {row?.original?.court_record_url && (
              <a
                href={row?.original?.court_record_url}
                target='_blank'
                rel='noopener noreferrer'
                title='Court Check Report'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='21'
                  viewBox='0 0 20 21'
                  fill='none'>
                  <path
                    d='M0.713867 19.1562V19.9128H19.4551V19.1562H0.713867Z'
                    fill='#102C98'
                  />
                  <path
                    d='M1.64746 17.6445V18.4011H18.5225V17.6445H1.64746Z'
                    fill='#102C98'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M2.89746 7.42969V7.99711C2.89746 8.19776 2.96331 8.3902 3.08052 8.53208C3.19773 8.67396 3.3567 8.75367 3.52246 8.75367V15.5627C3.3567 15.5627 3.19773 15.6424 3.08052 15.7843C2.96331 15.9262 2.89746 16.1186 2.89746 16.3193V16.8867H5.70996V16.3193C5.70996 16.1186 5.64411 15.9262 5.5269 15.7843C5.40969 15.6424 5.25072 15.5627 5.08496 15.5627V8.75367C5.25072 8.75367 5.40969 8.67396 5.5269 8.53208C5.64411 8.3902 5.70996 8.19776 5.70996 7.99711V7.42969H2.89746ZM8.67871 7.42969V7.99711C8.67871 8.19776 8.74456 8.3902 8.86177 8.53208C8.97898 8.67396 9.13795 8.75367 9.30371 8.75367V15.5627C9.13795 15.5627 8.97898 15.6424 8.86177 15.7843C8.74456 15.9262 8.67871 16.1186 8.67871 16.3193V16.8867H11.4912V16.3193C11.4912 16.1186 11.4254 15.9262 11.3082 15.7843C11.1909 15.6424 11.032 15.5627 10.8662 15.5627V8.75367C11.032 8.75367 11.1909 8.67396 11.3082 8.53208C11.4254 8.3902 11.4912 8.19776 11.4912 7.99711V7.42969H8.67871Z'
                    fill='#102C98'
                  />
                  <path
                    d='M10.085 1L1.33496 5.91765V6.67422H18.835V5.91765L10.085 1ZM10.085 2.86871C10.3336 2.86871 10.5721 2.98827 10.7479 3.2011C10.9237 3.41392 11.0225 3.70257 11.0225 4.00355C11.0225 4.30453 10.9237 4.59318 10.7479 4.80601C10.5721 5.01883 10.3336 5.1384 10.085 5.1384C9.83632 5.1384 9.59786 5.01883 9.42205 4.80601C9.24623 4.59318 9.14746 4.30453 9.14746 4.00355C9.14746 3.70257 9.24623 3.41392 9.42205 3.2011C9.59786 2.98827 9.83632 2.86871 10.085 2.86871Z'
                    fill='#102C98'
                  />
                  <path
                    fillRule='evenodd'
                    clipRule='evenodd'
                    d='M14.46 7.42969V7.99711C14.46 8.19776 14.5258 8.3902 14.643 8.53208C14.7602 8.67396 14.9192 8.75367 15.085 8.75367V15.5627C14.9192 15.5627 14.7602 15.6424 14.643 15.7843C14.5258 15.9262 14.46 16.1186 14.46 16.3193V16.8867H17.2725V16.3193C17.2725 16.1186 17.2066 15.9262 17.0894 15.7843C16.9722 15.6424 16.8132 15.5627 16.6475 15.5627V8.75367C16.8132 8.75367 16.9722 8.67396 17.0894 8.53208C17.2066 8.3902 17.2725 8.19776 17.2725 7.99711V7.42969H14.46Z'
                    fill='#102C98'
                  />
                </svg>
              </a>
            )}
            {row?.original?.report_url && (
              <a
                href={row?.original?.report_url}
                target='_blank'
                rel='noopener noreferrer'
                title='Overall Report'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 128 128'
                  id='Report'>
                  <path
                    d='M28,127h72c7.2,0,13-5.8,13-13V37h0c0-0.8-0.3-1.5-0.9-2.1l-33-33C78.3,1,77,0.8,75.9,1.2C74.7,1.7,74,2.8,74,4v23c0,7.2,5.8,13,13,13h20v74c0,3.9-3.1,7-7,7H28c-3.9,0-7-3.1-7-7V14c0-3.9,3.1-7,7-7h34c1.7,0,3-1.3,3-3s-1.3-3-3-3H28c-7.2,0-13,5.8-13,13v100C15,121.2,20.8,127,28,127z'
                    fill='#102C98'></path>
                  <path
                    d='M39 91v10c0 2.8 2.2 5 5 5s5-2.2 5-5V91c0-2.8-2.2-5-5-5S39 88.2 39 91zM59 71v30c0 2.8 2.2 5 5 5s5-2.2 5-5V71c0-2.8-2.2-5-5-5S59 68.2 59 71zM89 101V81c0-2.8-2.2-5-5-5s-5 2.2-5 5v20c0 2.8 2.2 5 5 5S89 103.8 89 101z'
                    fill='#102C98'
                  />
                </svg>
              </a>
            )}
          </div>
        ),
      },
    ],
    []
  );

  const table = useMaterialReactTable({
    columns,
    data: Details,
    enableColumnOrdering: true,
    enableColumnFilters: true,
    enableGlobalFilter: true,
    enableColumnPinning: true,
    paginationDisplayMode: "pages",
    enableStickyHeader: true,
    muiTableContainerProps: { sx: { maxHeight: "calc(100% - 7rem)" } },
    getRowId: (row) => row.user_id,
    initialState: {
      density: "compact",
      columnPinning: {
        right: ["mrt-row-actions"],
      },
      columnOrder: columnOrder,
    },
    // enableRowActions: true,

    muiCircularProgressProps: {
      Component: <FullPageLoader />,
    },
    // displayColumnDefOptions: {
    //   "mrt-row-actions": {
    //     size: 150,
    //     grow: false,
    //   },
    // },
    enableDensityToggle: true,
    manualFiltering: true,
    onGlobalFilterChange: setGlobalFilter,
    onColumnFiltersChange: setColumnFilters,
    manualPagination: true,
    manualSorting: true,
    onSortingChange: setSorting,
    onColumnOrderChange: (newOrder) => {
      console.log("newOrder", newOrder);
      const modifiedOrder = Array.isArray(newOrder)
        ? newOrder.map((col) => {
            const specificData = columnData?.Custom_tables?.find(
              (withVis) => withVis.key_name === col
            );
            return { ...specificData, key_name: col };
          })
        : [];
      console.log("modifiedOrder", modifiedOrder);
      if (hasMounted.current) {
        modifiedOrder && createCustomtablesInfoFn(modifiedOrder);
      }
      // createCustomtablesInfoFn(modifiedOrder);
    },
    muiToolbarAlertBannerProps: isError
      ? {
          color: "error",
          children: "Error loading data",
        }
      : undefined,
    enableColumnResizing: true,
    onPaginationChange: setPagination,
    rowCount,
    positionActionsColumn: "last",
    state: {
      isLoading,
      pagination,
      showAlertBanner: isError,
      columnFilters,
      globalFilter,
      sorting,
      columnOrder,
      columnVisibility: hiddenColumn,
    },
    muiTopToolbarProps: {
      sx: {
        background: darkMode ? "#333333" : "#ffffff",
        color: darkMode ? "#ffffff" : "#333333",
      },
    },
    mrtTheme: getMRTTheme(darkMode),
    onColumnVisibilityChange: setTempHiddenColumn,
    localization: {
      filterFuzzy: "Filtering by",
    },
  });

  // useEffect(() => {
  //   setPagination({ ...pagination, pageIndex: 0 });
  //   setSelectedStatus(status);
  //   setTrigger(trigger + 1);
  // }, [status]);
  useEffect(() => {
    hasMounted.current = true;
  }, []);
  useEffect(() => {
    const newDefaultOrder = columnData?.Default_tables?.map(
      (column) => column?.key_name
    );
    const newCustomOrder = columnData?.Custom_tables?.map(
      (column) => column?.key_name
    );
    console.log(
      "columnData?.Default_tables",
      columnData?.Default_tables,
      newDefaultOrder,
      newCustomOrder
    );
    setColumnOrder(
      newCustomOrder?.length === 0
        ? //  newDefaultOrder : newDefaultOrder

          newDefaultOrder
        : newCustomOrder
    );
    const visibilityChanges = columnData?.Custom_tables?.reduce((acc, item) => {
      acc[item.key_name] = item.visibility === false ? false : true;
      return acc;
    }, {});
    console.log("visibilityChanges", visibilityChanges);
    setHiddenColumn(visibilityChanges);
  }, [columnData]);
  useEffect(() => {
    const updatedColumnData = columnData?.Custom_tables?.map((column) => {
      if (tempHiddenColumn.hasOwnProperty(column.key_name)) {
        return {
          ...column,
          visibility: tempHiddenColumn[column.key_name],
        };
      }
      return column;
    });

    if (hasMounted.current) {
      updatedColumnData && createCustomtablesInfoFn(updatedColumnData);
    }
  }, [tempHiddenColumn]);
  useEffect(() => {
    setPagination({ ...pagination, pageIndex: 0 });
    const formattedData = columnFilters
      .map((item) => {
        if (
          (item.id === "initiated_time" || item.id === "case_pending_time") &&
          Array.isArray(item.value)
        ) {
          const isValidDate = (date) => {
            if (!(date instanceof Date) || isNaN(date.getTime())) {
              return false;
            }
            const year = date.getFullYear();
            return year.toString().length === 4;
          };

          const [startDateStr, endDateStr] = item.value;
          const startDate = startDateStr ? new Date(startDateStr) : null;
          const endDate = endDateStr ? new Date(endDateStr) : null;
          if (!isValidDate(startDate) && !isValidDate(endDate)) {
            return null;
          }
          console.log("startDate", startDate);
          return {
            ...item,
            value: {
              start_date: isValidDate(startDate)
                ? startDate.toISOString().split("T")[0]
                : null,
              end_date: isValidDate(endDate)
                ? endDate.toISOString().split("T")[0]
                : null,
            },
          };
        } else if (item.id === "candidate_status") {
          setSelectedStatus(item.value);
          return;
        }
        return item;
      })
      .filter(Boolean);
    const hasCandidateStatus = columnFilters.some(
      (item) => item.id === "candidate_status"
    );
    if (!hasCandidateStatus) {
      setSelectedStatus([]);
    }
    setManualColumnFilters(formattedData || []);
    console.log(formattedData);
    console.log("columnFilters", columnFilters);
  }, [columnFilters]);

  const GetCandidates = () => {
    const MasterId = localStorage.getItem("user_id");
    setIsLoading(true);
    const data = {
      master_id: MasterId,
      client_id: MasterId,
      module_name: "report_details",
      search_query: globalFilter || "",
      sorting_query: sorting?.[0]?.id,
      sorting_type: sorting?.[0]?.desc ? "False" : "True",
      filter_queries: manualColumnFilters,
      user_status: selectedStatus,
      offset: pagination.pageIndex * pagination.pageSize,
      limits: pagination.pageSize,
    };

    const successFn = (response) => {
      setDetails(response.data.Over_all_data);
      setTotalNoOfPages(
        Math.ceil(response.data.Candidate_count / itemsPerPage)
      );
      setRowCount(Math.ceil(response.data.Candidate_count));
      setIsLoading(false);
      setColumnData(response.data || []);
    };

    const failureFn = (error) => {
      console.error("Logged-in failed", error);
      setIsLoading(false);
    };

    dispatch(ClientCandidateSearch({ data, successFn, failureFn }));
  };
  console.log("rowCount", rowCount);

  useEffect(() => {
    GetCandidates();
  }, [
    itemsPerPage,
    RequestPage,
    pagination,
    trigger,
    manualColumnFilters,
    selectedStatus,
    globalFilter,
    sorting,
  ]);

  useEffect(() => {
    if (query) {
      setGlobalFilter(query);
    } else {
      setGlobalFilter("");
    }
  }, [searchParams]);

  const handleSearch = (e) => {
    e.preventDefault();
    if (RequestPage !== 1) {
      setRequestPage(1);
    } else {
      setRequestPage(1);
      GetCandidates();
    }
  };

  useEffect(() => {
    if (!searchTerm) {
      GetCandidates();
    }
  }, [searchTerm]);

  return (
    <main className='ClientCandidateReport'>
      {!!Details.length || !!columnFilters.length || !!globalFilter ? (
        <section className='Table'>
          <section className='TableContainer'>
            {/* <table>
              <thead>
                <tr>
                  <th>Candidate Name</th>
                  <th>Job Seeker Id</th>
                  <th>Request Number</th>
                  <th>Created At</th>
                  <th>Initiated Date</th>
                  <th>Pack Name</th>
                  <th>Current Status</th>
                  <th>Vendor Name</th>
                  <th>Email - ID</th>
                  <th>Phone</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {Details &&
                  Details.map((candidate, index) => (
                    <tr key={index}>
                      <td
                        className='NameReport'
                        onClick={() => {
                          handleView(candidate.username, candidate.client_info);
                          navigate(
                            `/client/dashboard/report/${candidate.username}/${candidate.client_info}`
                          );
                        }}>
                        {candidate.full_name}
                      </td>
                      <td>{candidate?.job_seeker_id}</td>
                      <td>{candidate?.request_number}</td>
                      <td>
                        {candidate.initiated_time
                          ? candidate.initiated_time?.slice(0, 10)
                          : ""}
                      </td>
                      <td>
                        {candidate.case_pending_time
                          ? candidate.case_pending_time?.slice(0, 10)
                          : ""}
                      </td>
                      <td
                        title={
                          candidate.bgv_code &&
                          Array.isArray(candidate.bgv_code) &&
                          candidate.bgv_code?.length > 0
                            ? candidate.bgv_code
                                ?.map((item) =>
                                  Array.isArray(item) ? item.join(", ") : item
                                )
                                .join(", ")
                            : "-"
                        }>
                        {candidate.bgv_code &&
                        Array.isArray(candidate.bgv_code) &&
                        candidate.bgv_code?.length > 0
                          ? candidate.bgv_code
                              ?.map((item) =>
                                Array.isArray(item) ? item.join(", ") : item
                              )
                              .join(", ")
                          : "-"}
                      </td>
                      <td>
                        <span
                          className={`status ${candidate.candidate_status}`}>
                          {candidate.candidate_status}
                        </span>
                      </td>
                      <td title={candidate?.client_name}>
                        {candidate?.client_name}
                      </td>
                      <td>{candidate?.user_email}</td>
                      <td>{candidate?.user_phone}</td>
                      <td className='Actions'>
                        <svg
                          xmlns='http://www.w3.org/2000/svg'
                          width='21'
                          height='20'
                          viewBox='0 0 21 20'
                          fill='none'
                          onClick={() => {
                            handleView(
                              candidate.username,
                              candidate.client_info
                            );
                            navigate(
                              `/client/dashboard/report/${candidate.username}/${candidate.client_info}`
                            );
                          }}>
                          <path
                            d='M10.4999 4.6875C11.9964 4.68853 13.4636 5.10285 14.7395 5.88477C16.0155 6.66669 17.0508 7.78582 17.7311 9.11875C17.87 9.39175 17.9424 9.69371 17.9424 10C17.9424 10.3063 17.87 10.6082 17.7311 10.8812C17.0493 12.2121 16.0134 13.329 14.7375 14.109C13.4616 14.8889 11.9953 15.3016 10.4999 15.3016C9.00451 15.3016 7.53813 14.8889 6.26225 14.109C4.98637 13.329 3.95047 12.2121 3.26863 10.8812C3.12977 10.6082 3.05739 10.3063 3.05739 10C3.05739 9.69371 3.12977 9.39175 3.26863 9.11875C3.94897 7.78582 4.98422 6.66669 6.26022 5.88477C7.53621 5.10285 9.00337 4.68853 10.4999 4.6875ZM4.38113 10.3125C4.95807 11.4386 5.8346 12.3837 6.91419 13.0436C7.99379 13.7036 9.23456 14.0528 10.4999 14.0528C11.7652 14.0528 13.006 13.7036 14.0856 13.0436C15.1652 12.3837 16.0417 11.4386 16.6186 10.3125C16.6735 10.2175 16.7024 10.1097 16.7024 10C16.7024 9.89029 16.6735 9.78251 16.6186 9.6875C16.0417 8.56136 15.1652 7.61629 14.0856 6.95636C13.006 6.29642 11.7652 5.94723 10.4999 5.94723C9.23456 5.94723 7.99379 6.29642 6.91419 6.95636C5.8346 7.61629 4.95807 8.56136 4.38113 9.6875C4.32628 9.78251 4.2974 9.89029 4.2974 10C4.2974 10.1097 4.32628 10.2175 4.38113 10.3125Z'
                            fill='#102C97'
                          />
                          <path
                            d='M10.5 7.1875C11.0563 7.1875 11.6 7.35245 12.0625 7.66149C12.5251 7.97053 12.8855 8.40979 13.0984 8.9237C13.3113 9.43762 13.367 10.0031 13.2585 10.5487C13.1499 11.0943 12.8821 11.5954 12.4887 11.9887C12.0954 12.3821 11.5943 12.6499 11.0487 12.7585C10.5031 12.867 9.93762 12.8113 9.4237 12.5984C8.90978 12.3855 8.47053 12.0251 8.16149 11.5625C7.85245 11.1 7.6875 10.5563 7.6875 10C7.68915 9.25459 7.986 8.54017 8.51308 8.01309C9.04017 7.486 9.75458 7.18915 10.5 7.1875ZM10.5 11.5625C10.809 11.5625 11.1111 11.4709 11.3681 11.2992C11.625 11.1275 11.8253 10.8835 11.9436 10.5979C12.0618 10.3124 12.0928 9.99827 12.0325 9.69517C11.9722 9.39208 11.8234 9.11367 11.6049 8.89515C11.3863 8.67663 11.1079 8.52781 10.8048 8.46752C10.5017 8.40723 10.1876 8.43818 9.90206 8.55644C9.61655 8.6747 9.37252 8.87497 9.20083 9.13192C9.02914 9.38887 8.9375 9.69097 8.9375 10C8.9375 10.4144 9.10212 10.8118 9.39514 11.1049C9.68817 11.3979 10.0856 11.5625 10.5 11.5625Z'
                            fill='#102C97'
                          />
                        </svg>
                        {candidate?.court_record_url && (
                          <a
                            href={candidate?.court_record_url}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Court Check Report'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='20'
                              height='21'
                              viewBox='0 0 20 21'
                              fill='none'>
                              <path
                                d='M0.713867 19.1562V19.9128H19.4551V19.1562H0.713867Z'
                                fill='#102C98'
                              />
                              <path
                                d='M1.64746 17.6445V18.4011H18.5225V17.6445H1.64746Z'
                                fill='#102C98'
                              />
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M2.89746 7.42969V7.99711C2.89746 8.19776 2.96331 8.3902 3.08052 8.53208C3.19773 8.67396 3.3567 8.75367 3.52246 8.75367V15.5627C3.3567 15.5627 3.19773 15.6424 3.08052 15.7843C2.96331 15.9262 2.89746 16.1186 2.89746 16.3193V16.8867H5.70996V16.3193C5.70996 16.1186 5.64411 15.9262 5.5269 15.7843C5.40969 15.6424 5.25072 15.5627 5.08496 15.5627V8.75367C5.25072 8.75367 5.40969 8.67396 5.5269 8.53208C5.64411 8.3902 5.70996 8.19776 5.70996 7.99711V7.42969H2.89746ZM8.67871 7.42969V7.99711C8.67871 8.19776 8.74456 8.3902 8.86177 8.53208C8.97898 8.67396 9.13795 8.75367 9.30371 8.75367V15.5627C9.13795 15.5627 8.97898 15.6424 8.86177 15.7843C8.74456 15.9262 8.67871 16.1186 8.67871 16.3193V16.8867H11.4912V16.3193C11.4912 16.1186 11.4254 15.9262 11.3082 15.7843C11.1909 15.6424 11.032 15.5627 10.8662 15.5627V8.75367C11.032 8.75367 11.1909 8.67396 11.3082 8.53208C11.4254 8.3902 11.4912 8.19776 11.4912 7.99711V7.42969H8.67871Z'
                                fill='#102C98'
                              />
                              <path
                                d='M10.085 1L1.33496 5.91765V6.67422H18.835V5.91765L10.085 1ZM10.085 2.86871C10.3336 2.86871 10.5721 2.98827 10.7479 3.2011C10.9237 3.41392 11.0225 3.70257 11.0225 4.00355C11.0225 4.30453 10.9237 4.59318 10.7479 4.80601C10.5721 5.01883 10.3336 5.1384 10.085 5.1384C9.83632 5.1384 9.59786 5.01883 9.42205 4.80601C9.24623 4.59318 9.14746 4.30453 9.14746 4.00355C9.14746 3.70257 9.24623 3.41392 9.42205 3.2011C9.59786 2.98827 9.83632 2.86871 10.085 2.86871Z'
                                fill='#102C98'
                              />
                              <path
                                fillRule='evenodd'
                                clipRule='evenodd'
                                d='M14.46 7.42969V7.99711C14.46 8.19776 14.5258 8.3902 14.643 8.53208C14.7602 8.67396 14.9192 8.75367 15.085 8.75367V15.5627C14.9192 15.5627 14.7602 15.6424 14.643 15.7843C14.5258 15.9262 14.46 16.1186 14.46 16.3193V16.8867H17.2725V16.3193C17.2725 16.1186 17.2066 15.9262 17.0894 15.7843C16.9722 15.6424 16.8132 15.5627 16.6475 15.5627V8.75367C16.8132 8.75367 16.9722 8.67396 17.0894 8.53208C17.2066 8.3902 17.2725 8.19776 17.2725 7.99711V7.42969H14.46Z'
                                fill='#102C98'
                              />
                            </svg>
                          </a>
                        )}
                        {candidate?.report_url && (
                          <a
                            href={candidate?.report_url}
                            target='_blank'
                            rel='noopener noreferrer'
                            title='Overall Report'>
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              width='16'
                              height='16'
                              viewBox='0 0 128 128'
                              id='Report'>
                              <path
                                d='M28,127h72c7.2,0,13-5.8,13-13V37h0c0-0.8-0.3-1.5-0.9-2.1l-33-33C78.3,1,77,0.8,75.9,1.2C74.7,1.7,74,2.8,74,4v23c0,7.2,5.8,13,13,13h20v74c0,3.9-3.1,7-7,7H28c-3.9,0-7-3.1-7-7V14c0-3.9,3.1-7,7-7h34c1.7,0,3-1.3,3-3s-1.3-3-3-3H28c-7.2,0-13,5.8-13,13v100C15,121.2,20.8,127,28,127z'
                                fill='#102C98'></path>
                              <path
                                d='M39 91v10c0 2.8 2.2 5 5 5s5-2.2 5-5V91c0-2.8-2.2-5-5-5S39 88.2 39 91zM59 71v30c0 2.8 2.2 5 5 5s5-2.2 5-5V71c0-2.8-2.2-5-5-5S59 68.2 59 71zM89 101V81c0-2.8-2.2-5-5-5s-5 2.2-5 5v20c0 2.8 2.2 5 5 5S89 103.8 89 101z'
                                fill='#102C98'
                              />
                            </svg>
                          </a>
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table> */}
            <MaterialReactTable table={table} />
          </section>
          {/* <section className='PaginationContainer'>
            <div className='Pagination'>
              <button
                className={`NextPrev ${RequestPage === 1 ? "disabled" : ""}`}
                disabled={RequestPage === 1}
                onClick={() => {
                  setRequestPage((prevPage) => prevPage - 1);
                }}>
                {" "}
                Prev{" "}
              </button>
              {pageNumbers.map((pageNumber, index) => (
                <React.Fragment key={index}>
                  {" "}
                  {pageNumber === ellipsis ? (
                    <span> {ellipsis} </span>
                  ) : (
                    <button
                      className={`PageNo ${
                        pageNumber === RequestPage ? "disabled CurrentPage" : ""
                      }`}
                      onClick={() => {
                        setRequestPage(pageNumber);
                      }}
                      disabled={pageNumber === RequestPage}>
                      {" "}
                      {pageNumber}{" "}
                    </button>
                  )}
                </React.Fragment>
              ))}
              <button
                className={`NextPrev ${
                  RequestPage === TotalNoOfPages ? "disabled" : ""
                }`}
                disabled={RequestPage === TotalNoOfPages}
                onClick={() => {
                  setRequestPage((prevPage) => prevPage + 1);
                }}>
                {" "}
                Next{" "}
              </button>
            </div>
            <div className='ItemsPerPage'>
              <label className='OptionsLabel'> Count </label>
              <select
                className='Options'
                value={itemsPerPage}
                onChange={handleItemsPerPageChange}>
                <option value={25}> 25 </option>
                <option value={50}> 50 </option>
                <option value={100}> 100 </option>
              </select>
            </div>
          </section> */}
        </section>
      ) : !SearchLoading && searchTerm ? (
        <span>
          No Candidates are available for the Searched Criteria{" "}
          <b>{`"${searchTerm}"`}</b>{" "}
        </span>
      ) : SearchLoading ? (
        <FullPageLoader />
      ) : (
        <MaterialReactTable table={table} />
      )}
    </main>
  );
};

export default ClientCandidateReport;
