import React, { useEffect, useState } from "react";
import "./InvoiceModal.scss";
// import { useDispatch } from "react-redux";
// import { fetchCandidateTransactionDetails } from "../../../redux/actions/CompanyBillingDetailsAction";

function InvoiceModal({ selectedOption, data, setOpenModal }) {
  console.log("data298719828309128309183", data);
  // const dispatch = useDispatch();
  // const [transactionDetails, setTransactionDetails] = useState([]);
  const splitChecks = data?.client_checks?.split_value;
  const listofChecks =
    selectedOption === "Invoice"
      ? Object.keys(data?.client_checks?.split_value)
      : data?.service_apicheck;
  // const callfetchCandidateTransactionDetails = () => {
  //   data = {
  //     order_id: data?.order_id,
  //   };
  //   const successFn = (res) => setTransactionDetails(res.data);
  //   const failureFn = (err) => console.log(err);
  //   dispatch(fetchCandidateTransactionDetails({ data, successFn, failureFn }));
  // };
  // useEffect(() => {
  //   if (selectedOption !== "Invoice") callfetchCandidateTransactionDetails();
  // }, []);
  // const candidateDetails =
  //   selectedOption !== "Invoice" ? transactionDetails?.candidate_details[0] : {};

  return (
    <div className='modalBackGround'>
      {selectedOption === "Invoice" ? (
        <div className='modalContainer'>
          <h1>
            Billing Breakdown
            {/* <button className="closeBtn" onClick={() => setOpenModal((prev) => !prev)}>
            Close
        </button> */}
          </h1>
          <div className='invoiceDetails'>
            <div>
              <p>Invoice Id</p>
              <p>{data?.client_invoice_id}</p>
            </div>
            {/* <div>
              <p>Payment Span</p>
              <p>{data?.paymentSpan}</p>
            </div> */}
            <div>
              <p>Invoice Date</p>
              <p>{data?.created_at?.slice(0,10)}</p>
            </div>
            <div>
              <p>Payment Status</p>
              <p className='status'>{data.is_paid === 1 ? "Paid" : "Unpaid"}</p>
            </div>
            <div>
              <p>Payment Mode</p>
              <p>Post Paid</p>
            </div>
          </div>
          <div className='userDetails'>
            {listofChecks?.map((check) => (
              <>
                <div>
                  <p>{check}</p>
                  <p>₹ {splitChecks?.[check]?.["cost"]}</p>
                </div>
              </>
            ))}
          </div>
          <div className='inVoiceTotal'>
            <p>Total</p>
            <p>₹ {data?.client_checks?.total_amount}</p>
          </div>
          <button onClick={() => setOpenModal((prev) => !prev)}>Done</button>
        </div>
      ) : (
        <div className='modalContainer'>
          <h1>
            Billing Breakdown
            {/* <button className="closeBtn" onClick={() => setOpenModal((prev) => !prev)}>
            Close
        </button> */}
          </h1>
          <div className='transactionDetails'>
            <div>
              <p>Candidate Name</p>
              <p>{`${data.candidate_details[0]?.first_name} ${data.candidate_details[0]?.last_name}`}</p>
            </div>
            <div>
              <p>Job Seeker ID</p>
              <p>{data.candidate_details[0]?.job_seeker_id}</p>
            </div>
            <div>
              <p>Work Order ID</p>
              <p>{data.candidate_details[0]?.work_order_id}</p>
            </div>
          </div>
          <div className='transactionDetails'>
            <div>
              <p>Transaction ID</p>
              <p>{data?.razor_transaction_id ? data?.razor_transaction_id : '-'}</p>
            </div>
            {/* <div>
              <p>Payment Method</p>
              <p>{data?.paymentMethod}</p>
            </div> */}
            <div>
              <p>Transaction Date</p>
              <p>
                {data?.created_at ? 
                  data?.created_at?.slice(0,10)
                  :
                  '-'
                }
              </p>
            </div>
            <div>
              <p>Payment Status</p>
              <p className='status'>{data.payment_status}</p>
            </div>
            <div>
              <p>Payment Mode</p>
              <p>Prepaid</p>
            </div>
          </div>
          <div className='userCredentials'>
            {listofChecks?.map((check) => (
              <>
                <div>
                  <p>{check.display_name}</p>
                  <p>₹ {check.valiant_rate_tax}</p>
                </div>
              </>
            ))}
          </div>
          <div className='transactionTotal'>
            <p>Total</p>
            <p>₹ {data?.total_amount}</p>
          </div>
          <button onClick={() => setOpenModal((prev) => !prev)}>Done</button>
        </div>
      )}
    </div>
  );
}

export default InvoiceModal;
