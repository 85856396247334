import React from "react";
import { Route, Routes } from "react-router-dom";
import CompanyDashboard from "../Components/CompanyDashboard/CompanyDashboard";
import ResetPassword from "../Components/Login/ResetPassword/ResetPassword";
import CompanyInfo from "../Components/CompanyRegistration/Info/Forms/CompanyInfo";
import Setup from "../Components/CompanyRegistration/Setup/Setup";
import Info from "../Components/CompanyRegistration/Info/Info";

function Companyroute({ changeTheme }) {
  return (
    <>
      <Routes>
        <Route
          path='/'
          exact
          element={<CompanyDashboard changeTheme={changeTheme} />}
        />
        {/* <Route path="/welcome" exact element={<Greetings />} /> */}
        {/* <Route path="/companyregistration/:component" exact element={<CompanyRegistration/>}/> */}
        <Route
          path='/company/:component'
          exact
          element={<CompanyDashboard changeTheme={changeTheme} />}
        />
        <Route
          path='/company/:component/:status/:serviceid'
          exact
          element={<CompanyDashboard changeTheme={changeTheme} />}
        />
        <Route
          path='/welcome'
          exact
          element={<Setup changeTheme={changeTheme} />}
        />
        <Route
          path='/company-register/:component'
          exact
          element={<Info changeTheme={changeTheme} />}
        />

<Route
          path='/company/:component/:subcomponent'
          exact
          element={<CompanyDashboard changeTheme={changeTheme} />}
        />
        {/* <Route path="/company/:component/:status" element={<CompanyDashboard />} /> */}
        <Route path="/company/:component/:subcomponent/:username/:clientinfo" element={<CompanyDashboard changeTheme={changeTheme} />}   />
        {/* <Route path="/beginverification" exact element={<BeginVerification/>}/> */}
      </Routes>
    </>
  );
}

export default Companyroute;
