import React, { useEffect } from 'react'
import createImageViewer from '../../../ReusableComponents/ImagePreview'

const EducationInformation = ({details, renderPreview}) => {
  const PGdata = Object.keys(details)?.length > 0 && details?.PG
  const UGdata = Object.keys(details)?.length > 0 && details?.UG
  const SSLCdata = Object.keys(details)?.length > 0 && details?.SSLC
  const HSCdata = Object.keys(details)?.length > 0 && details?.HSC
  const Details = details
useEffect(() => {
  createImageViewer()
},[])
  return (
    <main className='EducationInformation'>
      {Object.keys(Details).filter(key => key === 'UG' || key === 'PG' ).map((key, index) => (
        Details[key].length > 0 && 
        <section className='Education' key={index}>
          <h3 className='EduHeader'>{key}</h3>
          {Details[key]?.map((data, index) => (
            <div className='EduContent' key={index}>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Level of Education</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.level_of_education}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Institution Name</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.institution_name}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Degree/Certificate</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.degree_certificate}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Major/Field of Study</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.field_of_study}</span>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>CGPA</label>
                  <span className='Value'>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.gpa}</span>
                </div>
              </div>
              <h3>Qualification Details</h3>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Date Enrolled from</label>
                  <div className='Value'>
                    <span>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.graduation_details[0] && data[Object.keys(data)[0]]?.graduation_details[0]?.date_enrolled_from_month}-</span>
                    <span>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.graduation_details[0] && data[Object.keys(data)[0]]?.graduation_details[0]?.date_enrolled_from_year}</span>
                  </div>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Date Enrolled to</label>
                  <div className='Value'>
                    <span>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.graduation_details[0] && data[Object.keys(data)[0]]?.graduation_details[0]?.date_enrolled_to_month}-</span>
                    <span>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.graduation_details[0] && data[Object.keys(data)[0]]?.graduation_details[0]?.date_enrolled_to_year}</span>
                  </div>
                </div>
                <div className='LabelValue'>
                  <label className='Label'>Date of Degree/Qualification Received</label>
                  <div className='Value'>
                    <span>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.graduation_details[0] && data[Object.keys(data)[0]]?.graduation_details[0]?.degree_received_from_month}-</span>
                    <span>{data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.graduation_details[0] && data[Object.keys(data)[0]]?.graduation_details[0]?.degree_received_from_year}</span>
                  </div>
                </div>
              </div>
              {data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.certificate_info && <h3>Documents</h3>}
              {data[Object.keys(data)[0]] && data[Object.keys(data)[0]]?.certificate_info && data[Object.keys(data)[0]]?.certificate_info?.map((doc, index)=> (
                <div className='Document documentPrev' key={index}>
                  {renderPreview(doc)}
                  {/* <label>
                    <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.25rem" viewBox="0 0 17 20" fill="none">
                      <path d="M11.8838 6H16.2838L10.8838 0.6V5C10.8838 5.6 11.2838 6 11.8838 6ZM11.8838 8C10.1838 8 8.88379 6.7 8.88379 5V0H3.88379C2.18379 0 0.883789 1.3 0.883789 3V17C0.883789 18.7 2.18379 20 3.88379 20H13.8838C15.5838 20 16.8838 18.7 16.8838 17V8H11.8838ZM5.88379 6H6.88379C7.48379 6 7.88379 6.4 7.88379 7C7.88379 7.6 7.48379 8 6.88379 8H5.88379C5.28379 8 4.88379 7.6 4.88379 7C4.88379 6.4 5.28379 6 5.88379 6ZM11.8838 16H5.88379C5.28379 16 4.88379 15.6 4.88379 15C4.88379 14.4 5.28379 14 5.88379 14H11.8838C12.4838 14 12.8838 14.4 12.8838 15C12.8838 15.6 12.4838 16 11.8838 16ZM11.8838 12H5.88379C5.28379 12 4.88379 11.6 4.88379 11C4.88379 10.4 5.28379 10 5.88379 10H11.8838C12.4838 10 12.8838 10.4 12.8838 11C12.8838 11.6 12.4838 12 11.8838 12Z" fill="#102C98"/>
                    </svg>
                    <a href={doc} target='_blank'>{doc.split('/')[doc.split('/').length - 1]}</a>
                  </label> */}
                </div>
              ))}
              <h3>Department Contact Number</h3>
              <div className='DetailsContainer'>
                <div className='LabelValue'>
                  <label className='Label'>Phone Number</label>
                  <span className='Value'>{data?.[Object.keys(data)?.[0]]?.graduation_details?.[0]?.department_phone_no ? (data?.[Object.keys(data)?.[0]]?.graduation_details?.[0]?.department_phone_no) : '--'}</span>
                </div>
              </div>
            </div>
          ))}
        </section>
      ))}
      {Object.keys(Details).filter(key => key === 'SSLC' || key === 'HSC' ).map((key, index) => (
        <section className='Education' key={index}>
          <h3 className='EduHeader'>{key}</h3>
          <div className='EduContent'>
            <div className='DetailsContainer'>
              <div className='LabelValue'>
                <label className='Label'>Level of Education</label>
                <span className='Value'>{Details[key] && Details[key]?.level_of_education}</span>
              </div>
              <div className='LabelValue'>
                <label className='Label'>Institution Name</label>
                <span className='Value'>{Details[key] && Details[key]?.institution_name}</span>
              </div>
              <div className='LabelValue'>
                <label className='Label'>Degree/Certificate</label>
                <span className='Value'>{Details[key] && Details[key]?.degree_certificate}</span>
              </div>
              <div className='LabelValue'>
                <label className='Label'>CGPA</label>
                <span className='Value'>{Details[key] && Details[key]?.gpa}</span>
              </div>
            </div>
            {Details[key] && Details[key]?.certificate_info && <h3>Documents</h3>}
            {Details[key] && Details[key]?.certificate_info && Details[key]?.certificate_info?.map((doc, index) =>(
              <div className='Document documentPrev' key={index}>
                {renderPreview()}
                {/* <label>
                  <svg xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.25rem" viewBox="0 0 17 20" fill="none">
                    <path d="M11.8838 6H16.2838L10.8838 0.6V5C10.8838 5.6 11.2838 6 11.8838 6ZM11.8838 8C10.1838 8 8.88379 6.7 8.88379 5V0H3.88379C2.18379 0 0.883789 1.3 0.883789 3V17C0.883789 18.7 2.18379 20 3.88379 20H13.8838C15.5838 20 16.8838 18.7 16.8838 17V8H11.8838ZM5.88379 6H6.88379C7.48379 6 7.88379 6.4 7.88379 7C7.88379 7.6 7.48379 8 6.88379 8H5.88379C5.28379 8 4.88379 7.6 4.88379 7C4.88379 6.4 5.28379 6 5.88379 6ZM11.8838 16H5.88379C5.28379 16 4.88379 15.6 4.88379 15C4.88379 14.4 5.28379 14 5.88379 14H11.8838C12.4838 14 12.8838 14.4 12.8838 15C12.8838 15.6 12.4838 16 11.8838 16ZM11.8838 12H5.88379C5.28379 12 4.88379 11.6 4.88379 11C4.88379 10.4 5.28379 10 5.88379 10H11.8838C12.4838 10 12.8838 10.4 12.8838 11C12.8838 11.6 12.4838 12 11.8838 12Z" fill="#102C98"/>
                  </svg>
                  <a href={doc} target='_blank'>{doc.split('/')[doc.split('/').length - 1]}</a>
                </label> */}
              </div>
            ))}
            <h3>Department Contact Number</h3>
            <div className='DetailsContainer'>
              <div className='LabelValue'>
                <label className='Label'>Phone Number</label>
                <span className='Value'>{(Details[key] && Details[key]?.graduation_details[0] && Details[key]?.graduation_details[0]?.department_phone_no) ? Details[key]?.graduation_details[0]?.department_phone_no : '--'}</span>
              </div>
            </div>
          </div>
        </section>
      ))}
    </main>
  )
}

export default EducationInformation