import React, { useEffect, useState } from "react";
import "./ResetPassword.scss";
import logo from "../../../assets/images/Valiantt_logo.svg";
import locked from "../../../assets/images/Locked.svg";
import unlock from "../../../assets/images/Locked.svg";
import Avatar from "../../../assets/images/AvatarImage.svg";
import { useParams, useNavigate } from "react-router-dom";
import BackGround from "../Background/BackGround";
import { useDispatch, useSelector } from "react-redux";
import { apiCall } from "../../../api";
import { baseUrl } from "../../../constant/baseurl";
import Loader from "../../Loader/ButtonLoader";
import { setNewPassword } from "../../../redux/actions/LoginAction";
import CustomAlert from "../../ReusableComponents/CustomAlert";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";

function ResetPassword() {
  const [userData, setUserData] = useState({
    password: "",
    confirmPassword: "",
  });
  const { component, token, username } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isValidated, setIsValidated] = useState(false);
  const [verifyLoading, setVerifyLoading] = useState(true);
  const [showPassword, setShowpassword] = useState({
    password: false,
    confirmPassword: false,
  });
  const [customAlert, setCustomAlert] = useState(false);
  const [passwordStrength, setPasswordstrength] = useState("");

  const [alertData, setAlertData] = useState({
    type: "",
    message: "",
    handleClose: () => setCustomAlert(false),
  });

  const setNewPasswordLoading = useSelector((state) => state.setNewPassword.loading);


  useEffect(() => {
    if(component === 'resetpassword') {
      if (token && username) {
        const payload = {
          method: "get",
          url: `${baseUrl}ClientUser/verify_forgot_password_link/${username}/${token}/`,
        };
        apiCall(payload)
          .then((response) => {
            if (response.data.statusFlag) {
              setIsValidated(true);
            } else {
              setIsValidated(false);
              setCustomAlert(true);
              setAlertData({ ...alertData, type: "Failed", message: "Link Invalid or Expired !!" });
            }
            setVerifyLoading(false);
          })
          .catch((error) => {
            // Handle error
            console.error("Error:", error);
            setVerifyLoading(false);
          });
      }
    }
  }, [component, token, username]);


  const handleChange = (e) => {
    console.log("userdata");
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const data = {
      username: username,
      new_password: userData.password,
      token: token,
    };
    const successFn = (response) => {
      setCustomAlert(true);
      setAlertData({
        type: "Success",
        message: "Password created successfully",
        handleClose: () => {
          setCustomAlert(false);
          navigate("/");
          setAlertData({
            type: "",
            message: "",
            handleClose: () => setCustomAlert(false),
          });
        },
      });
    };
    const failureFn = (message) => {
      console.log("passwordSet Failed", message);
      setCustomAlert(true);
      setAlertData({ ...alertData, type: "Failed", message: message });
    };

    if (!userData.password || !userData.confirmPassword) {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        type: "Failed",
        message: "Please enter your password",
      });
      return;
    } else if (userData.password !== userData.confirmPassword) {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        type: "Failed",
        message: "Passwords do not match.",
      });
      return;
    } else if (checkPasswordStrength(userData.password) !== "Strong") {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        type: "Failed",
        message:
          "Password is not strong enough. It must have at least 8 characters, including at least one uppercase letter, one lowercase letter, one number, and one special character.",
      });
      return;
    }
    dispatch(
      setNewPassword({
        data: data,
        successFn: successFn,
        failureFn: failureFn,
      })
    );
  };

  const checkPasswordStrength = (password) => {
    const regex = {
      minLength: /.{8,}/,
      uppercase: /[A-Z]/,
      lowercase: /[a-z]/,
      number: /[0-9]/,
      specialChar: /[^A-Za-z0-9]/,
    };

    const strength = {
      minLength: regex.minLength.test(password),
      uppercase: regex.uppercase.test(password),
      lowercase: regex.lowercase.test(password),
      number: regex.number.test(password),
      specialChar: regex.specialChar.test(password),
    };

    const strengthScore = Object.values(strength).filter(
      (criterion) => criterion
    ).length;

    if (strengthScore === 5) {
      return "Strong";
    } else if (strengthScore >= 3) {
      return "Medium";
    } else {
      return "Weak";
    }
  };
  useEffect(() => {
    setPasswordstrength(checkPasswordStrength(userData.password));
  }, [userData]);

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      handleSubmit(e);
    }
  }

  return (
    <>
      {/* <BackGround /> */}

      <div className={`resetPass ${component === "resetpassword" ? "active" : "next"}`}>
        {customAlert && <CustomAlert {...alertData} />}
        {(!verifyLoading && isValidated) ? (
          <>
            <div className="top">
              <img src={logo} alt="" />
              <h1>Reset Password</h1>
              <p> Create a new password and regain access.</p>
            </div>

            <div className="inputContainer">
              <div className="passwordDiv">
              <input
                            name='password'
                            type={!showPassword.password ? "password" : "text"}
                            placeholder='New Password'
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                            autoComplete='password'
                          />
                {showPassword.password ? (
                  <svg onClick={() =>
                    setShowpassword({
                      ...showPassword,
                      password: !showPassword.password,
                    })
                  } xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.125rem" viewBox="0 0 16 18" fill="none">
                  <path d="M2.75 18H13.25C13.8467 18 14.419 17.7629 14.841 17.341C15.2629 16.919 15.5 16.3467 15.5 15.75V9.75C15.5 9.15326 15.2629 8.58097 14.841 8.15901C14.419 7.73705 13.8467 7.5 13.25 7.5H5V4.5C5 3.70435 5.31607 2.94129 5.87868 2.37868C6.44129 1.81607 7.20435 1.5 8 1.5C8.79565 1.5 9.55871 1.81607 10.1213 2.37868C10.6839 2.94129 11 3.70435 11 4.5C11 4.69891 11.079 4.88968 11.2197 5.03033C11.3603 5.17098 11.5511 5.25 11.75 5.25C11.9489 5.25 12.1397 5.17098 12.2803 5.03033C12.421 4.88968 12.5 4.69891 12.5 4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5V7.5H2.75C2.15326 7.5 1.58097 7.73705 1.15901 8.15901C0.737053 8.58097 0.5 9.15326 0.5 9.75V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18ZM2 9.75C2 9.55109 2.07902 9.36032 2.21967 9.21967C2.36032 9.07902 2.55109 9 2.75 9H13.25C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V15.75C14 15.9489 13.921 16.1397 13.7803 16.2803C13.6397 16.421 13.4489 16.5 13.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V9.75Z" fill="black"/>
                  <path d="M7.25 13.29V14.25C7.25 14.4489 7.32902 14.6397 7.46967 14.7803C7.61032 14.921 7.80109 15 8 15C8.19891 15 8.38968 14.921 8.53033 14.7803C8.67098 14.6397 8.75 14.4489 8.75 14.25V13.29C8.97671 13.1591 9.16522 12.9712 9.29679 12.7449C9.42836 12.5186 9.49842 12.2618 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5C7.60218 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.50158 12.2618 6.57164 12.5186 6.70321 12.7449C6.83479 12.9712 7.02329 13.1591 7.25 13.29Z" fill="black"/>
                </svg>
                  // <img
                  //   src={unlock}
                  //   alt=""
                    
                  // />
                ) : (
                  <svg onClick={() =>
                    setShowpassword({
                      ...showPassword,
                      password: !showPassword.password,
                    })
                  } xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.125rem" viewBox="0 0 16 18" fill="none">
                        <path d="M2.75 18H13.25C13.8467 18 14.419 17.7629 14.841 17.341C15.2629 16.919 15.5 16.3467 15.5 15.75V9.75C15.5 9.15326 15.2629 8.58097 14.841 8.15901C14.419 7.73705 13.8467 7.5 13.25 7.5H12.5V4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5V7.5H2.75C2.15326 7.5 1.58097 7.73705 1.15901 8.15901C0.737053 8.58097 0.5 9.15326 0.5 9.75V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18ZM5 4.5C5 3.70435 5.31607 2.94129 5.87868 2.37868C6.44129 1.81607 7.20435 1.5 8 1.5C8.79565 1.5 9.55871 1.81607 10.1213 2.37868C10.6839 2.94129 11 3.70435 11 4.5V7.5H5V4.5ZM2 9.75C2 9.55109 2.07902 9.36032 2.21967 9.21967C2.36032 9.07902 2.55109 9 2.75 9H13.25C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V15.75C14 15.9489 13.921 16.1397 13.7803 16.2803C13.6397 16.421 13.4489 16.5 13.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V9.75Z" fill="black"/>
                        <path d="M7.25 13.29V14.25C7.25 14.4489 7.32902 14.6397 7.46967 14.7803C7.61032 14.921 7.80109 15 8 15C8.19891 15 8.38968 14.921 8.53033 14.7803C8.67098 14.6397 8.75 14.4489 8.75 14.25V13.29C8.97671 13.1591 9.16522 12.9712 9.29679 12.7449C9.42836 12.5186 9.49842 12.2618 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5C7.60218 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.50158 12.2618 6.57164 12.5186 6.70321 12.7449C6.83479 12.9712 7.02329 13.1591 7.25 13.29Z" fill="black"/>
                      </svg>
                  // <img
                  //   src={locked}
                  //   alt=""
                    
                  // />
                )}
              </div>

              <div className="passwordDiv">
              <input
                            name='confirmPassword'
                            type={
                              !showPassword.confirmPassword ? "password" : "text"
                            }
                            placeholder='Confirm Password'
                            id='Password'
                            onKeyDown={handleKeyDown}
                            onChange={handleChange}
                            autoComplete='password'
                          />
                {showPassword.confirmPassword ? (
                  <svg  onClick={() =>
                    setShowpassword({
                      ...showPassword,
                      confirmPassword: !showPassword.confirmPassword,
                    })
                  } xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.125rem" viewBox="0 0 16 18" fill="none">
                  <path d="M2.75 18H13.25C13.8467 18 14.419 17.7629 14.841 17.341C15.2629 16.919 15.5 16.3467 15.5 15.75V9.75C15.5 9.15326 15.2629 8.58097 14.841 8.15901C14.419 7.73705 13.8467 7.5 13.25 7.5H5V4.5C5 3.70435 5.31607 2.94129 5.87868 2.37868C6.44129 1.81607 7.20435 1.5 8 1.5C8.79565 1.5 9.55871 1.81607 10.1213 2.37868C10.6839 2.94129 11 3.70435 11 4.5C11 4.69891 11.079 4.88968 11.2197 5.03033C11.3603 5.17098 11.5511 5.25 11.75 5.25C11.9489 5.25 12.1397 5.17098 12.2803 5.03033C12.421 4.88968 12.5 4.69891 12.5 4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5V7.5H2.75C2.15326 7.5 1.58097 7.73705 1.15901 8.15901C0.737053 8.58097 0.5 9.15326 0.5 9.75V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18ZM2 9.75C2 9.55109 2.07902 9.36032 2.21967 9.21967C2.36032 9.07902 2.55109 9 2.75 9H13.25C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V15.75C14 15.9489 13.921 16.1397 13.7803 16.2803C13.6397 16.421 13.4489 16.5 13.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V9.75Z" fill="black"/>
                  <path d="M7.25 13.29V14.25C7.25 14.4489 7.32902 14.6397 7.46967 14.7803C7.61032 14.921 7.80109 15 8 15C8.19891 15 8.38968 14.921 8.53033 14.7803C8.67098 14.6397 8.75 14.4489 8.75 14.25V13.29C8.97671 13.1591 9.16522 12.9712 9.29679 12.7449C9.42836 12.5186 9.49842 12.2618 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5C7.60218 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.50158 12.2618 6.57164 12.5186 6.70321 12.7449C6.83479 12.9712 7.02329 13.1591 7.25 13.29Z" fill="black"/>
                </svg>
                  // <img
                  //   src={unlock}
                  //   alt=""
                  
                  // />
                ) : (
                  <svg onClick={() =>
                    setShowpassword({
                      ...showPassword,
                      confirmPassword: !showPassword.confirmPassword,
                    })
                  } xmlns="http://www.w3.org/2000/svg" width="1rem" height="1.125rem" viewBox="0 0 16 18" fill="none">
                        <path d="M2.75 18H13.25C13.8467 18 14.419 17.7629 14.841 17.341C15.2629 16.919 15.5 16.3467 15.5 15.75V9.75C15.5 9.15326 15.2629 8.58097 14.841 8.15901C14.419 7.73705 13.8467 7.5 13.25 7.5H12.5V4.5C12.5 3.30653 12.0259 2.16193 11.182 1.31802C10.3381 0.474106 9.19347 0 8 0C6.80653 0 5.66193 0.474106 4.81802 1.31802C3.97411 2.16193 3.5 3.30653 3.5 4.5V7.5H2.75C2.15326 7.5 1.58097 7.73705 1.15901 8.15901C0.737053 8.58097 0.5 9.15326 0.5 9.75V15.75C0.5 16.3467 0.737053 16.919 1.15901 17.341C1.58097 17.7629 2.15326 18 2.75 18ZM5 4.5C5 3.70435 5.31607 2.94129 5.87868 2.37868C6.44129 1.81607 7.20435 1.5 8 1.5C8.79565 1.5 9.55871 1.81607 10.1213 2.37868C10.6839 2.94129 11 3.70435 11 4.5V7.5H5V4.5ZM2 9.75C2 9.55109 2.07902 9.36032 2.21967 9.21967C2.36032 9.07902 2.55109 9 2.75 9H13.25C13.4489 9 13.6397 9.07902 13.7803 9.21967C13.921 9.36032 14 9.55109 14 9.75V15.75C14 15.9489 13.921 16.1397 13.7803 16.2803C13.6397 16.421 13.4489 16.5 13.25 16.5H2.75C2.55109 16.5 2.36032 16.421 2.21967 16.2803C2.07902 16.1397 2 15.9489 2 15.75V9.75Z" fill="black"/>
                        <path d="M7.25 13.29V14.25C7.25 14.4489 7.32902 14.6397 7.46967 14.7803C7.61032 14.921 7.80109 15 8 15C8.19891 15 8.38968 14.921 8.53033 14.7803C8.67098 14.6397 8.75 14.4489 8.75 14.25V13.29C8.97671 13.1591 9.16522 12.9712 9.29679 12.7449C9.42836 12.5186 9.49842 12.2618 9.5 12C9.5 11.6022 9.34196 11.2206 9.06066 10.9393C8.77936 10.658 8.39782 10.5 8 10.5C7.60218 10.5 7.22064 10.658 6.93934 10.9393C6.65804 11.2206 6.5 11.6022 6.5 12C6.50158 12.2618 6.57164 12.5186 6.70321 12.7449C6.83479 12.9712 7.02329 13.1591 7.25 13.29Z" fill="black"/>
                      </svg>
                  // <img
                  //   src={locked}
                  //   alt=""
                    
                  // />
                )}
              </div>
            </div>

            <div className="passwordCred">
              <p>Password Requirements :</p>
              <ul>
                <li>Minimum 8 Characters</li>
                <li>Atleast one uppercase letter</li>
                <li>Atleast one lowercase letter</li>
                <li>Atleast one number</li>
                <li>Atleast one Special character</li>
              </ul>
            </div>
            <button onClick={handleSubmit} className="CenterDiv" disabled={setNewPasswordLoading}>{setNewPasswordLoading ? <ButtonSpinner size={1}/> : "Reset Password"} </button>
          </>)
          :
          (!verifyLoading &&
            <>
              <div className="top">
                <img src={logo} alt=""/>
                <h1>Oops..!</h1>
                <p> Something went wrong. We kindly ask you to re-initiate the password reset procedure in order to receive the reset link.</p>
              </div>
              <button type="submit" onKeyDown={handleKeyDown} onClick={() => navigate('/forgot')}>Back to Forgot Password</button>
            </>
          )
        }
      </div>
    </>
  );
}

export default ResetPassword;
