import React from 'react';
import { useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';
import Empty from '../../Empty/Empty';

function VerticalBarChart({ marginValue, data }) {
  // const [hoveredBar, setHoveredBar] = useState();
  const DarkMode = localStorage.getItem('Theme') === 'true' ? true : false
  // console.log("hovering id",hoveredBar);
    // Transform the data to match the required structure
    const transformedData = data
    .filter(item => item.check_avg !== 0 && item.check_avg !== null) // Exclude items where check_avg is 0 or null
    .map(item => ({
        id: item.check_name,
        value: item.check_avg,
    }));

    // const darkColor = {hoverColor: '#09A5E0', barColor: '#393939'}
    // const lightColor = {hoverColor: '#102C97', barColor: '#B2DCFF'}

const hasData = transformedData.length > 0;




    return (
        <>
      {hasData ? (
        <ResponsiveBar
        data={transformedData}
        keys={['value']}
        enableGridX={false}
        enableGridY={false}
        indexBy="id"
        margin={marginValue || { top: 50, right: 130, bottom: 50, left: 60 }}
        padding={0.3}
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: true }}
        // colors={({ id, data }) => 
        //     hoveredBar === data.id ? '#102C97' : '#B2DCFF'
        // }
        borderRadius={5}
        borderColor={{
            from: 'color',
            modifiers: [
                ['darker', 1.6]
            ]
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
            tickSize: 0,
            tickPadding: 0,
            tickValues: [],
            // legend: 'Check',
            legendPosition: 'middle',
            legendOffset: 10
        }}
        axisLeft={{
            tickSize: 0,        
            tickPadding: 0,     
            tickValues: [],     
            // legend: 'Days',    
            legendPosition: 'middle',
            legendOffset: 5
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
            from: 'color',
            modifiers: [
                ['darker', 1.6]
            ]
        }}
        tooltip={({ id, value, indexValue }) => (
            <div 
            style={{ transform: 'rotate(0deg)', textAlign: 'center', fontSize:'.6rem', color:'var(--TextColor)' }}>
                <strong>
                    {indexValue} - {value}
                </strong>
            </div>
        )}
        theme={{
            axis: {
              ticks: {
                text: {
                  fill: 'var(--TextColor)',
                },
              },
              legend: {
                text: {
                  fill: 'var(--TextColor)',
                  fontSize: 14,
                  fontWeight: 600,
                },
              },
            },
            labels: {
              text: {
                fill: 'var(--TextColor)',
              },
            },
          }}
        legends={[
            {
                dataFrom: 'keys',
                anchor: 'bottom-right',
                direction: 'column',
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: 'left-to-right',
                itemOpacity: 0.85,
                symbolSize: 20,
                effects: [
                    {
                        on: 'hover',
                        style: {
                            itemOpacity: 1
                        }
                    }
                ]
            }
        ]}
        // onMouseEnter={(data) => {
        //   setHoveredBar(data.indexValue)}} 
        // onMouseLeave={() => setHoveredBar()} 
        defs={[
          linearGradientDef('gradientD', [
              { offset: 0, color: 'var(--Pending)' },
              { offset: 100, color: 'var(--Accepted)' ,opacity: 1},
          ], {
              x1: 0,
              y1: 1,
              x2: 0,
              y2: 0,
          }),
      ]}
      fill={[
          { match: '*', id: 'gradientD' },
      ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        barAriaLabel={e => `${e.id}: ${e.formattedValue} in check: ${e.indexValue}`}
    />
    ) : (
        <Empty sign='negative' size={10}/>
      )}
    </>
    );
}

export default VerticalBarChart;
