// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loader {
  border: 4px solid rgba(255, 255, 255, 0.3); /* Lighter border */
  border-left-color: #ffffff; /* White color for the spinning part */
  border-radius: 50%;
  width: 16px; /* Adjust size as needed */
  height: 16px; /* Adjust size as needed */
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Loader/ButtonLoader.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA,EAAA,mBAAA;EACA,0BAAA,EAAA,sCAAA;EACA,kBAAA;EACA,WAAA,EAAA,0BAAA;EACA,YAAA,EAAA,0BAAA;EACA,kCAAA;EACA,qBAAA;EACA,sBAAA;AACF;;AAEA;EACE;IACE,yBAAA;EACF;AACF","sourcesContent":[".loader {\n  border: 4px solid rgba(255, 255, 255, 0.3); /* Lighter border */\n  border-left-color: #ffffff; /* White color for the spinning part */\n  border-radius: 50%;\n  width: 16px; /* Adjust size as needed */\n  height: 16px; /* Adjust size as needed */\n  animation: spin 1s linear infinite;\n  display: inline-block;\n  vertical-align: middle;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
