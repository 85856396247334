import React, { useEffect, useState } from "react";
import { ReactComponent as Personal } from "./Icons/Personal.svg";
import { ReactComponent as Residence } from "./Icons/Residence.svg";
import { ReactComponent as Education } from "./Icons/Education.svg";
import { ReactComponent as Employment } from "./Icons/Employment.svg";
import { ReactComponent as Id } from "./Icons/Id.svg";
import { ReactComponent as Signature } from "./Icons/Signature.svg";
import PersonalInformation from "./ApplicantData/PersonalInformation";
import ResidenceInformation from "./ApplicantData/ResidenceInformation";
import SignatureInformation from "./ApplicantData/SignatureInformation";
import EducationInformation from "./ApplicantData/EducationInformation";
import EmploymentInformation from "./ApplicantData/EmploymentInformation";
import IdInformation from "./ApplicantData/IdInformation";
import Empty from "../../Empty/Empty";
import PdfViewer from "../../BeginVerification/PdfViewer/PdfViewer";

const Icons = [
  {
    icon: <Personal />,
    title: "Personal Information",
  },
  {
    icon: <Residence />,
    title: "Residence Information",
  },
  {
    icon: <Education />,
    title: "Education Information",
  },
  {
    icon: <Employment />,
    title: "Employment Information",
  },
  {
    icon: <Id />,
    title: "Id Information",
  },
  {
    icon: <Signature />,
    title: "E-Signature",
  },
];

const CandidateApplicantData = ({ details }) => {
  const Data = details && details;
  const [SelectedStatus, setSelectedStatus] = useState("Personal Information");
  const [AddDelaySelectedStatus, setAddDelaySelectedStatus] = useState(
    "Personal Information"
  );
  const CurrentTab = Icons.indexOf(
    Icons.find((data) => data.title === SelectedStatus)
  );

  const handleTabClick = (option) => {
    setSelectedStatus(option);
    setTimeout(() => {
      setAddDelaySelectedStatus(option);
    }, 300);
  };

  const renderPreview = (data) => {
    const type = data?.split(".")[data?.split(".").length - 1];
    if (type === "png" || type === "jpg" || type === "jpeg") {
      return (
        <div className='imgPreviewer'>
          <img
            src={data}
            alt=''
          />
        </div>
      );
    } else if (type === "pdf") {
      return (
        <div className='imgPreviewer'>
          <PdfViewer pdf={data} />
        </div>
      );
    } else if (type === "doc" || type === "docx") {
      return (
        <div className='imgPreviewer'>
          <p>There is no preview available for Doc files</p>
        </div>
      );
    }
  };

  return (
    <main className='CandidateApplicantData'>
      <section className='StatusBarWrapper'>
        <div className='StatusBar'>
          {Icons.map((data, index) => (
            <div
              className={`Status ${
                SelectedStatus === data.title ? "Active" : ""
              }`}
              key={index}
              onClick={() => {
                handleTabClick(data.title);
              }}>
              <span className='Title'>{data.title}</span>
              <div className='Icon'>{data.icon}</div>
            </div>
          ))}
          <div
            className='Indicator'
            style={{
              top: `calc((((100% - 2rem) / ${
                Icons.length - 1
              }) * ${CurrentTab}) - 0.5rem)`,
            }}
          />
        </div>
      </section>
      <section className='ApplicantData'>
        <h1 className='Header'>{AddDelaySelectedStatus}</h1>
        <div className='Information'>
          {AddDelaySelectedStatus === "Personal Information" ? (
            Data?.candidatepersonalInfo ? (
              <PersonalInformation details={Data.candidatepersonalInfo} />
            ) : (
              <Empty sign='negative' />
            )
          ) : AddDelaySelectedStatus === "Residence Information" ? (
            Data?.candidateAddress ? (
              <ResidenceInformation details={Data.candidateAddress} />
            ) : (
              <Empty sign='negative' />
            )
          ) : AddDelaySelectedStatus === "Education Information" ? (
            Data?.candidate_Education?.candidateeducationInfo ? (
              <EducationInformation
                renderPreview={renderPreview}
                details={Data.candidate_Education?.candidateeducationInfo}
              />
            ) : (
              <Empty sign='negative' />
            )
          ) : AddDelaySelectedStatus === "Employment Information" ? (
            Data?.candidate_Employment &&
            Data?.candidate_Employment.length !== 0 ? (
              <EmploymentInformation
                renderPreview={renderPreview}
                details={Data.candidate_Employment}
              />
            ) : (
              <Empty sign='negative' />
            )
          ) : AddDelaySelectedStatus === "Id Information" ? (
            Data?.id_documents && Data?.id_documents.length !== 0 ? (
              <IdInformation details={Data?.id_documents} />
            ) : (
              <Empty sign='negative' />
            )
          ) : AddDelaySelectedStatus === "E-Signature" ? (
            Data?.candidateSign ? (
              <SignatureInformation sign={Data.candidateSign} />
            ) : (
              <Empty sign='negative' />
            )
          ) : (
            <span>No data</span>
          )}
        </div>
      </section>
    </main>
  );
};

export default CandidateApplicantData;
