import React from "react";
import { ResponsiveLine } from '@nivo/line';
import { linearGradientDef } from '@nivo/core';

function AreaChart({ marginValue, data }) {
  const DarkMode = localStorage.getItem('Theme') === 'true' ? true : false
  const dataMax = Math.ceil(data[0].data.reduce((max, item) => Math.max(max, item.y), 0))
  const roundedDataMax = Math.round(dataMax / 100) * 100;

  const getTickValues = () => {
    const numberOfTicks = 5; 
    const tickInterval = Math.ceil(roundedDataMax / numberOfTicks);
    const tickValues = Array.from({ length: numberOfTicks }, (_, i) => (i + 1) * tickInterval);
  
    return tickValues;
  };
  return (
    <>
      <ResponsiveLine
        data={data}
        margin={marginValue}
        xScale={{ type: 'point' }}
        yScale={{
          type: 'linear',
          min: 'auto',
          max: 'auto',
          stacked: true,
          reverse: false
        }}
        enablePoints={true}
        yFormat=" >-.2f"
        curve="linear"
        lineWidth={1}
        colors={['#42CB6F']}
        axisTop={null}
        axisRight={null}
        enableGridY={false}
        isInteractive={true}
        axisBottom={{
          tickSize: 2,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'Month',
          legendOffset: 36,
          legendPosition: 'middle',
          truncateTickAt: 0,
          format: (value) => {
            const strValue = String(value);
            
            const monthIndex = new Date(`${strValue} 1`).getMonth() + 1; 
            
            if (monthIndex % 2 !== 0) {
              return strValue.charAt(0).toUpperCase() + strValue.slice(1);
            }
            
            return '';
          },
        }}
        axisLeft={{
          tickSize: 0,
          tickPadding: 5,
          tickRotation: 0,
          // legend: 'count',
          legendOffset: -40,
          legendPosition: 'middle',
          truncateTickAt: 0,
          tickValues: getTickValues(),
        }}
        theme={{
          grid: {
            line: {
              stroke: '#9b9b9b', 
              strokeWidth: .1,    
            },
          },
            axis: {
              ticks: {
                text: {
                  fill: 'var(--TextColor)',
                },
              },
              legend: {
                text: {
                  fill: 'var(--TextColor)', 
                  fontSize: 12, 
                  fontWeight: 600,
                },
              },
            },
            labels: {
              text: {
                fill: 'var(--TextColor)',
              },
            },
          }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="data.yFormatted"
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.35}
        enableTouchCrosshair={true}
        useMesh={true}
        pointSize={3}
        tooltip={({ point }) => (
          <div
            style={{
              color: 'var(--TextColor)',
              textAlign:'center',
              fontSize:'.6rem',
              position:'relative',
              top:'1rem'
            }}
          >
            <strong style={{textTransform: 'capitalize'}}>{point.data.xFormatted}</strong>: {point.data.yFormatted}
          </div>
        )}
        defs={[
          linearGradientDef('gradientB', [
            { offset: 0, color: '#42CB6F' },
            { offset: 100, color: '#DAFFF6', opacity: 1 },
          ], {
            x1: 0,
            y1: 1,
            x2: 0,
            y2: 0,
          }),
        ]}
        fill={[
          { match: '*', id: 'gradientB' },
        ]}
      />
    </>
  );
}

export default AreaChart;
