import React, { useEffect, useState } from "react";
import "./AccountSettings.scss";
import {
  GetClientInformation,
  updateClientInformation,
} from "../../redux/actions/CompanyAccountSettingAction";
import { useDispatch, useSelector } from "react-redux";
import CustomAlert from "../ReusableComponents/CustomAlert";
import FullPageLoader from "../Loader/FullPageLoader";
import PdfViewer from "../BeginVerification/PdfViewer/PdfViewer";
import createImageViewer from "../ReusableComponents/ImagePreview";

const AccountSettings = () => {
  const [SelectedTab, setSelectedTab] = useState('Company')
  const clientInfoID = localStorage.getItem("client_info_id");
  const [isEditMode, setIsEditMode] = useState(false);
  const dispatch = useDispatch();
  const ClientDetailsLoading = useSelector(
    (state) => state.GetClientInformation?.loading
  );
  const updateLoading = useSelector(
    (state) => state.updateClientInformation?.loading
  );
  const [edited, setEdited] = useState(false);

  const [cinFile, setCinFile] = useState(null);
  const [gstFile, setGstFile] = useState(null);
  const [panFile, setPanFile] = useState(null);
  const [clientDetails, setClientDetails] = useState({
    client_info_id: clientInfoID,
    client_name: "",
    client_address: "",
    type_of_client: "",
    client_email: "",
    client_phone: "",
    client_website: "",
    country: "",
    city: "",
    state: "",
    postal_code: "",
    gst_number: "",
    pan_number: "",
    client_logo: "",
    client_category: "",
    client_description: "",
    documents: { cin_file: cinFile, pan_file: gstFile, gst_file: panFile },
    pan_owners_name: "",
    updated_client_logo: null, // For updated client logo
  });

  const [EditRefData, setEditRefData] = useState({});

  useEffect(() => {
    createImageViewer();
  },[])
  const CompanyCategory = {
    Technology: [
      "Software",
      "Hardware",
      "Internet Services",
      "Telecommunications",
    ],
    Retail: ["E-commerce", "Brick-and-mortar", "Specialty", "Department Store"],
    Finance: ["Banking", "Investment", "Insurance", "Fintech"],
    Healthcare: [
      "Hospitals",
      "Pharmaceuticals",
      "Biotechnology",
      "Medical Devices",
    ],
    Manufacturing: [
      "Automotive",
      "Aerospace",
      "Consumer Goods",
      "Industrial Equipment",
    ],
    Transportation: ["Airlines", "Shipping", "Logistics", "Public Transit"],
    Hospitality: ["Hotels", "Restaurants", "Tourism", "Event Management"],
    Energy: ["Oil and Gas", "Renewable Energy", "Utilities", "Energy Services"],
    Education: [
      "Schools and Universities",
      "EdTech",
      "Training and Development",
      "Publishing",
    ],
    "Media and Entertainment": [
      "Broadcasting",
      "Film and Television",
      "Gaming",
      "Social Media",
    ],
    Others: [],
  };

  useEffect(() => {
    // Assuming clientDetails.documents contains the document URLs
    // Update input values with document URLs when client details are loaded
    if (clientDetails.documents) {
      setCinFile(clientDetails.documents.cin_file);
      setGstFile(clientDetails.documents.gst_file);
      setPanFile(clientDetails.documents.pan_file);
    }
  }, [clientDetails]);

  // const handleTabClick = (option) => {
  //   setSelectedTab(option)
  // }

  const GetClientDetails = () => {
    const data = {
      client_info_id: clientInfoID,
    };

    const successFn = (response) => {
      setClientDetails(response.data[0]);
      setEditRefData(response.data[0]);
      setClientDetails((prevClientDetails) => ({
        ...prevClientDetails,
        client_logo_url: response.data[0].client_logo,
      }));
      setEditRefData((prevClientDetails) => ({
        ...prevClientDetails,
        client_logo_url: response.data[0].client_logo,
      }));
    };

    const failureFn = (error) => {
      console.error("Response failed:", error);
      alert(error);
    };
    dispatch(GetClientInformation({ data, successFn, failureFn }));
  };

  useEffect(() => {
    if (!isEditMode) {
      GetClientDetails();
    }
  }, [clientInfoID, isEditMode]);

  const handleFileChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    setEdited(true);
    if (file) {
      // Create a FileReader instance to read the file
      const reader = new FileReader();
      reader.onloadend = () => {
        // Update state with the file object and URL for preview
        setClientDetails({
          ...clientDetails,
          updated_client_logo: file,
          client_logo_url: reader.result,
        });
      };
      reader.readAsDataURL(file); // Read the selected file as a data URL
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    // Function to remove emojis from the input value
    const removeEmojis = (input) => {
      return input.replace(
        /[\u{1F000}-\u{1F9FF}\u{1F300}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{2600}-\u{26FF}\u{2700}-\u{27BF}\u{1F100}-\u{1F1FF}\u{1F200}-\u{1F2FF}\u{1F300}-\u{1F5FF}\u{1F600}-\u{1F64F}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}\u{1FC00}-\u{1FCFF}\u{1FD00}-\u{1FDFF}\u{1FE00}-\u{1FEFF}\u{1FF00}-\u{1FFFF}\u{20000}-\u{2FFFF}\u{30000}-\u{3FFFF}\u{40000}-\u{4FFFF}\u{50000}-\u{5FFFF}\u{60000}-\u{6FFFF}\u{70000}-\u{7FFFF}\u{80000}-\u{8FFFF}\u{90000}-\u{9FFFF}\u{A0000}-\u{AFFFF}\u{B0000}-\u{BFFFF}\u{C0000}-\u{CFFFF}\u{D0000}-\u{DFFFF}\u{E0000}-\u{EFFFF}]/gu,
        ""
      );
    };

    // Remove emojis from the input value
    const sanitizedValue = removeEmojis(value);

    setEdited(true);
    setClientDetails({
      ...clientDetails,
      [name]: sanitizedValue,
    });
  };

  const toggleEditMode = () => {
    setIsEditMode(!isEditMode);
  };

  const handleCinFileChange = (file) => {
    setClientDetails((prevState) => ({
      ...prevState,
      documents: {
        ...prevState.documents,
        cin_file: file,
        cin_file_name: file.name, // Optionally store the file name
      },
    }));
  };

  const handleGstFileChange = (file) => {
    setClientDetails((prevState) => ({
      ...prevState,
      documents: {
        ...prevState.documents,
        gst_file: file,
        gst_file_name: file.name, // Optionally store the file name
      },
    }));
  };

  const handlePanFileChange = (file) => {
    setClientDetails((prevState) => ({
      ...prevState,
      documents: {
        ...prevState.documents,
        pan_file: file,
        pan_file_name: file.name, // Optionally store the file name
      },
    }));
  };

  const [SuccessFailed, setSuccessFailed] = useState("Success");
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState("");
  const [Alert, setAlert] = useState(false);
  const handleSuccessClose = () => {
    setAlert(false);
    setIsEditMode(false);
  };
  const handleFailedClose = () => {
    setAlert(false);
  };

  const handleSaveClick = () => {

    if (!edited) {
      setSuccessFailed("Failed");
      setSuccessFailedMessage("No changes added to save");
      setAlert(true);
      return;
    }

    let isEmptyField = false;

    console.log('khdgfrhfr:::::::::::', clientDetails)

    for (const key in clientDetails) {
      if (clientDetails[key] === "" || clientDetails[key] === null) {
        // Skip validation for documents and updated_client_logo
        if (
          key !== "documents" &&
          key !== "updated_client_logo" &&
          key !== "client_logo" &&
          key !== "master" &&
          key !== "client" &&
          key !== "client_logo_url" &&
          key !== "is_single_check_cancel" &&
          key !== "overall_tat" &&
          key !== "vendor_user_count" &&
          key !== "payment_interval"
        ) {
          isEmptyField = true;
          break;
        }
      }
    }

    if (isEmptyField) {
      alert("Please fill all the fields.");
      return;
    }

    // // Check if cin_file, pan_file, and gst_file are all null
    // if (
    //   formData.cin_file === null &&
    //   formData.pan_file === null &&
    //   formData.gst_file === null
    // ) {
    //   alert('Please upload your documents.');
    //   return;
    // }

    const formDataToSend = new FormData();
    for (const key in clientDetails) {
      if (clientDetails[key] !== null && clientDetails[key] !== "") {
        // Append all fields except documents and updated_client_logo
        if (
          key !== "documents" &&
          key !== "updated_client_logo" &&
          key !== "client_logo_url" &&
          key !== "client_logo" &&
          key !== "master" &&
          key !== "client"
        ) {
          formDataToSend.append(key, clientDetails[key]);
        } else if (key === "documents") {
          // Handle documents separately
          for (const docKey in clientDetails[key]) {
            // Check if the value is a File object before appending
            if (clientDetails[key][docKey] instanceof File) {
              formDataToSend.append(docKey, clientDetails[key][docKey]);
            }
          }
        }
      }
    }

    if (clientDetails.updated_client_logo instanceof File) {
      formDataToSend.append("client_logo", clientDetails.updated_client_logo);
    }

    // Dispatch API call
    const successFn = (response) => {
      if (response.statusFlag) {
        setSuccessFailed("Success");
        setSuccessFailedMessage(response.message);
        setAlert(true);
        // window.location.reload();
      }
      setEdited(false);
    };
    const failureFn = (err) => {
      console.log("Logged-In Failed", err);
      setSuccessFailed("Failed");
      setSuccessFailedMessage(err);
      setAlert(true);
    };

    dispatch(
      updateClientInformation({
        data: formDataToSend,
        successFn: successFn,
        failureFn: failureFn,
      })
    );

    // Proceed to next step
    // handleNext(); // Call your handleNext function here
  };

  function getFileNameFromUrl(url) {
    if (!url) return null; // Return null if URL is null or undefined
    // Get the last part of the URL after the last "/"
    const parts = url.split("/");
    // Decode the URI component to handle special characters
    return decodeURIComponent(parts[parts.length - 1]);
  }


  const documentLabels = {
    cin_file: "CIN Document",
    gst_file: "GST Document",
    pan_file: "PAN Document",
  };

  return (
    <main className='AccountSettings'>
      {(ClientDetailsLoading || updateLoading) && (
        <section className='LoaderContainer'>
          <FullPageLoader/>
        </section>
      )}
      {clientDetails && (
        <>
          <article className='Details'>
            <header className='Logo'>
              <div>
                {clientDetails?.client_logo_url ? (
                  <img src={clientDetails?.client_logo_url} alt='Logo' loading="lazy" draggable={false}/>
                ) : (
                  <span>{clientDetails?.client_name[0]}</span>
                )}
                <span className={isEditMode ? "EditLogo" : "Hide"} onClick={() => {document.getElementById("fileInput").click();}}>
                  <svg xmlns='http://www.w3.org/2000/svg' width='14' height='15' viewBox='0 0 14 15' fill='none'>
                    <path d='M0 11.747V14.1112C0 14.3289 0.171087 14.5 0.388835 14.5H2.75295C2.85405 14.5 2.95515 14.4611 3.02514 14.3833L11.5173 5.89897L8.60103 2.98271L0.116651 11.4671C0.0388836 11.5449 0 11.6382 0 11.747ZM13.7725 3.64373C14.0758 3.34044 14.0758 2.85051 13.7725 2.54722L11.9528 0.727468C11.6495 0.424177 11.1596 0.424177 10.8563 0.727468L9.43314 2.1506L12.3494 5.06687L13.7725 3.64373Z' fill='white'/>
                  </svg>
                  <span>Edit</span>
                </span>
              </div>
              <input type='file' id='fileInput' accept='.jpg,.jpeg,.png' style={{ display: "none" }} onChange={handleFileChange}/>
            </header>
            <div className='DetailsContainer'>
              <h1 title={isEditMode ? EditRefData.client_name: clientDetails.client_name}>
                {isEditMode ? EditRefData.client_name : clientDetails.client_name}
              </h1>
              <span className='Description' title={isEditMode ? EditRefData.client_description : clientDetails.client_description}>
                {isEditMode ? EditRefData.client_description : clientDetails.client_description}
              </span>
              {clientDetails && clientDetails.client_name && (
                <div className='Detail'>
                  <div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 28 28' fill='none'>
                      <path d='M11.5641 7.05719C11.2299 3.21244 7.21886 1.32594 7.04911 1.24894C6.89042 1.17434 6.71259 1.15051 6.53986 1.18069C1.90936 1.94894 1.21286 4.64394 1.18486 4.75594C1.14677 4.91201 1.15283 5.07561 1.20236 5.22844C6.72536 22.3644 18.2036 25.5407 21.9766 26.5854C22.2671 26.6659 22.5069 26.7307 22.6889 26.7902C22.8965 26.858 23.1222 26.8449 23.3206 26.7534C23.4361 26.7009 26.1626 25.4164 26.8294 21.2269C26.8589 21.044 26.8295 20.8565 26.7454 20.6914C26.6859 20.5759 25.2596 17.8617 21.3046 16.9027C21.1704 16.8684 21.0299 16.8672 20.8952 16.8992C20.7605 16.9312 20.6355 16.9954 20.5311 17.0864C19.2834 18.1522 17.5596 19.2879 16.8159 19.4052C11.8301 16.9674 9.04586 12.2897 8.94086 11.4024C8.87961 10.9037 10.0224 9.15194 11.3366 7.72744C11.4192 7.63779 11.4821 7.53183 11.5213 7.4164C11.5605 7.30096 11.5751 7.1786 11.5641 7.05719Z' fill='#102C98'/>
                    </svg>
                    <span className="Ellipsis" title={isEditMode ? EditRefData.client_phone : clientDetails.client_phone}>
                      {isEditMode ? EditRefData.client_phone : clientDetails.client_phone}
                    </span>
                  </div>
                  <div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 28 28' fill='none'>
                      <path d='M1.93066 7.16406V20.8474L8.77232 14.0057L1.93066 7.16406Z' fill='#102C98'/>
                      <path d='M3.29004 5.79297L11.9517 14.4546C13.0456 15.5486 14.9535 15.5486 16.0474 14.4546L24.7091 5.79297H3.29004Z' fill='#102C98'/>
                      <path d='M17.4128 15.8201C16.5023 16.7315 15.2896 17.2345 13.9997 17.2345C12.7098 17.2345 11.4971 16.7315 10.5866 15.8201L10.1376 15.3711L3.30176 22.207H24.6976L17.8618 15.3711L17.4128 15.8201Z' fill='#102C98'/>
                      <path d='M19.2275 14.0057L26.0692 20.8474V7.16406L19.2275 14.0057Z' fill='#102C98'/>
                    </svg>
                    <span className="Ellipsis" title={isEditMode ? EditRefData.client_email : clientDetails.client_email}>
                      {isEditMode ? EditRefData.client_email : clientDetails.client_email}
                    </span>
                  </div>
                  <div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 28 28' fill='none'>
                      <path fillRule='evenodd' clipRule='evenodd' d='M17.206 18.375C16.9164 20.4347 16.4264 22.2088 15.7964 23.5021C15.4892 24.1329 15.1541 24.6409 14.7853 24.9852C14.5342 25.2197 14.2791 25.375 14 25.375C13.7209 25.375 13.4658 25.2197 13.2147 24.9852C12.8459 24.6409 12.5108 24.1329 12.2036 23.5021C11.5736 22.2088 11.0836 20.4347 10.794 18.375H17.206ZM24.5018 18.375C23.1608 21.5863 20.3897 24.0537 16.9859 24.9782C17.8806 23.4749 18.5929 21.1488 18.9656 18.375H24.5018ZM9.03437 18.375C9.40712 21.1488 10.1194 23.4749 11.0141 24.9782C7.60988 24.0533 4.83875 21.5858 3.49825 18.375H9.03437ZM8.85019 11.375C8.78456 12.222 8.75 13.1001 8.75 14C8.75 14.8999 8.78456 15.778 8.85019 16.625H2.92994C2.73044 15.7824 2.625 14.9034 2.625 14C2.625 13.0966 2.73044 12.2176 2.92994 11.375H8.85019ZM17.3972 11.375C17.4646 12.2194 17.5 13.0979 17.5 14C17.5 14.9021 17.4646 15.7806 17.3972 16.625H10.6028C10.5354 15.7806 10.5 14.9021 10.5 14C10.5 13.0979 10.5354 12.2194 10.6028 11.375H17.3972ZM25.0701 11.375C25.2696 12.2176 25.375 13.0966 25.375 14C25.375 14.9034 25.2696 15.7824 25.0701 16.625H19.1498C19.2154 15.778 19.25 14.8999 19.25 14C19.25 13.1001 19.2154 12.222 19.1498 11.375H25.0701ZM11.0141 3.02181C10.1194 4.52506 9.40712 6.85125 9.03437 9.625H3.49825C4.83919 6.41375 7.61031 3.94625 11.0141 3.02181ZM14 2.625C14.2791 2.625 14.5342 2.78031 14.7853 3.01481C15.1541 3.35912 15.4892 3.86706 15.7964 4.49794C16.4264 5.79119 16.9164 7.56525 17.206 9.625H10.794C11.0836 7.56525 11.5736 5.79119 12.2036 4.49794C12.5108 3.86706 12.8459 3.35912 13.2147 3.01481C13.4658 2.78031 13.7209 2.625 14 2.625ZM16.9859 3.02181C20.3901 3.94669 23.1613 6.41419 24.5018 9.625H18.9656C18.5929 6.85125 17.8806 4.52506 16.9859 3.02181Z' fill='#102C98'/>
                    </svg>
                    <span className="Ellipsis" title={isEditMode ? EditRefData.client_website : clientDetails.client_website}>
                      {isEditMode ? EditRefData.client_website : clientDetails.client_website}
                    </span>
                  </div>
                  <div>
                    <svg xmlns='http://www.w3.org/2000/svg' width='20' height='20' viewBox='0 0 28 28' fill='none'>
                      <path d='M20.9998 5.25078C17.1498 1.40078 10.8498 1.40078 6.9998 5.25078C3.1498 9.10078 3.1498 15.4008 6.9998 19.2508L13.1831 25.4341C13.6498 25.9008 14.3498 25.9008 14.8165 25.4341L20.9998 19.2508C24.8498 15.2841 24.8498 9.10078 20.9998 5.25078ZM13.9998 17.2674C11.1998 17.2674 8.98314 15.0508 8.98314 12.2508C8.98314 9.45078 11.1998 7.11745 13.9998 7.11745C16.7998 7.11745 19.0165 9.33411 19.0165 12.1341C19.0165 14.9341 16.7998 17.2674 13.9998 17.2674Z' fill='#102C98'/>
                    </svg>
                    <span className='Address' title={isEditMode ? EditRefData.client_address : clientDetails.client_address}>
                      {isEditMode ? EditRefData.client_address : clientDetails.client_address}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </article>
          <article className='Informations'>
            <section className='InformationContainer'>
              <header className='InformationHeader'>
                <div className="Tabs">
                  <span className={`${SelectedTab === 'Company' ? 'Tab' : ''}`} onClick={() => {setSelectedTab('Company')}}>Company Details</span>
                  <span className={`${SelectedTab === 'Documents' ? 'Tab' : ''} ${isEditMode ? 'NotAllowed' : ''}`} onClick={() => {if(!isEditMode){setSelectedTab('Documents')}}}>Documents</span>
                </div>
                {isEditMode ? (
                  <div className='SaveCancel'>
                    <span onClick={() => {setIsEditMode(false)}}>Cancel</span>
                    <span onClick={handleSaveClick}>Save</span>
                  </div>
                ) : (
                  SelectedTab !== 'Documents' && <span onClick={toggleEditMode}>Edit</span>
                )}
              </header>
              {SelectedTab === 'Company' &&
                <article className='Information'>
                  <div className='Accordion'>
                    <div className='AccHeader'>
                      <span>Company Information</span>
                    </div>
                    <div className="AccInfoWrapper">
                      <div className='AccInfo'>
                        <div>
                          <label>Company Name</label>
                          <input type='text' name='client_name' value={clientDetails.client_name} onChange={handleInputChange} readOnly/>
                        </div>
                        <div>
                          <label>Company Mobile number</label>
                          <input type='text' value={clientDetails.client_phone} maxLength={10} name='client_phone' onChange={handleInputChange} readOnly={!isEditMode} pattern='[0-9]{10}' title='Please enter a 10-digit numeric mobile number'
                            onKeyPress={(e) => {
                              const isValidInput = /[0-9]/.test(e.key);
                              if (!isValidInput) {
                                e.preventDefault();
                              }
                            }}
                          />
                        </div>
                        <div>
                          <label>Company Mail</label>
                          <input type='text' value={clientDetails.client_email} name='client_email' readOnly/>
                        </div>
                        <div>
                          <label>Company Website</label>
                          <input type='text' value={clientDetails.client_website} name='client_website' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='Accordion'>
                    <div className='AccHeader'>
                      <span>Business Overview</span>
                    </div>
                    <div className="AccInfoWrapper">
                      <div className='AccInfo'>
                        <div>
                          <label>Company Category</label>
                          {!isEditMode ? (
                            <input type='text' value={clientDetails.client_category} name='client_category' onChange={handleInputChange} readOnly/>
                          ) : (
                            <select value={clientDetails.client_category} name='client_category' onChange={handleInputChange} readOnly={!isEditMode}>
                              {Object.keys(CompanyCategory).map((key, index) => (
                                <option key={index} value={key}>
                                  {key}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div>
                          <label>Type of Company</label>
                          {!isEditMode ||
                          clientDetails.client_category === "Others" ? (
                            <input type='text' value={clientDetails.type_of_client} name='type_of_client' onChange={handleInputChange} readOnly={!isEditMode}/>
                          ) : (
                            <select value={clientDetails.type_of_client} name='type_of_client' onChange={handleInputChange} readOnly={!isEditMode}>
                              {CompanyCategory?.[clientDetails.client_category]?.map((data, index) => (
                                <option key={index} value={data}>
                                  {data}
                                </option>
                              ))}
                            </select>
                          )}
                        </div>
                        <div>
                          <label>Company Description</label>
                          <input type='text' value={clientDetails.client_description} name='client_description' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='Accordion'>
                    <div className='AccHeader'>
                      <span>Business Details</span>
                    </div>
                    <div className="AccInfoWrapper">
                      <div className='AccInfo'>
                        <div>
                          <label>Business PAN</label>
                          <input type='text' value={clientDetails.pan_number} name='pan_number' onChange={handleInputChange} readOnly/>
                        </div>
                        <div>
                          <label>PAN Owner's Name</label>
                          <input type='text' value={clientDetails.pan_owners_name} name='pan_owners_name' onChange={handleInputChange} readOnly/>
                        </div>
                        <div>
                          <label>GSTIN</label>
                          <input type='text' value={clientDetails.gst_number} name='gst_number' onChange={handleInputChange} readOnly/>
                        </div>
                        <div>
                          <label>City</label>
                          <input type='text' value={clientDetails.city} name='city' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                        <div>
                          <label>State</label>
                          <input type='text' value={clientDetails.state} name='state' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                        <div>
                          <label>Country</label>
                          <input type='text' value={clientDetails.country} name='country' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                        <div>
                          <label>Address</label>
                          <input type='text' value={clientDetails.client_address} name='client_address' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                        <div>
                          <label>Pin Code</label>
                          <input type='text' value={clientDetails.postal_code} name='postal_code' onChange={handleInputChange} readOnly={!isEditMode}/>
                        </div>
                        {/* <div>
                          <label>CIN Document</label>
                          {clientDetails.documents.cin_file && (
                            <a href={clientDetails.documents.cin_file} className="DocumentName" target='_blank' rel='noopener noreferrer'>
                              {getFileNameFromUrl(clientDetails.documents.cin_file) || "CIN Document"}
                            </a>
                          )}
                        </div>
                        <div>
                          <label>GST Document</label>
                          {clientDetails.documents.gst_file && (
                            <a href={clientDetails.documents.gst_file} className="DocumentName" target='_blank' rel='noopener noreferrer'>
                              {getFileNameFromUrl(clientDetails.documents.gst_file) || "GST Document"}
                            </a>
                          )}
                        </div>
                        <div>
                          <label>PAN Document</label>
                          {clientDetails.documents.pan_file && (
                            <a href={clientDetails.documents.pan_file} className="DocumentName" target='_blank' rel='noopener noreferrer'>
                              {getFileNameFromUrl(clientDetails.documents.pan_file) || "PAN Document"}
                            </a>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </article>
              }
              {SelectedTab === 'Documents' && (
                <div className="Documents">
                  {Object.keys(clientDetails?.documents || {}).map((key) => (
                    <div className="DocCont" key={key}>
                      <div className="Document">
                        {(clientDetails?.documents[key].endsWith(".pdf")) ? (
                          <PdfViewer pdf={clientDetails?.documents[key]} showPagination={false} />
                        ) : clientDetails?.documents[key].startsWith("data:image/") ? (
                          <img src={clientDetails?.documents[key]} alt={documentLabels[key]} className="image-preview" />
                        ) : (clientDetails?.documents[key].endsWith(".png") ||
                        clientDetails?.documents[key].endsWith(".jpg") ||
                        clientDetails?.documents[key].endsWith(".jpeg") ||
                        clientDetails?.documents[key].endsWith(".webp")
                      ) ? (
                          <img src={clientDetails?.documents[key]} alt={documentLabels[key]} className="image-preview" />
                        ) : null}
                      </div>
                      <div className="DocDetail">
                        <span className="DocName">{documentLabels[key]}</span>
                        <a href={clientDetails?.documents[key]} target="_blank" rel="noopener noreferrer">
                          <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 23 23" fill="none">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.5013 16.5404C7.47893 16.5404 5.09193 13.541 4.06526 11.8305C4.00348 11.7309 3.97074 11.616 3.97074 11.4987C3.97074 11.3814 4.00348 11.2665 4.06526 11.1669C5.09193 9.45636 7.47893 6.45703 11.5013 6.45703C15.5236 6.45703 17.9106 9.45636 18.9373 11.1669C18.999 11.2665 19.0318 11.3814 19.0318 11.4987C19.0318 11.616 18.999 11.7309 18.9373 11.8305C17.9106 13.541 15.5236 16.5404 11.5013 16.5404ZM2.88643 12.5373C3.97543 14.3541 6.72084 17.9154 11.5013 17.9154C16.2817 17.9154 19.0271 14.3541 20.1161 12.5373C20.3059 12.2241 20.4062 11.8649 20.4062 11.4987C20.4062 11.1325 20.3059 10.7733 20.1161 10.4601C19.0271 8.64328 16.2817 5.08203 11.5013 5.08203C6.72084 5.08203 3.97543 8.64328 2.88643 10.4601C2.69672 10.7733 2.59643 11.1325 2.59643 11.4987C2.59643 11.8649 2.69672 12.2241 2.88643 12.5373Z" fill="#212121"/>
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.4988 14.1654C12.7874 14.1654 13.8313 13.1215 13.8313 11.8329C13.8313 10.5443 12.7874 9.50037 11.4988 9.50037C10.2102 9.50037 9.16626 10.5443 9.16626 11.8329C9.16626 13.1215 10.2102 14.1654 11.4988 14.1654ZM11.4988 15.5404C13.5382 15.5404 15.2063 13.8723 15.2063 11.8329C15.2063 9.7935 13.5382 8.12537 11.4988 8.12537C9.45938 8.12537 7.79126 9.7935 7.79126 11.8329C7.79126 13.8723 9.45938 15.5404 11.4988 15.5404Z" fill="#212121"/>
                          </svg>
                        </a>
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </section>
          </article>
        </>
      )}
      {Alert && (
        <CustomAlert
          type={SuccessFailed}
          message={SuccessFailedMessage}
          handleClose={
            SuccessFailed === "Success" ? handleSuccessClose : handleFailedClose
          }
        />
      )}
    </main>
  );
};

export default AccountSettings;
