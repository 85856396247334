import React, { useEffect, useState } from 'react';
import PdfViewer from '../../../BeginVerification/PdfViewer/PdfViewer';
import createImageViewer from '../../../ReusableComponents/ImagePreview';
// import ImageAndPdfViewer from '../../../ReusableComponents/ImageAndPdfViewer/ImageAndPdfViewer';

const isImage = (fileUrl) => {
  return fileUrl.startsWith('data:image/') || /\.(jpg|jpeg|png|gif)$/i.test(fileUrl);
};

// Utility function to check if the file is a PDF
const isPDF = (fileUrl) => {
  return fileUrl.startsWith('data:application/pdf') || /\.pdf$/i.test(fileUrl);
};

const PersonalInformation = ({details}) => {
  // const [showImage, setImage] = useState(false);
  // const [imageViewData, setImageViewerData] = useState({currentUrl:null, urlType:''})

  // const handleClick = (url, type) => {
  //   setImage((prev) => !prev)
  //   if(url){
  //     setImageViewerData((prev) => ({
  //       ...prev,
  //       currentUrl: url,
  //       urlType:type
  //     }))
  //   }
  // }

  const Fields = [
    {
      key: 'first_name',
      label: 'First Name'
    },
    {
      key: 'last_name',
      label: 'Last Name'
    },
    {
      key: 'father_name',
      label: `Father's Name`
    },
    {
      key: 'dob',
      label: 'Date of Birth'
    },
    {
      key: 'gender',
      label: 'Gender'
    },
    {
      key: 'candidate_email',
      label: 'Email Id'
    },
    {
      key: 'contact_no',
      label: 'Phone Number'
    },
    {
      key: 'alias_name',
      label: 'Alias Name'
    },
  ]

  useEffect(() => {
    createImageViewer()
  },[])
  return (
    <main className='PersonalInformation'>
      {details &&
        <section className='DetailsContainer'>
          {Fields.map((field, index) => (
            <div className='LabelValue' key={index}>
              <span className='Label'>{field?.label}</span>
              <span className='Value'>{details?.[field?.key] ? details?.[field?.key] : '-'}</span>
            </div>
          ))}
        </section>
      }
      {details?.aadhar_doc && details?.aadhar_doc.length > 0 &&
        <section className='Documents'>
          <span className='Title'>Aadhaar Document</span>
          <div className='IdContainer'>
            {details.aadhar_doc.map((doc, index) => (
              <div className='DocumentCont' key={index}>
                {isImage(doc) ? (
                  <img /* onClick={() => handleClick(doc, 'image')} */ src={doc} alt={`Aadhaar Doc ${index}`} draggable={false} />
                ) : isPDF(doc) ? (
                  <div className='pdfViewer' /* onClick={() => handleClick(doc, 'pdf')} */ >
                    <PdfViewer pdf={doc} />
                    <a href={doc} target="_blank" rel="noopener noreferrer" download>
                      Download Aadhaar Document
                    </a>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </section>
      }
      {details?.pan_doc && details?.pan_doc.length > 0 &&
        <section className='Documents'>
          <span className='Title'>PAN Document</span>
          <div className='IdContainer'>
            {details.pan_doc.map((doc, index) => (
              <div className='DocumentCont' key={index}>
                {isImage(doc) ? (
                  <img src={doc} /* onClick={() => handleClick(doc, 'image')} */ alt={`PAN Doc ${index}`} draggable={false} />
                ) : isPDF(doc) ? (
                  <div className='pdfViewer' /*onClick={() => handleClick(doc, 'pdf')} */>
                    <PdfViewer pdf={doc} />
                    <a href={doc} target="_blank" rel="noopener noreferrer" download>
                      Download PAN Document
                    </a>
                  </div>
                ) : null}
              </div>
            ))}
          </div>
        </section>
      }
    {/* {showImage && <ImageAndPdfViewer setImage={setImage} imageViewData={imageViewData} />} */}
    </main>
  )
}

export default PersonalInformation