import React, { useEffect, useState } from 'react';
import { ResponsiveBar } from '@nivo/bar';
import { linearGradientDef } from '@nivo/core';

function BarChart({ marginValue, data }) {
    const [mode, setMode] = useState(localStorage.getItem('Theme') === 'true' ? true : false);
    const lightTheme = ['#B2E7FF', '#DAD4FF'];
    const darkTheme = ['#0DB2FC4D', '#806BFF4D'];

    return (
        <>
            <ResponsiveBar
                data={data}
                keys={['Pack']}
                indexBy="country"
                margin={{
                    top: marginValue.top || 10,
                    right: marginValue.right || 10,
                    bottom: marginValue.bottom || 10,
                    left: marginValue.left || 10
                }}
                padding={0.3}
                enableGridY={true}
                layout="horizontal"
                valueScale={{ type: 'linear' }}
                indexScale={{ type: 'band', round: true }}
                borderColor={{
                    from: 'color',
                    modifiers: [['opacity', '0.9']]
                }}
                axisTop={null}
                axisRight={null}
                axisBottom={null}
                axisLeft={null}
                enableLabel={false}
                labelSkipHeight={19}
                borderRadius={3}
                labelTextColor={{
                    from: 'color',
                    modifiers: [['darker', 1.6]]
                }}
                isInteractive={true}
                role="application"
                tooltip={({ indexValue, value, color }) => (
                    <div
                        style={{
                            // padding: '5px 10px',
                            // background: 'rgba(0, 0, 0, 0.75)',
                            // borderRadius: '3px',
                            color: 'var(--TextColor)',
                            textAlign:'center',
                            fontSize:'.6rem',
                            position:'relative',
                            top:'1rem',
                        }}
                    >
                        <strong>{`${indexValue}: ${value}`}</strong>
                    </div>
                )}
                theme={{
                    grid: {
                        line: {
                          stroke: '#9b9b9b',  
                          strokeWidth: .1,
                        },
                      },
                }}
                defs={[
                    linearGradientDef('gradientA', [
                        { offset: 0, color: 'var(--Pending)' },
                        { offset: 100, color: 'var(--Accepted)' ,opacity: 1},
                    ], {
                        x1: 0,
                        y1: 0,
                        x2: 1,
                        y2: 0,
                    }),
                ]}
                fill={[
                    { match: '*', id: 'gradientA' },
                ]}
                
            />
        </>
    );
}

export default BarChart;
