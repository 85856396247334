import React,{ useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import './AdditionalCostConfirmationModal.scss'
import { updateAdditionalCostApproval } from '../../redux/actions/CompanyAdditionalCostAction';
import { useParams } from 'react-router-dom';
import ButtonSpinner from '../ReusableComponents/ButtonSpinner';
import CustomAlert from '../ReusableComponents/CustomAlert';

const AdditionalCostConfirmationModal = ({ trigger, toggle, data, onClose }) => {
  const {component} = useParams();
  const [isChecked, setIsChecked] = useState(false); // Checkbox state
  const [ApprovalType, setApprovalType] = useState('')
  const dispatch = useDispatch();
  const updateAdditionalCostApprovalLoading = useSelector(state => state.updateAdditionalCostApproval?.loading)
  // Handle checkbox change
  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };
  const [AlertInfo, setAlertInfo] = useState({
    Alert: false,
    SuccessFailed: '',
    SuccessFailedMessage: '',
  })

  const handleApproval = (approvalStatus) => {
    if (!isChecked) {
      setAlertInfo({
        Alert: true,
        SuccessFailed: 'Failed',
        SuccessFailedMessage: 'Please check the agreement box before proceeding',
      })
      return;
    }

    if(!updateAdditionalCostApprovalLoading) {
      const payload = {
        additional_cost_id: data.additional_cost_id,
        approval: approvalStatus,
      };

      const successFn = (response) => {
        if(component === "additional-cost"){
          trigger();
        }
        else{
          toggle();
        }
        onClose();
      };
  
      const failureFn = (error) => {
        console.error('Failed to save data', error);
        alert(error.message || 'Failed to update the approval status.');
      };
  
      dispatch(updateAdditionalCostApproval({ data: payload, successFn, failureFn }));
    }
  };

  return (
    <>
      {!AlertInfo.Alert ?
        <main className='AdditionalCostConfirmationModal'>
          <article className='Content'>
            <div className='HeaderClose'>
              <h1 className='ModalHeader Ellipsis'>Additional Cost Confirmation</h1>
              <svg xmlns="http://www.w3.org/2000/svg" width="1.5rem" height="1.5rem" viewBox="0 0 30 30" fill="none" onClick={onClose}>
                <path d="M16.7624 15.0002L24.6374 7.13773C24.8728 6.90235 25.005 6.58311 25.005 6.25023C25.005 5.91735 24.8728 5.59811 24.6374 5.36273C24.402 5.12735 24.0828 4.99512 23.7499 4.99512C23.417 4.99512 23.0978 5.12735 22.8624 5.36273L14.9999 13.2377L7.13742 5.36273C6.90204 5.12735 6.5828 4.99512 6.24992 4.99512C5.91705 4.99512 5.5978 5.12735 5.36242 5.36273C5.12704 5.59811 4.99481 5.91735 4.99481 6.25023C4.99481 6.58311 5.12704 6.90235 5.36242 7.13773L13.2374 15.0002L5.36242 22.8627C5.24526 22.9789 5.15227 23.1172 5.08881 23.2695C5.02535 23.4218 4.99268 23.5852 4.99268 23.7502C4.99268 23.9152 5.02535 24.0786 5.08881 24.231C5.15227 24.3833 5.24526 24.5215 5.36242 24.6377C5.47863 24.7549 5.61688 24.8479 5.7692 24.9113C5.92153 24.9748 6.08491 25.0075 6.24992 25.0075C6.41494 25.0075 6.57832 24.9748 6.73064 24.9113C6.88297 24.8479 7.02122 24.7549 7.13742 24.6377L14.9999 16.7627L22.8624 24.6377C22.9786 24.7549 23.1169 24.8479 23.2692 24.9113C23.4215 24.9748 23.5849 25.0075 23.7499 25.0075C23.9149 25.0075 24.0783 24.9748 24.2306 24.9113C24.383 24.8479 24.5212 24.7549 24.6374 24.6377C24.7546 24.5215 24.8476 24.3833 24.911 24.231C24.9745 24.0786 25.0072 23.9152 25.0072 23.7502C25.0072 23.5852 24.9745 23.4218 24.911 23.2695C24.8476 23.1172 24.7546 22.9789 24.6374 22.8627L16.7624 15.0002Z" fill="black"/>
              </svg>
            </div>
            <span className='Hint'>Please review the details of the additional costs for the {data?.serviceprovider === 'CLIENT_1232' ? 'education' : data?.serviceprovider === 'CLIENT_1233' && 'employment' } verification</span>
            <div className='Details'>
              <p><b>Candidate Name :</b> {data?.candidate}</p>
              {/* <p><b>Additional Cost :</b> {data?.university_employer_amount}</p>
              <p><b>Operational Cost :</b> {data?.valiantt_operational_cost}</p> */}
              <div className='Breakdown'>
                <div>
                  <span>Additional Cost</span>
                  <span>₹ {data?.university_employer_amount}</span>
                </div>
                <div className='Total'>
                  <span>Total Cost</span>
                  <span>₹ {data?.tax_amount}</span>
                </div>
              </div>
            </div>
            <label>
              <input type='checkbox' checked={isChecked} onChange={handleCheckboxChange} />
              <span>I understand the importance of accepting/rejecting this additional cost.</span>
            </label>
            <div className='ButtonContainer'>
              <button className='Reject' onClick={() => {setApprovalType('rejected'); handleApproval('rejected')}}>
                {(updateAdditionalCostApprovalLoading && ApprovalType === 'rejected') ? <ButtonSpinner/> : 'Reject'}
              </button>
              <button onClick={() => {setApprovalType('approved'); handleApproval('approved')}}>
                {(updateAdditionalCostApprovalLoading && ApprovalType === 'approved') ? <ButtonSpinner/> : 'Accept'}
              </button>
            </div>
          </article>
        </main>
        :
        <CustomAlert type={AlertInfo?.SuccessFailed} message={AlertInfo?.SuccessFailedMessage} handleClose={() => {setAlertInfo({Alert: false, SuccessFailed: '', SuccessFailedMessage: ''})}}/>
      }
    </>
  )
}

export default AdditionalCostConfirmationModal