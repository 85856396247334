import React,{useEffect, useState} from 'react'
import './AdditionalCostList.scss'
import { useDispatch, useSelector } from 'react-redux';
import { listAdditionalCostVendor } from '../../redux/actions/CompanyAdditionalCostAction';
import AdditionalCostConfirmationModal from './AdditionalCostConfirmationModal'
import Pagination from '../ReusableComponents/Pagination';
import FullPageLoader from '../Loader/FullPageLoader';
import Empty from '../Empty/Empty';

const AdditionalCostList = ({additionalData, setToggle}) => {
  const clientInfoID = localStorage.getItem("client_info_id");
  const dispatch = useDispatch();
  const [itemsPerPage, setItemsPerPage] = useState(10); // Default items per page
  const [requestPage, setRequestPage] = useState(1);
  const [data, setData] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState('all');
  const [totalItems, setTotalItems] = useState(0); // This should be set from API response
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [trigger, setTrigger] = useState(0);
  const listAdditionalCostVendorLoading = useSelector(state => state.listAdditionalCostVendor?.loading)

  useEffect(()=>{
    if(additionalData !== null){
      setData(additionalData);
    }
  },[additionalData])

  useEffect(() => {

    if(additionalData === null){
    
      if(!clientInfoID){
        return;
      }
      const payload = {
        client_info_id: clientInfoID,
        limit: itemsPerPage,
        offset: (requestPage - 1) * itemsPerPage,
        status: selectedStatus,
      };

      const successFn = (response) => {
        setData(response.data);
        setTotalItems(response?.additional_cost_count);
      };

      const failureFn = (error) => {
        console.error('Failed to save data', error);
      };

      dispatch(listAdditionalCostVendor({ data: payload, successFn, failureFn }));
    }
  }, [itemsPerPage, requestPage, selectedStatus, dispatch, trigger]);


  const addTrigger = () =>{
    setTrigger(trigger + 1);
  }
  const handleChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const handleItemsPerPageChange = (event) => {
    setItemsPerPage(Number(event.target.value));
    setRequestPage(1); // Reset to first page when items per page changes
  };

  const totalPages = Math.ceil(totalItems / itemsPerPage);
  const capitalizeText = (text) => {
    return text
      .toLowerCase()
      .replace(/(^|\s)\w/g, (match) => match.toUpperCase());
  };

  // Handle click when `is_approved` is "waiting"
  const handleClick = (item) => {
    if (item.is_approved === "waiting") {
      setSelectedItem(item); // Set the additional_cost_id
      setIsModalOpen(true); // Open the modal
    }
  };

  return (
    <main className='AdditionalCostList'>
      {additionalData === null &&
        <header className='Header'>
          <div className='FilterContainer'>
            <select value={selectedStatus} onChange={handleChange}>
              <option value="all">All</option>
              <option value="approved">Approved</option>
              <option value="rejected">Rejected</option>
              <option value="waiting">Waiting</option>
            </select>
          </div>
        </header>
      }
      <section className='AdditionalCostCont'>
        {listAdditionalCostVendorLoading ?
          <FullPageLoader/>
        :
          ((data && data?.length > 0) ?
            (data?.map((item, index) => (
              <article key={index} className='AdditionalCost'>
                <span className='Name Ellipsis'>{item.candidate}</span>
                <section>
                  <div className='LabelValue'>
                    <span>Check Name</span>
                    <span className='Ellipsis'>{item.serviceprovider === 'CLIENT_1232' ? 'Education Check' : item.serviceprovider === 'CLIENT_1233' ? 'Employment Check' : item.serviceprovider}</span>
                  </div>
                  <div className='LabelValue'>
                    <span>Institution Name</span>
                    <span className='Ellipsis'>{item.university_employer_name}</span>
                  </div>
                  <div className='LabelValue'>
                    <span>Operation Cost</span>
                    <span>{item.valiantt_operational_cost}</span>
                  </div>
                  <div className='LabelValue'>
                    <span>University Cost</span>
                    <span>{item.university_employer_amount}</span>
                  </div>
                </section>
                <span
                  className={`Status ${item.is_approved}`}
                  style={{ cursor: item.is_approved === "waiting" ? 'pointer' : 'default' }}
                  onClick={()=>handleClick(item)}
                >
                  {capitalizeText(item.is_approved)}
                </span>
              </article>
            )))
            :
            <Empty/>
          )
        }
      </section>
      {additionalData === null &&
        ((data && data?.length > 0) &&
          <Pagination
            currentPage={requestPage}
            totalPages={totalPages}
            onPageChange={(page) => setRequestPage(page)}
            dataPerPage={itemsPerPage}
            setDataPerPage={setItemsPerPage}
          />
        )
      }
      {isModalOpen && (
        <AdditionalCostConfirmationModal
          trigger={addTrigger}
          toggle={setToggle}
          data={selectedItem}
          onClose={() => setIsModalOpen(false)}
        />
      )}
    </main>
  )
}

export default AdditionalCostList