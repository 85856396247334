// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ButtonSpinner {
  border: 0.125rem solid #ffffff;
  border-bottom: none;
  border-right: none;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/ReusableComponents/ButtonSpinner.scss"],"names":[],"mappings":"AAAA;EACE,8BAAA;EACA,mBAAA;EACA,kBAAA;EACA,kBAAA;EACA,kCAAA;AACF;;AAEA;EACE;IACE,yBAAA;EACF;AACF","sourcesContent":[".ButtonSpinner{\n  border: 0.125rem solid #ffffff;\n  border-bottom: none;\n  border-right: none;\n  border-radius: 50%;\n  animation: spin 1s linear infinite;\n}\n\n@keyframes spin {\n  to {\n    transform: rotate(360deg);\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
