import React, { useState } from "react";
import Login from "../Components/Login/Login";
import Clientroute from "./Clientroute";
import Companyroute from "./Companyroute";
import { Navigate, Route, Routes } from "react-router-dom";
import ResetPassword from "../Components/Login/ResetPassword/ResetPassword";
import { createTheme, ThemeProvider } from "@mui/material";

const AppRouter = () => {
  const token = localStorage.getItem("token");
  const [darkMode, setDarkMode] = useState(
    localStorage.getItem("Theme") === "true" ? true : false
  );
  const IsMaster = localStorage.getItem("IsMaster");
  const handleThemeChange = () => {
    localStorage.setItem("Theme", !darkMode);
    setDarkMode(!darkMode);
  };
  const lightTheme = createTheme({
    palette: {
      mode: "light",
      primary: {
        main: "#102C97", // Light mode primary color
      },
      secondary: {
        main: "#ff4081", // Example secondary color, adjust as needed
      },
      error: {
        main: "#f44336",
      },
      warning: {
        main: "#ffa726",
      },
      info: {
        main: "#29b6f6",
      },
      success: {
        main: "#66bb6a",
      },
      background: {
        default: "#f4f6f8",
        paper: "#ffffff",
      },
      text: {
        primary: "#333333", // Example text color, adjust as needed
        secondary: "#666666",
      },
      divider: "#e0e0e0",
      action: {
        active: "#102C97",
        hover: "#f4f6f8",
        selected: "#f4f6f8",
        disabled: "#cccccc",
        disabledBackground: "#e0e0e0",
      },
      grey: {
        50: "#fafafa",
        100: "#f5f5f5",
        200: "#eeeeee",
        300: "#e0e0e0",
        400: "#bdbdbd",
        500: "#9e9e9e",
        600: "#757575",
        700: "#616161",
        800: "#424242",
        900: "#212121",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ].join(","),
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  });

  const darkTheme = createTheme({
    palette: {
      mode: "dark",
      primary: {
        main: "#09A5E0", // Dark mode primary color
      },
      secondary: {
        main: "#ff4081", // Example secondary color, adjust as needed
      },
      error: {
        main: "#ef5350",
      },
      warning: {
        main: "#ffb74d",
      },
      info: {
        main: "#4fc3f7",
      },
      success: {
        main: "#81c784",
      },
      background: {
        default: "#121212",
        paper: "#1d1d1d",
      },
      text: {
        primary: "#ffffff",
        secondary: "#bbbbbb",
      },
      divider: "#424242",
      action: {
        active: "#09A5E0",
        hover: "#333333",
        selected: "#424242",
        disabled: "#666666",
        disabledBackground: "#333333",
      },
      grey: {
        50: "#212121",
        100: "#424242",
        200: "#616161",
        300: "#757575",
        400: "#9e9e9e",
        500: "#bdbdbd",
        600: "#e0e0e0",
        700: "#eeeeee",
        800: "#f5f5f5",
        900: "#fafafa",
      },
    },
    typography: {
      fontFamily: [
        "-apple-system",
        "BlinkMacSystemFont",
        "Segoe UI",
        "Roboto",
        "Oxygen",
        "Ubuntu",
        "Cantarell",
        "Fira Sans",
        "Droid Sans",
        "Helvetica Neue",
        "sans-serif",
      ].join(","),
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  });

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className='App'>
        {token ? (
          <div className={`${darkMode ? "DarkTheme" : "LightTheme"}`}>
            {IsMaster === "1" ? (
              <Clientroute changeTheme={() => {handleThemeChange()}}/>
            ) : (
              <Companyroute
                changeTheme={() => {
                  handleThemeChange();
                }}
              />
            )}
          </div>
        ) : (
          <>
            <div className={`${darkMode ? "DarkTheme" : "LightTheme"}`}>
              <Routes>
                <Route
                  path='/'
                  exact
                  element={
                    <Login
                      changeTheme={() => {
                        handleThemeChange();
                      }}
                    />
                  }
                />
                <Route
                  path='*'
                  exact
                  element={<Navigate to='/' />}
                />
                <Route
                  path='/:component'
                  exact
                  element={
                    <Login
                      changeTheme={() => {
                        handleThemeChange();
                      }}
                    />
                  }
                />
                <Route
                  path='/:component/:username'
                  exact
                  element={
                    <Login
                      changeTheme={() => {
                        handleThemeChange();
                      }}
                    />
                  }
                />

                <Route
                  path='/:component/:token/:username'
                  exact
                  element={
                    <Login
                      changeTheme={() => {
                        handleThemeChange();
                      }}
                    />
                  }
                />
                <Route
                  path='/:component/:token/:username'
                  exact
                  element={
                    <Login
                      changeTheme={() => {
                        handleThemeChange();
                      }}
                    />
                  }
                />
              </Routes>
            </div>
          </>
        )}
      </div>
    </ThemeProvider>
  );
};

export default AppRouter;
