import { createSlice } from "@reduxjs/toolkit";
import {getPlan, candidateBeginVerificationOld, clientCancelOrderDetails, updateServiceProviderChecks,
  clientPaymentLinkGenerate, fetchExistingUser, fetchOrderDetails, candidateBeginVerificationUpdateOld
} from "../actions/CompanyBeginVerificationAction";

const generateExtraReducers = (builder, asyncThunk) => {
  builder
    .addCase(asyncThunk.pending, (state) => {
      state.loading = true;
      state.error = null;
    })
    .addCase(asyncThunk.fulfilled, (state, action) => {
      state.loading = false;
      state.data = action.payload;
      // Call the success callback if provided
      if (typeof action.meta.arg.successFn === "function") {
        action.meta.arg.successFn(action.payload);
      }
    })
    .addCase(asyncThunk.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
      // Call the failure callback if provided
      if (typeof action.meta.arg.failureFn === "function") {
        action.meta.arg.failureFn(action.error.message);
      }
    });
};

const createAsyncSlice = (name, asyncThunk) => createSlice({
  name,
  initialState: {
    loading: false,
    data: null,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => generateExtraReducers(builder, asyncThunk)
});



const getPlanSlice = createAsyncSlice("getPlan", getPlan);
const candidateBeginVerificationOldSlice = createAsyncSlice("candidateBeginVerificationOld", candidateBeginVerificationOld);
const clientCancelOrderDetailsSlice = createAsyncSlice("clientCancelOrderDetails", clientCancelOrderDetails);
const updateServiceProviderChecksSlice = createAsyncSlice("updateServiceProviderChecks", updateServiceProviderChecks);
const clientPaymentLinkGenerateSlice = createAsyncSlice("clientPaymentLinkGenerate", clientPaymentLinkGenerate);
const fetchExistingUserSlice = createAsyncSlice("fetchExistingUser", fetchExistingUser);
const fetchOrderDetailsSlice = createAsyncSlice("fetchOrderDetails", fetchOrderDetails);
const candidateBeginVerificationUpdateOldSlice = createAsyncSlice("candidateBeginVerificationUpdateOld", candidateBeginVerificationUpdateOld);

export const reducers = {
    getPlan: getPlanSlice.reducer,
    candidateBeginVerificationOld: candidateBeginVerificationOldSlice.reducer,
    candidateBeginVerificationUpdateOld: candidateBeginVerificationUpdateOldSlice.reducer,
    clientCancelOrderDetails: clientCancelOrderDetailsSlice.reducer,
    updateServiceProviderChecks: updateServiceProviderChecksSlice.reducer,
    clientPaymentLinkGenerate: clientPaymentLinkGenerateSlice.reducer,
    fetchExistingUser: fetchExistingUserSlice.reducer,
    fetchOrderDetails: fetchOrderDetailsSlice.reducer,
};