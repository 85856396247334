import React, { useEffect } from 'react'
import createImageViewer from '../../../ReusableComponents/ImagePreview'

const SignatureInformation = ({sign}) => {
  useEffect(() => {
    createImageViewer()
  },[])
  return (
    <main className='SignatureInformation'>
      {sign ?
        <>
          <span>Signature</span>
          <img src={sign} alt='Signature'/>
        </>
        :
        <span>No data</span>
      }
    </main>
  )
}

export default SignatureInformation