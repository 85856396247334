import React from 'react'
import './StatusStats.scss'
import ButtonLoader from '../Components/Loader/ButtonLoader'

const StatusStats = ({stats, handleInteraction}) => {
  const Stats = stats
  const handleStatsClick = (status) => {
    handleInteraction(status)
  }


  return (
    <main className='StatusStats'>
      <article className='Stat Total'>
        <div className='SvgCont'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M54.7 24H25.3C21.8221 24.0037 19.0038 26.6877 19 30V50C19.0038 53.3123 21.8221 55.9963 25.3 56H54.7C58.1779 55.9963 60.9962 53.3123 61 50V30C60.9962 26.6877 58.1779 24.0037 54.7 24ZM25.3 26H54.7C55.4771 26.001 56.1955 26.2163 56.8197 26.5671L42.2271 40.4648C40.9955 41.6329 39.0043 41.6329 37.7727 40.4648L23.1801 26.5671C23.8043 26.2163 24.5229 26.001 25.3 26ZM58.9 50C58.897 52.208 57.0184 53.9972 54.7 54H25.3C22.9816 53.9972 21.1029 52.208 21.1 50V30C21.101 29.2599 21.3271 28.5757 21.6955 27.9812L36.2881 41.8789C37.2717 42.8175 38.6076 43.344 40 43.3418C41.3924 43.3439 42.7282 42.8175 43.7118 41.8789L58.3045 27.9811C58.6729 28.5756 58.899 29.2599 58.9 30V50Z" fill="#102C98"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.total_invitation_sent : <ButtonLoader color={'#102C98'}/> }</span>
          <span>Total</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats.invitation !== 0 && handleStatsClick('invitation')}}>
        <div className='SvgCont invitation'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M54.7 24H25.3C21.8221 24.0037 19.0038 26.6877 19 30V50C19.0038 53.3123 21.8221 55.9963 25.3 56H54.7C58.1779 55.9963 60.9962 53.3123 61 50V30C60.9962 26.6877 58.1779 24.0037 54.7 24ZM25.3 26H54.7C55.4771 26.001 56.1955 26.2163 56.8197 26.5671L42.2271 40.4648C40.9955 41.6329 39.0043 41.6329 37.7727 40.4648L23.1801 26.5671C23.8043 26.2163 24.5229 26.001 25.3 26ZM58.9 50C58.897 52.208 57.0184 53.9972 54.7 54H25.3C22.9816 53.9972 21.1029 52.208 21.1 50V30C21.101 29.2599 21.3271 28.5757 21.6955 27.9812L36.2881 41.8789C37.2717 42.8175 38.6076 43.344 40 43.3418C41.3924 43.3439 42.7282 42.8175 43.7118 41.8789L58.3045 27.9811C58.6729 28.5756 58.899 29.2599 58.9 30V50Z" fill="#FDCB00"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.invitation : <ButtonLoader color={'#102C98'}/> }</span>
          <span>Invited</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats.accepted !== 0 && handleStatsClick('accepted')}}>
        <div className='SvgCont accepted'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M60.6665 33.6796L55.265 29.1656V22.7615C55.265 22.2086 54.7831 21.7864 54.2208 21.7864H46.3595L43.207 19.1524C41.3496 17.6142 38.6588 17.6142 36.8015 19.1624L33.6489 21.7864H25.6872C25.135 21.7864 24.643 22.1986 24.643 22.7615V29.2661L19.3419 33.6796C19.121 33.8706 18.9905 34.1621 19.0005 34.4537H19.0206V56.9632C19.0206 59.738 21.2495 62 24.0105 62H55.9879C58.7589 62 60.9778 59.7279 60.9778 56.9632V34.4537H60.9978C61.0179 34.1521 60.8974 33.8706 60.6665 33.6796ZM58.4276 34.4135L55.265 36.7659V31.7795L58.4276 34.4135ZM38.0866 20.7006C39.201 19.7757 40.8174 19.7857 41.9319 20.7107L43.207 21.7864H36.7914L38.0866 20.7006ZM53.257 23.797V38.2639L41.8214 46.7892C40.7371 47.5834 39.2713 47.5834 38.197 46.7892L26.651 38.1835V23.797H53.257ZM24.643 31.8699V36.6855L21.5808 34.4034L24.643 31.8699ZM55.9979 59.9893H24.0105C22.3539 59.9793 21.0186 58.6221 21.0286 56.9632V36.4543L37.0123 48.4078C38.7894 49.7248 41.219 49.7248 42.9961 48.4078L58.9798 36.4543V56.9532C58.9898 58.6221 57.6545 59.9793 55.9979 59.9893Z" fill="#FF1C68"/>
            <path d="M33.9722 33.2857H39.2222C39.7569 33.2857 40.1944 32.7714 40.1944 32.1429C40.1944 31.5143 39.7569 31 39.2222 31H33.9722C33.4375 31 33 31.5143 33 32.1429C33 32.7714 33.4375 33.2857 33.9722 33.2857ZM33.9722 39H46.0278C46.5625 39 47 38.4857 47 37.8571C47 37.2286 46.5625 36.7143 46.0278 36.7143H33.9722C33.4375 36.7143 33 37.2286 33 37.8571C33 38.4857 33.4375 39 33.9722 39Z" fill="#FF1C68"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.accepted : <ButtonLoader color={'#102C98'}/>}</span>
          <span>Accepted</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats.inprogress !== 0 && handleStatsClick('inprogress')}}>
        <div className='SvgCont inprogress'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M58.6827 52.2461L53.4394 46.9223C53.4394 46.9221 53.4396 46.9224 53.4394 46.9223C53.0164 46.493 52.3304 46.4927 51.9076 46.9223L44.1508 54.7984C43.9475 55.0047 43.8334 55.2844 43.8334 55.5762V60.9012C43.8338 61.5084 44.3187 62.0004 44.9167 62H50.1599C50.4473 62 50.7227 61.8841 50.9259 61.6777L58.6827 53.8016C58.6826 53.8017 58.6827 53.8014 58.6827 53.8016C59.1055 53.372 59.1056 52.6754 58.6827 52.2461ZM49.7114 59.8H46.0001V56.0316L52.6736 49.2553L56.385 53.0238L49.7114 59.8ZM29.75 48.8C29.1518 48.8 28.6667 49.2925 28.6667 49.9C28.6667 50.5075 29.1518 51 29.75 51H36.25C36.8483 51 37.3334 50.5075 37.3334 49.9C37.3334 49.2925 36.8483 48.8 36.25 48.8H29.75ZM46.0001 41.1C46.0001 40.4925 45.515 40 44.9167 40H29.75C29.1518 40 28.6667 40.4925 28.6667 41.1C28.6667 41.7075 29.1518 42.2 29.75 42.2H44.9167C45.515 42.2 46.0001 41.7075 46.0001 41.1ZM29.75 33.4H34.0834C34.6816 33.4 35.1667 32.9075 35.1667 32.3C35.1667 31.6925 34.6816 31.2 34.0834 31.2H29.75C29.1518 31.2 28.6667 31.6925 28.6667 32.3C28.6667 32.9075 29.1518 33.4 29.75 33.4ZM38.4167 59.8H26.5C24.108 59.7969 22.1697 57.8288 22.1667 55.4V24.6C22.1697 22.1712 24.108 20.2031 26.5 20.2H39.5001V28.2674C39.5036 31.1005 41.7647 33.3964 44.5549 33.4H52.5001V41.1C52.5001 41.7075 52.9852 42.2 53.5834 42.2C54.1817 42.2 54.6668 41.7075 54.6668 41.1V32.3C54.6668 32.0082 54.5526 31.7285 54.3494 31.5223L41.3493 18.3223C41.1462 18.1159 40.8708 18 40.5834 18H26.5C22.9117 18.004 20.004 20.9565 20 24.6V55.4C20.004 59.0435 22.9117 61.996 26.5 62H38.4167C39.015 62 39.5001 61.5075 39.5001 60.9C39.5001 60.2925 39.015 59.8 38.4167 59.8ZM41.6667 21.7553L50.9683 31.2H44.5549C42.9603 31.1987 41.6681 29.8865 41.6667 28.2674V21.7553Z" fill="#00C2FF"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.inprogress : <ButtonLoader color={'#102C98'}/>}</span>
          <span>In-Progress</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats.pending !== 0 && handleStatsClick('pending')}}>
        <div className='SvgCont pending'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M43 58H30C27.792 57.9972 26.0028 56.208 26 54V26C26.0028 23.792 27.792 22.0028 30 22H42V29.334C42.0033 31.9095 44.0905 33.9967 46.666 34H54V47C54 47.5522 54.4478 48 55 48C55.5522 48 56 47.5522 56 47V33C56 32.7347 55.8947 32.4805 55.707 32.293L43.707 20.293C43.5195 20.1053 43.2653 20 43 20H30C26.6877 20.0037 24.0037 22.6877 24 26V54C24.0037 57.3123 26.6877 59.9963 30 60H43C43.5522 60 44 59.5522 44 59C44 58.4478 43.5522 58 43 58ZM44 23.4139L52.5861 32H46.666C45.1941 31.9988 44.0012 30.8059 44 29.334V23.4139ZM33 32C32.4478 32 32 32.4478 32 33C32 33.5522 32.4478 34 33 34H37C37.5522 34 38 33.5522 38 33C38 32.4478 37.5522 32 37 32H33ZM43 48H33C32.4478 48 32 48.4478 32 49C32 49.5522 32.4478 50 33 50H43C43.5522 50 44 49.5522 44 49C44 48.4478 43.5522 48 43 48ZM47 40H33C32.4478 40 32 40.4478 32 41C32 41.5522 32.4478 42 33 42H47C47.5522 42 48 41.5522 48 41C48 40.4478 47.5522 40 47 40Z" fill="#7442F6"/>
            <path d="M48.8562 60C48.3834 60 48 59.545 48 58.9837L48 51.0163C48 50.455 48.3834 50 48.8562 50C49.329 50 49.7123 50.455 49.7123 51.0163L49.7123 58.9837C49.7123 59.545 49.329 60 48.8562 60Z" fill="#7442F6"/>
            <path d="M52.1438 60C51.671 60 51.2877 59.545 51.2877 58.9837V51.0163C51.2877 50.455 51.671 50 52.1438 50C52.6166 50 53 50.455 53 51.0163V58.9837C53 59.545 52.6166 60 52.1438 60Z" fill="#7442F6"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.pending : <ButtonLoader color={'#102C98'}/>}</span>
          <span>Pending</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats.completed !== 0 && handleStatsClick('completed')}}>
        <div className='SvgCont completed'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M42.506 57.1H29.844C27.6934 57.0973 25.9507 55.3976 25.948 53.3V26.7C25.9507 24.6024 27.6934 22.9027 29.844 22.9H41.532V29.8673C41.5352 32.3141 43.5681 34.2969 46.0767 34.3H53.22V46.65C53.22 47.1746 53.6561 47.6 54.194 47.6C54.7319 47.6 55.168 47.1746 55.168 46.65V33.35C55.168 33.098 55.0654 32.8564 54.8826 32.6783L43.1946 21.2783C43.012 21.1001 42.7644 21 42.506 21H29.844C26.6179 21.0035 24.0036 23.5534 24 26.7V53.3C24.0036 56.4466 26.6179 58.9965 29.844 59H42.506C43.0439 59 43.48 58.5746 43.48 58.05C43.48 57.5254 43.0439 57.1 42.506 57.1ZM43.48 24.2432L51.8428 32.4H46.0767C44.643 32.3988 43.4812 31.2656 43.48 29.8673V24.2432ZM32.766 32.4C32.2281 32.4 31.792 32.8254 31.792 33.35C31.792 33.8746 32.2281 34.3 32.766 34.3H36.662C37.1999 34.3 37.636 33.8746 37.636 33.35C37.636 32.8254 37.1999 32.4 36.662 32.4H32.766ZM60.7265 49.8016C60.3528 49.4243 59.7362 49.4138 59.3493 49.7783L52.246 56.7066L49.0385 53.5784C48.6611 53.2228 48.0626 53.2228 47.6852 53.5784C47.2982 53.9429 47.2876 54.5443 47.6613 54.9217L51.5573 58.7217C51.7397 58.9002 51.9876 59.0003 52.246 59C52.5043 59.0002 52.7521 58.9002 52.9345 58.7217L60.7265 51.1217C61.0912 50.7535 61.0912 50.1698 60.7265 49.8016ZM42.506 47.6H32.766C32.2281 47.6 31.792 48.0254 31.792 48.55C31.792 49.0746 32.2281 49.5 32.766 49.5H42.506C43.0439 49.5 43.48 49.0746 43.48 48.55C43.48 48.0254 43.0439 47.6 42.506 47.6ZM46.402 40H32.766C32.2281 40 31.792 40.4254 31.792 40.95C31.792 41.4746 32.2281 41.9 32.766 41.9H46.402C46.9399 41.9 47.376 41.4746 47.376 40.95C47.376 40.4254 46.9399 40 46.402 40Z" fill="#3FEF3B"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.completed : <ButtonLoader color={'#102C98'}/>}</span>
          <span>Completed</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats.cancelled !== 0 && handleStatsClick('cancelled')}}>
        <div className='SvgCont cancelled'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M41.8076 60.8808H26.4764C23.8724 60.8775 21.7624 58.8021 21.759 56.2408V23.7608C21.7624 21.1995 23.8724 19.124 26.4764 19.1208H40.6283V27.6282C40.6322 30.6159 43.0936 33.037 46.1311 33.0408H54.7803V48.1208C54.7803 48.7614 55.3083 49.2808 55.9596 49.2808C56.6109 49.2808 57.1389 48.7614 57.1389 48.1208V31.8808C57.1389 31.5731 57.0147 31.2781 56.7934 31.0606L42.6415 17.1406C42.4203 16.923 42.1205 16.8008 41.8076 16.8008H26.4764C22.5701 16.805 19.4047 19.9186 19.4004 23.7608V56.2408C19.4047 60.083 22.5701 63.1965 26.4764 63.2008H41.8076C42.4589 63.2008 42.987 62.6814 42.987 62.0408C42.987 61.4002 42.4589 60.8808 41.8076 60.8808ZM42.987 20.761L53.1128 30.7208H46.1311C44.3952 30.7194 42.9884 29.3356 42.987 27.6282V20.761ZM30.0144 30.7208C29.3631 30.7208 28.835 31.2402 28.835 31.8808C28.835 32.5214 29.3631 33.0408 30.0144 33.0408H34.7317C35.3829 33.0408 35.911 32.5214 35.911 31.8808C35.911 31.2402 35.3829 30.7208 34.7317 30.7208H30.0144ZM63.8692 51.9691C63.4168 51.5083 62.6702 51.4956 62.2017 51.9406L53.6009 60.4005L49.7173 56.5808C49.2604 56.1465 48.5357 56.1465 48.0787 56.5808C47.6101 57.0258 47.5973 57.7602 48.0498 58.2209L52.7671 62.8609C52.9879 63.0789 53.2881 63.2012 53.6009 63.2008C53.9138 63.2011 54.2138 63.0789 54.4346 62.8609L63.8692 53.5809C64.3108 53.1314 64.3108 52.4187 63.8692 51.9691ZM41.8076 49.2808H30.0144C29.3631 49.2808 28.835 49.8002 28.835 50.4408C28.835 51.0814 29.3631 51.6008 30.0144 51.6008H41.8076C42.4589 51.6008 42.987 51.0814 42.987 50.4408C42.987 49.8002 42.4589 49.2808 41.8076 49.2808ZM46.525 40.0008H30.0144C29.3631 40.0008 28.835 40.5202 28.835 41.1608C28.835 41.8014 29.3631 42.3208 30.0144 42.3208H46.525C47.1762 42.3208 47.7043 41.8014 47.7043 41.1608C47.7043 40.5202 47.1762 40.0008 46.525 40.0008Z" fill="#EF5B3B"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats?.cancelled : <ButtonLoader color={'#102C98'}/>}</span>
          <span>Cancelled</span>
        </div>
      </article>
      <article className='Stat' onClick={() => {Stats['re-initiated'] !== 0 && handleStatsClick('re-initiated')}}>
        <div className='SvgCont re-initiated'>
          <svg xmlns="http://www.w3.org/2000/svg" width="2.5rem" height="2.5rem" viewBox="0 0 80 80" fill="none">
            <path d="M54.7 24H25.3C21.8221 24.0037 19.0038 26.6877 19 30V50C19.0038 53.3123 21.8221 55.9963 25.3 56H54.7C58.1779 55.9963 60.9962 53.3123 61 50V30C60.9962 26.6877 58.1779 24.0037 54.7 24ZM25.3 26H54.7C55.4771 26.001 56.1955 26.2163 56.8197 26.5671L42.2271 40.4648C40.9955 41.6329 39.0043 41.6329 37.7727 40.4648L23.1801 26.5671C23.8043 26.2163 24.5229 26.001 25.3 26ZM58.9 50C58.897 52.208 57.0184 53.9972 54.7 54H25.3C22.9816 53.9972 21.1029 52.208 21.1 50V30C21.101 29.2599 21.3271 28.5757 21.6955 27.9812L36.2881 41.8789C37.2717 42.8175 38.6076 43.344 40 43.3418C41.3924 43.3439 42.7282 42.8175 43.7118 41.8789L58.3045 27.9811C58.6729 28.5756 58.899 29.2599 58.9 30V50Z" fill="#01af87"/>
          </svg>
        </div>
        <div className='Value'>
          <span>{Stats ? Stats['re-initiated'] : <ButtonLoader color={'#102C98'}/>}</span>
          <span>Re-initiated</span>
        </div>
      </article>
    </main>
  )
}

export default StatusStats