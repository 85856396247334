import React from 'react';
import PdfViewer from '../../../BeginVerification/PdfViewer/PdfViewer';
import createImageViewer from '../../../ReusableComponents/ImagePreview';
import { useEffect } from 'react';

const ResidenceInformation = ({details}) => {
  const PermanentAddress = Object.keys(details)?.length > 0 && details?.[details?.findIndex(item => item.permanentAddress)]?.permanentAddress
  const TemporaryAddress = Object.keys(details)?.length > 0 && details?.[details?.findIndex(item => item.temporaryAddress)]?.temporaryAddress
  useEffect(() => {
    createImageViewer()
  },[])
  const displayPrevImg = (value) => {
    const splitedVal = value.split('.');
    if(splitedVal[splitedVal.length - 1] === 'pdf'){
      return <PdfViewer pdf={value} />
    }
    else if(splitedVal[splitedVal.length - 1] === 'jpg' || splitedVal[splitedVal.length - 1] === 'jpeg' || splitedVal[splitedVal.length - 1] === 'png'){
      return <img src={value} />
    }
    else if(splitedVal[splitedVal.length - 1] === 'doc' || splitedVal[splitedVal.length - 1] === 'docx' || splitedVal[splitedVal.length - 1] === 'document'){
      return <p>There is no preview for Document file</p>
    }
  }
  return (
    <main className='ResidenceInformation'>
      {(PermanentAddress && Object.keys(PermanentAddress)?.length > 0) &&
        <div className='addressPreviewDiv'>
          <h1>Permanent Address</h1>
          <div className='Details'>
            <div className='LabelValue'>
              <span className='Label'>House number/Building name</span>
              <span className='Value'>{PermanentAddress?.house_number}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Street Name</span>
              <span className='Value'>{PermanentAddress?.street_name}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Landmark</span>
              <span className='Value'>{PermanentAddress?.landmark}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>City</span>
              <span className='Value'>{PermanentAddress?.city}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>State</span>
              <span className='Value'>{PermanentAddress?.state}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Country</span>
              <span className='Value'>{PermanentAddress?.country}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Postal Code</span>
              <span className='Value'>{PermanentAddress?.pincode}</span>
            </div>
            
          </div>
          {PermanentAddress?.utils_bills?.length > 0 && 
            <>
              <h3>Utility Bills</h3>
              <section className='Documents'>
                {PermanentAddress?.utils_bills.length > 0 &&
                  <>
                    {PermanentAddress?.utils_bills.map((val, idx) => (
                      <div key={idx} className='imgPrev'>
                        {displayPrevImg(val)}
                      </div>
                    ))}
                  </>
                }
              </section>
            </>
          }
        </div>
      }
      {(TemporaryAddress && Object.keys(TemporaryAddress)?.length > 0) &&
        <div className='addressPreviewDiv'>
          <h1>Temporary Address</h1>
          <div className='Details'>
            <div className='LabelValue'>
              <span className='Label'>House number/Building name</span>
              <span className='Value'>{TemporaryAddress?.house_number}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Street Name</span>
              <span className='Value'>{TemporaryAddress?.street_name}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Landmark</span>
              <span className='Value'>{TemporaryAddress?.landmark}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>City</span>
              <span className='Value'>{TemporaryAddress?.city}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>State</span>
              <span className='Value'>{TemporaryAddress?.state}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Country</span>
              <span className='Value'>{TemporaryAddress?.country}</span>
            </div>
            <div className='LabelValue'>
              <span className='Label'>Postal Code</span>
              <span className='Value'>{TemporaryAddress?.pincode}</span>
            </div>
          </div>
          {TemporaryAddress?.utils_bills?.length > 0 &&
          <>
          <h1>Utility Bills</h1>
          <section className='Documents'>
            {TemporaryAddress?.utils_bills.length > 0 &&
              <>
                {TemporaryAddress?.utils_bills?.map((val, idx) => (
                  <div key={idx} className='imgPrev'>
                    {displayPrevImg(val)}
                  </div>
                ))}
              </>
            }
          </section>
          </>}
        </div>
      }
    </main>
  )
}

export default ResidenceInformation