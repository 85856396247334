import React, { useEffect, useState } from 'react';
import { ResponsiveRadialBar } from '@nivo/radial-bar';
import { linearGradientDef } from '@nivo/core';

function RadialChart({marginValue,data}) {

    const radialBarData = [
      
          {
            "data": [
              {
                "x": "Completion Rate",
                "y": data.positive_per > 10 ? data.positive_per : 10, // Fallback to 0 if not available
              },
            ],
          },
          {
            "data": [
              {
                "x": "TAT Breach Rate",
                "y":  data.negative_per ,
              },
            ],
          }
          
      ];


      const dat = [
        {
        //   "id": "TAT Breach Rate",
          "data": [
              {
                "x": "TAT Breach Rate",
                "y":  data.negative_per ,
              },
              {
                "x": "Completion Rate",
                "y": data.positive_per,
              }
            ]
        },
        // {
        //     "id": "Combini",
        //     "data": [
        //       {
        //         "x": "Completion Rate",
        //         "y": data.positive_per > 10 ? data.positive_per : 10,
        //       }
        //     ]
        //   }
      ]
    //   const bgColor = getComputedStyle(document.documentElement).getPropertyValue('--Background').trim();
    //   const completedColor = getComputedStyle(document.documentElement).getPropertyValue('--Completed').trim();
    // const cancelColor = getComputedStyle(document.documentElement).getPropertyValue('--Cancelled').trim();

    const DarkMode = localStorage.getItem('Theme') === 'true' ? true : false

    var completedColor;
    var cancelColor;
    if(DarkMode){
      var completedColor = "#FF43434D";
      var cancelColor = "#1EFF274D";
    }
    else{
      var completedColor = "#FFBDBD";
      var cancelColor = "#C0FFC3";
    }
    

  return (
    <>
      <ResponsiveRadialBar
        data={dat}
        valueFormat=" >-.2f"
        endAngle={360}
        innerRadius={0.4}
        padding={0.4}
        isInteractive={true}
        padAngle={0}
        cornerRadius={2}
        colors={[completedColor, cancelColor]}
        margin={marginValue}
        borderColor={{
            from: 'color',
            modifiers: [
                [
                    'darker',
                    '1'
                ]
            ]
        }}
        enableRadialGrid={false}
        enableCircularGrid={false}
        radialAxisStart={null}
        circularAxisOuter={null}
        tooltip={(data) => (
          <div
                        style={{
                            color: 'var(--TextColor)',
                            textAlign:'center',
                            fontSize:'.6rem',
                        }}
                    >
                        <strong>{`${data?.bar?.category}: ${data?.bar?.value}`}</strong>
                    </div>
        )}
        
        // legends={[
        //     {
        //         anchor: 'right',
        //         direction: 'column',
        //         justify: false,
        //         translateX: 80,
        //         translateY: 0,
        //         itemsSpacing: 6,
        //         itemDirection: 'left-to-right',
        //         itemWidth: 100,
        //         itemHeight: 18,
        //         itemTextColor: '#999',
        //         symbolSize: 18,
        //         symbolShape: 'square',
        //         effects: [
        //             {
        //                 on: 'hover',
        //                 style: {
        //                     itemTextColor: '#000'
        //                 }
        //             }
        //         ]
        //     }
        // ]}
        defs={[
            linearGradientDef('gradientC', [
                { offset: 0, color: '#DAFFF6' },
                { offset: 100, color: '#42CB6F' },
            ], {
                x1: 0,
                y1: 1,
                x2: 0,
                y2: 0,
            }),
        ]}
        fill={[
            { match: d => d.id === 'Supermarket', id: 'gradientC' },
        ]}
    />
    </>
  )
}

export default RadialChart;
