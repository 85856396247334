import './BackGround.scss';
import React, { useState, useEffect } from 'react';
import img1 from '../../../assets/images/Component1.svg';
import img2 from '../../../assets/images/Component2.svg';
import img3 from '../../../assets/images/Component3.svg';
import img4 from '../../../assets/images/Component4.svg';

function BackGround() {
    const [bgImageIndex, setBgImageIndex] = useState(0);
    const [animation, setAnimation] = useState(false);
    const classes = ['background-image-1', 'background-image-2', 'background-image-3', 'background-image-4'];
    const images = [img2, img3, img4, img1];
    
    useEffect(() => {
        const interval = setInterval(() => {
            setAnimation(true);
            setTimeout(() => {
                setBgImageIndex(prevIndex => (prevIndex + 1) % classes.length);
                setAnimation(false);
            }, 500); 
        }, 3000); 

        return () => clearInterval(interval);
    }, [classes.length]);

    const backGroundContent = () => {
        switch(bgImageIndex) {
            case 0:
                return (
                    <div>
                        <h3>Comprehensive Background <br />Verification Services</h3>
                        <p>Offering a wide range of background verification services tailored to meet your specific needs, ensuring thorough and reliable results for every candidate.</p>
                        {/* <img src={img1} alt="" /> */}
                    </div>
                );
            case 1:
                return (
                    <div>
                        <h3>Dedicated Customer Support</h3>
                        <p>Our dedicated support team is always ready to assist you, ensuring smooth and hassle-free verification processes.</p>
                        {/* <img src={img2} alt="" /> */}
                    </div>
                );
            case 2:
                return (
                    <div>
                        <h3>Customizable Solutions</h3>
                        <p>Tailored Solutions for Every Business. Flexibility to fit your needs.</p>
                        {/* <img src={img3} alt="" /> */}
                    </div>
                );
            case 3:
                return (
                    <div>
                        <h3>Secure and Confidential</h3>
                        <p>We prioritize the security and confidentiality of your data, employing state-of-the-art encryption and security protocols.</p>
                        {/* <img src={img4} alt="" /> */}
                    </div>
                );
            default:
                return null;
        }
    };

    return (
        <div className="loginContainer">
            <div className={`loginBg ${animation ? 'slideOut' : 'slideIn'} ${classes[bgImageIndex]}`}>
                <div className='loginComp'>
                    {backGroundContent()}
                    <img src={images[bgImageIndex]} alt="" />
                </div>
            </div>
        </div>
    );
}

export default BackGround;
