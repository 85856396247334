import React, { useEffect, useState } from 'react'
import './ClientPackList.scss'
import { useDispatch, useSelector } from 'react-redux'
import { GetPacksListClient } from '../redux/actions/ClientAction'
import FullPageLoader from '../Components/Loader/FullPageLoader'

const ClientPackList = () => {
  const dispatch = useDispatch()
  const MasterId = localStorage.getItem("user_id");
  const companyPlansLoading = useSelector((state) => state.GetPacksListClient?.loading)
  const [RecallPlans, setRecallPlans] = useState(false)

  const [searchTerm, setSearchTerm] = useState('');
  const [planDetailsResp, setPlanDetailsResp] = useState({}); // Initialize as an object
  const [filteredPlans, setFilteredPlans] = useState({});

  useEffect(() => {
    setFilteredPlans(
      Object.keys(planDetailsResp)
        .filter(key => key !== 'Custom package' && key.toLowerCase().includes(searchTerm.toLowerCase()))
        .reduce((obj, key) => {
          obj[key] = planDetailsResp[key];
          return obj;
        }, {})
    );
  }, [planDetailsResp, searchTerm]);


  const filteredKeys = Object.keys(planDetailsResp)
    .filter(key => key.toLowerCase().includes(searchTerm.toLowerCase()));



  useEffect(() => {
    fetchPlanDetails();
  }, [MasterId]);

  const fetchPlanDetails = () => {
    const data = {
      master_id: MasterId
    };

    const successFn = (response) => {
      console.log('Plan fetched successfully', response.data);
      setPlanDetailsResp(response.data)
      setRecallPlans(false)
    };

    const failureFn = (error) => {
      console.error('Logged-in failed', error);
    };

    dispatch(GetPacksListClient({ data, successFn, failureFn }));
  };


  useEffect(()=> {
    if(RecallPlans) {
      fetchPlanDetails()
    }
  },[RecallPlans])

  return (
    <main className='ClientPackList'>
      {(companyPlansLoading) &&
        <FullPageLoader/>
      }
      <section className='SearchContainer'>
        <div className='Input'>
          <input type='text' placeholder='Search Pack Name' value={searchTerm} onChange={(e) => setSearchTerm(e.target.value)}/>
          <svg xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 32 32" fill="none">
            <path d="M27.6133 25.72L23.08 21.2C24.5427 19.3366 25.3363 17.0355 25.3333 14.6667C25.3333 12.557 24.7077 10.4947 23.5357 8.74059C22.3636 6.98646 20.6977 5.61929 18.7486 4.81196C16.7996 4.00462 14.6548 3.79339 12.5857 4.20496C10.5166 4.61654 8.61596 5.63244 7.1242 7.1242C5.63244 8.61596 4.61654 10.5166 4.20496 12.5857C3.79339 14.6548 4.00462 16.7996 4.81196 18.7486C5.61929 20.6977 6.98646 22.3636 8.74059 23.5357C10.4947 24.7077 12.557 25.3333 14.6667 25.3333C17.0355 25.3363 19.3366 24.5427 21.2 23.08L25.72 27.6133C25.844 27.7383 25.9914 27.8375 26.1539 27.9052C26.3164 27.9729 26.4907 28.0077 26.6667 28.0077C26.8427 28.0077 27.017 27.9729 27.1794 27.9052C27.3419 27.8375 27.4894 27.7383 27.6133 27.6133C27.7383 27.4894 27.8375 27.3419 27.9052 27.1794C27.9729 27.017 28.0077 26.8427 28.0077 26.6667C28.0077 26.4907 27.9729 26.3164 27.9052 26.1539C27.8375 25.9914 27.7383 25.844 27.6133 25.72ZM6.66667 14.6667C6.66667 13.0844 7.13586 11.5377 8.01491 10.2221C8.89397 8.90652 10.1434 7.88114 11.6052 7.27564C13.067 6.67013 14.6755 6.51171 16.2274 6.82039C17.7792 7.12907 19.2047 7.891 20.3235 9.00982C21.4423 10.1286 22.2043 11.5541 22.513 13.1059C22.8216 14.6578 22.6632 16.2663 22.0577 17.7281C21.4522 19.1899 20.4268 20.4394 19.1112 21.3184C17.7956 22.1975 16.2489 22.6667 14.6667 22.6667C12.5449 22.6667 10.5101 21.8238 9.00982 20.3235C7.50953 18.8232 6.66667 16.7884 6.66667 14.6667Z" fill="#A2A2A2"/>
          </svg>
        </div>
      </section>
      {(filteredPlans && Object.keys(filteredPlans).length > 0) ? (
        <div className='PackContainerWrapper'>
          <section className='PackContainer'>
            {Object.keys(filteredPlans).map((key, index) => (
              <div className='Pack' key={index}>
                <h3 title={key}>{key}</h3>
                {filteredPlans[key].checks.map((item, idx) => (
                  <div className='Checks' key={idx}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 17 17" fill="none">
                      <path d="M8.5 0C3.80457 0 0 3.80457 0 8.5C0 13.1954 3.80457 17 8.5 17C13.1954 17 17 13.1954 17 8.5C17 3.80457 13.1954 0 8.5 0ZM7.18822 11.5118C7.09014 11.6099 6.9512 11.6916 6.82861 11.6916C6.70601 11.6916 6.56707 11.6058 6.4649 11.5077L4.17644 9.21923L4.90385 8.49183L6.83269 10.4207L11.9327 5.28389L12.6478 6.02356L7.18822 11.5118Z" fill="#102C98"/>
                    </svg>
                    <span>{item.display_name}</span>
                  </div>
                  ))}
              </div>
            ))}
          </section>
        </div>
      )  : (
        <div className='NoData'>
          {searchTerm ?
            <span>No packs found</span>
            :
            !companyPlansLoading &&
            <span>No packs found </span>
          }
        </div>
        )
      }
    </main>
  )
}

export default ClientPackList