import { useEffect, useState, useRef } from "react";
import { Document, Page } from "react-pdf";
import { pdfjs } from "react-pdf";
import "./PdfViewer.scss";
import CustomAlert from "../../ReusableComponents/CustomAlert";
// import useImageFullscreen from "../../../ImageFullScreenViewer";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.mjs",
  import.meta.url
).toString();

function PdfViewer({ pdf, showPagination = true }) {
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [fullScreen, setFullScreen] = useState(false);
  // const [hideScreen, setHideScreen] = useState(false);
  const [showAlert, setshowAlert] = useState(false)
  const clickOutside = useRef(null);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  useEffect(() => {
    if(showAlert){
      setTimeout(() => {  
        setFullScreen(false)
        setshowAlert(false)  
    }, 3000); 
    }
  },[showAlert])

  useEffect(() => {
    const disableShortcuts = (e) => {

      if (e.keyCode === 44 || ((e.ctrlKey || e.metaKey) && e.key.toLowerCase() === "s")) { 
        setshowAlert(true)
        e.preventDefault();
      } 
      
    };

    document.addEventListener("keyup", disableShortcuts);
    
    return () => document.removeEventListener("keyup", disableShortcuts);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        clickOutside.current &&
        !clickOutside.current.contains(event.target)
      ) {
        setFullScreen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  
  return (
    <>
      <div className="pdfBg" onClick={() => setFullScreen(true)}>
        <Document
          file={pdf}
          className="pdfContainer"
          onLoadSuccess={onDocumentLoadSuccess}
          loading={<div className="loadingText">Loading PDF ...</div>}
        >
          {showPagination ? (
            <Page
              className="pdfPage"
              renderAnnotationLayer={false}
              renderTextLayer={false}
              pageNumber={pageNumber}
            />
          ) : (
            Array.from(new Array(numPages), (el, index) => (
              <Page
                key={`page_${index + 1}`}
                className="pdfPage"
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={index + 1}
              />
            ))
          )}
          <div className="upperLayer"></div>
        </Document>
        {showPagination ? (
          <div className="pagination">
            <p>
              Page {pageNumber} of {numPages}
            </p>
            <button
              disabled={pageNumber <= 1}
              onClick={() => setPageNumber((prev) => prev - 1)}
            >
              prev
            </button>
            <button
              disabled={pageNumber >= numPages}
              onClick={() => setPageNumber((prev) => prev + 1)}
            >
              next
            </button>
          </div>
        ) : (
          ""
        )}
      </div>
      {fullScreen && (
        <div className="fullScreenViewer">
          <div className="closeBtn" onClick={() => setFullScreen(false)}>
            X
          </div>
          <div id="pdfBg" ref={clickOutside}>
            <Document
              file={pdf}
              className="pdfContainer"
              onLoadSuccess={onDocumentLoadSuccess}
              loading={<div className="loadingText">Loading PDF ...</div>}
            >
              {showPagination ? (
                <Page
                  className="pdfPage"
                  renderAnnotationLayer={false}
                  renderTextLayer={false}
                  pageNumber={pageNumber}
                />
              ) : (
                Array.from(new Array(numPages), (el, index) => (
                  <Page
                    key={`page_${index + 1}`}
                    className="pdfPage"
                    renderAnnotationLayer={false}
                    renderTextLayer={false}
                    pageNumber={index + 1}
                  />
                ))
              )}
              <div className="upperLayer"></div>
              {showAlert ? 
              <div className="DisplayAlert">
                <CustomAlert message='Screen shots are restricted' type='Failed' />
              </div> : ''}
            </Document>
            
          </div>
        </div>
      )}
    </>
  );
}

export default PdfViewer;
