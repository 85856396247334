import React from 'react';
import './ButtonLoader.scss';

const ButtonLoader = () => {
  return (
    <div className="loader"></div>
  );
}

export default ButtonLoader;
