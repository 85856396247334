import './Login.scss';
import { useState } from 'react';
import BackGround from './Background/BackGround';
import Avatar from '../../assets/images/AvatarImage.svg';
import LoginForm from './LoginForm/LoginForm';
import ForgotPassword from './ForgotPassword/ForgotPassword';
import ResetPassword from './ResetPassword/ResetPassword';
import { useParams } from "react-router-dom";
import LicenceVerify from './LicenVerify/LicenceVerify';
import SetPassword from './SetPassword/SetPassword';

function Login() {

    return (
        <>
            <BackGround />
            <div className="LoginScreen">
                <div className="InnerContainer">
                    <div className="Slider">
                      <LoginForm />
                      <ForgotPassword />
                      <ResetPassword />
                      <LicenceVerify/>
                      <SetPassword/>
                    </div>
                    <div className='rightBar'>
                        <img src={Avatar} alt="" />
                    </div>
                </div>
            </div>
        </>
    );
}

export default Login;
