import React, { useEffect } from "react";
import "./DashBoard.scss";
import { useState, useRef } from "react";
import {
  data,
  barChartData,
  radialBarData,
  pieChartData,
  candidateDetails,
  verticalBarChartData,
  areaChartData,
} from "./image/datas";

import {
  candidateChecksCount,
  candidateStatuscountAmountSpent,
  fetchCandidateDetails,
  getBgvCount,
  getMonthlyBgvCount,
  overallTatPercentage,
  getCheckAverageDetails,
  CheckInteractivity,
} from "../../redux/actions/CompanyDashboardAction";
import { useDispatch, useSelector } from "react-redux";
import ButtonLoader from "../Loader/ButtonLoader";
import InteractivityDetails from "./InteractivityDetails";
import { useNavigate, useParams } from "react-router-dom";
import ChartsComp from "./DashBoardComponents/ChartsComp";
import CandidateDetailsList from "./DashBoardComponents/CandidateDetailsList";
import MainLoader from "../Loader/MainLoader";
import ButtonSpinner from "../ReusableComponents/ButtonSpinner";
import FullPageLoader from "../Loader/FullPageLoader";

function DashBoard() {
  const clientInfoID = localStorage.getItem("client_info_id");
  const masterID = localStorage.getItem("master_id");
  const dispatch = useDispatch();
  const [toggleBtn, setToggleBtn] = useState("invitation");
  const [addScroller, setAddScroller] = useState(false);
  // const [selection, setSelection] = useState('All');
  const [selections, setSelections] = useState({
    candidate_details: "All",
    tat_overview: "All",
    status_count: "All",
  });
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [requestPage, setRequestPage] = useState(1);
  const [itemsPerPage, setItemsPerPage] = useState(25); // Default items per page
  const [tatOverviewResp, setTatOverviewResp] = useState({
    negative_per: 0,
    positive_per: 0,
  });

  const [tatOverviewstartDate, settatOverviewStartDate] = useState("");
  const [tatOverviewendDate, settatOverviewEndDate] = useState("");
  const [statusCountStartDate, setStatusCountStartDate] = useState("");
  const [statusCountEndDate, setStatusCountEndDate] = useState("");
  const [statusCountKey, setStatusCountKey] = useState("all");
  const [bgvCountStartDate, setBgvCountStartDate] = useState("");
  const [bgvCountEndDate, setBgvCountEndDate] = useState("");
  const [value, setValue] = useState({ id: "", value: "" });
  const [checksCountdata, setChecksCountData] = useState([]);
  const [apiCount, setApiCount] = useState([]);
  const [statusCountResp, setStatusCountResp] = useState({});
  const [monthlyBgvYear, setMonthlyBgvYear] = useState(
    new Date().getFullYear()
  ); // Default to current year
  const [monthlyBgvResp, setMonthlyBgvResp] = useState([]);
  const [bgvCountResp, setBgvCountResp] = useState([]);
  const [CheckStatus, setCheckStatus] = useState({
    check_status: "",
    check_code: "",
  });
  const [Modal, setModal] = useState(false);
  const [dateDropDown, setDropDown] = useState({
    TAT: false,
    AmountSpent: false,
    status: false,
    packDetails: false,
    ACC: false,
    candidateDetails: false,
  });
  const scrollDivRef = useRef(null);

  const handleScroll = (name) => {
    if (scrollDivRef.current) {
      scrollDivRef.current.scrollTo({
        left: scrollDivRef.current.scrollLeft + (name === "right" ? 160 : -160),
        behavior: "smooth",
      });
    }
  };

  const getCheckAverageDetailsLoading = useSelector((state) => state.getCheckAverageDetails.loading) 

  const candidateChecksCountLoading = useSelector(
    (state) => state.candidateChecksCount?.loading
  );
  const CheckInteractivityLoading = useSelector(
    (state) => state.CheckInteractivity?.loading
  );
  const CheckInteractivityData = useSelector(
    (state) => state.CheckInteractivity?.data?.data?.status_Data
  );
  // const candidateStatuscountAmountSpentLoading = useSelector((state) => state.candidateStatuscountAmountSpent.loading);
  // const [dateDropDown, setDropDown] = useState({TAT: false, AmountSpent: false, status: false, packDetails: false, ACC: false, candidateDetails:false});
  const navigate = useNavigate();

  const {status, serviceid} = useParams()

  const [InteractivityCheckName, setInteractivityCheckName] = useState('')
  const [InteractivityCheckStatus, setInteractivityCheckStatus] = useState('')

  useEffect(() => {
    if (CheckStatus.check_status && CheckStatus.check_code) {
      setModal(false)
      getCheckInteractivity();
    }
  }, [CheckStatus]);

  useEffect(() => {
    if (status && serviceid) {
      setModal(true)
      getCheckInteractivity();
    }
  }, [status, serviceid]);

  const ModalTrigger = () => {
    setModal(!Modal);
    setCheckStatus('')
  };

  const getCheckInteractivity = () => {
    if (!masterID || !clientInfoID) {
      return;
    }
    const data = {
      master_id: masterID,
      client_info_id: clientInfoID,
      check_status: CheckStatus?.check_status || status,
      check_code: CheckStatus?.check_code || serviceid,
      user_status: "",
      limits: 0,
      offset: 0,
    };

    const successFn = (response) => {
      if (response.data?.status_Data.length > 0) {
        setModal(true);
      }
    };

    const failureFn = (error) => {
      alert(error);
    };

    dispatch(CheckInteractivity({ data, successFn, failureFn }));
  };

  useEffect(() => {
    setValue((prev) => ({
      id: pieChartData[0].id,
      value: pieChartData[0].value,
    }));
  }, [pieChartData]);

  const handleInteractivityClick = (checkstatus, checkcode, checkQty) => {

    if (checkQty !== 0) {
      if (checkstatus && checkcode) {
        setCheckStatus({
          check_status: checkstatus,
          check_code: checkcode,
        });
        navigate(`${checkstatus}/${checkcode}`)
      }
    } else return;
  };

  // useEffect(() => {
  //   fetchCandidateDashboard();
  // }, [startDate, endDate, itemsPerPage, requestPage, toggleBtn]);

  useEffect(() => {
    fetchTatOverview();
  }, [tatOverviewendDate, tatOverviewstartDate]);

  const fetchTatOverview = () => {
    let key = "all";
    if (tatOverviewendDate && tatOverviewstartDate !== "") {
      key = "";
    }

    const offset = (requestPage - 1) * itemsPerPage;

    const data = {
      client_info_id: clientInfoID,
      master_id: masterID,
      start_date: tatOverviewstartDate,
      end_date: tatOverviewendDate,
    };

    const successFn = (response) => {
      console.log("TAT Overview data fetched successfully", response.data);
      setTatOverviewResp({
        negative_per: response.data.negative_per,
        positive_per: response.data.positive_per,
      });
    };

    const failureFn = (error) => {
      console.error("Data fetching failed", error);
      alert(error);
    };

    dispatch(overallTatPercentage({ data, successFn, failureFn }));
  };

  useEffect(() => {
    fetchChecksCount();
  }, []);

  const fetchChecksCount = () => {
    if (!clientInfoID && !masterID) {
      return;
    }

    const data = {
      client_info_id: clientInfoID,
      master_id: masterID,
    };

    const successFn = (response) => {
      console.log("Check count fetched", response.data);
      // Transform the response data
      const transformedData = Object.keys(response.data).map((key) => ({
        checkName: key,
        pending: response.data[key].pending,
        completed: response.data[key].completed,
        serviceprovider_id: response.data[key].serviceprovider_id,
      }));

      // Update the state with the transformed data
      setChecksCountData(transformedData);
    };

    const failureFn = (error) => {
      console.error("Data fetching failed", error);
      alert(error);
    };

    dispatch(candidateChecksCount({ data, successFn, failureFn }));
  };

  useEffect(() => {
    const fetchCandidatesStatusCount = () => {
      if (!clientInfoID && !masterID) {
        return;
      }

      const data = {
        client_info_id: clientInfoID,
        key: statusCountKey,
        from_date: statusCountStartDate,
        to_date: statusCountEndDate,
      };

      const successFn = (response) => {
        console.log("status count fetched", response.data?.candidate_status_count);
        // Transform the response data
        setStatusCountResp(response.data?.candidate_status_count);
        // setApiCount(response.data?.apicheck_count)
      };

      const failureFn = (error) => {
        console.error("Data fetching failed", error);
        alert(error);
      };

      dispatch(candidateStatuscountAmountSpent({ data, successFn, failureFn }));
    };

    fetchCandidatesStatusCount();
  }, [statusCountStartDate, statusCountEndDate]);

  useEffect(() => {
    const fetchChecksAverageDetails = () => {
      if (!clientInfoID && !masterID) {
        return;
      }

      const data = {
        client_id: clientInfoID,
        master_id: masterID,
      };

      const successFn = (response) => {
        console.log("status count fetched", response.data);
        // Transform the response data
        setApiCount(response.data);
      };

      const failureFn = (error) => {
        console.error("Data fetching failed", error);
        alert(error);
      };

      dispatch(getCheckAverageDetails({ data, successFn, failureFn }));
    };

    fetchChecksAverageDetails();
  }, []);

  const handleYearChange = (value) => {
    // setMonthlyBgvYear(e.target.value);
    setMonthlyBgvYear(value);
  };
  useEffect(() => {
    const fetchMonthlyBgvCount = () => {
      if (!clientInfoID) {
        return;
      }

      const data = {
        client_info_id: clientInfoID,
        year: [monthlyBgvYear], // Pass the year as an array
      };

      const successFn = (response) => {
        console.log("status count fetched", response.data);
        const transformedData = [
          {
            id: monthlyBgvYear, // Label for the dataset
            color: "hsl(131, 70%, 50%)",
            data: Object.entries(response.data[monthlyBgvYear]).map(
              ([month, value]) => ({
                x: month.toLowerCase(),
                y: value,
              })
            ),
          },
        ];

        // Update the state with the transformed data
        setMonthlyBgvResp(transformedData);
      };

      const failureFn = (error) => {
        console.error("Data fetching failed", error);
        alert(error);
      };

      dispatch(getMonthlyBgvCount({ data, successFn, failureFn }));
    };

    fetchMonthlyBgvCount();
  }, [monthlyBgvYear]);

  useEffect(() => {
    const fetchBgvCount = () => {
      if (!clientInfoID) {
        return;
      }

      const data = {
        client_info_id: clientInfoID,
        from_date: bgvCountStartDate,
        to_date: bgvCountEndDate,
      };

      const successFn = (response) => {
        console.log("status count fetched", response.data);
        // Transform the response data
        const transformedData = Object.keys(response.data).map((key) => ({
          country: key,
          Pack: response.data[key],
          // "color": "hsl(64, 70%, 50%)"  // You can generate dynamic colors here if needed
        }));

        // Set the transformed data to state
        setBgvCountResp(transformedData);
      };

      const failureFn = (error) => {
        console.error("Data fetching failed", error);
        alert(error);
      };

      dispatch(getBgvCount({ data, successFn, failureFn }));
    };

    fetchBgvCount();
  }, [bgvCountStartDate, bgvCountEndDate]);

  const handleWheel = (e) => {
    if(e.deltaY > 0) {
      e.currentTarget.scrollLeft += e.currentTarget.clientWidth/4
    } else {
      e.currentTarget.scrollLeft -= e.currentTarget.clientWidth/4
    }
  }

  return (
    <div className="dashBoardBg">
      {Modal && (
        <section className="LoadContainer">
          {CheckInteractivityLoading ? (
            <FullPageLoader />
          ) : (
            !CheckInteractivityLoading &&
            status &&
            serviceid && (
              <div className="InteractivityModal">
                <InteractivityDetails
                  ModalTrigger={ModalTrigger}
                  data={CheckInteractivityData}
                />
              </div>
            )
          )}
        </section>
      )}
      {!Modal && (
        <>
          <div className="statusContainer">
            <h1>Check Status</h1>
            {candidateChecksCountLoading ? (
              <div className="LoadingContainer">
                <MainLoader scale={0.5} />
              </div>
            ) : (
              <>
                {checksCountdata?.length > 0 && (
                  <div
                    onMouseLeave={() => setAddScroller(false)}
                    onMouseOver={() => setAddScroller(true)}
                    className={`statusAnimate ${addScroller && "removeMask"}`}
                  >
                    <div
                      onWheel={handleWheel}
                      className={`${
                        addScroller ? "scrollerDiv" : "animationDiv"
                      } ${CheckInteractivityLoading ? "animationDivStop" : ""}`}
                      // className="animationDiv"
                      ref={scrollDivRef}
                    >
                      {checksCountdata.map((val, idx) => (
                        <div key={idx} className="statusBox">
                          <p title={val.checkName}>{val.checkName}</p>
                          <div className="statusBar">
                            <div>
                              <span className="pending"></span>
                              <p
                                className={`status ${
                                  val?.pending !== 0 ? "Interact" : ""
                                }`}
                                onClick={() => {
                                  handleInteractivityClick(
                                    "pending",
                                    val?.serviceprovider_id,
                                    val?.pending
                                  );
                                  setInteractivityCheckName(val.checkName);
                                  setInteractivityCheckStatus("pending");
                                }}
                              >
                                Pending -{" "}
                                {CheckInteractivityLoading &&
                                InteractivityCheckName === val.checkName &&
                                InteractivityCheckStatus === "pending" ? (
                                  <ButtonSpinner size={0.5} />
                                ) : (
                                  val.pending
                                )}
                              </p>
                            </div>
                            <div>
                              <span className="completed"></span>
                              <p
                                className={`status ${
                                  val?.completed !== 0 ? "Interact" : ""
                                }`}
                                onClick={() => {
                                  handleInteractivityClick(
                                    "completed",
                                    val?.serviceprovider_id,
                                    val?.completed
                                  );
                                  setInteractivityCheckName(val.checkName);
                                  setInteractivityCheckStatus("completed");
                                }}
                              >
                                Completed -{" "}
                                {CheckInteractivityLoading &&
                                InteractivityCheckName === val.checkName &&
                                InteractivityCheckStatus === "completed" ? (
                                  <ButtonSpinner size={0.5} />
                                ) : (
                                  val.completed
                                )}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    <div
                      className={`${
                        addScroller ? "duplicateDiv" : "animationDiv"
                      } ${CheckInteractivityLoading ? "animationDivStop" : ""}`}
                      // className="animationDiv"
                    >
                      {checksCountdata.map((val, idx) => (
                        <div key={idx} className="statusBox">
                          <p title={val.checkName}>{val.checkName}</p>
                          <div className="statusBar">
                            <div>
                              <span className="pending"></span>
                              <p
                                className="status"
                                onClick={() => {
                                  handleInteractivityClick(
                                    "pending",
                                    val?.serviceprovider_id,
                                    val?.pending
                                  );
                                }}
                              >
                                Pending -{" "}
                                {CheckInteractivityLoading &&
                                InteractivityCheckName === val.checkName &&
                                InteractivityCheckStatus === "pending" ? (
                                  <ButtonSpinner size={0.5} />
                                ) : (
                                  val.pending
                                )}
                              </p>
                            </div>
                            <div>
                              <span className="completed"></span>
                              <p
                                className="status"
                                onClick={() => {
                                  handleInteractivityClick(
                                    "completed",
                                    value?.serviceprovider_id,
                                    value?.completed
                                  );
                                }}
                              >
                                {`Completed - ${
                                  CheckInteractivityLoading &&
                                  InteractivityCheckName === val.checkName &&
                                  InteractivityCheckStatus === "completed" ? (
                                    <ButtonSpinner size={0.5} />
                                  ) : (
                                    val.completed
                                  )
                                }`}
                              </p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                    {addScroller && (
                      <div className="leftBtn">
                        <button onClick={() => handleScroll('left')}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.25rem"
                            height="1.25rem"
                            viewBox="0 0 38 38"
                            fill="none"
                          >
                            <path
                              d="M29.5557 16.2555H13.5113L20.4779 9.28884C21.3224 8.4444 21.3224 7.17773 20.4779 6.33328C19.6335 5.48884 18.3668 5.48884 17.5224 6.33328L6.96683 16.8888C6.12238 17.7333 6.12238 19 6.96683 19.8444L17.5224 30.4C18.3668 31.2444 19.6335 31.2444 20.4779 30.4C21.3224 29.5555 21.3224 28.2888 20.4779 27.4444L13.5113 20.4777H29.5557C30.8224 20.4777 31.6668 19.6333 31.6668 18.3666C31.6668 17.3111 30.8224 16.2555 29.5557 16.2555Z"
                              fill="var(--TextColor)"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                    {addScroller && (
                      <div className="rightBtn">
                        <button onClick={() => handleScroll('right')} style={{ transform: "rotate(180deg)" }}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="1.25rem"
                            height="1.25rem"
                            viewBox="0 0 38 38"
                            fill="none"
                          >
                            <path
                              d="M29.5557 16.2555H13.5113L20.4779 9.28884C21.3224 8.4444 21.3224 7.17773 20.4779 6.33328C19.6335 5.48884 18.3668 5.48884 17.5224 6.33328L6.96683 16.8888C6.12238 17.7333 6.12238 19 6.96683 19.8444L17.5224 30.4C18.3668 31.2444 19.6335 31.2444 20.4779 30.4C21.3224 29.5555 21.3224 28.2888 20.4779 27.4444L13.5113 20.4777H29.5557C30.8224 20.4777 31.6668 19.6333 31.6668 18.3666C31.6668 17.3111 30.8224 16.2555 29.5557 16.2555Z"
                              fill="var(--TextColor)"
                            />
                          </svg>
                        </button>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
          <div className="statusCharts">
            <ChartsComp
              setStartDate={setStartDate}
              setStatusCountStartDate={setStatusCountStartDate}
              setStatusCountEndDate={setStatusCountEndDate}
              settatOverviewStartDate={settatOverviewStartDate}
              tatOverviewResp={tatOverviewResp}
              setStatusCountKey={setStatusCountKey}
              setBgvCountStartDate={setBgvCountStartDate}
              settatOverviewEndDate={settatOverviewEndDate}
              setBgvCountEndDate={setBgvCountEndDate}
              setMonthlyBgvYear={setMonthlyBgvYear}
              statusCountResp={statusCountResp}
              monthlyBgvResp={monthlyBgvResp}
              setEndDate={setEndDate}
              apiCount={apiCount}
              bgvCountResp={bgvCountResp}
              setSelections={setSelections}
              setDropDown={setDropDown}
              dateDropDown={dateDropDown}
              getCheckAverageDetailsLoading={getCheckAverageDetailsLoading}
            />

            <CandidateDetailsList
              setSelections={setSelections}
              setStartDate={setStartDate}
              startDate={startDate}
              endDate={endDate}
              setEndDate={setEndDate}
              settatOverviewStartDate={settatOverviewStartDate}
              settatOverviewEndDate={settatOverviewEndDate}
              setStatusCountStartDate={setStatusCountStartDate}
              setStatusCountEndDate={setStatusCountEndDate}
              setStatusCountKey={setStatusCountKey}
              setBgvCountStartDate={setBgvCountStartDate}
              setBgvCountEndDate={setBgvCountEndDate}
              // candidateFetch={candidateFetch}
              // totalCandidate={totalCandidate}
              itemsPerPage={itemsPerPage}
              setItemsPerPage={setItemsPerPage}
              setDropDown={setDropDown}
              dateDropDown={dateDropDown}
            />
          </div>
        </>
      )}
    </div>
  );
}

export default DashBoard;
