import React, { useState } from "react";
import "./ForgotPassword.scss";
import logo from "../../../assets/images/Valiantt_logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { forgotPassword } from "../../../redux/actions/LoginAction";
import { useParams, useNavigate } from "react-router-dom";
import CustomAlert from "../../ReusableComponents/CustomAlert";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";

function ForgotPassword() {
  const forgotPasswordLoading = useSelector(
    (state) => state.forgotPassword.loading
  );
  const [userData, setUserData] = useState({
    user_email: "",
  });
  const [emailSent, setEmailsent] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { component } = useParams();
  const [customAlert, setCustomAlert] = useState(false);
  const [alertData, setAlertData] = useState({
    type: "",
    message: "",
    handleClose: () => setCustomAlert(false),
  });
  const handleChange = (e) => {
    setUserData({ ...userData, [e.target.name]: e.target.value });
  };

  const handleSubmit = () => {
    console.log("Submit button clicked!");
    if (!userData.user_email) {
      setCustomAlert(true);
      setAlertData({
        ...alertData,
        message: "Please enter username",
        type: "Failed",
      });
      return;
    }

    const data = {
      username: userData.user_email,
    };
    const successFn = (response) => {
      console.log("success", response);
      setEmailsent(true);
      if (emailSent) {
        setCustomAlert(true);
        setAlertData({
          ...alertData,
          message: "Email is sent to registered email id",
          type: "Success",
        });
      }
    };
    const failureFn = (response) => {
      setCustomAlert(true);
      setAlertData({ ...alertData, message: response, type: "Failed" });
    };

    dispatch(
      forgotPassword({
        data: data,
        successFn: successFn,
        failureFn: failureFn,
      })
    );
  };

  const handleKeyDown = (e) => {
    if(e.key === 'Enter'){
      handleSubmit(e);
    }
  }

  return (
    <div
      className={`forgotPass ${component === "forgot" ? "active" : "next"}`}
    >
      {customAlert && <CustomAlert {...alertData} />}
      {!emailSent ? (
        <>
          <div className="top">
            <img src={logo} alt="Valiant Logo" />
            <h1>Forgot Password?</h1>
            <p>No worries! Enter your username to reset it</p>
          </div>
          <div className="bottom">
            <input
              className="EmailInput"
              name="user_email"
              type="text"
              placeholder="Enter username"
              id="Email"
              onKeyDown={handleKeyDown}
              value={userData.user_email}
              onChange={handleChange}
            />
            <button type="button" onKeyDown={handleKeyDown} onClick={handleSubmit}>
              {forgotPasswordLoading ? <ButtonSpinner size={1}/> : "Next"}
            </button>
          </div>
        </>
      ) : (
        <>
          <div className="top">
            <img src={logo} alt="Valiant Logo" />
            <h1>Email Confirmation</h1>
            <p>
              We have sent an email to{" "}
              {userData?.user_email
                ? `${userData.user_email}'s registered email id. If you haven't received the verification email, kindly check your
              'Spam' or 'Junk Email' folder. To resend, click on 'Resend Email'`
                : "(Registered email id) "}
            </p>
          </div>
          <div className="bottom">
            <button type="submit" onClick={handleSubmit}>
              {forgotPasswordLoading ? <ButtonSpinner size={1}/> : "Resend mail"}
            </button>
            {/* <p className="Editemail" onClick={() => setEmailsent(!emailSent)}>
              Edit Username
            </p> */}
            <p className="Editemail" onClick={() => {setEmailsent(!emailSent); navigate('/')}}>
              Login
            </p>
          </div>
        </>
      )}
    </div>
  );
}

export default ForgotPassword;
