import { createAsyncThunk } from "@reduxjs/toolkit";
import { baseUrl } from "../../constant/baseurl";
import { apiCall } from "../../api/index";

const createAccountAsyncThunk = (method, type, contentType, url) =>
  createAsyncThunk(type, async (payload, thunkAPI) => {
    const { data, params } = payload;
    const { dispatch } = thunkAPI;
    console.log("data:::", data);

    let apiUrl = url;
    if (params) {
      Object.keys(params).forEach((key) => {
        apiUrl = apiUrl.replace(`{${key}}`, params[key]);
      });
    }

    const token = localStorage.getItem("token");
    const apiRequest = {
      method: method,
      headers: token
        ? {
            "Content-Type": contentType,
            Authorization: "Token " + token,
          }
        : { "Content-Type": contentType },
      url: `${baseUrl}${apiUrl}`,
      data,
    };

    try {
    
      const res = await apiCall(apiRequest, dispatch);
      const response = res.data;

      if (response.statusFlag === true) {
        console.log(`login action ------ ${type}`, response);
        return response;
      } else {
        console.error(`account action - ${type}`, response);

        // Check if there is an error code in the response
        const errorCode = response.message;

        // You can throw a custom error with the error code
        throw new Error(errorCode);
      }
    } catch (error) {
      throw error;
    }
  });

export const getPlan = createAccountAsyncThunk(
  "post",
  "getPlan",
  "application/json",
  "ClientPlanDetails/get_plan/"
);


export const candidateBeginVerificationUpdateOld = createAccountAsyncThunk(
    "post",
    "candidateBeginVerificationUpdateOld",
    "multipart/form-data",
    "CompanyCandidateRegister/candidate_begin_verification_update_old/"
  );


  export const candidateBeginVerificationOld = createAccountAsyncThunk(
    "post",
    "candidateBeginVerificationOld",
    "multipart/form-data",
    "CompanyCandidateRegister/candidate_begin_verification_old/"
  );


export const clientCancelOrderDetails = createAccountAsyncThunk(
    "post",
    "clientCancelOrderDetails",
    "application/json",
    "Client_billing_details/client_cancel_order_details/"
  );

  export const updateServiceProviderChecks = createAccountAsyncThunk(
    "post",
    "updateServiceProviderChecks",
    "application/json",
    "CompanyCandidateRegister/update_serviceprovider_checks/"
  );

  export const clientPaymentLinkGenerate = createAccountAsyncThunk(
    "post",
    "clientPaymentLinkGenerate",
    "multipart/form-data",
    "client_payment_service/client_payment_link_generate/"
  );

  export const fetchExistingUser = createAccountAsyncThunk(
    "post",
    "fetchExistingUser",
    "application/json",
    "client_payment_service/fetch_existing_user/"
  );

  export const fetchOrderDetails = createAccountAsyncThunk(
    "get",
    "fetchOrderDetails",
    "application/json",
    "client_payment_service/fetch_order_details/{order_id}"
  );







