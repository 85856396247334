import React, { useEffect, useState } from "react";
import "./UpdateModal.scss";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  getPlan,
  updateServiceProviderChecks,
} from "../../../redux/actions/CompanyBeginVerificationAction";
import CustomAlert from "../../ReusableComponents/CustomAlert";
import ButtonSpinner from "../../ReusableComponents/ButtonSpinner";
import FullPageLoader from "../../Loader/FullPageLoader";

const UpdateModal = ({ cid, handleCancel, fetchDashboard }) => {
  const clientInfoID = localStorage.getItem("client_info_id");
  const [planDetailsResp, setPlanDetailsResp] = useState({});
  const [filteredPlans, setFilteredPlans] = useState({});
  const [selectedPlan, setSelectedPlan] = useState("");

  const [searchTerm, setSearchTerm] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [SuccessFailed, setSuccessFailed] = useState("Success");
  const [SuccessFailedMessage, setSuccessFailedMessage] = useState("");
  const [Alert, setAlert] = useState(false);
  const [Cancel, setCancel] = useState(false);
  const [customAmount, setCustomAmount] = useState(0);
  const [totalAmount, setTotalAmount] = useState("");
  const [selectedCustomItems, setSelectedCustomItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [userChecks, setUserChecks] = useState([]);
  const [bgvCode, setBgvCode] = useState("");

  const updateChecksLoading = useSelector(
    (state) => state.updateServiceProviderChecks.loading
  );
  const companyPlansLoading = useSelector((state) => state.getPlan.loading);

  useEffect(() => {
    fetchPlanDetails();
  }, [clientInfoID]);

  const fetchPlanDetails = () => {
    const data = {
      client_info_id: clientInfoID,
    };

    const successFn = (response) => {
      console.log("Plan fetched successfully", response.data);
      setPlanDetailsResp(response.data);
      // setCustomPlan(response.data.Custompackage)
    };

    const failureFn = (error) => {
      console.error("Logged-in failed", error);
    };

    dispatch(getPlan({ data, successFn, failureFn }));
  };

  useEffect(() => {
    setFilteredPlans(
      Object.keys(planDetailsResp)
        .filter((key) => key.toLowerCase().includes(searchTerm.toLowerCase()))
        .reduce((obj, key) => {
          obj[key] = planDetailsResp[key];
          return obj;
        }, {})
    );
  }, [planDetailsResp, searchTerm]);

  const handlePlanSectionClick = (planName) => {
    const planItems = [];
    const TotalValue = planDetailsResp[planName].total_amount;
    setBgvCode(planName);
    // Check if the planName is not 'Custom_package'
    if (planName !== "Custom_package") {
      const plan = planDetailsResp[planName];
      planItems.push(...plan.checks.map((item) => item.serviceprovider_id));
    }
    setSelectedCustomItems([]);
    setSelectedItems(planItems);
    setSelectedPlan(planName);
    setTotalAmount(TotalValue);
    // setFormData((prev) => ({...prev, 'total_amount': TotalValue}))
    setCustomAmount(0);
  };

  useEffect(() => {
    setTotalAmount(customAmount);
  }, [customAmount]);

  const handleSuccessClose = () => {
    setAlert(false);
    setCancel(false);
    handleCancel();
    fetchDashboard();
  };
  const handleFailedClose = () => {
    setAlert(false);
  };

  const handleCustomPackageCheckboxChange = (
    e,
    serviceprovider_id,
    valiant_rate_tax
  ) => {
    const isChecked = e.target.checked;
    setSelectedPlan("Custom_package");
    setBgvCode("Custom package");
    setSelectedItems([]);
    if (isChecked) {
      setSelectedCustomItems((prevItems) => [...prevItems, serviceprovider_id]);
      setCustomAmount((prev) => prev + valiant_rate_tax);
    } else {
      setSelectedCustomItems((prevItems) =>
        prevItems.filter((id) => id !== serviceprovider_id)
      );
      setCustomAmount((prev) => prev - valiant_rate_tax);
    }
  };

  const handleUpdateCheck = () => {
    // alert("hi");
    console.log("########inside updatre", selectedPlan);
    // Null validation for form fields
    if (!selectedPlan && !bgvCode) {
      // console.log("inside alert ggfhgfg")
      alert("Please select any plan.");
      return;
    }
    if (selectedPlan === "Custom_package") {
      if (selectedCustomItems.length < 1) {
        alert("Please select any plan.");
        return;
      }
    }

    let serviceProvidersList = [];
    if (selectedPlan === "Custom_package") {
      serviceProvidersList = selectedCustomItems;
    } else {
      serviceProvidersList = selectedItems;
    }
    const data = {
      candidate_id: cid,
      client_info_id: clientInfoID,
      update_check_id: serviceProvidersList,
      bgv_code: bgvCode,
    };

    // Dispatch action with form data
    const successFn = (response) => {
      console.log("candidate verification", response.data);
      setSuccessFailed("Success");
      setSuccessFailedMessage(response.message);
      setAlert(true);
    };
    const failureFn = (err) => {
      console.log("Logged-In Failed");
      setSuccessFailed("Failed");
      setSuccessFailedMessage(err);
      setAlert(true);
    };
    dispatch(updateServiceProviderChecks({ data, successFn, failureFn }));
  };

  return (
    <>
      {Cancel && (
        <section className='VeriSavedContainer'>
          <div className='VeriSaved'>
            <div className='SuccessBeginVerification'>
              <img
                // src={CancelInvite}
                alt='SuccessBeginVerification'
              />
            </div>
            <h1>Cancel Invitation</h1>
            <span className='Hint'>
              Hey, are you sure you want to cancel this invite of{" "}
              {/* <b>{CancelCandidateName}</b>?<br /> */}
              Once it's gone, it's gone forever! Make your decision wisely!
            </span>
            <div className='ButtonContainer'>
              <button
                className='Cancel'
                onClick={() => {
                  setCancel(false);
                }}>
                Keep Invitation
              </button>
              <button
                className='Done'
                onClick={() => {
                  // handleCancelClick();
                }}>
                Cancel Invitation
              </button>
            </div>
          </div>
        </section>
      )}
      <section className='UpdateModal'>
        <div className='UpdateModalContainer'>
          <section className='SearchPacklist'>
            {/* <button type="button" onClick={() => {handleUpdateCheck()}}>Update</button> */}
            <input
              type='text'
              placeholder='Search'
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
            />{" "}
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='1.25rem'
              height='1.25rem'
              viewBox='0 0 24 24'
              fill='none'
              className='SearchIcon'>
              <path
                d='M21.7104 20.2914L18.0004 16.6114C19.4405 14.8158 20.1379 12.5367 19.9492 10.2427C19.7605 7.94867 18.7001 5.81415 16.9859 4.27801C15.2718 2.74188 13.0342 1.92088 10.7333 1.98384C8.43243 2.0468 6.24311 2.98893 4.61553 4.61651C2.98795 6.24409 2.04582 8.43341 1.98286 10.7343C1.9199 13.0352 2.7409 15.2727 4.27704 16.9869C5.81318 18.701 7.94769 19.7615 10.2417 19.9502C12.5357 20.1389 14.8148 19.4415 16.6104 18.0014L20.2904 21.6814C20.3834 21.7751 20.494 21.8495 20.6158 21.9003C20.7377 21.951 20.8684 21.9772 21.0004 21.9772C21.1324 21.9772 21.2631 21.951 21.385 21.9003C21.5068 21.8495 21.6174 21.7751 21.7104 21.6814C21.8906 21.4949 21.9914 21.2457 21.9914 20.9864C21.9914 20.727 21.8906 20.4778 21.7104 20.2914ZM11.0004 18.0014C9.61592 18.0014 8.26255 17.5908 7.1114 16.8217C5.96026 16.0525 5.06305 14.9592 4.53324 13.6802C4.00342 12.4011 3.8648 10.9936 4.1349 9.63574C4.40499 8.27787 5.07168 7.03059 6.05065 6.05162C7.02961 5.07265 8.27689 4.40597 9.63476 4.13587C10.9926 3.86578 12.4001 4.0044 13.6792 4.53421C14.9583 5.06403 16.0515 5.96123 16.8207 7.11238C17.5899 8.26352 18.0004 9.6169 18.0004 11.0014C18.0004 12.8579 17.2629 14.6384 15.9501 15.9511C14.6374 17.2639 12.8569 18.0014 11.0004 18.0014Z'
                fill='#102C98'
              />
            </svg>
            <div className='PackContWrapper'>
              {companyPlansLoading ?
                (
                  <section className='UpdateLoaderContainer'>
                    <FullPageLoader/>
                  </section>
                )
                :
                (
                  <article className='PackContainer'>
                    {Object.entries(filteredPlans)
                      .filter(([key, _]) => key !== "Custom package")
                      .map(([key, values], index) => (
                        <div
                          className={`Pack ${selectedPlan === key ? "Active" : ""}`}
                          key={index}
                          onClick={() => handlePlanSectionClick(key)}>
                          <h3>{key}</h3>
                          <span className='Amount'>₹ {values.total_amount}/-</span>
                          <span className='InclTax'>*Inclusive of all taxes</span>
                          {values.checks.map((data, dataIndex) => (
                            <div
                              className='Checks'
                              key={dataIndex}>
                              <svg xmlns="http://www.w3.org/2000/svg" width="0.75rem" height="0.75rem" viewBox="0 0 17 17" fill="none">
                                <path d="M8.5 0C3.80457 0 0 3.80457 0 8.5C0 13.1954 3.80457 17 8.5 17C13.1954 17 17 13.1954 17 8.5C17 3.80457 13.1954 0 8.5 0ZM7.18822 11.5118C7.09014 11.6099 6.9512 11.6916 6.82861 11.6916C6.70601 11.6916 6.56707 11.6058 6.4649 11.5077L4.17644 9.21923L4.90385 8.49183L6.83269 10.4207L11.9327 5.28389L12.6478 6.02356L7.18822 11.5118Z" fill="var(--AccentColor)"/>
                              </svg>
                              <span>{data.display_name}</span>
                            </div>
                          ))}
                        </div>
                      ))}

                    <div
                      className={`Pack ${
                        selectedPlan === "Custom package" ? "Active" : ""
                      }`}>
                      <h3>Custom package</h3>
                      <span className='Amount'>₹ {customAmount?.toFixed(2)}/-</span>
                      <span className='InclTax'>*Inclusive of all taxes</span>
                      {planDetailsResp["Custom package"]?.map((data, dataIndex) => (
                        <label
                          className='Checks'
                          key={dataIndex}>
                          <input
                            type='checkbox'
                            onChange={(e) =>
                              handleCustomPackageCheckboxChange(
                                e,
                                data.serviceprovider_id,
                                data.valiant_rate_tax
                              )
                            }
                            checked={selectedCustomItems.includes(
                              data.serviceprovider_id
                            )}
                          />
                          <span title={data.display_name}>{data.display_name}</span>
                        </label>
                      ))}
                    </div>
                  </article>
                )
              }
            </div>
          </section>
          <div className='ButtonContainer'>
            <button
              className='Cancel'
              onClick={handleCancel}>
              Cancel
            </button>
            <button
              onClick={() => {
                handleUpdateCheck();
              }}>
              {updateChecksLoading ? (
                <ButtonSpinner />
              ) : (
                <>
                  Proceed
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='1.25rem'
                    height='1rem'
                    viewBox='0 0 20 15'
                    fill='none'>
                    <path
                      d='M1.5 6.5C0.947715 6.5 0.5 6.94772 0.5 7.5C0.5 8.05228 0.947715 8.5 1.5 8.5L1.5 6.5ZM19.2071 8.20711C19.5976 7.81658 19.5976 7.18342 19.2071 6.79289L12.8431 0.428932C12.4526 0.0384078 11.8195 0.0384078 11.4289 0.428932C11.0384 0.819457 11.0384 1.45262 11.4289 1.84315L17.0858 7.5L11.4289 13.1569C11.0384 13.5474 11.0384 14.1805 11.4289 14.5711C11.8195 14.9616 12.4526 14.9616 12.8431 14.5711L19.2071 8.20711ZM1.5 8.5L18.5 8.5V6.5L1.5 6.5L1.5 8.5Z'
                      fill='white'
                    />
                  </svg>
                </>
              )}
            </button>
          </div>
        </div>
      </section>

      {Alert && (
        <CustomAlert
          type={SuccessFailed}
          message={SuccessFailedMessage}
          handleClose={
            SuccessFailed === "Success" ? handleSuccessClose : handleFailedClose
          }
        />
      )}
    </>
  );
};

export default UpdateModal;
